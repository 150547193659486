import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { inviteToPayment } from "../../actions/user";

const InviteHousekeeperToPay = (props) => {
  const { id, cleaning, closeDialog, inviteToPayment } = props;

  const handleDialogClose = (type) => {
    if (type === "confirm") {
      if (props && props.id) {
        inviteToPayment(id);
        closeDialog();
      }
    } else if (type === "cancel") {
      closeDialog();
    }
  };

  return (
    <>
      <DialogTitle
        id="scroll-dialog-title"
        className="assign-housekeeper__title"
      >
        Invite your housekeeper
      </DialogTitle>
      <DialogContent dividers={true}>
        Invite housekeeper to pay using Automatebnb
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleDialogClose("cancel")}
          variant="contained"
          disableElevation
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleDialogClose("confirm")}
          variant="contained"
          color="primary"
          disableElevation
        >
          Invite
        </Button>
      </DialogActions>
    </>
  );
};

export default connect(
  (state) => ({}),
  (dispatch) => bindActionCreators({ inviteToPayment }, dispatch)
)(InviteHousekeeperToPay);
