import React, { useEffect } from "react";
import { isEmpty, property } from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Moment from "react-moment";

// Material
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HelpIcon from "@material-ui/icons/Help";

import Navigation from "../containers/navigation";
import ItemsCount from "../components/itemsCount";
import Pagination from "../containers/pagination";

import { fetchOpsClientProperties } from "../actions/ops";
import { getOpsClientProperties } from "../reducers/clients";
import LoadingScreen from "../components/loadingScreen";
import { getFormattedCheckInOutTime } from "../helpers/format";

import { OPERATIONS_CLIENTS } from "../routes";
import "./clientsProperty.scss";

const HELP_ARTICLE_AUTOASSIGNED =
  "https://automatebnb.notion.site/Auto-assignments-acd45acf5e744b099d75b9923074c068";

const OpsClientProperties = (props) => {
  const {
    fetchOpsClientProperties,
    location: { state: locationState = {} },
    history,
    properties = {},
  } = props;

  const { isLoading = false, items = {} } = properties;
  const {
    id: clientId = null,
    first = "",
    last = "",
    phone = null,
    client_since,
    total_assigned_properties: totalAssignedProperties = null,
  } = locationState;

  useEffect(() => {
    fetchOpsClientProperties(clientId);
  }, []);

  const RenderProperties = () => {
    if (isEmpty(items)) {
      return <EmptyState />;
    } else {
      return (
        <Table aria-label="simple table" className="table">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                Atuo-assigned properties
                <IconButton
                  color="primary"
                  aria-label="Help"
                  component="span"
                  onClick={() =>
                    window.open(HELP_ARTICLE_AUTOASSIGNED, "_blank")
                  }
                  style={{ padding: "0" }}
                >
                  <HelpIcon
                    size="small"
                    style={{ opacity: 0.7, width: "0.8em", marginLeft: "4px" }}
                  />
                </IconButton>
              </TableCell>
              <TableCell align="left">Address</TableCell>
              <TableCell align="left">Check in time</TableCell>
              <TableCell align="left">Check out time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="--bk-white">
            {items.map((property) => {
              const {
                id,
                title,
                zip,
                street,
                state,
                city,
                check_in_time,
                check_out_time,
              } = property;

              return (
                <TableRow key={id} hover={false}>
                  <TableCell component="th" scope="row">
                    {title}
                  </TableCell>
                  <TableCell align="left">{`${street} ${city}, ${state} ${zip}`}</TableCell>
                  <TableCell component="th" scope="row">
                    {getFormattedCheckInOutTime(check_in_time)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {getFormattedCheckInOutTime(check_out_time)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      );
    }
  };

  const EmptyState = () => {
    return (
      <div className="empty-state">
        <div className="text-center mt3" style={{ width: "440px" }}>
          <p className="mb1">
            It appears that currently, you are not Auto-assigned to any of the
            properties that{" "}
            <span className="semibold">{`${first} ${last}`}</span> onboarded on
            the platform.
          </p>
          <a href={HELP_ARTICLE_AUTOASSIGNED} target="_blank">
            Learn more about Auto-assignment
          </a>
        </div>
      </div>
    );
  };

  const ClientsProperties = () => {
    if (isLoading) {
      return <LoadingScreen />;
    } else {
      return (
        <div className="page-wrapper">
          <div className="page-content">
            <div className="clients-property">
              <div className="clients-property__hero">
                <div className="inline-flex align-center">
                  <IconButton
                    aria-label="Go back"
                    size="medium"
                    color="primary"
                    onClick={() => history.push(OPERATIONS_CLIENTS)}
                  >
                    <ArrowBackIcon fontSize="inherit" />
                  </IconButton>
                  <h3
                    className="header-3"
                    style={{ margin: 0 }}
                  >{`${first} ${last}`}</h3>
                </div>
                <div>
                  <div className="inline-flex">
                    <div className="clients-property__meta">
                      <p className="clients-property__label">Phone</p>
                      <p className="clients-property__body">{phone}</p>
                    </div>
                    <div className="clients-property__meta">
                      <p className="clients-property__label">Customer since</p>
                      <p className="clients-property__body">
                        <Moment format="MMMM DD, YYYY">{client_since}</Moment>
                      </p>
                    </div>
                    <div className="clients-property__meta">
                      <p className="clients-property__label">
                        Auto-assigned properties
                      </p>
                      <p className="clients-property__body">
                        {totalAssignedProperties ? (
                          `${totalAssignedProperties} properties`
                        ) : (
                          <div>None</div>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clients-property__body">
                <RenderProperties />
              </div>
              <div className="intercom-spacer" />
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <Navigation />
      <ClientsProperties />
    </>
  );
};

export default connect(
  (state) => ({
    properties: getOpsClientProperties(state),
  }),
  (dispatch) =>
    bindActionCreators(
      { fetchOpsClientProperties, getOpsClientProperties },
      dispatch
    )
)(OpsClientProperties);
