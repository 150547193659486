import React from "react";
import Link from "@material-ui/core/Link";
import { APP } from "../routes";
import Navigation from "../containers/navigation";

const NotFound = () => {
  return [
    <Navigation key="appbar-navigation-not-found-page" />,
    <div
      className="full-page flex align-center justify-center"
      key="not-found-page"
    >
      <div>
        <h1 className="header-1">Oops!</h1>
        <h3 className="header-3">
          We can't seem to find the page you're looking for.
        </h3>
        <b>Error code: 404</b>
        <p>Here are some helpful links instead:</p>
        <div>
          <Link href={APP}>Home</Link>
        </div>
        <Link href="https://automatebnb.notion.site/automatebnb/Automatebnb-Help-Center-fb870801dca94dddbde79a02b74cfe4f">
          Help
        </Link>
      </div>
    </div>,
  ];
};

export default NotFound;
