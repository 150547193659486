import React from "react";
import { isEmpty } from "lodash";

// Enum || Constants
import {
  CLEANER_STATUS_DECLINED,
  CLEANER_STATUS_ACCEPTED,
  CLEANER_STATUS_COMPLETED,
} from "../constants/apiEnum";

import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";

import "../containers/jobAssignedPros.scss";

function getClassName(status) {
  if (status === CLEANER_STATUS_ACCEPTED) {
    return "pro-avatar__border--success";
  } else if (status === CLEANER_STATUS_DECLINED) {
    return "pro-avatar__border--danger";
  } else if (status === CLEANER_STATUS_COMPLETED) {
    return "pro-avatar__border--completed";
  } else {
    return "pro-avatar__border--grey";
  }
}

export default ({ assignments, size = "regular" }) => {
  const avatarStyles =
    size === "small" ? { width: "32px", height: "32px" } : {};

  return (
    <AvatarGroup max={3}>
      {assignments.map((assignment) => {
        const pro = assignment.pro;
        return (
          <Avatar
            alt={`${pro.first} ${pro.last}`}
            key={`${pro.id}`}
            className={`pro-avatar pro-avatar__border ${getClassName(
              assignment.status
            )}`}
            style={avatarStyles}
          >
            {Array.from(`${pro.first}`)[0]}
            {Array.from(`${pro.last}`)[0]}
          </Avatar>
        );
      })}
    </AvatarGroup>
  );
};
