import React, { useState, useEffect } from "react";
import axios from "../helpers/axios";
import { isEmpty } from "lodash";
import amplitude from "amplitude-js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Material-ui Componetns
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import { Typography, useMediaQuery } from "@material-ui/core";
import FormatQuoteRoundedIcon from "@material-ui/icons/FormatQuoteRounded";

// Actions
import { registerHost } from "../actions/pmsUser";

// Helpers
import normalizePhone from "../helpers/helper_normalize-phone";
import history from "../helpers/history";
import { parseQuery } from "../helpers/url";
import { validateStrongPassword } from "../helpers/strongPassword";

// Constants
import { emailRegex } from "../constants/validate";
import { JOBS, LOG_IN, PMS_PROPERTIES_NEW } from "../routes";

// Reducers
import { getUserToken } from "../reducers/user";

import "./publicPages.scss";
import {
  EmailTextField,
  GeneralTextField,
  PasswordField,
  PhoneNumberField,
} from "../containers/accountFields";
import Navigation from "../containers/navigation";

const TESTIMONIAL_URL =
  "https://automatebnb-testimonies.s3.us-west-1.amazonaws.com/testimonial.json";

const HostSignup = (props) => {
  const { registerHost, userToken } = props;

  const passwordRef = React.useRef(null);
  const emailRef = React.useRef(null);
  const phoneRef = React.useRef(null);
  const firstNameRef = React.useRef(null);
  const lastNameRef = React.useRef(null);

  const { email: queryEmail, invite_code } = parseQuery();

  const [isDisabled, setDisabled] = useState(true);
  const [isInvalidPassword, setIsInvalidPassword] = useState(false);
  const [errors, setErrors] = useState({
    first: false,
    last: false,
    phone: false,
    email: false,
    password: false,
  });

  const [values, setValues] = useState({
    first: "",
    last: "",
    phone: "",
    email: queryEmail || "",
    password: "",
    inviteCode: invite_code,
  });

  const isMobile = useMediaQuery("(max-width:600px)");

  let testimony = {
    name: "Peter",
    reference: {
      name: "Trustpilot",
      url: "https://www.trustpilot.com/review/automatebnb.com",
      image: "",
    },
    profile:
      "https://automatebnb-testimonies.s3.us-west-1.amazonaws.com/trustpilot_peter.png",
    body:
      "I recently started using Automatebnb for managing my vacation rental properties and I couldn't be happier! The platform streamlines all aspects of cleaning management from scheduling cleanings to processing payments. With the option for customizable notifications, I never miss a cleaning or important update. The user-friendly app makes it easy for me to monitor my cleanings from anywhere, at any time.",
  };

  const [testimonyJSONData, setJSONData] = useState(testimony);

  useEffect(() => {
    const handleInputEvent = (event) => {
      const { name, value } = event.target;
      // Handle password validation when browser prefills value
      if (name == "password") {
        const isValidPassword = validateStrongPassword(value);
        setIsInvalidPassword(!isValidPassword);
      }

      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    };

    const elements = [
      emailRef.current,
      passwordRef.current,
      phoneRef.current,
      firstNameRef.current,
      lastNameRef.current,
    ];

    elements.forEach((element) => {
      if (element) {
        element.addEventListener("input", handleInputEvent);
        element.addEventListener("change", handleInputEvent);
      }
    });

    // Clean up event listeners on component unmount
    return () => {
      elements.forEach((element) => {
        if (element) {
          element.removeEventListener("input", handleInputEvent);
          element.removeEventListener("change", handleInputEvent);
        }
      });
    };
  }, []);

  const isTablet = useMediaQuery("(max-width:800px)");

  const isValid = () => {
    return (
      !isEmpty(values.first) &&
      !isEmpty(values.last) &&
      !isEmpty(values.phone) &&
      !isEmpty(values.email) &&
      !isEmpty(values.password)
    );
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    const isValidPassword = validateStrongPassword(value);
    setIsInvalidPassword(!isValidPassword);
    setValues({ ...values, password: value });
  };

  useEffect(() => {
    if (!isEmpty(userToken)) {
      if (userToken.is_host) {
        window.location.replace(`${PMS_PROPERTIES_NEW}?opu=onboarding`);
      }
    }
  }, [userToken]);

  useEffect(() => {
    axios
      .get(TESTIMONIAL_URL, {
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        const testimonials = response.data.testimonials;
        if (!isEmpty(testimonials)) {
          setJSONData(
            testimonials[Math.floor(Math.random() * testimonials.length)]
          );
        }
      })
      .catch((err) => console.log("err: ", err));
  }, []);

  if (localStorage.jwtToken) {
    history.replace(JOBS);
  }

  const stripFormatting = (phone) => {
    return phone.replace(/[^\d]/g, "");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let newVal = "";

    if (name === "email") {
      if (emailRegex.test(value)) {
        setErrors({ ...errors, [name]: false });
      } else {
        setErrors({ ...errors, [name]: true });
      }
      newVal = { ...values, [name]: value };
    } else if (name === "phone") {
      newVal = { ...values, [name]: stripFormatting(value) };
    } else {
      newVal = { ...values, [name]: value };
    }

    setValues(newVal);
  };

  useEffect(() => {
    if (isValid()) {
      if (isInvalidPassword || errors.email) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    } else if (!isValid() && !isDisabled) {
      setDisabled(true);
    }
  }, [values]);

  const handleSubmit = (value) => {
    if (values && isValid()) {
      registerHost(values);
    }
  };

  return (
    <>
      <Navigation type="public" />
      <div className="page-wrapper">
        <div
          className="px2 py3"
          style={{
            maxWidth: "450px",
            margin: "0 auto",
            backgroundColor: "white",
            marginTop: isMobile ? 0 : 24,
            marginBottom: 24,
            borderRadius: 8,
            border: "1px solid #dddddd",
          }}
        >
          <div className="form">
            {isEmpty(values.inviteCode) && (
              <>
                <Typography variant="h3">
                  $5<span className="header-3"> / property / month</span>
                </Typography>
                <Typography variant="body2">
                  Free 14-day trial. No Credit Card required.
                </Typography>
              </>
            )}
            <div className="inline-flex align-center mt2 mb3">
              <img
                src="https://automatebnb-assets.s3.us-west-1.amazonaws.com/icons8-usa-96.png"
                style={{ width: "22px" }}
                className="pr1"
              />{" "}
              Only available in U.S.A.
            </div>
            <div className="mb3">
              <GeneralTextField
                required
                name="first"
                inputRef={firstNameRef}
                autoFocus
                label="First name"
                handleChange={handleInputChange}
                value={values.first}
              />
            </div>
            <div className="mb3">
              <GeneralTextField
                required
                name="last"
                inputRef={lastNameRef}
                autoFocus
                label="Last name"
                handleChange={handleInputChange}
                value={values.last}
              />
            </div>
            <div className="mb3">
              <PhoneNumberField
                required
                value={values.phone}
                handleChange={handleInputChange}
                error={errors.phone}
                inputRef={phoneRef}
              />
            </div>
            <div className="mb3">
              <EmailTextField
                name="email"
                label="Email"
                inputRef={emailRef}
                required
                handleChange={handleInputChange}
                disabled={!isEmpty(queryEmail)}
                helperText={
                  !isEmpty(queryEmail) &&
                  "You cannot change this since you were invited via this email. Please request an invitation using your preferred email if you want to make changes."
                }
                value={values.email}
              />
            </div>
            <div className="mb3">
              <PasswordField
                name="password"
                label="Password"
                inputRef={passwordRef}
                // handleChange={handlePasswordChange}
                onInput={handlePasswordChange}
                required
                value={values.password}
                error={isInvalidPassword}
                helperText="Password must be at least 6 characters and include numbers, letters, and uppercase letters."
              />
            </div>
            <p className="small">
              By signing up, you are agreeing to Automatebnb&apos;s{" "}
              <a
                href="https://www.automatebnb.com/terms"
                target="__new"
                className="link"
              >
                Terms and Conditions
              </a>{" "}
              and{" "}
              <a
                href="https://www.automatebnb.com/privacy"
                target="__new"
                className="link"
              >
                Privacy Policy
              </a>
              .
            </p>
            <div className="mt2">
              <Button
                onClick={() => handleSubmit()}
                fullWidth={true}
                disabled={isDisabled}
                color="secondary"
                variant="contained"
                disableElevation
              >
                Sign up
              </Button>
            </div>
            <p className="text-center mt2">
              Already a member? <Link href={LOG_IN}>Log In</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(
  (state) => ({ userToken: getUserToken(state) }),
  (dispatch) => bindActionCreators({ registerHost }, dispatch)
)(HostSignup);
