import {
  SET_PMS_MY_HOUSEKEEPERS,
  SET_PMS_MY_HOUSEKEEPER
} from "../actions/pmsMyHousekeepers";

export const getPMSMyHousekeepers = ({ pmsHousekeepers }) => pmsHousekeepers;
export const getPMSMyHousekeeper = ({ pmsMyHousekeeper }) => pmsMyHousekeeper;

export const pmsMyHousekeeper = (state = {}, action) => {
  switch (action.type) {
    case SET_PMS_MY_HOUSEKEEPER:
      return action.data;

    default:
      return state;
  }
};

export const pmsHousekeepers = (state = {}, action) => {
  switch (action.type) {
    case SET_PMS_MY_HOUSEKEEPERS:
      return action.data;

    default:
      return state;
  }
};
