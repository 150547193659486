import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { inviteToPayment } from "../actions/user";

const InviteProToPay = (props) => {
  const { proId: id, closeThisDialog, inviteToPayment } = props;

  const handleSubmit = (type) => {
    if (id) {
      inviteToPayment(id);
      closeThisDialog();
    } else {
      alert("Looks like Id is missing");
      closeThisDialog();
    }
  };

  return (
    <>
      <DialogTitle
        id="scroll-dialog-title"
        className="assign-housekeeper__title"
      >
        Invite your housekeeper
      </DialogTitle>
      <DialogContent dividers={true}>
        Invite housekeeper to pay using Automatebnb
      </DialogContent>
      <DialogActions>
        <Button onClick={closeThisDialog} variant="contained" disableElevation>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disableElevation
        >
          Invite
        </Button>
      </DialogActions>
    </>
  );
};

export default connect(
  (state) => ({}),
  (dispatch) => bindActionCreators({ inviteToPayment }, dispatch)
)(InviteProToPay);
