import { isEmpty, property } from "lodash";
import React, { useEffect } from "react";
import classnames from "classnames";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import Moment from "react-moment";
import moment from "moment";

// Material Components
import { Button, Chip, Typography } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import { useMediaQuery } from "@material-ui/core";
import LoopIcon from "@material-ui/icons/Loop";

// Local Components
import NormalizedCleaningStatus from "../components/normalizedCleaningStatus";
import LoadingScreen from "../components/loadingScreen";
import JobActions from "../containers/jobActions";
import SameDayFlip from "../components/sameDayFlip";
import RenderDateTime from "../components/renderDateTime";
import JobLocation from "../components/jobLocation";

import JobSchedule from "../components/jobSchedule";
import HostDetails from "../components/jobHostDetails";
import GuestReservationDetails from "../components/jobGuestReservationDetails";
import GuestRating from "../components/guestRating";
// Helpers
import { getFormattedCheckInOutTime } from "../helpers/format";
import { parseQuery } from "../helpers/url";

// Contants
import {
  PAYMENT_STATUS_PENDING,
  PAYMENT_STATUS_SUCCESS,
  CLEANER_STATUS_COMPLETED,
  DATE_FORMAT,
} from "../constants/apiEnum";

import { DIALOG_TYPE_REQUEST_CLEANING_PAYMENT } from "../constants/dialogTypes";

// Actions
import { fetchProsJobDetails, fetchWalkthroughTasks } from "../actions/jobs";
import { openThisDialog, setDialogOpen } from "../actions/dialog";
import { fetchUser } from "../actions/user";
import { getUser, getUserToken } from "../reducers/user";

// Reducer
import { getJobDetails } from "../reducers/jobs";
import { getWalkthroughTasks } from "../reducers/walkthroughTasks";
import ProsPropertyDetails from "../components/prosPropertyDetails";
import CleaningImageViewer from "../components/cleaningImageViewer";

const ProsJobView = (props) => {
  const { fetchProsJobDetails, job, user, userToken, tasks, fetchWalkthroughTasks } = props;
  const { isLoading } = job;

  const url = window.location.href;

  const localId = url.substring(url.lastIndexOf("/") + 1);

  const isMobile = useMediaQuery("(max-width:600px)");

  const { request_payment } = parseQuery();

  const dispatch = useDispatch();

  useEffect(() => {
    if (localId) {
      fetchProsJobDetails(localId);
      fetchWalkthroughTasks(localId);
    }
  }, []);

  const RenderJob = (props) => {
    const {
      setDialogOpen,
      openThisDialog,
      id: jobId,
      job_date: jobDate,
      job_time: jobTime = "11:00",
      address,
      host_name: hostName,
      assignor_name: assignorName,
      assignor_phone: assignorPhone,
      host_phone: hostPhone = "",
      property,
      channel,
      job_fee: jobFee,
      is_completed: isCompleted,
      status,
      is_flip: isFlip,
      additional_fee,
      total_fee,
      cleaning_fee_note: paymentDescription,
      payment,
      description,
      reservation: {
        check_in: checkInDate = "",
        check_out: checkOutDate = "",
        duration = 0,
      } = {},
      userToken,
      guest_rating,
      guest_review,
      tasks,
      user,
    } = props;

    const {
      title = "",
      check_in_time: checkInTime = "",
      street = "",
    } = property;

    const isMarketplace = channel === "Marketplace" || false;

    useEffect(() => {
      if (request_payment) {
        dispatch(setDialogOpen(DIALOG_TYPE_REQUEST_CLEANING_PAYMENT, jobId));
      }
    }, [request_payment]);

    const isJobDescriptionEmpty = isEmpty(description);
    const isJobCompleted = status === CLEANER_STATUS_COMPLETED;

    // REUSED
    const RenderJobStatus = (props) => {
      const { proJobStatus } = props;
      if (isCompleted) {
        if (!proJobStatus && !isEmpty(payment)) {
          return (
            <NormalizedCleaningStatus
              status={payment.status}
              human={payment.status_human}
            />
          );
        } else {
          return <NormalizedCleaningStatus status={status} />;
        }
      } else {
        return <NormalizedCleaningStatus status={status} />;
      }
    };

    // REUSED
    const RenderJobPayment = () => {
      const {
        id: paymentId,
        amount,
        receipt_url: receiptUrl,
        status: paymentStatus,
      } = payment;

      const isPending = paymentStatus === PAYMENT_STATUS_PENDING;

      return (
        <>
          <div className="job__container">
            <div className="border">
              <div className="sm-col sm-col-6 border">
                <p className="label">Total</p>
                <p className="bold">{`$${total_fee}`}</p>
              </div>
            </div>
          </div>
          <div className="job__container">
            <div className="border">
              <div className="sm-col sm-col-6 border mt2">
                <p className="label">Job fee</p>
                <p className="">{`$${jobFee}`}</p>
              </div>
              <div className="sm-col sm-col-6 border mt2">
                <p className="label">Additional</p>
                <p className="">{`$${additional_fee}`}</p>
              </div>
            </div>
          </div>
          {paymentDescription && (
            <div className="job__container">
              <div className="border">
                <div className="sm-col sm-col-12 border mt2">
                  <p className="label">Payment description</p>
                  <p className="">{paymentDescription}</p>
                </div>
              </div>
            </div>
          )}

          {!isPending && (
            <a
              href={receiptUrl}
              target="_blank"
              className="button button--hyperlink"
            >
              View Receipt
              <LaunchIcon className="button__icon--right" />
            </a>
          )}
        </>
      );
    };

    return (
      <div className="page-wrapper">
        <div className="page-content pt3">
          <div className="job__header mb2" key="host-job-primary-action">
            {isMobile ? (
              <div key="desktop-page-header-first" className="">
                <h1 className="page-header">
                  <RenderDateTime date={jobDate} time={jobTime} />
                </h1>
                <RenderJobStatus />
              </div>
            ) : (
              [
                <div key="desktop-page-header-first" className="">
                  <h1 className="page-header">
                    <RenderDateTime date={jobDate} time={jobTime} />
                  </h1>
                </div>,
                <div key="desktop-page-header-second" className="">
                  <RenderJobStatus />
                </div>,
              ]
            )}
          </div>
          <div className="flex items-center ml2">
            {isFlip && (
              <Chip
                icon={<LoopIcon style={{ color: "white" }} />}
                label="Same day flip"
                size="small"
                style={{ backgroundColor: "#ff2929", color: "white" }}
              />
            )}
          </div>
          {/* If payment render this card at the top of the page */}
          {payment && (
            <div className="job__payment job__border mt3">
              <h3 className="header-3">Payment</h3>
              <RenderJobPayment />
            </div>
          )}
          {/* Completed Jobs: Only render note section iff there was a note left */}
          {isCompleted
            ? !isJobDescriptionEmpty && (
                <div
                  className={classnames(
                    "job__note job__border job__border--light mt3"
                  )}
                >
                  <h3 className="header-3">Description</h3>
                  <p>{description}</p>
                </div>
              )
            : !isJobDescriptionEmpty && (
                <div
                  className={classnames(
                    "job__note job__border job__border--light mt3",
                    {
                      "job__note--value": !isJobDescriptionEmpty,
                    }
                  )}
                >
                  <h3 className="header-3">Description</h3>
                  <p>{description}</p>
                </div>
              )}
          <div className="job__details mt4">
            <JobSchedule
              jobDate={jobDate}
              jobTime={jobTime}
              propertyName={title}
              propertyAddress={address}
            />
          </div>
          <div className="job__pro mt4">
            <JobLocation
              property={property}
              isHost={userToken.is_host}
              className="mt4"
            />
          </div>
          <div className="job__details mt4">
            <HostDetails
              name={assignorName}
              isClient={true}
              phone={assignorPhone}
              title="Job provider"
            />
          </div>
          <div className="job__details mt4">
            <GuestReservationDetails
              duration={duration}
              format={DATE_FORMAT}
              reservationDate={checkInDate}
              reservationTime={checkInTime}
              jobDate={jobDate}
              jobTime={jobTime}
              title="Guest reservation details"
            />
          </div>
          {guest_rating > 0 && (
            <div className="job__details mt3">
              {/* Requires images array of objects with original, thumbnail and description */}
              <Typography
                variant="h6"
                style={{
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "140%",
                }}
              >
                Inspection report
              </Typography>
              {/* <Typography variant="subtitle2" style={{ marginBottom: 16 }}>
            Photos showing property condition and damages after guest departure.
          </Typography> */}
              <div style={{ marginTop: 8 }}>
                <GuestRating rate={guest_rating} />
                {guest_review && (
                  <div style={{ marginTop: 8 }}>
                    <Typography style={{ fontWeight: 500, fontSize: 14 }}>
                      Note:{" "}
                      <Typography
                        variant="body1"
                        component="span"
                        style={{
                          marginTop: 4,
                          fontWeight: "normal",
                          fontSize: 14,
                        }}
                      >
                        {guest_review}
                      </Typography>
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          )}

          {isJobCompleted && !isEmpty(tasks.data) && (
            <div className="job__details mt3">
              <Typography
                variant="h6"
                style={{
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "140%",
                }}
              >
                Final walkthrough
              </Typography>
              <Typography variant="body2" style={{ marginBottom: 16 }}>
                Photos taken on the job during the walkthrough.
              </Typography>
              <CleaningImageViewer images={tasks.data} />
            </div>
          )}
          <div className="job__details mt3 mb3 pros__jobs pb3">
            <JobActions {...props} view="jobDetails" />
          </div>
        </div>
      </div>
    );
  };

  return isLoading ? (
    <LoadingScreen />
  ) : (
    !isEmpty(job) && (
      <RenderJob
        {...job}
        user={user}
        userToken={userToken}
        openThisDialog={openThisDialog}
        setDialogOpen={setDialogOpen}
        tasks={tasks}
      />
    )
  );
};

export default connect(
  (state) => ({
    job: getJobDetails(state),
    user: getUser(state),
    userToken: getUserToken(state),
    tasks: getWalkthroughTasks(state),
  }),
  (dispatch) => bindActionCreators({ fetchProsJobDetails, fetchWalkthroughTasks }, dispatch)
)(ProsJobView);
