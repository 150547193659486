const PUBLIC_S3_URL = process.env.REACT_APP_PUBLIC_S3_URL;

export const s3UrlMapper = ({
    image_processed,
    url,
    size = 'lg'
}: {
    image_processed?: boolean;
    url: string;
    size?: 'thumb' | 'md' | 'lg' | 'original';
}) => {
    if (!url) {
        return '';
    }

    const splitUrl = url.split('/');
    if (image_processed) {
        switch (size) {
            case 'thumb':
                splitUrl[splitUrl.length - 1] =
                    'thumb.' + splitUrl[splitUrl.length - 1];
                break;
            case 'md':
                splitUrl[splitUrl.length - 1] =
                    'md.' + splitUrl[splitUrl.length - 1];
                break;
            case 'lg':
                splitUrl[splitUrl.length - 1] =
                    'lg.' + splitUrl[splitUrl.length - 1];
                break;
            case 'original':
            default:
                break;
        }
    }
    return PUBLIC_S3_URL + splitUrl.join('/');
};

export const cleanS3Url = (url: string) => {
    return url ? url.replace(PUBLIC_S3_URL, '') : '';
};