import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Dialog,
  DialogActions,
  Button,
  IconButton,
  LinearProgress,
  DialogTitle,
  Box,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { Add as AddIcon, Remove as RemoveIcon } from "@material-ui/icons";
import AvatarEditor from "react-avatar-editor";
import silhouetteImage from "../images/silhouetteImage.png";

const MAX_ZOOM_IN = 3;
const MAX_ZOOM_OUT = 1;
const ZOOM_ADJUSTMENT = 0.2;

function CircularImageCropDialog({ open, onClose, onSave, imageUrl }) {
  const [zoom, setZoom] = useState(1.2); // Zoom level
  const [src, setSrc] = useState(imageUrl);
  const imgRef = useRef(null);

  let newZoom;

  useEffect(() => {
    if (open) {
      setZoom(1.2);
      setSrc(imageUrl);
    }
  }, [open]);

  const handleSave = () => {
    if (imgRef.current) {
      const canvas = imgRef.current.getImageScaledToCanvas();
      // Convert canvas to data URL
      // const imageDataUrl = canvas.toDataURL("image/jpeg");

      // Alternatively, convert canvas to Blob (if needed)
      canvas.toBlob(
        (blob) => {
          const formData = new FormData();
          // Pass the blob to the parent component
          formData.append("profile_photo", blob);
          onSave(formData);
        },
        "image/png",
        1
      );

      // Pass the data URL to the parent component
      // onSave(imageDataUrl);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      // Check if the file type is jpg, jpeg, or png
      const fileType = file.type === "image/jpeg" || file.type === "image/png";

      // Check if the file size is less than or equal to 10MB
      const fileSize = file.size <= 10 * 1024 * 1024;

      if (fileType && fileSize) {
        // If file is valid, update the imageSrc state
        const reader = new FileReader();
        reader.onloadend = () => {
          setSrc(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        // Handle invalid file (e.g., wrong type or size)
        alert("File must be a JPG, JPEG, or PNG and not exceed 10MB.");
      }
    }
  };

  const handleZoom = (zoomIn) => {
    if (zoomIn) {
      // ZOOM IN
      newZoom = zoom + ZOOM_ADJUSTMENT;
      setZoom(Math.min(newZoom, MAX_ZOOM_IN));
    } else {
      // ZOOM OUT
      newZoom = zoom - ZOOM_ADJUSTMENT;
      setZoom(Math.max(newZoom, MAX_ZOOM_OUT));
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Choose profile picture</DialogTitle>
      <AvatarEditor
        ref={imgRef}
        image={src}
        width={250}
        height={250}
        border={50}
        borderRadius={250}
        color={[0, 0, 0, 0.7]} // RGBA
        scale={zoom}
        rotate={0}
      />
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        justifyContent="space-between"
      >
        <IconButton
          onClick={() => handleZoom(false)}
          disabled={zoom === MAX_ZOOM_OUT}
        >
          <RemoveIcon />
        </IconButton>
        <LinearProgress
          style={{ flexGrow: 1 }}
          variant="determinate"
          value={((zoom - MAX_ZOOM_OUT) / (MAX_ZOOM_IN - MAX_ZOOM_OUT)) * 100}
        />
        <IconButton
          onClick={() => handleZoom(true)}
          disabled={zoom === MAX_ZOOM_IN}
        >
          <AddIcon />
        </IconButton>
      </Box>
      <DialogActions>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box>
            <input
              type="file"
              hidden
              id="image-input"
              accept="image/jpeg, image/png"
              onChange={handleImageChange}
            />
            <label htmlFor="image-input">
              <Button component="span" variant="outlined">
                Change image
              </Button>
            </label>
          </Box>
          <Box>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              onClick={handleSave}
              variant="contained"
              color="primary"
              disableElevation
              style={{ marginLeft: 16 }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

function CircularProfileImage({
  imageUrl,
  handleChange,
  readOnly,
  requireAction = false,
}) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleProfileSave = (croppedImage) => {
    handleChange(croppedImage);
    setDialogOpen(false);
  };

  return (
    <>
      {requireAction ? (
        <>
          <div
            style={{
              width: 100,
              height: 100,
              position: "relative",
              cursor: readOnly ? "default" : "pointer",
            }}
          >
            <img
              src={imageUrl ? imageUrl : silhouetteImage}
              style={{
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                width: "100%",
                borderRadius: "100px",
                border: imageUrl ? "2px solid white" : "2px solid #bebec1",
                outline: imageUrl && "2px solid #b4b4b4",
              }}
            />
          </div>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            onClick={() => (readOnly ? null : setDialogOpen(true))}
            style={{ marginTop: 16 }}
          >
            Update photo
          </Button>
        </>
      ) : (
        <div className="flex align-center justify-center mb3">
          <div
            style={{
              width: 100,
              height: 100,
              position: "relative",
              cursor: readOnly ? "default" : "pointer",
            }}
            onClick={() => (readOnly ? null : setDialogOpen(true))}
          >
            <img
              src={imageUrl ? imageUrl : silhouetteImage}
              style={{
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                width: "100%",
                borderRadius: "100px",
                border: imageUrl ? "2px solid white" : "2px solid #bebec1",
                outline: imageUrl && "2px solid #b4b4b4",
              }}
            />
            {!readOnly && (
              <div
                style={{
                  position: "absolute",
                  width: 32,
                  height: 32,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "32px",
                  backgroundColor: "black",
                  bottom: imageUrl ? -5 : 0,
                  right: imageUrl ? -5 : 0,
                }}
              >
                <EditIcon style={{ fontSize: "16px", color: "white" }} />
              </div>
            )}
          </div>
        </div>
      )}
      <CircularImageCropDialog
        open={dialogOpen}
        imageUrl={imageUrl ? imageUrl : silhouetteImage}
        onClose={() => setDialogOpen(false)}
        onSave={handleProfileSave}
      />
    </>
  );
}

export default CircularProfileImage;
