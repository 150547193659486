import axios from "../helpers/axios";
import amplitude from "amplitude-js";

// NOT sure what it does
import { serverError } from "../helpers/helper_serverError";
import setAuthToken from "../helpers/helper_setAuthToken";
import { showSnackbar } from "./action_snackbar";

export const SET_PROMOTION_DIALOG = "SET_PROMOTION_DIALOG";
export const FILTER_PROMOTION_ARRAY = "FILTER_PROMOTION_ARRAY";

let seedData = [
  {
    id: 1,
    viewed: false,
    promotion: {
      id: 1,
      title: "Filters are here",
      description:
        "Do you have a lot of properties?  Does you dashboard have a lot of reservations?  You can now filter your search results to your heart's content!  Features now available:  ability to fiter reservations by properties, by channels (airbnb, vrbo, etc), by housekeepers.  You can combine these filters the way you like it.  If you would like get a specific filter added, just let us know!  Hope you find this feature useful.  If you need help, watch our 1 minute tutorial below to get familiar with how to use Filters!",
      role: "Host",
      video_url: "BVgA4uH6B2A",
      learn_more_url: "https://automatebnb.com/blog/filter-tutorial",
      created_at: "2020-02-23T03:26:51.141Z",
      updated_at: "2020-02-26T04:52:38.163Z"
    }
  },
  {
    id: 2,
    viewed: false,
    promotion: {
      id: 2,
      title: "Add a cleaning note",
      description:
        "Would you like to send a reminder to your housekeeper with a cleaning note? Send it with us.",
      role: "Host",
      video_url: "a5SxyQ-9YDA",
      learn_more_url: "https://automatebnb.com/blog/filter-tutorial",
      created_at: "2020-02-23T03:26:51.141Z",
      updated_at: "2020-02-26T04:52:38.163Z"
    }
  },
  {
    id: 3,
    viewed: false,
    promotion: {
      id: 3,
      title: "Top gun 2",
      description:
        "Top Gun Maverick official Super Bowl TV spot + trailers starring Tom Cruise, Miles Teller, Jennifer Connelly, Jon Hamm, Glen Powell, Lewis Pullman with Ed Harris.",
      role: "Host",
      video_url: "epgkqPJ_bc0",
      learn_more_url: "https://automatebnb.com/blog/filter-tutorial",
      created_at: "2020-02-23T03:26:51.141Z",
      updated_at: "2020-02-26T04:52:38.163Z"
    }
  },
  {
    id: 4,
    viewed: false,
    promotion: {
      id: 4,
      title:
        "Marcin Patrzalek: Polish Guitarist MURDERS His Guitar! WOW! | America's Got Talent 2019",
      description:
        "In season 14, NBC's America's Got Talent follows Simon Cowell, Julianne Hough, Gabrielle Union, Howie Mandel, and host Terry Crews in their talent search, showcasing unique performers from across the country.",
      role: "Host",
      video_url: "eollizcRpgQ",
      learn_more_url: "https://automatebnb.com/blog/filter-tutorial",
      created_at: "2020-02-23T03:26:51.141Z",
      updated_at: "2020-02-26T04:52:38.163Z"
    }
  },
  {
    id: 5,
    viewed: false,
    promotion: {
      id: 5,
      title: "Top gun 2",
      description:
        "Top Gun Maverick official Super Bowl TV spot + trailers starring Tom Cruise, Miles Teller, Jennifer Connelly, Jon Hamm, Glen Powell, Lewis Pullman with Ed Harris.",
      role: "Host",
      video_url: "epgkqPJ_bc0",
      learn_more_url: "https://automatebnb.com/blog/filter-tutorial",
      created_at: "2020-02-23T03:26:51.141Z",
      updated_at: "2020-02-26T04:52:38.163Z"
    }
  }
];

export function fetchMockPromotion(id) {
  return dispatch => {
    if (id) {
      let filteredSeed = seedData.filter(d => {
        return d.id !== id;
      });
      seedData = filteredSeed;
      dispatch(setPromotionDialog(filteredSeed));
    } else {
      dispatch(setPromotionDialog(seedData));
    }
  };
}

export function fetchPromotion() {
  return dispatch => {
    return axios
      .get("/v1/user_promotions")
      .then(res => {
        if (res.status === 200) {
          dispatch(setPromotionDialog(res.data));
        }
      })
      .catch(error => {
        dispatch(serverError(error));
      });
  };
}

export function viewedPromotion(id) {
  return dispatch => {
    return axios
      .put(`/v1/user_promotions/${id}?viewed=true`)
      .then(res => {
        if (res.status === 200) {
          dispatch(fetchPromotion());
        }
      })
      .catch(error => {
        dispatch(serverError(error));
      });
  };
}

export const setPromotionDialog = promotion => ({
  type: SET_PROMOTION_DIALOG,
  promotion
});

export const promotionViewed = id => ({
  type: FILTER_PROMOTION_ARRAY,
  id
});
