import { FETCH_INVOICES, RESET_INVOICES } from "../actions/previousInvoices";

export const getPreviousInvoices = ({ invoices }) => invoices;

export const invoices = (state = { page: { total: 0 }, items: [] }, action) => {
  const { data } = action;
  switch (action.type) {
    case RESET_INVOICES:
      return { page: { total: 0 }, items: [], isLoading: true };
    case FETCH_INVOICES:
      return {
        ...state,
        items: [...state.items, ...data.items],
        isLoading: false,
        page: {
          total: data.total,
          currentPage: data.current_page,
          nextPage: data.next_page,
          previousPage: data.previous_page,
        },
      };

    default:
      return state;
  }
};
