import React from "react";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

import { MANAGE_SUBSCRIPTION } from "../../routes";

import history from "../../helpers/history";

const PaymentSetupPrompt = (props) => {
  const { closeDialog } = props;

  const handleDialogClose = (type) => {
    if (type === "confirm") {
      history.push(MANAGE_SUBSCRIPTION);
      closeDialog();
    } else if (type === "cancel") {
      closeDialog();
    }
  };

  return (
    <>
      <DialogTitle id="scroll-dialog-title" className="">
        Subscribe
      </DialogTitle>
      <DialogContent dividers={true}>
        Please subscribe to make payment.
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleDialogClose("cancel")}
          variant="contained"
          disableElevation
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleDialogClose("confirm")}
          variant="contained"
          className="host-primary"
          disableElevation
        >
          Subscribe
        </Button>
      </DialogActions>
    </>
  );
};

export default PaymentSetupPrompt;
