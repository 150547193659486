import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import moment from "moment";

import NativeSelect from "@material-ui/core/NativeSelect";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

import { updateCleaningDate } from "../../actions/pmsCleanings";

const CleaningDateChange = (props) => {
  const { id, updateCleaningDate, closeDialog, cleaning } = props;

  const [cleaningDate, setCleaningDate] = useState();

  const handleDialogClose = (type) => {
    if (type === "update") {
      updateCleaningDate({ cleaning_date: cleaningDate, id });
    } else if (type === "cancel") {
      closeDialog();
    }
  };

  useEffect(() => {
    if (isEmpty(cleaningDate)) {
      var _date = cleaning.cleaning_date;
      _date = _date.split("-");
      _date = `${_date[1]}-${_date[2]}-${_date[0]}`;
      setCleaningDate(_date);
    }
  }, [cleaning]);

  return (
    <>
      <DialogTitle
        id="scroll-dialog-title"
        className="assign-housekeeper__title"
      >
        Change cleaning date
      </DialogTitle>
      <DialogContent dividers={true}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="outlined"
            fullWidth={true}
            minDate={cleaningDate}
            required
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Cleaning date"
            value={cleaningDate}
            onChange={setCleaningDate}
            KeyboardButtonProps={{
              "aria-label": "Change cleaning date",
            }}
          />
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleDialogClose("cancel")}
          variant="contained"
          disableElevation
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleDialogClose("update")}
          variant="contained"
          color="primary"
          disableElevation
        >
          Update
        </Button>
      </DialogActions>
    </>
  );
};

export default connect(
  (state) => ({}),
  (dispatch) => bindActionCreators({ updateCleaningDate }, dispatch)
)(CleaningDateChange);
