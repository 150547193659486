import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

import { cancelJob } from "../actions/jobs";
import { Analytics } from "../services/analyticsService.tsx";

const CancelJobPrompt = ({ cancelJob, closeThisDialog, jobId, view }) => {
  const [isLoading, setLoading] = React.useState(false);

  const handleSubmit = () => {
    if (jobId) {
      setLoading(true);
      Analytics.trackEvent("job_canceled_action_from_cancel_prompt", {
        job_id: jobId,
      });
      cancelJob({ jobId, view });
    }
  };

  return (
    <>
      <DialogTitle
        id="scroll-dialog-title"
        className="assign-housekeeper__title"
      >
        Cancel job?
      </DialogTitle>
      <DialogContent dividers={true}>
        Are you sure you want to cancel this job? This action cannot be undone.
      </DialogContent>
      <DialogActions>
        <Button onClick={closeThisDialog} variant="contained" disableElevation>
          Close
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disableElevation
          disabled={isLoading}
          style={{ backgroundColor: "red", color: "white" }}
        >
          Cancel job
        </Button>
      </DialogActions>
    </>
  );
};

export default connect(
  (state) => ({}),
  (dispatch) => bindActionCreators({ cancelJob }, dispatch)
)(CancelJobPrompt);
