import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";

// Material UI - Dialog
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

// Material-UI - Forms
import TextField from "@material-ui/core/TextField";

import { inviteAClient } from "../actions/ops";
import { Typography } from "@material-ui/core";

import { emailRegex } from "../constants/validate";
import { Analytics } from "../services/analyticsService.tsx";

function RenderInviteOpsClientsForm(props) {
  const { closeThisDialog } = props;
  const [data, setData] = useState({
    email: "",
    note: "",
    hostCost: false,
  });
  const [errorEmail, setErrorEmail] = useState(false);
  const dispatch = useDispatch();

  function handleSubmit() {
    Analytics.trackSimple("invited_host_action_from_invite_host_prompt");
    dispatch(inviteAClient({ email: data.email, note: data.note }));
  }

  function handleClose() {
    closeThisDialog();
  }

  // Input number returns value only
  const handleEmail = (event) => {
    const { value } = event.target;

    if (emailRegex.test(value)) {
      setErrorEmail(false);
    } else if (value.length > 2) {
      setErrorEmail(true);
    }
    setData({ ...data, ["email"]: value });
  };

  function handleNote(event) {
    setData({ ...data, ["note"]: event.target.value });
  }

  function handleHostCostChange(event) {
    setData({ ...data, ["hostCost"]: event.target.checked });
  }

  return (
    <>
      <DialogTitle id="scroll-dialog-title" className="client-modal">
        Invite a host
        <br />
        <Typography variant="body2">
          Automatebnb will charge your host{" "}
          <span style={{ fontWeight: 500 }}>$5 per property per month</span> to
          use the platform. If you're interested in covering the cost on behalf
          of your host as well as learning more about volume discount, please
          get in touch with us at{" "}
          <a href="mailto:sales@automatebnb.com" className="link semibold">
            sales@automatebnb.com.
          </a>
        </Typography>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{ position: "relative" }}
        className="ab-dialog"
      >
        <TextField
          label={"Email"}
          className="full-width mb2"
          value={data.email}
          onChange={handleEmail}
          error={errorEmail}
          variant="outlined"
        />
        <TextField
          label="Send a note (optional)"
          multiline
          className="full-width mb2"
          minRows={4}
          value={data.note}
          onChange={handleNote}
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <div className="inline-flex justify-end full-width">
          <div className="pms-dialog__actions">
            <Button
              onClick={handleClose}
              variant="text"
              disableElevation
              className="actions actions--default"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              disableElevation
              disabled={isEmpty(data.email)}
              color="primary"
              className="btn-brand--ops"
            >
              Invite a host
            </Button>
          </div>
        </div>
      </DialogActions>
    </>
  );
}

export default RenderInviteOpsClientsForm;
