import React from "react";

import Link from "@material-ui/core/Link";
import { useMediaQuery } from "@material-ui/core";

import JobDetails from "../components/JobDetails";
import { DATE_FORMAT } from "../constants/apiEnum";

import RenderDateTime from "./renderDateTime";

function pluralize(count, label) {
  return count === 1 ? label : `${label}s`;
}

export default ({
  propertyName = null,
  propertyAddress = null,
  property = null,
  propertyNameLinkPath = null,
  rooms = null,
  isMarketplace = false,
  isHost = false,
  className = "",
}) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const {
    title = "",
    id = "",
    street = "",
    city = "",
    state = "",
    zip = "",
    full_address = null,
  } = property;

  const address = full_address
    ? full_address
    : `${street} ${city} ${state} ${zip}`;
  const addressLink = `/listings/${id}/listing-details`;

  const RoomLabel = () => {
    // Exit if rooms is empty
    if (!rooms) return null;

    const rates = Object.entries(rooms).map(([key, value]) => {
      const quantity = property[key];

      return {
        key,
        quantity,
        label: pluralize(quantity, key.slice(0, -1)),
      };
    });

    return rates
      .map(({ quantity, label }) => `${quantity} ${label}`)
      .join(" • ");
  };

  return (
    <JobDetails className={className} variant="stack">
      <JobDetails.Title>Property details</JobDetails.Title>
      <JobDetails.Content>
        <JobDetails.Group>
          <JobDetails.Label>Address</JobDetails.Label>
          {address ? (
            <JobDetails.Link href={`https://maps.google.com/?q=${address}`}>
              {address}
            </JobDetails.Link>
          ) : (
            "--"
          )}
        </JobDetails.Group>
        <JobDetails.Group>
          <JobDetails.Label>Title</JobDetails.Label>
          {isHost ? (
            <JobDetails.Link href={addressLink}>{title}</JobDetails.Link>
          ) : (
            <JobDetails.Body>{title}</JobDetails.Body>
          )}
        </JobDetails.Group>
      </JobDetails.Content>
      {isMarketplace && rooms && (
        <JobDetails.Content className="mt1">
          <JobDetails.Group>
            <JobDetails.Label>Rooms</JobDetails.Label>
            <JobDetails.Body>
              <RoomLabel />
            </JobDetails.Body>
          </JobDetails.Group>
        </JobDetails.Content>
      )}
    </JobDetails>
  );
};
