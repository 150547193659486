import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import classnames from "classnames";
import { bindActionCreators } from "redux";
import { useDispatch, connect } from "react-redux";

// Material-ui Components
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Link from "@material-ui/core/Link";

// Actions
import { fetchProsCategories } from "../actions/pros";
import { proPreSignup, registerPro } from "../actions/user";

// Reducers
import { getUser, getUserToken } from "../reducers/user";
import { getProsCategories } from "../reducers/pros";

// Helpers
import history from "../helpers/history";
import { parseQuery } from "../helpers/url";
import normalizePhone from "../helpers/helper_normalize-phone";

// Constants
import { JOBS, LOG_IN } from "../routes";
import { emailRegex } from "../constants/validate";

import "./signup.scss";
import { Typography } from "@material-ui/core";

const ProsSignup = (props) => {
  const {
    userToken,
    user,
    fetchProsCategories,
    registerPro,
    proPreSignup,
  } = props;
  const [isDisabled, setDisabled] = useState(true);
  const [token, setToken] = useState();
  const [errors, setErrors] = useState({
    first: false,
    last: false,
    phone: false,
    email: false,
    password: false,
    zip: false,
    categoryId: false,
  });
  const [values, setValues] = useState({
    first: "",
    last: "",
    phone: "",
    email: "",
    password: "",
    zip: "",
    categoryId: "",
  });

  if (localStorage.jwtToken) {
    if (!isEmpty(userToken)) {
      history.replace(JOBS);
    }
  }

  const { invite_code } = parseQuery();

  const isOpsSignup =
    invite_code === "a9564a419cd51f5196abde96e582f9a37420913c";

  const categoryHousekpeerId = "0d264dc5-9d82-4ccf-934c-c145cb7ce02b";

  const search = window.location.search;
  const newToken = search.replace("?invite_code=", "");

  useEffect(() => {
    if (isEmpty(user)) {
      if (newToken) {
        proPreSignup(newToken);

        if (!token) {
          setToken(newToken);
        }
      }
    } else if (!isEmpty(user)) {
      setValues({
        first: user.first ? user.first : "",
        last: user.last ? user.last : "",
        phone: user.phone ? user.phone : "",
        email: "",
        password: "",
        zip: "",
        categoryId: user.category
          ? user.category.id
          : isOpsSignup
          ? categoryHousekpeerId
          : "",
      });
    }

    // Get categories
    fetchProsCategories();
  }, [user]);

  const dispatch = useDispatch();

  const isValid = () => {
    return (
      !isEmpty(values.first) &&
      !isEmpty(values.last) &&
      !isEmpty(values.phone) &&
      !isEmpty(values.zip) &&
      !isEmpty(values.email) &&
      !isEmpty(values.password)
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let newVal = "";

    if (name === "phone") {
      // 10 digits in a number. formatting adds 2 hyphens
      if (value.length < 12) {
        setErrors({ ...errors, [name]: true });
      } else {
        setErrors({ ...errors, [name]: false });
      }
      newVal = { ...values, [name]: normalizePhone(value) };
    } else if (name === "email") {
      if (emailRegex.test(value)) {
        setErrors({ ...errors, [name]: false });
      } else {
        setErrors({ ...errors, [name]: true });
      }
      newVal = { ...values, [name]: value };
    } else {
      newVal = { ...values, [name]: value };
    }

    setValues(newVal);

    if (isValid()) {
      setDisabled(false);
    } else if (!isValid() && !isDisabled) {
      setDisabled(true);
    }
  };

  const handleSubmit = () => {
    if (values && isValid()) {
      registerPro({
        first: values.first,
        last: values.last,
        phone: values.phone,
        zip: values.zip,
        email: values.email,
        password: values.password,
        invite_code: token,
      });
    }
  };

  return (
    <>
      <div className="page-wrapper ab-public new-signup">
        <p className="login-cta">
          Already a member?{" "}
          <Link href={LOG_IN} style={{ whiteSpace: "nowrap" }}>
            Log In
          </Link>
        </p>
        <div
          className="page-content ab-public__pros-signup"
          key="signup-cleaning"
        >
          <div className="form-wrapper">
            <div className="form">
              <div className="text-center mb2">
                {isOpsSignup ? (
                  <>
                    <p>Operations platform (Beta)</p>
                    <p className="mt1 semibold">Sign up</p>
                  </>
                ) : (
                  <Typography variant="h6">Pro sign up</Typography>
                )}
              </div>
              <div className="mb3">
                <TextField
                  required
                  name="first"
                  label="First name"
                  fullWidth={true}
                  onChange={handleInputChange}
                  variant="outlined"
                  value={values.first}
                />
              </div>
              <div className="mb3">
                <TextField
                  required
                  name="last"
                  label="Last name"
                  fullWidth={true}
                  onChange={handleInputChange}
                  variant="outlined"
                  value={values.last}
                />
              </div>
              <div className="mb3">
                <TextField
                  required
                  name="phone"
                  error={errors.phone}
                  label="Phone number"
                  fullWidth={true}
                  onChange={handleInputChange}
                  variant="outlined"
                  value={values.phone}
                  helperText="Job updates are sent via text messages (standard rates apply). Use our app for mobile notifications instead."
                />
              </div>
              <div className="mb3">
                <TextField
                  required
                  name="zip"
                  autoFocus={true}
                  label="Zip"
                  fullWidth={true}
                  onChange={handleInputChange}
                  variant="outlined"
                  value={values.zip}
                />
              </div>
              {/* <div className="mb3">
                <FormControl
                  variant="outlined"
                  className={classnames("full-width mb2", {
                    "Mui-filled": values.categoryId,
                  })}
                >
                  <InputLabel id="request-a-job-properties-selection">
                    Line of work
                  </InputLabel>
                  <Select
                    value={
                      isOpsSignup
                        ? categoryHousekpeerId
                        : values.categoryId ?? ""
                    }
                    name="categoryId"
                    disabled={isOpsSignup}
                    onChange={handleInputChange}
                    inputProps={{ "aria-label": "Pros Categories" }}
                  >
                    <MenuItem key="item-empty" value="">
                      <em>None</em>
                    </MenuItem>
                    {isEmpty(categories) ? (
                      <MenuItem key="item-null" />
                    ) : (
                      categories.map((category) => {
                        return (
                          <MenuItem key={category.id} value={category.id}>
                            {category.name}
                          </MenuItem>
                        );
                      })
                    )}
                  </Select>
                </FormControl>
              </div> */}
              <div className="mb3">
                <TextField
                  required
                  name="email"
                  label="Email"
                  error={errors.email}
                  fullWidth={true}
                  onChange={handleInputChange}
                  variant="outlined"
                  value={values.email}
                />
              </div>
              <div className="mb3">
                <TextField
                  required
                  name="password"
                  label="Password"
                  fullWidth={true}
                  type="password"
                  onChange={handleInputChange}
                  variant="outlined"
                  value={values.password}
                />
              </div>

              <p className="small">
                By signing up, you are agreeing to Automatebnb&apos;s{" "}
                <a
                  href="https://www.automatebnb.com/terms"
                  target="__new"
                  className="link"
                >
                  Terms and Conditions
                </a>{" "}
                and{" "}
                <a
                  href="https://www.automatebnb.com/privacy"
                  target="__new"
                  className="link"
                >
                  Privacy Policy
                </a>
                .
              </p>

              <div className="mt2">
                <Button
                  onClick={() => handleSubmit()}
                  fullWidth={true}
                  disabled={isDisabled}
                  color="primary"
                  variant="contained"
                >
                  Sign up
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    userToken: getUserToken(state),
    user: getUser(state),
    categories: getProsCategories(state),
  }),
  (dispatch) =>
    bindActionCreators(
      { registerPro, fetchProsCategories, proPreSignup },
      dispatch
    )
)(ProsSignup);
