import axios from "../helpers/axios";

// NOT sure what it does
import { serverError } from "../helpers/helper_serverError";
import { Analytics } from "../services/analyticsService.tsx";
import { setContentLoading } from "./loading";

export const SET_SUBSCRIPTION = "set_subscription";

export function fetchSubscription() {
  return (dispatch) => {
    return axios
      .get("/v1/subscriptions/manage")
      .then((res) => {
        dispatch(setContentLoading(false));
        if (res.status === 200) {
          dispatch(setSubscription(res));
          const data = res.data;

          Analytics.setUserProperty({
            subscriptionType: data.subscription_type,
            subscriptionStatus: data.status,
            propertiesCount: data.properties_count,
          });

          Analytics.register({
            subscriptionStatus: data.status,
          });
        }
      })
      .catch((error) => {
        dispatch(setContentLoading(false));
        dispatch(serverError(error));
      });
  };
}

export function chargeCC(token) {
  let id = token.id;

  return (dispatch) => {
    return axios
      .post("/v1/subscriptions", { card_token: id })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            dispatch(fetchSubscription());
          }
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
}

export function updateCC(token) {
  let id = token.id;

  return (dispatch) => {
    return axios
      .post("/v1/subscriptions/update_payment", { card_token: id })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
}

export function cancelSubscription() {
  return (dispatch) => {
    dispatch(setContentLoading(true));
    return axios
      .post("/v1/subscriptions/cancel")
      .then((res) => {
        if (res.status === 200) {
          //SUCCESS SHOW SNACKBAR
          // showSnackbar
          setTimeout(() => {
            dispatch(fetchSubscription());
          }, 3000);
        }
      })
      .catch((error) => {
        dispatch(setContentLoading(false));
        dispatch(serverError(error));
      });
  };
}

export const setSubscription = (data) => ({
  type: SET_SUBSCRIPTION,
  data,
});
