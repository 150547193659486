import {
  SET_WALKTHROUGH_TASKS,
  RESET_WALKTHROUGH_TASKS,
} from "../actions/jobs";

export const getWalkthroughTasks = ({ walkthroughTasks }) => walkthroughTasks;

export const walkthroughTasks = (state = { isLoading: true }, action) => {
  const { data } = action;
  switch (action.type) {
    case RESET_WALKTHROUGH_TASKS:
      return { isLoading: true };
    case SET_WALKTHROUGH_TASKS:
      return {
        ...state,
        data,
        isLoading: false,
      };

    default:
      return state;
  }
};
