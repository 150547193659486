import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

import { getUserToken } from "../reducers/user";
import { login } from "../actions/user";
import { emailRegex } from "../constants/validate";
import { parseQuery } from "../helpers/url";

import { setOverlayLoadingScreen } from "../actions/overlayLoading";
import logo from "../images/logo.png";
import NewLogo from "../images/logo.svg";

import LoadingScreen from "../components/loadingScreen";

import { FORGOT_PASSWORD, JOBS, HOST_SIGNUP } from "../routes";

import "./signin.scss";
import { Typography } from "@material-ui/core";
import { PasswordField } from "../containers/accountFields";

const SignIn = (props) => {
  const { userToken, login, location } = props;
  const [isDisabled, setDisabled] = useState(true);
  const [errors, setErros] = useState({
    email: false,
    password: false,
  });
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const dispatch = useDispatch();

  const { state } = location;

  useEffect(() => {
    if (!isEmpty(userToken)) {
      const isOps = userToken.is_ops;
      if (state && state.from) {
        const path = state.from.pathname;
        const search = state.from.search;

        if (path && search) {
          window.location.replace(`${path}${search}`);
        } else if (path) {
          window.location.replace(path);
        } else {
          window.location.replace(JOBS);
        }
      } else {
        window.location.replace(JOBS);
      }
    }
  }, [userToken]);

  const isValid = () => {
    return !isEmpty(values.email) && !isEmpty(values.password);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let newVal = { ...values, [name]: value };
    setValues(newVal);

    if (isValid()) {
      setDisabled(false);
    } else if (!isValid() && !isDisabled) {
      setDisabled(true);
    }
  };

  const handleValidation = (e) => {
    if (emailRegex.test(values.email)) {
      setErros({ ...errors, ["email"]: false });
    } else {
      setErros({ ...errors, ["email"]: true });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (values && isValid()) {
      login(values);
    }
  };

  return (
    <div className="page-wrapper ab-public ab-public--negative-space">
      <div className="full-width inline-flex justify-center">
        <a
          href="https://automatebnb.com/"
          target="_new"
          className="flex align-center flex-column mb2"
        >
          <img
            src={NewLogo}
            alt="automatebnb-logo"
            className="ab-public__branding-logo"
          />
          <Typography variant="h6">automatebnb</Typography>
        </a>
      </div>
      <div className="page-content ab-public__login" key="sign-in">
        <div className="form">
          <h1 className="header-3 semibold text-center">Log In</h1>
          <form onSubmit={handleSubmit} role="form">
            <div className="mb3">
              <TextField
                required
                name="email"
                error={errors.email}
                type="email"
                label="Email"
                fullWidth={true}
                onChange={handleInputChange}
                onBlur={handleValidation}
                variant="outlined"
                value={values.email}
              />
            </div>
            <div className="mb3">
              <PasswordField
                required
                name="password"
                label="Password"
                handleChange={handleInputChange}
                value={values.password}
              />
              <div className="mt1">
                <Link className="link" href={FORGOT_PASSWORD}>
                  Forgot password?
                </Link>
              </div>
            </div>
            <div className="mt3">
              <Button
                type="submit"
                fullWidth={true}
                disableElevation
                color="secondary"
                variant="contained"
              >
                Sign in
              </Button>
            </div>
          </form>
          <h3 className="text-center pt3 header-3">
            Not a member?{" "}
            <Link className="" href={HOST_SIGNUP}>
              Sign up now
            </Link>
          </h3>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({ userToken: getUserToken(state) }),
  (dispatch) => bindActionCreators({ login }, dispatch)
)(SignIn);
