export const CLEANER_STATUS_ACCEPTED = "ACCEPTED";
export const CLEANER_STATUS_DECLINED = "DECLINED";
export const CLEANER_STATUS_COMPLETED = "COMPLETED";
export const CLEANING_CANCELLED = "CLEANING CANCELLED";
export const WAITING_TO_ACCEPT = "WAITING TO ACCEPT";
export const CLEANING_STARTED = "STARTED";
export const CLEANING_DATE_CHANGED = "CLEANING DATE CHANGED";
export const NOTIFICATION_TYPE_OFF = "OFF";
export const NOTIFICATION_TYPE_EMAIL = "EMAIL";
export const NOTIFICATION_TYPE_SMS = "SMS";

export const PAYMENT_STATUS_PENDING = "requires_source";
export const PAYMENT_STATUS_SUCCESS = "succeeded";
export const PAYMENT_STATUS_PROCESSING = "processing";
export const PAYMENT_STATUS_FAILED = "requires_payment_method";
export const DATE_FORMAT = "ddd, MMM DD";
export const DATE_FORMAT_FULL = "ddd, MMM DD, YYYY";
export const DATE_FORMAT_STANDARD = "dddd, MMMM Do";

// Subscription
export const SUBSCRIBE_STATUS_ACTIVE = "ACTIVE";
export const SUBSCRIBE_STATUS_CANCELLED = "CANCELLED";
export const SUBSCRIBE_STATUS_TRIAL_PERIOD = "TRIAL_PERIOD";
export const SUBSCRIBE_STATUS_TRIAL_EXPIRED = "TRIAL_EXPIRED";
export const SUBSCRIBE_STATUS_PAST_DUE = "PAST_DUE";

// Filter type
export const FILTER_TYPE_CANCELLED = "cancelled";
export const JOB_TYPE_PAST = "past";
export const JOB_TYPE_TODO = "todo";

// Empty states
export const EMPTY_STATE_TYPE_RESERVATIONS = "reservations";
export const EMPTY_STATE_TYPE_PROPERTIES = "properties";
export const EMPTY_STATE_TYPE_EVENTS = "events";
export const EMPTY_STATE_TYPE_HOUSEKEEPER_CLEANINGS = "housekeeper-cleanings";
export const EMPTY_STATE_TYPE_PREVIOUS_INVOICES = "previous-invoices";
export const EMPTY_STATE_TYPE_CLEANING_PAYMENTS = "cleaning-payments";

export const HOST_STRIPE_FREE_PLAN = "STRIPE_FREE_FOR_HOST_PLAN_ID";
export const MARKETPLACE = "Marketplace";

// Automation rules
export const RULE_ASSIGN_MAID_AUTO = "ASSIGN_MAID_AUTO";
export const RULE_ASSIGN_MAID_MANUAL = "ASSIGN_MAID_MANUAL";

export const ABNB_PROFILE_BASE_URL = "https://autobnb.pro/";
export const ABNB_PREFIX_URL = "autobnb.pro/";
