import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import { deleteProperty } from "../../actions/pmsProperties";
import { isEmpty } from "lodash";

import { serverError } from "../../helpers/helper_serverError";

const DeletePropertyPrompt = (props) => {
  const [isLoading, setLoading] = React.useState(false);

  const { deleteProperty, closeThisDialog, serverError, property } = props;

  const handleDialogClose = (type) => {
    if (type === "confirm") {
      if (props.propertyId) {
        setLoading(true);
        deleteProperty(props.propertyId).catch((error) => {
          serverError(error);
          setLoading(false);
        });
      }
    } else if (type === "cancel") {
      closeThisDialog();
    }
  };

  return (
    <>
      <DialogTitle
        id="scroll-dialog-title"
        className="assign-housekeeper__title"
      >
        Delete property
      </DialogTitle>
      <DialogContent>
        You're about to delete{" "}
        {!isEmpty(property) && (
          <Box
            component="div"
            display="inline"
            p={0.5}
            m={0.2}
            bgcolor="#eaeaea"
          >
            {property.title}
          </Box>
        )}{" "}
        property. Please understand the impact of this action: <br />
        <br />
        <ul style={{ listStyleType: "initial", paddingLeft: "20px" }}>
          <li>All associated calendars will be removed.</li>
          <li>All cleaning jobs linked to this property will be canceled.</li>
        </ul>
        <br />
        This action cannot be undone. Are you absolutely certain you want to
        proceed?
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleDialogClose("cancel")}
          variant="contained"
          disableElevation
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleDialogClose("confirm")}
          variant="contained"
          color="primary"
          style={{ backgroundColor: "#FF2929" }}
          disableElevation
          disabled={isLoading}
        >
          Delete
        </Button>
      </DialogActions>
    </>
  );
};

export default connect(
  (state) => ({}),
  (dispatch) => bindActionCreators({ deleteProperty, serverError }, dispatch)
)(DeletePropertyPrompt);
