import {
  SET_PAYOUT_BALANCE,
  SET_PAYMENT_REMINDER,
  SET_PAYOUT_ACCOUNT,
  SET_PAYMENT_METHOD,
  SET_CLEANING_PAYMENT_INVOICES,
  SET_PAYMENT,
} from "../actions/payments";

export const getPaymentReminders = ({ paymentReminders }) => paymentReminders;
export const getPayment = ({ payment }) => payment;
export const getPayoutBalance = ({ payoutBalance }) => payoutBalance;
export const getPayoutAccount = ({ payoutAccount }) => payoutAccount;
export const getPaymentMethod = ({ paymentMethod }) => paymentMethod;

export const getCleaningPaymentInvoices = ({ cleaningPaymentInvoices }) =>
  cleaningPaymentInvoices;

export const payment = (state = {}, action) => {
  const { data } = action;
  switch (action.type) {
    case SET_PAYMENT:
      return data;
    default:
      return state;
  }
};

export const payoutAccount = (state = {}, action) => {
  const { data } = action;
  switch (action.type) {
    case SET_PAYOUT_ACCOUNT:
      return data;
    default:
      return state;
  }
};

export const paymentReminders = (state = {}, action) => {
  const { data } = action;
  switch (action.type) {
    case SET_PAYMENT_REMINDER:
      return {
        pendingPayment: data.payments_available_count,
        role: data.role,
      };

    default:
      return state;
  }
};

export const paymentMethod = (state = {}, action) => {
  const { data } = action;
  switch (action.type) {
    case SET_PAYMENT_METHOD:
      return data;
    default:
      return state;
  }
};

export const payoutBalance = (state = {}, action) => {
  const { data, type } = action;

  switch (type) {
    case SET_PAYOUT_BALANCE:
      return data;
    default:
      return state;
  }
};

export const cleaningPaymentInvoices = (state = {}, action) => {
  const { data, type } = action;

  switch (type) {
    case SET_CLEANING_PAYMENT_INVOICES:
      return data;
    default:
      return state;
  }
};
