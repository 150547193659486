import React from "react";

export default function({ onPage, totalItems, type, className }) {
  let typeMod = "";
  let page = parseInt(onPage);
  let total = parseInt(totalItems);

  if (type === "jobs") {
    typeMod = page > 1 ? "jobs" : "job";
  } else if (type === "properties") {
    typeMod = page > 1 ? "properties" : "property";
  } else if (type === "cleanings") {
    typeMod = page > 1 ? "cleanings" : "cleaning";
  } else if (type === "invoices") {
    typeMod = page > 1 ? "invoices" : "invoice";
  } else if (type === "hosts") {
    typeMod = page > 1 ? "hosts" : "host";
  } else {
    typeMod = "Unknown";
  }

  if (page > 1) {
    return (
      <p className={`items-count label small ${className ? className : ""}`}>
        Showing {page ? page : "N/A"} of {total ? total : "N/A"} {typeMod}
      </p>
    );
  } else if (page === 1) {
    return (
      <p
        className={`items-count label small ${className ? className : ""}`}
      >{`Showing 1 ${typeMod}`}</p>
    );
  } else {
    return (
      <p className={`items-count italic small ${className ? className : ""}`}>
        No results
      </p>
    );
  }
}
