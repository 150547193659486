import React from "react";
import { useDispatch } from "react-redux";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

import { cancelPayment } from "../../actions/payments";

const CancelPaymentPrompt = (props) => {
  const { id, closeDialog } = props;

  const dispatch = useDispatch();

  const handleDialogClose = (type) => {
    if (type === "confirm") {
      dispatch(cancelPayment(id));
    } else if (type === "cancel") {
      closeDialog();
    }
  };

  return (
    <>
      <DialogTitle id="scroll-dialog-title" className="">
        Cancel payment
      </DialogTitle>
      <DialogContent dividers={true}>
        Are you sure you would like to cancel this payment? This action cannot
        be undone.
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleDialogClose("cancel")}
          variant="contained"
          disableElevation
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleDialogClose("confirm")}
          variant="contained"
          className="host-primary"
          disableElevation
        >
          Cancel payment
        </Button>
      </DialogActions>
    </>
  );
};

export default CancelPaymentPrompt;
