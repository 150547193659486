export const AUTHENTICATE_USER = "authenticate_user";
export const AUTHENTICATE_USER_SUCCESS = "authenticate_user_success";
export const AUTHENTICATE_USER_FAILED = "authenticate_user_failed";

export const SET_CURRENT_USER = "set_current_user";
export const RESET_CURRENT_USER = "reset_current_user";

export const EMPTY_USER_PROFILE = "empty_user_profile";
export const SET_USER_PROFILE = "set_user_profile";

export const CREATE_USER = "create_user";
export const CREATE_USER_SUCCESS = "create_user_success";
export const CREATE_USER_FAILED = "create_user_failed";

export const CONTENT_LOADING = "content_loading";
export const CONTENT_LOADED = "content_loaded";

export const BUTTON_LOADING = "button_loading";
export const BUTTON_LOADED = "button_loaded";

export const FETCH_RESERVATIONS = "fetch_reservations";
export const FETCH_RESERVATIONS_SUCCESS = "fetch_reservations_success";
export const UPDATE_RESERVATION_HOUSEKEEPER = "update_reservation_housekeeper";

export const FETCH_LISTINGS = "fetch_listings";
export const FETCH_LISTINGS_SUCCESS = "fetch_listings_success";

export const DELETE_LISTING = "delete_listing";

export const FETCH_CLEANINGS = "fetch_cleanings";
export const FETCH_CLEANINGS_SUCCESS = "fetch_cleanings_success";
export const UPDATE_CLEANING_STATUS = "update_cleaning_status";

export const MY_HOUSEKEEPERS = "fetch_my_housekeepers";
export const MY_HOUSEKEEPERS_SUCCESS = "fetch_my_housekeepers_success";

export const LINK_CALENDAR = "link_calendar";
export const LINK_CALENDAR_SUCCESS = "link_calendar_success";
export const LINK_CALENDAR_FAILED = "link_calendar_failed";
export const PROPERTY_UPDATING = "property_updating";
export const PROPERTY_UPDATED = "property_updated";
export const RESET_NEW_LISTING_FLOW = "reset_new_listing_flow";

export const UPDATE_USER = "update_user";
export const UPDATE_USER_SUCCESS = "update_user_success";

export const FETCH_USER = "fetch_user";

export const SHOW_MODAL = "show_modal";
export const HIDE_MODAL = "close_modal";
export const SHOW_SNACKBAR = "show_snackbar";
export const HIDE_SNACKBAR = "hide_snackbar";

export const FETCH_SUBSCRIBE = "fetch_subscribe";
