import React, { useState, useEffect } from "react";
import { Typography, Button, Link } from "@material-ui/core";
import { showArticle } from "@intercom/messenger-js-sdk";

import SpacesSelector from "./spaces.js";
import { JOBS } from "../../routes.js";
import { useDispatch, useSelector } from "react-redux";
import { fetchProperty } from "../../actions/pmsProperties.js";
import { Analytics } from "../../services/analyticsService.tsx";

const InAppMarketingCampaign = () => <div></div>;

const StagingGuide = (props) => {
  const {
    propertyId = null,
    isOnboarding = false,
    newPropertyFlow = false,
  } = props;
  const [hasSpace, setSpaceValidation] = useState(false);
  const spaces = useSelector((state) => state.spaces);
  const dispatch = useDispatch();

  useEffect(() => {
    if (propertyId) {
      dispatch(fetchProperty(propertyId));
    }
  }, []);

  useEffect(() => {
    if (spaces && spaces.data?.length > 0) {
      if (!hasSpace) {
        setSpaceValidation(true);
      }
    } else {
      if (hasSpace) {
        setSpaceValidation(false);
      }
    }
  }, [spaces]);

  return (
    <div className="flex flex-column px2 pt3">
      <div>
        <Typography variant="body1">
          Helping you set clear expectations with your cleaner on how each space
          in your property should be staged to ensure your guests gets a 5-star
          experience.{" "}
          <Link
            href="https://help.automatebnb.com/en/articles/9463162-how-to-create-a-staging-guide"
            target="__blank"
          >
            Learn more
          </Link>
        </Typography>
        <div style={{ marginTop: 32, marginBottom: 32 }}>
          <InAppMarketingCampaign campaign="staging" />
        </div>
        <div>
          <SpacesSelector
            propertyId={propertyId}
            isOnboarding={isOnboarding}
            newPropertyFlow={newPropertyFlow}
          />
        </div>
      </div>
      {isOnboarding && (
        <div className="full-width text-center">
          <div style={{ marginTop: 16, paddingBottom: 50 }}>
            <Button
              fullWidth
              variant={hasSpace ? "contained" : "text"}
              color={hasSpace ? "secondary" : "primary"}
              disableElevation
              onClick={() => {
                if (hasSpace) {
                  Analytics.trackSimple("onboarding_finished");
                } else {
                  Analytics.trackSimple("onboarding_staging_guide_skipped");
                }
                window.location.replace(JOBS);
              }}
            >
              {hasSpace ? "Finish onboarding" : "Skip, I'll do it later"}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default StagingGuide;
