import axios from "../helpers/axios";

// NOT sure what it does
import { serverError } from "../helpers/helper_serverError";
import { showSnackbar } from "./action_snackbar";

export const SET_NOTIFICATIONS = "set_notifications";

const MOCK_DATA = [
  {
    id: 1,
    title: "Date change",
    rules: { EMAIL: true, SMS: false, PUSH: true },
    tip: null,
    type: "job",
  },
  {
    id: 2,
    title: "Cancelation",
    rules: { EMAIL: false, SMS: false, PUSH: true },
    tip: null,
    type: "job",
  },
  {
    id: 3,
    title: "24 hours Job reminder",
    rules: { EMAIL: false, SMS: true, PUSH: true },
    tip: null,
    type: "pro",
  },
  {
    id: 4,
    title: "Job marked as complete",
    rules: { EMAIL: false, SMS: true, PUSH: true },
    tip: "Hosts use this to notify their next guest!",
    type: "pros",
  },
  {
    id: 5,
    title: "Accepted or Declined a Job",
    rules: { EMAIL: true, SMS: false, PUSH: true },
    tip: "",
    type: "pros",
  },
  {
    id: 6,
    title: "Payment request is sent",
    rules: { EMAIL: true, SMS: false, PUSH: true },
    tip: "",
    type: "payment",
  },
];

export const fetchNotificationSettings = () => {
  return (dispatch) => {
    return axios
      .get("/v1/user_notification_rules/")
      .then((res) => {
        if (res.status === 200) {
          const _data = res.data;
          dispatch(setNotificationSettings(_data));
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
};

export const updateNotificationSettings = (props) => {
  const { id, email = false, sms = false } = props;
  return (dispatch) => {
    return axios
      .put(`/v1/user_notification_rules/${id}?email=${email}&sms=${sms}`)
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchNotificationSettings());
          dispatch(showSnackbar({ message: "Notification updated" }));
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
};

export const setNotificationSettings = (data) => ({
  type: SET_NOTIFICATIONS,
  data,
});
