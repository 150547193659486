import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";

import history from "../../helpers/history";

import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Checkbox from "@material-ui/core/Checkbox";
import { CircularProgress } from "@material-ui/core";

import { filterParams } from "../../helpers/url";

import { getPMSProperties } from "../../reducers/pmsProperties";
import {
  fetchProperties,
  fetchOpsJobsProperties,
} from "../../actions/pmsProperties";
import { getModalLoading } from "../../reducers/loading";
import { fetchHostJobs } from "../../actions/jobs";
import { fetchOpsJobs } from "../../actions/ops";

const FilterByProperties = (props) => {
  const {
    fetchProperties,
    fetchOpsJobsProperties,
    fetchOpsJobs,
    properties: { isLoading: isContentLoading, items, page },
    closeThisDialog,
    fetchHostJobs,
    propertiesSelected,
    user = {},
  } = props;

  const [selected, setSelected] = useState([]);

  let _location = null;

  useEffect(() => {
    if (user) {
      if (user.is_host) {
        if (isEmpty(items)) {
          fetchProperties({ nextProperties: false, allProperties: true });
        }
      } else {
        if (isEmpty(items)) {
          fetchOpsJobsProperties();
        }
      }
    }
    if (propertiesSelected) {
      setSelected(propertiesSelected);
    }
  }, [user]);

  const handleClose = () => {
    closeThisDialog();
  };

  const handleSearchResults = (type) => {
    _location = filterParams({ propertyIdsParam: selected });

    history.push({
      search: _location,
    });

    if (user.is_host) {
      fetchHostJobs();
    } else {
      fetchOpsJobs();
    }
    handleClose();
  };

  const handleFilterClear = () => {
    setSelected([]);
  };

  const handleRowSelect = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const PropertyDetails = ({ property }) => {
    const {
      title = "",
      street = "",
      city = "",
      state = "",
      zip = "",
    } = property;
    if (user.is_host) {
      return (
        <>
          <p>{title}</p>
          <small className="small grey">{city}</small>
        </>
      );
    } else {
      return (
        <>
          <p>{title}</p>
          <small className="small grey">
            {`${street} ${city}, ${state} ${zip}`}
          </small>
        </>
      );
    }
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return isContentLoading ? (
    <div className="text-center">
      <CircularProgress />
    </div>
  ) : (
    <>
      <DialogTitle
        id="scroll-dialog-title"
        className="assign-housekeeper__title"
      >
        <div className="inline-flex full-width space-between align-center pr pl">
          <CloseIcon className="cursor-pointer" onClick={() => handleClose()} />
          <span>Filter by properties</span>
          <div />
        </div>
      </DialogTitle>
      <DialogContent>
        {isEmpty(items) ? (
          "No properties available to show"
        ) : (
          <TableContainer className="table--max-height-64">
            <Table className="" aria-label="simple table">
              <TableBody>
                {items.map((property, index) => {
                  const isItemSelected = isSelected(property.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      key={property.id}
                      onClick={(event) => handleRowSelect(event, property.id)}
                      role="checkbox"
                      className="cursor-pointer"
                      aria-checked={isItemSelected}
                    >
                      <TableCell component="th" scope="row" padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          color="primary"
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <PropertyDetails property={property} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions>
        <div className="full-width inline-flex align-center space-between">
          <Button onClick={() => handleFilterClear()} disableElevation>
            Clear filters
          </Button>
          <Button
            onClick={() => handleSearchResults()}
            variant="contained"
            color="primary"
            disableElevation
          >
            Apply filters
          </Button>
        </div>
      </DialogActions>
    </>
  );
};

export default connect(
  (state) => ({
    properties: getPMSProperties(state),
  }),
  (dispatch) =>
    bindActionCreators(
      { fetchProperties, fetchOpsJobsProperties, fetchOpsJobs, fetchHostJobs },
      dispatch
    )
)(FilterByProperties);
