import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { isEmpty } from "lodash";

// Material Components
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Radio from "@material-ui/core/Radio";
import TableRow from "@material-ui/core/TableRow";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

// Reducer
import { getMyPros } from "../reducers/pros";

// Actions
import { fetchMyPros } from "../actions/pros";
import { changeAPro } from "../actions/jobs";
import { assignAPro } from "../actions/ops";
import { CircularProgress, Typography } from "@material-ui/core";

const ChangePro = (props) => {
  const {
    fetchMyPros,
    closeThisDialog,
    changeAPro,
    assignAPro,
    multiple = false,
    pros,
    jobId,
    proId,
    isLoading,
  } = props;

  const [selected, setSelected] = React.useState(null);

  useEffect(() => {
    fetchMyPros();
  }, []);

  const handleClick = (id) => {
    setSelected(id);
  };

  const handleSubmit = () => {
    if (multiple) {
      assignAPro({ jobId: jobId, proId: selected });
    } else {
      changeAPro({ jobId: jobId, proId: selected });
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  } else {
    return (
      <>
        <DialogTitle
          id="scroll-dialog-title"
          className="assign-housekeeper__title"
        >
          Assign a pro
          <Typography variant="body2" style={{ color: "rgba(255, 41, 41, 1)" }}>
            Heads up! This will action will send SMS message to your cleaner.
          </Typography>
        </DialogTitle>
        <DialogContent dividers={true}>
          {isEmpty(pros) ? (
            <div className="">There are no Pros found.</div>
          ) : (
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Category</TableCell>
                  <TableCell align="left">Phone</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="host">
                {pros.map((pro) => {
                  const isSelected = pro.id === selected;
                  const { category = {} } = pro;
                  return (
                    <TableRow
                      hover
                      key={pro.id}
                      onClick={() => handleClick(pro.id)}
                      tabIndex={-1}
                      selected={isSelected}
                    >
                      <TableCell padding="checkbox">
                        <Radio
                          checked={isSelected}
                          value={pro.id}
                          name="radio-housekeeper"
                          inputProps={{ "aria-label": `${pro.id}` }}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <div className="flex-r">
                          <p>{`${pro.first} ${pro.last}`}</p>
                          {!pro.email && (
                            <small className="italic grey">Invited</small>
                          )}
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        {pro && pro.category ? pro.category.name : "--"}
                      </TableCell>
                      <TableCell align="left">{pro.phone}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </DialogContent>
        <DialogActions>
          <div className="inline-flex full-width justify-end">
            <div className="pms-dialog__actions">
              <Button
                onClick={closeThisDialog}
                variant="contained"
                disableElevation
                className="actions actions--default"
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                disabled={!selected}
                variant="contained"
                disableElevation
                color="primary"
              >
                Save
              </Button>
            </div>
          </div>
        </DialogActions>
      </>
    );
  }
};

export default connect(
  (state) => ({
    pros: getMyPros(state).items,
    isLoading: getMyPros(state).isLoading,
  }),
  (dispatch) =>
    bindActionCreators({ fetchMyPros, assignAPro, changeAPro }, dispatch)
)(ChangePro);
