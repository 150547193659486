export const SET_ONBOARDING_ANALYTICS = "set_analytics_onboarding";
export const RESET_ONBOARDING_ANALYTICS = "reset_analytics_onboarding";

export const setOnboardingAnalytics = (data) => ({
  type: SET_ONBOARDING_ANALYTICS,
  data,
});

export const resetOnboardingAnalytics = () => ({
  type: RESET_ONBOARDING_ANALYTICS,
});

export const resigerOnboardingAnalyticsToSegment = (state) => {
  // Analytics
  let _trackString = "";
  const { step0 = "", step1 = "", step2 = "", step3 = "", step4 = "" } = state;

  if (
    step1 === "completed" &&
    step2 === "completed" &&
    (step3 === "completed" || step4 === "completed")
  ) {
    // Fully onboarded
    _trackString = "completed";
  } else if (step1 === "completed" && step2 === "completed") {
    // Housekeeper step skipped
    _trackString = "calendar imported";
  } else if (step1 === "completed") {
    // Property details added
    _trackString = "property created";
  } else {
    if (step0 === "initiated") {
      _trackString = "skipped";
    }
  }

  return (dispatch) => {
    if (state !== {} && _trackString !== "") {
      window.analytics.track("Onboarding", {
        step: _trackString,
        env: process.env.REACT_APP_ENV,
      });
      dispatch(resetOnboardingAnalytics());
    }
  };
};
