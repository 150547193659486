import React from "react";
import classnames from "classnames";

import {
  WAITING_TO_ACCEPT,
  CLEANER_STATUS_DECLINED,
  CLEANER_STATUS_ACCEPTED,
  CLEANING_DATE_CHANGED,
  CLEANING_CANCELLED,
  PAYMENT_STATUS_PENDING,
  PAYMENT_STATUS_SUCCESS,
  PAYMENT_STATUS_PROCESSING,
  PAYMENT_STATUS_FAILED,
  CLEANER_STATUS_COMPLETED,
  CLEANING_STARTED,
} from "../constants/apiEnum";

import "./normalizedCleaningStatus.scss";

const statusMap = {
  [WAITING_TO_ACCEPT]: "Waiting to accept",
  [CLEANER_STATUS_DECLINED]: "Declined",
  [CLEANER_STATUS_ACCEPTED]: "Accepted",
  [CLEANING_STARTED]: "Job started",
  [CLEANING_DATE_CHANGED]: "Rescheduled",
  [PAYMENT_STATUS_PENDING]: "Payment pending",
  [PAYMENT_STATUS_SUCCESS]: "Payment paid",
  [PAYMENT_STATUS_PROCESSING]: "Payment processing",
  [PAYMENT_STATUS_FAILED]: "Payment failed",
  [CLEANER_STATUS_COMPLETED]: "Completed",
};

export default function NomalizedCleaningStatus({ status, human }) {
  return (
    <span className="cleaning-status">
      <span
        className={classnames(
          "status text-nowrap",
          { "status--success": status === CLEANER_STATUS_ACCEPTED },
          { "status--danger": status === CLEANER_STATUS_DECLINED },
          { "status--grey": status === WAITING_TO_ACCEPT },
          { "status--orange": status === CLEANING_DATE_CHANGED },
          { "status--danger": status === PAYMENT_STATUS_PENDING },
          { "status--success": status === PAYMENT_STATUS_SUCCESS },
          { "status--grey": status === PAYMENT_STATUS_PROCESSING },
          { "status--grey": status === CLEANER_STATUS_COMPLETED },
          { "status--danger": status === PAYMENT_STATUS_FAILED },
          { "status--grey": status === CLEANING_CANCELLED },
          { "status--started": status === CLEANING_STARTED }
        )}
      >
        {human ? human : statusMap[status]}
      </span>
    </span>
  );
}
