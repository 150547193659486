import React from "react";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

function GenericDialog(props) {
  const {
    title = null,
    body = null,
    secondaryButton = {},
    primaryButton = {},
  } = props;

  const dispatch = useDispatch();

  // console.log(props);

  const { label: secondaryLabel, action: secondaryAction } = secondaryButton;
  const {
    label: primaryLabel,
    action: primaryAction,
    type: primaryType = "positive",
  } = primaryButton;

  const handleSecondaryAction = () => {
    dispatch(secondaryAction());
  };

  const handlePrimaryAction = () => {
    dispatch(primaryAction());
  };

  return (
    <>
      {title && (
        <DialogTitle
          id="scroll-dialog-title"
          className="pms-dialog__title assign-housekeeper__title"
        >
          {title}
        </DialogTitle>
      )}
      {body && (
        <DialogContent dividers={true} style={{ position: "relative" }}>
          {body}
        </DialogContent>
      )}
      {(!isEmpty(secondaryButton) || !isEmpty(primaryButton)) && (
        <DialogActions>
          <div className="inline-flex justify-end full-width">
            <div className="pms-dialog__actions">
              {!isEmpty(secondaryButton) && (
                <Button
                  onClick={handleSecondaryAction}
                  variant="contained"
                  disableElevation
                  className="button"
                  style={{ marginRight: !isEmpty(primaryButton) && "16px" }}
                >
                  {secondaryLabel}
                </Button>
              )}
              {!isEmpty(primaryButton) && (
                <Button
                  onClick={handlePrimaryAction}
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: primaryType === "negative" && "red",
                  }}
                  disableElevation
                  className="button"
                >
                  {primaryLabel}
                </Button>
              )}
            </div>
          </div>
        </DialogActions>
      )}
    </>
  );
}

export default GenericDialog;
