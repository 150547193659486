import React from "react";
import { isEmpty } from "lodash";
import CleaningImageViewer from "../components/cleaningImageViewer";
import { useSelector } from "react-redux";
import { CLEANING_STARTED } from "../constants/apiEnum";

interface WalkthroughProps {
  is_host: boolean;
  status: string;
  job_id: string;
  is_past: boolean;
  isStarted: boolean;
  isCompleted?: boolean;
  isWaiting?: boolean;
  isDeclined?: boolean;
  isAccepted?: boolean;
}

const JobWalkthrough: React.FC<WalkthroughProps> = ({
  job_id,
  status,
  is_host,
  is_past,
  isStarted,
  isAccepted,
  isDeclined,
  isWaiting,
  isCompleted,
}) => {
  const tasks = useSelector((state) => state.walkthroughTasks);

  const RenderWalkthroughLogic = () => {
    if (is_host) {
      if (isCompleted) {
        return (
          <div>
            <CleaningImageViewer
              images={tasks.data}
              noImagesLabel="No photos availble. Remind your pro to use this for future jobs."
            />
          </div>
        );
      } else {
        return (
          <div>
            <p className="mb-2">
              Photos will be available after your pro takes them post-cleaning
              and staging.
            </p>
            <CleaningImageViewer
              images={tasks.data}
              noImagesLabel="Remind your pro to use this feature. It is included in your
              subscription."
            />
          </div>
        );
      }
    } else {
      if (isAccepted) {
        return (
          <div>
            <p className="mb-2">
              You can take post-cleaning and staging photos directly in the
              mobile app.
            </p>
            <p className="italic mt-2 text-gray-500 text-sm">
              <a
                href="https://automatebnb.com/downloads?source=direct"
                target="_new"
                className="link"
              >
                Download our free app
              </a>{" "}
              to access this feature
            </p>
          </div>
        );
      } else {
        return (
          <div>
            <p className="mb-2">
              Photos will be available as soon as they are taken post-cleaning
              and staging.
            </p>
            <CleaningImageViewer
              images={tasks.data}
              noImagesLabel="No photos taken"
            />
          </div>
        );
      }
    }
  };

  return (
    <div className="mb-4 px-4 mt-4 py-4 bg-white rounded-md">
      <h3 className="txt-md font-semibold mb-2">Walkthrough</h3>
      <RenderWalkthroughLogic />
    </div>
  );
};

export default JobWalkthrough;
