import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Material UI - Dialog
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import { unassignProFromProperty } from "../actions/properties";

function PromptToUnassignProFromProperty(props) {
  const {
    closeThisDialog,
    assignedPro,
    proId,
    propertyId,
    property,
    unassignProFromProperty,
  } = props;

  const { first = null, last = null } = assignedPro;
  const { title = null } = property;

  function handleClose() {
    closeThisDialog();
  }

  const handleProUnassignement = () => {
    unassignProFromProperty(propertyId);
  };

  return (
    <>
      <DialogTitle id="scroll-dialog-title" className="client-modal">
        Unassign pro
      </DialogTitle>
      <DialogContent
        style={{ position: "relative", lineHeight: "140%" }}
        className="ab-dialog"
      >
        You are set to unassign{" "}
        <Box component="div" display="inline" p={0.5} m={0.2} bgcolor="#eaeaea">
          {`${first} ${last}`}
        </Box>{" "}
        from{" "}
        <Box component="div" display="inline" p={0.5} m={0.2} bgcolor="#eaeaea">
          {title}
        </Box>{" "}
        property, which will trigger cancellation notifications to this pro.
        Until another pro is assigned, all cleaning jobs will require manual
        assignment.
        <br />
        <br />
        Are you absolutely certain you want to proceed?
      </DialogContent>
      <DialogActions>
        <div className="inline-flex justify-end full-width">
          <div className="pms-dialog__actions">
            <Button
              onClick={handleClose}
              variant="text"
              style={{ marginRight: "8px" }}
              disableElevation
            >
              Cancel
            </Button>
            <Button
              onClick={handleProUnassignement}
              variant="contained"
              color="primary"
              disableElevation
              style={{ backgroundColor: "#FF2929" }}
            >
              Unassign
            </Button>
          </div>
        </div>
      </DialogActions>
    </>
  );
}

export default connect(
  (state) => ({}),
  (dispatch) => bindActionCreators({ unassignProFromProperty }, dispatch)
)(PromptToUnassignProFromProperty);
