import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";

// Material Components
import DialogContent from "@material-ui/core/DialogContent";
import { TextField } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// Actions
import { updateJobDescription } from "../actions/jobs";

const JobDescription = (props) => {
  const { description, jobId, closeThisDialog, updateJobDescription } = props;

  const [newNote, setNewNote] = useState(description);
  const [checked, setChecked] = useState(true);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleInputChange = (event) => {
    setNewNote(event.target.value);
  };

  const handleSubmit = () => {
    updateJobDescription({ newNote, checked, jobId });
  };

  return (
    <>
      <DialogTitle
        id="scroll-dialog-title"
        className="assign-housekeeper__title"
      >
        {isEmpty(description) ? "Add note" : "Update note"}
      </DialogTitle>
      <DialogContent dividers={true} className="ab-dialog__body">
        <TextField
          type="time"
          variant="outlined"
          row="5"
          placeholder="e.g., please check if supplies are running low"
          onChange={handleInputChange}
          autoFocus={true}
          multiline
          defaultValue={newNote}
          fullWidth={true}
        />
        {/* CHANGE THIS BASED on JOB CATEGORY */}
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={checked}
                onChange={handleCheckboxChange}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            }
            label="Notify your pro."
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeThisDialog} variant="contained" disableElevation>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disableElevation
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
};
export default connect(
  (state) => ({}),
  (dispatch) =>
    bindActionCreators(
      {
        updateJobDescription,
      },
      dispatch
    )
)(JobDescription);
