import React from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

import Navigation from "../containers/navigation";
import LoadingScreen from "../components/loadingScreen";
import PaymentReminder from "../components/paymentReminder";
import { getUserToken } from "../reducers/user";

import HostJobs from "./hostJobs";
import OpsJobsList from "./opsJobsList";
import ProsJobs from "./prosJobs";

import "./jobs.scss";

{
  /* 
Fix bug "Make payment" action is not hooked with the new changes
<PaymentReminder key="host-jobs-componet-payment-remunder" /> 
*/
}

const Jobs = ({ userToken, match, location }) => {
  const isOps = userToken.is_ops;
  const isHost = userToken.is_host;

  return (
    <>
      <Navigation />
      {isEmpty(userToken) ? (
        <LoadingScreen />
      ) : isHost ? (
        <HostJobs key="host-jobs-component" />
      ) : isOps ? (
        <OpsJobsList />
      ) : (
        <ProsJobs />
      )}
    </>
  );
};

export default connect((state) => ({
  userToken: getUserToken(state),
}))(Jobs);
