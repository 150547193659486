import React from "react";
import { useSelector } from "react-redux";

import CircularProgress from "@material-ui/core/CircularProgress";

import "./overlayLoadingScreen.scss";

const OverlayLoadingScreen = props => {
  const overlay = useSelector(state => state.overlay);

  return overlay.show ? (
    <div className="overlay-loading">
      <CircularProgress />
    </div>
  ) : null;
};

export default OverlayLoadingScreen;
