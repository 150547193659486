import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Checkbox from "@material-ui/core/Checkbox";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";

import { delayCleaning } from "../../actions/pmsProperties";

const DelayCleaningAgreement = (props) => {
  const { id, closeDialog } = props;
  const [agreementSelected, setAgreement] = useState([]);

  const dispatch = useDispatch();

  const handleDialogClose = (type) => {
    if (type === "add") {
      dispatch(delayCleaning(id, true));
    } else if (type === "cancel") {
      closeDialog();
    }
  };

  const handleAgreementRowSelect = (event, name) => {
    const selectedIndex = agreementSelected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(agreementSelected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(agreementSelected.slice(1));
    } else if (selectedIndex === agreementSelected.length - 1) {
      newSelected = newSelected.concat(agreementSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        agreementSelected.slice(0, selectedIndex),
        agreementSelected.slice(selectedIndex + 1)
      );
    }

    setAgreement(newSelected);
  };

  const isSelected = (name) => agreementSelected.indexOf(name) !== -1;

  return (
    <>
      <DialogTitle id="scroll-dialog-title" className="delay-cleaning-modal">
        Delay cleaning
      </DialogTitle>
      <DialogContent dividers={true} className="host">
        <TableContainer className="table--max-height-64">
          <Table className="" aria-label="simple table">
            <TableBody>
              <TableRow
                key="cdc-agreement"
                onClick={(event) =>
                  handleAgreementRowSelect(event, "cdc-agreement")
                }
                role="checkbox"
                className="cursor-pointer"
                aria-checked={isSelected("cdc-agreement")}
              >
                <TableCell component="th" scope="row" padding="checkbox">
                  <Checkbox
                    checked={isSelected("cdc-agreement")}
                    inputProps={{
                      "aria-labelledby":
                        "enhanced-table-checkbox-cdc-agreement",
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  I acknowledge that Airbnb advices a 24 hour wait period before
                  cleaning is scheduled.
                </TableCell>
              </TableRow>
              <TableRow
                key="airbnb-48"
                role="checkbox"
                className="cursor-pointer"
                aria-checked={isSelected("airbnb-48")}
              >
                <TableCell
                  component="th"
                  scope="row"
                  padding="checkbox"
                  onClick={(event) =>
                    handleAgreementRowSelect(event, "airbnb-48")
                  }
                >
                  <Checkbox
                    checked={isSelected("airbnb-48")}
                    inputProps={{
                      "aria-labelledby": "enhanced-table-checkbox-airbnb-48",
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  <div
                    onClick={(event) =>
                      handleAgreementRowSelect(event, "airbnb-48")
                    }
                    className="table-cell--negative"
                  >
                    I achnowledge that I have blocked minimum of 24 hours before
                    next reservation.
                  </div>
                  <a
                    href="https://automatebnb.notion.site/How-to-add-booking-buffer-on-Airbnb-3588ed37e11a46c39e3865b58e7146e8#485fd1a7b3a74f3885e7dd9b58b280e0"
                    target="_new"
                  >
                    How to block preparation time on Airbnb?
                  </a>
                </TableCell>
              </TableRow>
              <TableRow
                key="automatebnb-agreement"
                onClick={(event) =>
                  handleAgreementRowSelect(event, "automatebnb-agreement")
                }
                role="checkbox"
                className="cursor-pointer"
                aria-checked={isSelected("automatebnb-agreement")}
              >
                <TableCell component="th" scope="row" padding="checkbox">
                  <Checkbox
                    checked={isSelected("automatebnb-agreement")}
                    inputProps={{
                      "aria-labelledby":
                        "enhanced-table-checkbox-automatebnb-agreement",
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  I achnowledge that Automatebnb will add a 1 day wait period to
                  the cleaning.
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleDialogClose("cancel")}
          variant="contained"
          disableElevation
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleDialogClose("add")}
          variant="contained"
          color="primary"
          className="host-primary"
          disableElevation
          disabled={agreementSelected.length !== 3}
        >
          Activate
        </Button>
      </DialogActions>
    </>
  );
};

export default DelayCleaningAgreement;
