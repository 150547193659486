import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { isEmpty } from "lodash";
import classnames from "classnames";

// MUI
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import { CircularProgress, useMediaQuery } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

// Reducers
import { getMyPros } from "../reducers/pros";
import { getSubscription } from "../reducers/reducer_subscribe";
import { getUserToken } from "../reducers/user";

// Actions
import { openThisDialog } from "../actions/dialog";
import { fetchMyPros, deletePro } from "../actions/pros";

// Components
import Filter from "../containers/filter";
import LoadingScreen from "../components/loadingScreen";

// Containers
import Navigation from "../containers/navigation";
import Pagination from "../containers/pagination";

// Constants
import { DT_INVITE_PRO, DT_SUBSCRIBE_PROMPT } from "../constants/dialogTypes";

// ENUM
import { HOST_STRIPE_FREE_PLAN } from "../constants/apiEnum";

// Styles
import "../jobs/jobs.scss";
import "./myPros.scss";

const MyPros = (props) => {
  const {
    fetchMyPros,
    openThisDialog,
    deletePro,
    pros = {},
    subscribe = {},
    userToken = {},
  } = props;

  const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState(
    false
  );

  const [localDialog, setLocalDialog] = React.useState(false);

  const descriptionElementRef = React.useRef(null);

  const [proSelectedToDelete, setProToDelete] = React.useState(null);

  // Extract pros
  const { isLoading = true, items: mypros = {}, page = {} } = pros;

  const isMobile = useMediaQuery("(max-width:600px)");

  const isOps = userToken.is_ops;

  const isOnClientPaidPlan = subscribe.stripe_plan === HOST_STRIPE_FREE_PLAN;

  useEffect(() => {
    fetchMyPros();
  }, []);

  const handleInvitePro = (location) => {
    if (isOnClientPaidPlan) {
      setLocalDialog(true);
    } else {
      if (subscribe && subscribe.deactivated) {
        openThisDialog({
          dialogType: DT_SUBSCRIBE_PROMPT,
          dialogBody: "Your subscription has ended. Please subscribe first.",
        });
      } else {
        // Open dialog to invite pros
        openThisDialog({ dialogType: DT_INVITE_PRO });
      }
    }
  };

  const handleConfirmationDialogOpen = () => {
    setOpenConfirmationDialog(true);
  };

  const handleConfirmationDialogClose = () => {
    setOpenConfirmationDialog(false);
  };
  React.useEffect(() => {
    if (openConfirmationDialog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openConfirmationDialog]);

  const RenderMyProsMobileCard = ({ pros }) => {
    return pros.map((pro) => {
      const {
        id: proId = "",
        first = "",
        last = "",
        category = {},
        phone = "",
        email = "",
      } = pro;

      const handleRemovePro = () => {
        setProToDelete({ id: proId, first, last });
        handleConfirmationDialogOpen();
      };

      return (
        <div key={`my-pros-card-${proId}`} className="my-pros my-pros__card">
          <h2 className="header-3 mb1 my-pros__title">
            {`${first} ${last}`}{" "}
            {!isEmpty(category) && (
              <span className="small grey">{category.name}</span>
            )}
            {isEmpty(category) && <span className="italic grey">Invited</span>}
          </h2>
          <p className="my-pros__contact-info">
            <span className="label">Phone: </span>
            <a className="link" href={`tel:${phone}`}>
              {phone}
            </a>
          </p>
          <p className="my-pros__contact-info">
            <span className="label">Email: </span>
            {email ? (
              <a className="link" href={`mailto:${email}`}>
                {email}
              </a>
            ) : (
              " -- "
            )}
          </p>
          <div className="mt2 mb2">
            <Button
              variant="outlined"
              onClick={() => handleRemovePro()}
              startIcon={<RemoveCircleIcon />}
              size="small"
            >
              Remove pro
            </Button>
          </div>
        </div>
      );
    });
  };

  const DeleteProConfirmationDialog = () => {
    // Use isSelected to diable the primary action
    const [feedbackOptions, setFeedbackOptions] = React.useState([
      { id: "punctuality", label: "Lack of punctuality", checked: false },
      { id: "unreliability", label: "Unreliability", checked: false },
      { id: "communication", label: "Poor communication", checked: false },
      { id: "other", label: "Other", checked: false },
    ]);
    const [otherOptionSelected, setOtherOptionSelected] = React.useState(false);
    const [isOptionSelected, setOptionSelected] = React.useState(false);
    const [reasonToDelete, setReasonToDelete] = React.useState("");

    const handleProDeletion = () => {
      const matchedOptions = feedbackOptions.filter(
        (option) => option.checked === true
      );
      const matchedOptionsLabel = matchedOptions.map((option) => option.label);

      const otherIndex = matchedOptionsLabel.indexOf("Other");
      const { id } = proSelectedToDelete;

      if (matchedOptionsLabel[otherIndex] === "Other") {
        if (!isEmpty(reasonToDelete)) {
          matchedOptionsLabel[otherIndex] = `Other: ${reasonToDelete}`;
        }
      }

      deletePro({ id, reasons: matchedOptionsLabel });
      handleConfirmationDialogClose();
    };

    const handleOptionChange = (id) => {
      const selectedOption = feedbackOptions.map((option) => {
        if (option.id === id) {
          //
          return { ...option, checked: !option.checked };
        }
        return option;
      });

      setFeedbackOptions(selectedOption);

      if (id === "other") {
        // Need more information
        const _other = selectedOption.find((option) => option.id === "other");
        setOtherOptionSelected(_other.checked);
      }

      const _anyOptionSelected = Boolean(
        selectedOption.find((option) => option.checked === true)
      );
      setOptionSelected(_anyOptionSelected);
    };

    const handleReasonToDeleteValue = (event) => {
      setReasonToDelete(event.target.value);
    };

    if (proSelectedToDelete) {
      const { id, first, last } = proSelectedToDelete;
      const name = `${first} ${last}`;

      return (
        <Dialog
          open={openConfirmationDialog}
          scroll="paper"
          aria-labelledby="Confirm before deleting"
          aria-describedby="Confirm with user first to delete a pro"
          className="pros-dialog"
        >
          <DialogTitle id="scroll-dialog-title" style={{ fontSize: "18px" }}>
            Are you sure?
          </DialogTitle>
          <DialogContent className="pros-dialog__content">
            <div style={{ lineHeight: "120%" }}>
              <p>
                This action <span className="semibold">CANNOT</span> be undone.{" "}
                <span className="semibold">{name}</span> will be:
              </p>
              <ul style={{ listStyleType: "initial", paddingLeft: "20px" }}>
                <li>Removed from your account.</li>
                <li>Any future jobs assigned to them will be unassigned.</li>
                <li>
                  Any property automation that was assigned to this pro will
                  also be reset.
                </li>
                <li>Will be notified of the job cancellations accordingly.</li>
              </ul>
              <div className="pros-dialog__feedback">
                <p>
                  Reason for removing <span className="semibold">{name}</span>:
                </p>
                <small className="italic grey small">
                  Your feedback will not be shared with the pro.
                </small>
                <div className="pros-dialog__feedback--options">
                  <FormControl component="fieldset" fullWidth>
                    <FormGroup>
                      {feedbackOptions.map((option) => (
                        <FormControlLabel
                          key={`form-control-options-${option.id}`}
                          control={
                            <Checkbox
                              color="primary"
                              checked={option.checked}
                              onChange={() => handleOptionChange(option.id)}
                              name={option.id}
                            />
                          }
                          label={option.label}
                        />
                      ))}
                    </FormGroup>
                    {otherOptionSelected && (
                      <TextField
                        id="standard-textarea"
                        label="Describe your reasoning."
                        multiline
                        variant="outlined"
                        fullWidth
                        value={reasonToDelete}
                        onChange={handleReasonToDeleteValue}
                        style={{ marginTop: "4px" }}
                      />
                    )}
                  </FormControl>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions className="pros-dialog__actions">
            <Button
              variant="contained"
              onClick={handleProDeletion}
              color="primary"
              disableElevation
              fullWidth
              disabled={!isOptionSelected}
              className="btn-danger"
            >
              I understand, remove this Pro
            </Button>
            <Button
              variant="text"
              onClick={handleConfirmationDialogClose}
              color="primary"
              fullWidth
              style={{ marginTop: "8px" }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      );
    } else return null;
  };

  const RenderMyProsDesktopTables = () => {
    const handleRemovePro = ({ id, first, last }) => {
      setProToDelete({ id, first, last });
      handleConfirmationDialogOpen();
    };
    return (
      <Table aria-label="simple table" className="table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Pros</TableCell>
            <TableCell align="left">Category</TableCell>
            <TableCell align="left">Phone</TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="--bk-white">
          {mypros.map((pro) => {
            const { id, first, last, phone, email, category = "--" } = pro;
            return (
              <TableRow key={id} hover={false}>
                <TableCell component="th" scope="row">
                  {`${first} ${last}`}
                  {!email && (
                    <Chip
                      label="Invited"
                      color="primary"
                      variant="outlined"
                      style={{
                        height: "29px",
                        marginLeft: "10px",
                      }}
                    />
                  )}
                </TableCell>
                <TableCell align="left">{category}</TableCell>
                <TableCell align="left">{phone}</TableCell>
                <TableCell align="left">{email ? email : "--"}</TableCell>
                <TableCell align="left">
                  <Button
                    variant="text"
                    onClick={() => handleRemovePro(pro)}
                    startIcon={<RemoveCircleIcon />}
                    style={{ opacity: 0.8 }}
                  >
                    Remove pro
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  const RenderMyProsHeader = () => {
    if (!isMobile) {
      if (isEmpty(mypros)) {
        return null;
      } else {
        if (isOps) {
          return (
            <div
              className="inline-flex align-center mt2 mb2 pl2"
              key="host-jobs-primary-action"
            >
              <h1 className="page-header mr1">My Pros</h1>
              <Fab
                onClick={() => handleInvitePro()}
                color="primary"
                aria-label="Invite a pro"
                style={{ boxShadow: "none" }}
                size="small"
              >
                <AddIcon />
              </Fab>
            </div>
          );
        } else {
          return (
            <div className="primary-actions mb2" key="host-jobs-primary-action">
              <h1 className="page-header">My Pros</h1>
              <Button
                variant="contained"
                color="secondary"
                disableElevation={true}
                onClick={() => handleInvitePro()}
              >
                Invite a pro
              </Button>
            </div>
          );
        }
      }
    } else return null;
  };

  const MyProsEmptyState = () => {
    return (
      <div
        style={{ height: "calc(100% - 55px)" }}
        className="full-width full-height inline-flex justify-center align-center mt3"
      >
        <div className="text-center">
          <h1 className="header-3">Let’s start building your team.</h1>
          {/* ONLY render CTA Request a Job in a ToDo Tab */}

          <Button
            variant="contained"
            color="primary"
            disableElevation={true}
            onClick={() => handleInvitePro("empty")}
          >
            Invite a pro
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Navigation />
      {isLoading ? (
        <div
          className="inline-flex align-center justify-center full-width"
          style={{ height: "90%" }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="page-wrapper">
            <div className="page-content">
              <RenderMyProsHeader />
              <div className="table-wrapper table-wrapper--nav-title table-wrapper--no-padding">
                {isEmpty(mypros) ? (
                  <MyProsEmptyState />
                ) : (
                  <>
                    {/* HIDE CATEGORIES FILTER FOR NOW */}
                    {false && (
                      <div className="table-wrapper__filters">
                        <Filter displaySelectedValue={true} filterType="pros" />
                      </div>
                    )}
                    {isMobile ? (
                      <RenderMyProsMobileCard pros={mypros} />
                    ) : (
                      <RenderMyProsDesktopTables />
                    )}
                    {/* Pagination */}
                    <Pagination
                      nextPage={page.nextPage}
                      prevPage={page.prevPage}
                      type="pros"
                    />
                    <div className="intercom-spacer" />
                  </>
                )}
              </div>
            </div>
          </div>
          <DeleteProConfirmationDialog />
          <Dialog
            open={localDialog}
            scroll="paper"
            aria-labelledby="Confirm before deleting"
            aria-describedby="Confirm with user first to delete a pro"
            className="pros-dialog"
          >
            <DialogTitle>Contact us</DialogTitle>
            <DialogContent className="pros-dialog__content">
              <div style={{ lineHeight: "120%" }}>
                We would like to inform you that your account is paid by your
                cleaning business, therefore, you are not able to invite a pro.
                If you would like to work with a different pro, please contact
                us for assistance{" "}
                <a href="mailto:help@automatebnb.com">help@automatebnb.com</a>.
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                variant="text"
                onClick={() => setLocalDialog(false)}
                color="primary"
                variant="contained"
                disableElevation
              >
                Done
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

export default connect(
  (state) => ({
    pros: getMyPros(state),
    userToken: getUserToken(state),
    subscribe: getSubscription(state).data,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        fetchMyPros,
        deletePro,
        openThisDialog,
      },
      dispatch
    )
)(MyPros);
