import React, { useState } from "react";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { useDispatch } from "react-redux";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

import { LOG_IN } from "../routes";

import { forgotPassword } from "../actions/user";

import "./publicPages.scss";
import { CircularProgress } from "@material-ui/core";
import { serverError } from "../helpers/helper_serverError";

const ForgotPassword = (props) => {
  const { forgotPassword, serverError } = props;

  const [values, setValues] = useState({
    email: "",
  });

  const [recoverInitiated, setRecoverInitiation] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const isValid = () => {
    return !isEmpty(values.email);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newVal = { ...values, [name]: value };
    setValues(newVal);
  };

  const handleSubmit = () => {
    if (values && isValid()) {
      // Change the button state to loading
      setButtonLoading(true);
      // Reset error message
      if (!isEmpty(errorMessage)) {
        setErrorMessage(null);
      }
      // Fetch API
      forgotPassword(values)
        .then((res) => {
          if (res && res.status === 200) {
            setRecoverInitiation(true);
          }
        })
        .catch((error) => {
          setButtonLoading(false);
          setErrorMessage(
            error.response.data.message || "Uh oh! Something went wrong"
          );
        });
    }
  };

  return (
    <div className="page-wrapper ab-public">
      <div className="page-content ab-public__password" key="forgot-password">
        {recoverInitiated ? (
          <div className="form">
            <h1 className="header-3 semibold text-center">Recover password</h1>
            <p className="mb2">
              An email has been sent. Please click the link when you get it.
            </p>
          </div>
        ) : (
          <div className="form">
            <h1 className="header-3 semibold text-center">Recover password</h1>
            <h2 className="text-center grey">
              Don’t worry, happens to the best of us.
            </h2>{" "}
            <br />
            <p className="label mb2">
              Enter the email address you used when you joined and we’ll send
              you instructions to reset your password.
            </p>
            <div className="mb3">
              <TextField
                required
                error={!isEmpty(errorMessage)}
                name="email"
                label="Email"
                fullWidth={true}
                onChange={handleInputChange}
                variant="outlined"
                value={values.email}
              />
              {errorMessage && (
                <small className="small danger">{errorMessage}</small>
              )}
            </div>
            <div className="mt3">
              {buttonLoading ? (
                <Button
                  fullWidth={true}
                  color="primary"
                  disableElevation
                  variant="contained"
                  className="host-primary"
                >
                  <CircularProgress size={32} style={{ color: "white" }} />
                </Button>
              ) : (
                <Button
                  onClick={() => handleSubmit()}
                  fullWidth={true}
                  color="secondary"
                  disableElevation
                  variant="contained"
                  caps
                >
                  Recover password
                </Button>
              )}
            </div>
          </div>
        )}
        <h3 className="text-center pt3 header-3">
          Already have an account?{" "}
          <Link href={LOG_IN} style={{ whiteSpace: "nowrap" }}>
            Log In
          </Link>
        </h3>
      </div>
    </div>
  );
};

export default connect(null, (dispatch) =>
  bindActionCreators({ forgotPassword, serverError }, dispatch)
)(ForgotPassword);
