export const SET_CONTENT_LOADING = "set_content_loading";
export const SET_MODAL_LOADING = "set_modal_loading";

export const setContentLoading = (loading = false) => ({
  type: SET_CONTENT_LOADING,
  loading,
});

export const setModalLoading = (loading) => ({
  type: SET_MODAL_LOADING,
  loading,
});
