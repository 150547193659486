import React from "react";

// Material UI - Dialog
import Link from "@material-ui/core/Link";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

// Helpers
import history from "../helpers/history";

// route
import { HOST_SUBSCRIBE } from "../routes";

function PromptToSubscribe(props) {
  const { closeThisDialog, dialogBody = null } = props;

  const handleSubscribe = () => {
    closeThisDialog();
    history.push(HOST_SUBSCRIBE);
  };

  const dialogContent = dialogBody
    ? dialogBody
    : "Your trial has ended. Please subscribe and add a payment method first.";

  return (
    <>
      <DialogTitle
        id="scroll-dialog-title"
        className="pms-dialog__title assign-housekeeper__title"
      >
        Subscribe
      </DialogTitle>
      <DialogContent dividers={true} style={{ position: "relative" }}>
        {dialogContent}
      </DialogContent>
      <DialogActions>
        <div className="inline-flex justify-end full-width">
          <div className="pms-dialog__actions">
            <Button
              onClick={closeThisDialog}
              variant="contained"
              disableElevation
              className="button"
            >
              Close
            </Button>
            {
              <Button
                onClick={handleSubscribe}
                variant="contained"
                disableElevation
                className="button button--primary"
                style={{ marginLeft: "8px" }}
              >
                Subscribe
              </Button>
            }
          </div>
        </div>
      </DialogActions>
    </>
  );
}

export default PromptToSubscribe;
