import axios from "../helpers/axios";

// NOT sure what it does
import { serverError } from "../helpers/helper_serverError";
import { showSnackbar } from "./action_snackbar";

import setAuthToken from "../helpers/helper_setAuthToken";
import { fetchSubscription } from "./subscription";
import { fetchUser, setUser } from "./user";
import { Analytics } from "../services/analyticsService.tsx";

export function fetchHost() {
  return (dispatch) => {
    return axios
      .get("/v1/hosts/1")
      .then((res) => {
        if (res.status === 200) {
          dispatch(setUser(res.data));
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
}

export function updateHost(values) {
  return (dispatch) => {
    return axios
      .put(`/v1/hosts/${values.id}`, values)
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            showSnackbar({ message: "Profile Updated!", toReservation: true })
          );
          dispatch(setUser(res.data));
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
}

export function registerHost(data) {
  return (dispatch) => {
    return axios
      .post("/v1/hosts/signup", data)
      .then((res) => {
        if (res) {
          const { status, data } = res;
          if (status === 200 || status === 201) {
            if (data.jwt) {
              setAuthToken(data.jwt);
              localStorage.setItem("jwtToken", data.jwt);
              dispatch(fetchUser("signup"));
              dispatch(fetchSubscription());
            }
          }
        } else {
          dispatch(
            showSnackbar({
              message: "Something went wrong. Please try again",
              hideDuration: 8000,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
}
