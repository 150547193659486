import {
  RESET_RESERVATIONS,
  SET_RESERVATIONS,
  SET_RESERVATION
} from "../actions/pmsReservations";

export const getReservations = ({ reservations }) => reservations;
export const getReservation = ({ reservation }) => reservation;

export const reservations = (
  state = { page: { total: 0 }, items: [] },
  action
) => {
  const { data } = action;
  switch (action.type) {
    case RESET_RESERVATIONS:
      return { page: { total: 0 }, items: [] };
    case SET_RESERVATIONS:
      return {
        ...state,
        items: [...state.items, ...data.items],
        page: {
          total: data.total,
          currentPage: data.current_page,
          nextPage: data.next_page,
          prevPage: data.previous_page,
          previousPage: data.previous_page
        }
      };

    default:
      return state;
  }
};

export const reservation = (state = {}, action) => {
  const { data } = action;

  switch (action.type) {
    case SET_RESERVATION:
      return {
        ...state,
        ...data
      };

    default:
      return state;
  }
};
