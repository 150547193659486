import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Box from "@material-ui/core/Box";
import { useMediaQuery } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import { closeThisDialog, openThisDialog } from "../actions/dialog";

import { unassignPro } from "../actions/pros";

import {
  DT_ASSIGN_PRO_TO_PROPERTY,
  DT_GENERIC_MODAL,
} from "../constants/dialogTypes";
import { Analytics } from "../services/analyticsService.tsx";

const ChangeProSection = (props) => {
  const {
    property = {},
    openThisDialog,
    isOnboarding = true,
    isNewPropertyFlow = false,
    unassignPro,
  } = props;

  const {
    auto_assigned_maid: assignedPro = null,
    id: propertyId = null,
  } = property;

  const isMobile = useMediaQuery("(max-width:600px)");

  const handleChangePro = () => {
    if (isOnboarding) {
      Analytics.trackSimple("onboarding_change_pro_action_from_job_automation");
    } else {
      Analytics.trackSimple(
        "new_property_change_pro_action_from_job_automation"
      );
    }
    openThisDialog({
      dialogType: DT_ASSIGN_PRO_TO_PROPERTY,
      propertyId,
      property,
    });
  };

  const handleUnassignPro = () => {
    unassignPro(propertyId);

    // Prevent errors
    return { type: "" };
  };

  const handleUnassignmentPrompt = () => {
    openThisDialog({
      dialogType: DT_GENERIC_MODAL,
      title: "Unassign pro",
      body: (
        <span>
          This will unassign{" "}
          <span style={{ fontWeight: 500 }}>
            {first} {last}
          </span>{" "}
          from this property and all newly created jobs will need to be manually
          assigned to a pro. Jobs that are already synced and assigned to this
          pro will remain as-is.
        </span>
      ),
      secondaryButton: { label: "Cancel", action: closeThisDialog },
      primaryButton: {
        label: "Unassign",
        action: handleUnassignPro,
        type: "negative",
      },
    });
  };

  const {
    first = null,
    last = null,
    category = null,
    phone = null,
  } = assignedPro;

  const phoneAndCategory = phone && category ? `${phone} • ${category}` : null;
  const phoneOrCategory = phone ? phone : category ? category : null;

  const actionStylesForMobile = isMobile
    ? {
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "space-between",
        fullWidth: "100%",
      }
    : { display: "inline-flex" };

  return (
    <div className={isNewPropertyFlow ? "mt3" : "pl2 pr2 mt3"}>
      <h3 className="header-3" style={{ margin: 0, marginBottom: "4px" }}>
        All jobs are assigned to:
      </h3>
      <Box
        className={"mt2 inline-flex space-between full-width"}
        bgcolor="#FBFBFB"
        style={{
          width: "calc(100% - 32px",
          border: "1px solid #DDD2D2",
          alignItems: isMobile ? "" : "center",
          flexDirection: isMobile ? "column" : "",
        }}
        p={2}
      >
        <div style={{ marginBottom: isMobile ? "16px" : "" }}>
          <h4
            className="header-4"
            style={{ margin: 0, marginBottom: "4px" }}
          >{`${first} ${last}`}</h4>
          {phoneAndCategory ? (
            <p className="grey">{phoneAndCategory}</p>
          ) : phoneOrCategory ? (
            <p className="grey">{phoneOrCategory}</p>
          ) : null}
        </div>
        <div style={actionStylesForMobile}>
          <Button
            variant="text"
            style={{ marginRight: isMobile ? "" : "24px", color: "red" }}
            onClick={handleUnassignmentPrompt}
          >
            Unassign pro
          </Button>
          <Button
            variant="outlined"
            style={{ marginRight: "16px" }}
            onClick={handleChangePro}
          >
            Change pro
          </Button>
        </div>
      </Box>
      <p className="grey pt1 italic">
        Heads up - Changing pro will send out a cancellation notification to{" "}
        <span style={{ fontWeight: 500 }}>{`${first} ${last}`}</span>.
      </p>
    </div>
  );
};

export default connect(
  (state) => ({}),
  (dispatch) => bindActionCreators({ openThisDialog, unassignPro }, dispatch)
)(ChangeProSection);
