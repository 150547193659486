import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Typography } from "@material-ui/core";

import Navigation from "../containers/navigation";

import {
  GeneralSelectField,
  GeneralTextField,
  LanguageAutocomplete,
  MultiSelectChecklist,
  NumberInteractGeneralField,
  YesNoGeneralField,
} from "../containers/accountFields";

import CircularProfileImage from "../components/profileEditor";

import { addUpdateProfile } from "../actions/ops";

import { toNumber } from "lodash";
import { JOBS } from "../routes";

const distanceForJob = [
  { label: "Less than 5 miles", value: "5" },
  { label: "Less than 10 miles", value: "10" },
  { label: "Less than 30 miles", value: "30" },
  { label: "Less than 50 miles", value: "50" },
  { label: "Less than 80 miles", value: "80" },
];

const languageOptions = [
  "English",
  "Spanish",
  "Mandarin",
  "Cantonese",
  "Tagalog (including Filipino)",
  "Vietnamese",
  "Arabic",
  "French",
  "Korean",
  "Russian",
  "German",
];

const MIN_CHAR_BIO = 100;

let bioTextCharacterCounter = 0;

const ProfileInformationScreen = () => {
  const user = useSelector((state) => state.user);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const dispatch = useDispatch();

  const [profile, setProfile] = React.useState({
    id: null,
    url: "",
    profile_photo: null,
    business_name: "",
    years_of_experience: "",
    team_size: "", // Number of employees
    languages: [],
    drivers_license: "",
    bio: "",
    distance_in_miles: "",
    accepts_same_day_flip: "",
    green_cleaning_supplies: "",
  });

  const [charCounter, setChrCounter] = React.useState(0);

  // Use useEffect to update profile state when user changes
  useEffect(() => {
    if (user) {
      // Check if user object is not empty
      setProfile({
        id: user.id || "",
        url: user.url || "",
        profile_photo: null, // this is passed in to the API
        business_name: user.business_name || "",
        years_of_experience: user.years_of_experience || "",
        team_size: user.team_size || "",
        languages: user.languages || [],
        drivers_license: user.drivers_license,
        bio: user.bio || "",
        distance_in_miles: user.distance_in_miles || "",
        accepts_same_day_flip: user.accepts_same_day_flip,
        green_cleaning_supplies: user.green_cleaning_supplies,
      });
    }
  }, [user]); // Depend on user object

  const isCTADisabled = React.useMemo(() => {
    // Destructure the account state for easier access
    const {
      id,
      years_of_experience,
      team_size,
      accepts_same_day_flip,
      green_cleaning_supplies,
      distance_in_miles,
      bio,
    } = profile;

    // Check if any of the fields are empty
    const areFieldsEmpty =
      !id ||
      accepts_same_day_flip === null ||
      green_cleaning_supplies === null ||
      !bio ||
      !distance_in_miles; // checking if null as user can enter Yes ('true') rue or No ('false')

    const isBioLimitNotMet = bio.length < MIN_CHAR_BIO;
    return areFieldsEmpty && isBioLimitNotMet;
  }, [profile]); // Depend on account state to re-compute when it changes

  const handleChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    if (name === "bio") {
      setChrCounter(value.length);
    }

    setProfile({ ...profile, [name]: value });
  };

  const handleLanguageChagne = (event, newValue) => {
    setProfile((prev) => ({ ...prev, languages: newValue }));
  };

  const handleNumberChange = (name, value) => {
    setProfile((prev) => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (formData) => {
    const profilePhotoUrl = URL.createObjectURL(formData.get("profile_photo"));
    setProfile((prev) => ({
      ...prev,
      ["profile_photo"]: formData.get("profile_photo"),
      ["url"]: profilePhotoUrl,
    }));
  };

  const handleSubmit = () => {
    const formData = new FormData();
    let _languages = profile.languages;
    const maidId = profile.id;

    if (profile.profile_photo) {
      formData.append("profile_photo", profile.profile_photo);
    }

    // Append other profile attributes to formData
    formData.append("business_name", profile.business_name);
    formData.append("years_of_experience", profile.years_of_experience);
    formData.append("team_size", profile.team_size);
    formData.append("languages", _languages.join(", ")); // Convert array to comma separated
    formData.append("drivers_license", profile.drivers_license);
    formData.append("bio", profile.bio);
    formData.append("distance_in_miles", profile.distance_in_miles);
    formData.append("accepts_same_day_flip", profile.accepts_same_day_flip);
    formData.append("green_cleaning_supplies", profile.green_cleaning_supplies);

    dispatch(addUpdateProfile({ maidId, formData }));
  };

  const bioCharacterCounter = MIN_CHAR_BIO - charCounter;

  return (
    <>
      <Navigation type="onboarding" />
      <div
        className="flex align-center justify-center flex-column"
        style={{ marginTop: 24 }}
      >
        <div
          className="flex flex-column"
          style={{ maxWidth: "350px", width: "100%", minWidth: "260px" }}
        >
          <CircularProfileImage
            imageUrl={profile.url}
            handleChange={handleImageChange}
          />
          <GeneralTextField
            name="business_name"
            label="Business name"
            value={profile.business_name}
            handleChange={handleChange}
          />

          <NumberInteractGeneralField
            label="Years of Airbnb cleaning experience?"
            name="years_of_experience"
            className="mt2"
            value={toNumber(profile.years_of_experience)}
            handleChange={handleNumberChange}
          />
          <NumberInteractGeneralField
            label="Number of employees?"
            name="team_size"
            className="mt2"
            value={toNumber(profile.team_size)}
            handleChange={handleNumberChange}
          />

          <MultiSelectChecklist
            name="languages"
            className="mt2"
            label="Select languages you speak?"
            selectedValues={profile.languages}
            handleChange={handleChange}
            options={languageOptions}
          />

          <YesNoGeneralField
            name="drivers_license"
            label="Do you have a valid driver license?"
            handleChange={handleChange}
            value={profile.drivers_license}
            className="mt2"
          />

          <GeneralTextField
            name="bio"
            label="Describe why should hosts choose you?"
            className="mt2"
            value={profile.bio}
            handleChange={handleChange}
            multiline={true}
            rows={4}
            helperText={
              bioCharacterCounter < 0
                ? "Max 250 characters are recommended."
                : `Minimum ${bioCharacterCounter} characters are recommended.`
            }
          />

          <GeneralSelectField
            name="distance_in_miles"
            label="Travel distance for a job"
            className="mt2"
            value={profile.distance_in_miles}
            handleChange={handleChange}
            options={distanceForJob}
          />

          <YesNoGeneralField
            name="accepts_same_day_flip"
            label="Are you available to clean between 10am and 4pm (same-day flip)?"
            handleChange={handleChange}
            value={profile.accepts_same_day_flip}
            className="mt2"
          />

          <YesNoGeneralField
            name="green_cleaning_supplies"
            label="Do you use EcoFriendly supplies?"
            handleChange={handleChange}
            value={profile.green_cleaning_supplies}
            className="mt2"
          />

          <div className="mt4">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={isCTADisabled}
              onClick={() => handleSubmit()}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileInformationScreen;
