import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";

import {
  NOTIFICATION_TYPE_EMAIL,
  NOTIFICATION_TYPE_SMS,
  NOTIFICATION_TYPE_OFF,
} from "../constants/apiEnum";

import { updateNotificationSettings } from "../actions/notifications";

const NotificationConfigurationDialogBody = (props = {}) => {
  const { id, closeThisDialog } = props;

  const dispatch = useDispatch();

  const notificationSettings = useSelector((state) => state.notifications)
    .items;

  if (!id) {
    // If Notification id is not successfully passed to the Dialog
    return (
      <div className="card">
        Having issue finding this notification setting. Please try again. If
        this error is persistant then please contact support at{" "}
        <a href="mailto:help@automatebnb.com" alt="Support email">
          help@automatebnb.com
        </a>
      </div>
    );
  } else {
    // Enter the logic here to extract the proper notification rule based on the ID
    // sent to the dialog

    const currentSetting = notificationSettings.filter(
      (setting) => setting.id === id
    )[0];

    const handleChange = (type, val) => {
      const antiVal = typeof val === "boolean" ? !val : null;

      if (antiVal !== null) {
        if (type === "email") {
          dispatch(
            updateNotificationSettings({
              id,
              email: antiVal,
              sms: currentSetting.rules.sms.notify,
            })
          );
        } else {
          dispatch(
            updateNotificationSettings({
              id,
              email: currentSetting.rules.email.notify,
              sms: antiVal,
            })
          );
        }
      } else {
        console.warn("Incorrect attribute value type.");
      }
    };

    if (isEmpty(currentSetting)) {
      return (
        <div className="card">
          Couldn't find the notification. Please contact support at{" "}
          <a href="mailto:help@automatebnb.com" alt="Support email">
            help@automatebnb.com
          </a>
        </div>
      );
    } else {
      return (
        <div style={{ maxWidth: "300px" }}>
          <DialogTitle id="scroll-dialog-title" className="pms-dialog__title">
            {currentSetting.heading}
            {!isEmpty(currentSetting.title) && (
              <div className="flex flex-row align-center">
                <EmojiObjectsIcon style={{ color: "grey" }} />
                <p className="small normal-weight" style={{ color: "grey" }}>
                  {currentSetting.title}
                </p>
              </div>
            )}
          </DialogTitle>
          <DialogContent dividers={true} className="invitePros__content">
            <>
              <div
                className="inline-flex full-width space-between"
                style={{ paddingBottom: "16px" }}
              >
                <p>Email</p>
                <Switch
                  checked={currentSetting.rules.email.notify}
                  disabled={currentSetting.rules.email.disabled}
                  onChange={() =>
                    handleChange("email", currentSetting.rules.email.notify)
                  }
                  name="Email toggle"
                  color="primary"
                  inputProps={{ "aria-label": "Email checkbox" }}
                />
              </div>
              <div className="inline-flex full-width space-between">
                <p>SMS</p>
                <Switch
                  color="primary"
                  checked={currentSetting.rules.sms.notify}
                  disabled={currentSetting.rules.sms.disabled}
                  onChange={() =>
                    handleChange("sms", currentSetting.rules.sms.notify)
                  }
                  name="SMS toggle"
                  inputProps={{ "aria-label": "SMS checkbox" }}
                />
              </div>
            </>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => closeThisDialog()}
              variant="contained"
              color="secondary"
              disableElevation
              className="button button-dark"
            >
              Done
            </Button>
          </DialogActions>
        </div>
      );
    }
  }
};

export default NotificationConfigurationDialogBody;
