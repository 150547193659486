import React from "react";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { isEmpty } from "lodash";

import Navigation from "./navigation";
import history from "../helpers/history";
import { APP, JOBS } from "../routes";
import LoadingScreen from "../components/loadingScreen";

const UnauthorizedPage = () => {
  const user = useSelector((state) => state.user);

  return [
    <Navigation key="appbar-navigation-unauthorized-page" />,
    <div className="flex-center full-page " key="unauthorized-page">
      <div className="text-center">
        <h3 className="header-3">
          Sorry about that, but you don't have permission to access this page.
        </h3>
        <h3 className="header-3">Fortunately, you can go back to:</h3>
        {isEmpty(user) ? (
          <Button
            color="primary"
            variant="contained"
            onClick={() => history.push(APP)}
          >
            Log in
          </Button>
        ) : (
          <Button
            color="primary"
            variant="contained"
            onClick={() => history.replace(JOBS)}
          >
            Jobs
          </Button>
        )}
      </div>
    </div>,
  ];
};

export default UnauthorizedPage;
