import { SET_NOTIFICATIONS } from "../actions/notifications";

export const getNotifications = ({ notifications }) => notifications;

export const notifications = (
  state = { items: [], isLoading: true },
  action
) => {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return {
        ...state,
        items: action.data,
        isLoading: false,
      };

    default:
      return state;
  }
};
