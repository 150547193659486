import React from "react";
import { Link, useMediaQuery } from "@material-ui/core";
import classnames from "classnames";

import {
  DATE_FORMAT,
  DATE_FORMAT_FULL,
  DATE_FORMAT_STANDARD,
} from "../constants/apiEnum";

import RenderDateTime from "./renderDateTime";

const JobDetails = ({ className, children, variant }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const variantClass =
    variant === "stack"
      ? "job-details job-details--mobile-wrap"
      : "job-details job-details--mobile";
  return (
    <div
      className={classnames([
        className,
        isMobile ? variantClass : "job-details",
      ])}
    >
      {children}
    </div>
  );
};

const Title = ({ children, key = null }) => (
  <h3 className="job-details__title" key={key}>
    {children}
  </h3>
);

const Content = ({ className, style, children, key }) => (
  <div
    className={classnames([className, "job-details__wrapper"])}
    style={style}
    key={key}
  >
    {children}
  </div>
);

const Group = ({
  style,
  children,
  variant = ["stack", "inline"],
  key = null,
}) => (
  <div className={"job-details__group"} style={style} key={key}>
    {children}
  </div>
);

const Label = ({ children, key = null }) => (
  <p className="job-details__label" key={key}>
    {children}
  </p>
);
const Body = ({ children, variant, key = null }) => {
  const variantClass = variant === "bold" && "job-details__body--bold";
  return (
    <div className={classnames([variantClass, "job-details__body"])} key={key}>
      {children}
    </div>
  );
};
const LinkRender = ({ href, children, target = "_blank", key = null }) => (
  <Link
    className="job-details__body job-details__link"
    href={href}
    target={target}
    key={key}
  >
    {children}
  </Link>
);

const Date = ({ date, format = DATE_FORMAT_STANDARD, key = null }) => (
  <div className="job-details__body job-details__body--bold" key={key}>
    <RenderDateTime date={date} format={format} />
  </div>
);

JobDetails.Title = Title;
JobDetails.Content = Content;
JobDetails.Group = Group;
JobDetails.Label = Label;
JobDetails.Body = Body;
JobDetails.Link = LinkRender;
JobDetails.Date = Date;

export default JobDetails;
