import React from "react";
import MPStyles from "./MarketplaceStyles.module.scss";
import { Typography, Link } from "@material-ui/core";

export default ({
  addon_fees = [],
  price,
  special_request = null,
  viewType = "ops",
}) => {
  const {
    total_payout_minus_fees = 0,
    marketplace_fee = 0,
    total_fee = 0,
  } = price;
  function pluralize(count, label) {
    return count === 1 ? label : `${label}s`;
  }

  // const AdditionalFeaturesPriceCalculator = () => {
  //   // Dynamically generate feature keys from the cleaning_addons object
  //   const featureKeys = cleaning_addons ? Object.keys(cleaning_addons) : [];

  //   if (addon_fees.length > 0) {
  //     return (
  //       <div className="mb-2">
  //         <Typography
  //           style={{ fontWeight: 500, marginTop: 8 }}
  //           variant="subtitle1"
  //           key="cleaning-addons-title"
  //         >
  //           Service {pluralize(addon_fees.length, "Add-on")}:
  //         </Typography>
  //         <div className="mt-2" key="cleaning-addons-body">
  //           <ul className="list-disc list-inside">
  //             {addon_fees.map(({ label, item }) => (
  //               <li key={item}> {label}</li>
  //             ))}
  //           </ul>
  //         </div>
  //       </div>
  //     );
  //   } else {
  //     return null;
  //   }
  // };

  return (
    <div className={MPStyles.jobDetails}>
      <Typography variant="h6" style={{ fontSize: 18 }}>
        Job details
      </Typography>
      <Typography variant="body1">
        This job must meet Automatebnb's base{" "}
        <Link
          href="https://automatebnb.notion.site/Automatebnb-cleaning-checklist-62d4a8d3d726432985415ebd63b79790"
          className="link"
          target="_new"
          style={{ textDecoration: "underline" }}
        >
          Cleaning checklist.
        </Link>
      </Typography>
      {/* <AdditionalFeaturesPriceCalculator /> */}
      {special_request && [
        <Typography
          key="special-request-label"
          style={{ fontWeight: 500, marginTop: 8 }}
          variant="subtitle1"
        >
          Special request:
        </Typography>,
        <Typography key="special-request-body" variant="body1">
          {special_request}
        </Typography>,
      ]}
      <div className="mt-3 bg-white p-4">
        <div className="w-full max-w-[25rem]">
          {viewType === "ops" && (
            <>
              <div className="py-2 flex justify-between">
                <div>
                  <Typography variant="body1">{`Job fee:`}</Typography>
                </div>
                <div>
                  <Typography variant="body1">{`$${total_fee}`}</Typography>
                </div>
              </div>
              <div className="py-2 flex justify-between">
                <div className="flex flex-col ">
                  <div>
                    <Typography variant="body1">{`Marketplace fee:`}</Typography>
                  </div>
                  <div className="pr-8">
                    <Typography variant="caption">
                      {`First job commission is 12%.`}
                    </Typography>
                    <a
                      href="https://automatebnb.notion.site/Marketplace-job-payment-ce22ffdd5ad2477c8bce0492c2d4d4c7"
                      target="_BLANK"
                    >
                      {" "}
                      <Typography
                        variant="caption"
                        className="link"
                      >{` Learn more`}</Typography>
                    </a>
                  </div>
                </div>
                <div>
                  <Typography variant="body1">{`$${marketplace_fee}`}</Typography>
                </div>
              </div>
              <div className="border-solid border-t border-slate-400 py-2 flex justify-between">
                <div>
                  <Typography variant="body1">{`Total payout:`}</Typography>
                </div>
                <div>
                  <Typography variant="inherit" className="font-bold">
                    {`$${total_payout_minus_fees}`}
                  </Typography>
                </div>
              </div>
            </>
          )}
          {viewType === "host" && (
            <>
              <div className="py-2 flex flex-column">
                <div className="flex justify-between">
                  <div>
                    <Typography variant="body1">{`Job fee:`}</Typography>
                  </div>
                  <div>
                    <Typography
                      variant="inherit"
                      className="font-bold"
                    >{`$${total_fee}`}</Typography>
                  </div>
                </div>
                <div>
                  <Typography variant="caption">
                    {`Payment invoice will be sent to your email after the job is completed.`}
                  </Typography>
                  <a
                    href="https://automatebnb.notion.site/Marketplace-fees-6e32408dcbb34fe3ac4f590aa8eff107"
                    target="_BLANK"
                  >
                    {" "}
                    <Typography
                      variant="caption"
                      className="link"
                    >{` Learn more`}</Typography>
                  </a>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
