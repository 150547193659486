import React, { useEffect } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { bindActionCreators } from "redux";
import amplitude from "amplitude-js";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { Typography, useMediaQuery } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useLocation } from "react-router-dom";

import history from "../helpers/history";

import { PMS_PROPERTIES_NEW } from "../routes";
import { EMPTY_STATE_TYPE_PROPERTIES } from "../constants/apiEnum";
import {
  DT_SUBSCRIBE_PROMPT,
  DT_GENERIC_DIALOG,
} from "../constants/dialogTypes";

import LoadingScreen from "../components/loadingScreen";
import EmptyState from "../components/emptyState";

import Navigation from "../containers/navigation";
import Pagination from "../containers/pagination";

import { openThisDialog, setDialogOpen } from "../actions/dialog";
import { fetchProperties } from "../actions/pmsProperties";

import { Analytics } from "../services/analyticsService.tsx";

import { getPMSProperties, property } from "../reducers/pmsProperties";
import { getLoading } from "../reducers/loading";
import { getSubscription } from "../reducers/reducer_subscribe";
import { resetSpaces } from "../actions/staging";

const STATUS_ASSIGN_MAID_AUTO = "ASSIGN_MAID_AUTO";
const STATUS_ASSIGN_MAID_MANUAL = "ASSIGN_MAID_MANUAL";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Properties = (props) => {
  const {
    fetchProperties,
    properties,
    page,
    isLoading,
    subscribe,
    openThisDialog,
    resetSpaces,
    setDialogOpen,
  } = props;

  const isMobile = useMediaQuery("(max-width:600px)");
  const isIAP = !isEmpty(subscribe)
    ? subscribe.subscription_type === "iap"
    : false;

  const query = useQuery();
  const setupStaging = query.get("setupstaging");

  useEffect(() => {
    fetchProperties({ nextProperties: false, allProperties: false });
  }, []);

  function handleNewProperty() {
    Analytics.trackSimple("new_property_action_from_properties");
    if (isIAP) {
      if (subscribe && subscribe.deactivated) {
        openThisDialog({
          dialogType: DT_SUBSCRIBE_PROMPT,
          dialogBody:
            "Your subscription has ended. Please open the Automatebnb App on your iPhone to resubscribe.",
        });
      } else {
        if (subscribe.properties_count == properties.length) {
          amplitude
            .getInstance()
            .logEvent("viewed.dialog.upgradeIAPSubscription");
          setDialogOpen(DT_GENERIC_DIALOG, null, {
            title: "Uh oh!",
            body: (
              <p>
                You can only add maximum of{" "}
                <span style={{ fontWeight: "bold" }}>
                  {subscribe.properties_count} properties.
                </span>{" "}
                Please upgrade your subscription using your Automatebnb iPhone
                App to add more properties.
              </p>
            ),
          });
        } else {
          history.push(PMS_PROPERTIES_NEW);
        }
      }
    } else {
      if (subscribe && subscribe.deactivated) {
        openThisDialog({
          dialogType: DT_SUBSCRIBE_PROMPT,
          dialogBody: "Your subscription has ended. Please subscribe first.",
        });
      } else {
        history.push(PMS_PROPERTIES_NEW);
      }
    }
  }

  function handleCellClick(props) {
    let constructedPathName = ``;
    if (setupStaging) {
      constructedPathName = `/listings/${props.id}/staging`;
    } else {
      constructedPathName = `/listings/${props.id}/listing-details`;
    }

    resetSpaces();

    history.push({
      pathname: constructedPathName,
      state: { ...props },
    });
  }

  const getAssignmentRule = (rule, housekeeper) => {
    return (
      <div className="">
        {housekeeper ? (
          <span>
            Assigned to:{" "}
            <b className="semibold">
              {`${housekeeper.first} ${housekeeper.last}`}
            </b>
          </span>
        ) : (
          <span className="danger">No pros assigned</span>
        )}
      </div>
    );
  };

  const formattedPropertyAddress = (property) => {
    return `${property.street ? property.street : ""} ${
      property.city ? property.city : "n/a"
    }, ${property.state} ${property.zip ? property.zip : ""}`;
  };

  const formattedMobileProperty = (property) => {
    return `${property.city ? property.city : "n/a"}, ${property.state} ${
      property.zip
    }`;
  };

  const MobileDisplay = () => {
    return (
      <div className="mt2 mb5">
        <Table aria-label="simple table" className="table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Property</TableCell>
              <TableCell align="left">Cleaning assignment</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="--bk-white">
            {properties.map((property) => (
              <TableRow
                key={property.id}
                hover={true}
                onClick={() => handleCellClick({ ...property })}
              >
                <TableCell component="th" scope="row">
                  <div>
                    <Typography variant="subtitle1">
                      {property.title}
                    </Typography>
                  </div>
                  <Typography variant="caption">
                    {formattedMobileProperty(property)}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  {getAssignmentRule(
                    property.assign_maid_rule,
                    property.auto_assigned_maid
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  };

  const DesktopDisplay = () => {
    const subscriptionPropertiesCount = subscribe?.properties_count;
    const currentPropertiesCount = properties?.length;

    const remainderProperties =
      subscriptionPropertiesCount && currentPropertiesCount
        ? subscriptionPropertiesCount - currentPropertiesCount
        : null;

    let iapPropertiesLimitLabel = `${remainderProperties} properties`;

    if (remainderProperties === 0) {
      iapPropertiesLimitLabel = "None";
    } else if (remainderProperties === 1) {
      iapPropertiesLimitLabel = `${remainderProperties} property`;
    }

    return (
      <div className="page-wrapper">
        <div className="page-content">
          <div
            className="primary-actions mb2 mt2"
            key="host-jobs-primary-action"
          >
            <h1 className="page-header">Properties</h1>
            <div className="inline-flex">
              {isIAP && (
                <div className="mr2">
                  <div style={{ fontSize: "14px" }}>
                    You can add:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {iapPropertiesLimitLabel}
                    </span>
                    <div
                      style={{
                        fontSize: "12px",
                        textWrap: "all",
                        width: "250px",
                        lineHeight: "120%",
                        opacity: 0.65,
                      }}
                    >
                      Upgrade on Automatebnb App on your iPhone.
                    </div>
                  </div>
                </div>
              )}
              <Button
                variant="contained"
                color="secondary"
                disableElevation={true}
                onClick={() => handleNewProperty()}
              >
                New property
              </Button>
            </div>
          </div>
          <Table aria-label="simple table" padding="normal" className="table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Property</TableCell>
                <TableCell align="left">Address</TableCell>
                <TableCell align="left">Cleaning assignment</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="--bk-white">
              {properties.map((property) => (
                <TableRow
                  key={property.id}
                  hover={true}
                  onClick={() => handleCellClick({ ...property })}
                  className="tableRow--clickable"
                >
                  <TableCell component="th" scope="row">
                    {property.title}
                  </TableCell>
                  <TableCell align="left">
                    {formattedPropertyAddress(property)}
                  </TableCell>
                  <TableCell align="left">
                    {getAssignmentRule(
                      property.assign_maid_rule,
                      property.auto_assigned_maid
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  };

  return (
    <>
      <Navigation />
      {isLoading ? (
        <div
          className="inline-flex align-center justify-center full-width"
          style={{ height: "90%" }}
        >
          <CircularProgress color="primary" />
        </div>
      ) : !isEmpty(properties) ? (
        <>
          {isMobile ? <MobileDisplay /> : <DesktopDisplay />}
          <Pagination
            type="properties"
            nextPage={page.nextPage}
            prevPage={page.prevPage}
          />
        </>
      ) : (
        <EmptyState type={EMPTY_STATE_TYPE_PROPERTIES} />
      )}
    </>
  );
};

export default connect(
  (state) => ({
    isLoading: getLoading(state),
    properties: getPMSProperties(state).items,
    page: getPMSProperties(state).page,
    subscribe: getSubscription(state).data,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        fetchProperties,
        openThisDialog,
        setDialogOpen,
        resetSpaces,
      },
      dispatch
    )
)(Properties);
