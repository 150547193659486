import {
  SET_OPS_CLIENTS,
  RESET_OPS_CLIENTS,
  SET_OPS_CLIENTS_PROPERTOES,
  RESET_OPS_CLIENTS_PROPERTOES,
  RESET_INVITED_CLIENTS,
  SET_INVITED_CLIENTS,
} from "../actions/ops";

export const getOpsClients = ({ clients }) => clients;

export const getOpsClientProperties = ({ clientProperties }) =>
  clientProperties;
export const getInvitedClients = ({ invitedClients }) => invitedClients;
// export const getPMSNewProperty = ({ newProperty }) => newProperty;

export const clients = (state = { page: { total: 0 }, items: [] }, action) => {
  const { data } = action;
  switch (action.type) {
    case RESET_OPS_CLIENTS:
      return { page: { total: 0 }, items: [], isLoading: true };
    case SET_OPS_CLIENTS:
      return {
        ...state,
        items: [...state.items, ...data.items],
        page: {
          total: data.total,
          currentPage: data.current_page,
          nextPage: data.next_page,
          prevPage: data.previous_page,
          previousPage: data.previous_page,
        },
        isLoading: false,
      };

    default:
      return state;
  }
};

export const clientProperties = (state = { isLoading: true }, action) => {
  const { data } = action;
  switch (action.type) {
    case RESET_OPS_CLIENTS_PROPERTOES:
      return { page: { total: 0 }, items: [], isLoading: true };
    case SET_OPS_CLIENTS_PROPERTOES:
      return {
        ...state,
        items: [...state.items, ...data.items],
        page: {
          total: data.total,
          currentPage: data.current_page,
          nextPage: data.next_page,
          prevPage: data.previous_page,
          previousPage: data.previous_page,
        },
        isLoading: false,
      };

    default:
      return state;
  }
};

export const invitedClients = (
  state = { isLoading: true, items: [] },
  action
) => {
  switch (action.type) {
    case RESET_INVITED_CLIENTS:
      return { isLoading: true, items: [] };
    case SET_INVITED_CLIENTS:
      return { items: [...state.items, ...action.data], isLoading: false };

    default:
      return state;
  }
};

/* ===================
*
*  Clients properties - independent API needed for Properties to be paginated
*
// ===================
export const clientProperties = (
  state = { page: { total: 0 }, host: {}, items: [] },
  action
) => {
  const { data } = action;
  switch (action.type) {
    case RESET_OPS_CLIENTS_PROPERTOES:
      return { page: { total: 0 }, host: {}, items: [], isLoading: true };
    case SET_OPS_CLIENTS_PROPERTOES:
      return {
        ...state,
        items: [...state.items, ...data.items],
        host: {},
        page: {
          total: data.total,
          currentPage: data.current_page,
          nextPage: data.next_page,
          prevPage: data.previous_page,
          previousPage: data.previous_page,
        },
        isLoading: false,
      };

    default:
      return state;
  }
};
*/
