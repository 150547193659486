import React, { useEffect } from "react";
import amplitude from "amplitude-js";
import { isEmpty } from "lodash";
import Moment from "react-moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Material UI
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import { useMediaQuery } from "@material-ui/core";
// Components
import RenderDateTime from "../components/renderDateTime";
import Navigation from "../containers/navigation";
import LoadingScreen from "../components/loadingScreen";

// Actions
import { fetchInvitedClients } from "../actions/ops";

// Reducers
import { getInvitedClients } from "../reducers/clients";

// Routes
import { OPERATIONS_CLIENTS } from "../routes";

const InvitedClients = (props) => {
  const {
    clients: { isLoading, items },
    fetchInvitedClients,
  } = props;

  const isMobile = useMediaQuery("(max-width:600px)");

  // Fetch APIs
  useEffect(() => {
    fetchInvitedClients();
  }, []);

  const RenderInvitedClientsTable = () => {
    if (isEmpty(items)) {
      return "No clients";
    } else {
      return (
        <Table aria-label="simple table" className="ops-table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Note</TableCell>
              <TableCell align="left">Invited on</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.email}</TableCell>
                <TableCell>
                  <Box
                    component="div"
                    my={2}
                    textOverflow="ellipsis"
                    overflow="hidden"
                    bgcolor="background.paper"
                    style={
                      isMobile
                        ? { width: "150px", whiteSpace: "nowrap" }
                        : { width: "350px", whiteSpace: "nowrap" }
                    }
                  >
                    {item.invite_note}
                  </Box>
                </TableCell>

                <TableCell>
                  <Moment format="MMMM DD, YYYY">{item.updated_at}</Moment>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }
  };

  if (isLoading) {
    return <LoadingScreen />;
  } else {
    return (
      <>
        <Navigation
          hasASubNav={true}
          backToRoute={OPERATIONS_CLIENTS}
          pageTitle="Invited hosts"
        />
        <div className="page-wrapper">
          <div className="page-content">
            <RenderInvitedClientsTable />
          </div>
        </div>
      </>
    );
  }
};

export default connect(
  (state) => ({
    clients: getInvitedClients(state),
  }),
  (dispatch) => bindActionCreators({ fetchInvitedClients }, dispatch)
)(InvitedClients);
