import React, { useState, useEffect, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import { Typography, Checkbox, FormControlLabel } from "@material-ui/core";

import {
  GeneralNumberTextField,
  NumberInteractGeneralField,
} from "./accountFields";
import { SpecialRequirements } from "../constants/properties";

import { AddressField } from "./accountFields";

const hours = [
  { value: "8:00", time: "8AM" },
  { value: "9:00", time: "9AM" },
  { value: "10:00", time: "10AM" },
  { value: "11:00", time: "11AM" },
  { value: "12:00", time: "12PM (noon)" },
  { value: "13:00", time: "1PM" },
  { value: "14:00", time: "2PM" },
  { value: "15:00", time: "3PM" },
  { value: "16:00", time: "4PM" },
  { value: "17:00", time: "5PM" },
  { value: "18:00", time: "6PM" },
  { value: "19:00", time: "7PM" },
  { value: "20:00", time: "8PM" },
  { value: "21:00", time: "9PM" },
  { value: "22:00", time: "10PM" },
  { value: "23:00", time: "11PM" },
  { value: "24:00", time: "12AM (midnight)" },
  { value: "1:00", time: "1AM (next day)" },
];

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "transparent",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const NewPropertyDetailsField = ({ propertyValues, handleValue }) => {
  const googleSearchRef = useRef(null);

  const {
    title: initialTitle = "",
    street: initialStreet = "",
    city: initialCity = "",
    state: initialState = "",
    zip: initialZip = "",
    check_in_time: initialCheckIn = "",
    check_out_time: initialCheckOut = "",
    fulladdress: initialFullAddress = "",
    bedrooms: initialBedrooms = 0,
    beds: initialBeds = 0,
    bathrooms: initialBathrooms = 0,
    amenities: initialAmenities = {},
    total_guests: initialTotalGuests = 0,
    parking_info: initialParkingInfo = "",
    washer: initialWasher = false,
    dryer: initialDryer = false,
    lock_code: initialLockCode = null,
    access_instructions: initialAccessInstructions = "",
    gate_code: initialGateCode = null,
    gate_instructions: initialGateInstructions = "",
    maid_closet_code: initialMaidClosetCode = null,
    maid_closet_info: initialMaidClosetInfo = "",
    trash_instructions: initialTrashInstructions = "",
    wifi_instructions: initialWifiInstructions = {
      network: "",
      password: "",
    },
    cleaning_guide_url: initialCleaningGuideURL = "",
    square_feet: intialSquareFeet = "",
  } = propertyValues;

  const [values, setValues] = React.useState({
    title: initialTitle,
    street: initialStreet,
    city: initialCity,
    state: initialState,
    zip: initialZip,
    checkIn: initialCheckIn || "15:00",
    checkOut: initialCheckOut || "11:00",
    fulladdress:
      initialFullAddress ||
      `${initialStreet} ${
        initialCity ? initialCity + "," : ""
      } ${initialState} ${initialZip}`.trim(),
    bedrooms: initialBedrooms || 0,
    beds: initialBeds || 0,
    bathrooms: initialBathrooms || 0,
    amenities: initialAmenities,
    total_guests: initialTotalGuests || 0,
    parking_info: initialParkingInfo,
    washer: initialWasher,
    dryer: initialDryer,
    lock_code: initialLockCode,
    access_instructions: initialAccessInstructions,
    gate_code: initialGateCode,
    gate_instructions: initialGateInstructions,
    maid_closet_code: initialMaidClosetCode,
    maid_closet_info: initialMaidClosetInfo,
    trash_instructions: initialTrashInstructions,
    square_feet: intialSquareFeet,
    wifi_instructions: initialWifiInstructions || {
      network: "",
      password: "",
    },
    cleaning_guide_url: initialCleaningGuideURL,
  });

  const isMounted = useRef(true);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleNumberChange = (name, value) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setValues((prev) => ({ ...prev, [name]: checked }));
  };

  const handleAmenitiesChange = (event) => {
    const { name, checked } = event.target;

    setValues((prevValues) => {
      const newAmenities = { ...prevValues.amenities };
      if (checked) {
        newAmenities[name] = { label: SpecialRequirements[name] }; // Add or update the key with an object
      } else {
        delete newAmenities[name]; // Remove the key entirely if unchecked
      }
      return {
        ...prevValues,
        amenities: newAmenities,
      };
    });
  };

  const handleWifiInstructionsChange = (event) => {
    const { name, value } = event.target;

    setValues((prevValues) => {
      const newWifiInsturctions = { ...prevValues.wifi_instructions };

      newWifiInsturctions[name] = value;
      return {
        ...prevValues,
        wifi_instructions: newWifiInsturctions,
      };
    });
  };

  const handleAddressChange = (value) => {
    if (isMounted.current) {
      setValues((prevState) => ({
        ...prevState,
        ["street"]: value.street,
        ["city"]: value.city,
        ["state"]: value.state,
        ["zip"]: value.zip,
        ["fulladdress"]: value.fulladdress,
      }));
    }
  };

  useEffect(() => {
    isMounted.current = true;
    // Only call handleValue if the component is still mounted
    if (isMounted.current) {
      handleValue(values);
    }

    return () => {
      isMounted.current = false;
    };
  }, [values]);

  const isChecked = (key) => key in values.amenities;

  return (
    <div>
      <div>
        <div>
          <div style={{ marginBottom: 8 }}>
            <Typography variant="subtitle2">Property title</Typography>
            <Typography
              variant="caption"
              style={{ lineHeight: "120%", color: "#535353" }}
            >
              This title will be visible to your pros. Use a short, clear title,
              like your house address and street name combined.
            </Typography>
          </div>
          <TextField
            required
            name="title"
            color="primary"
            autoFocus={true}
            fullWidth={true}
            placeholder="e.g., 🏄🏼‍♂️ 301Oceanside"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleInputChange}
            variant="outlined"
            value={values.title}
          />
        </div>
        <div className="mt2">
          <Typography variant="subtitle2">Full address</Typography>
          <div>
            <AddressField
              value={{
                street: values.street,
                city: values.city,
                state: values.state,
                zip: values.zip,
                fulladdress: values.fulladdress,
              }}
              handleChange={handleAddressChange}
              placeholder="e.g., 321 Arizona Ave, Santa Monica, CA, USA"
            />
          </div>
        </div>
        <div className="mt2">
          <Typography variant="subtitle2">Property Size (sq ft)</Typography>
          <GeneralNumberTextField
            value={values.square_feet}
            handleChange={handleInputChange}
            labelInFieldset={false}
            name="square_feet"
            placeholder="e.g., 1500"
          />
        </div>
        <NumberInteractGeneralField
          label="Number of bedrooms"
          name="bedrooms"
          className="mt2"
          value={values.bedrooms}
          handleChange={handleNumberChange}
        />
        <NumberInteractGeneralField
          label="Number of beds*"
          name="beds"
          className="mt2"
          value={values.beds}
          handleChange={handleNumberChange}
        />
        <NumberInteractGeneralField
          label="Number of bathrooms*"
          name="bathrooms"
          className="mt2"
          value={values.bathrooms}
          handleChange={handleNumberChange}
        />
        <NumberInteractGeneralField
          label="Maximum number of guests"
          placeholder="The maximum number of guests your short-term rental accommodates."
          name="total_guests"
          className="mt2"
          value={values.total_guests}
          handleChange={handleNumberChange}
        />
      </div>
      <div className="mt4">
        <Typography variant="subtitle2">Onsite laundry</Typography>
        <Typography variant="caption">
          Indicate if there is an onsite washer and/or dryer available for your
          pro to use during the job.
        </Typography>
        <div className="clearfix">
          <FormControlLabel
            control={
              <Checkbox
                name="washer"
                onChange={handleCheckboxChange}
                checked={values.washer}
                color="primary"
              />
            }
            label="Washer"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="dryer"
                onChange={handleCheckboxChange}
                checked={values.dryer}
                color="primary"
              />
            }
            label="Dryer"
          />
        </div>
      </div>
      <div className="mt4">
        <Typography variant="subtitle2">Special cleaning tasks</Typography>
        <Typography variant="caption">
          Select any special cleaning tasks required in addition to the cleaning
          checklist. This helps set expectations for each job.
        </Typography>
        <div className="clearfix">
          {Object.entries(SpecialRequirements).map(([key, label]) => (
            <div className="sm-col sm-col-6" key={key}>
              <FormControlLabel
                key={key}
                control={
                  <Checkbox
                    name={key}
                    onChange={handleAmenitiesChange}
                    checked={isChecked(key)}
                    color="primary"
                  />
                }
                label={label}
              />
            </div>
          ))}
        </div>
        <div style={{ clear: "both" }} />
      </div>
      <div className="mt4">
        <Typography variant="subtitle2">
          WiFi connection instructions for pros
        </Typography>
        <Typography variant="caption" style={{ fontSize: 14, opacity: 0.7 }}>
          Pros might not have cell service at the property. This assists in
          communication while working.
        </Typography>
        <div className="mt2">
          <Typography variant="subtitle2">Network name</Typography>
          <TextField
            name="network"
            color="primary"
            onChange={handleWifiInstructionsChange}
            variant="outlined"
            defaultValue={values.wifi_instructions.network}
            fullWidth={true}
          />
        </div>
        <div className="mt2">
          <Typography variant="subtitle2">Network name</Typography>
          <TextField
            name="password"
            color="primary"
            onChange={handleWifiInstructionsChange}
            variant="outlined"
            defaultValue={values.wifi_instructions.password}
            fullWidth={true}
          />
        </div>
      </div>
      <div className="mt4">
        <Typography variant="subtitle2">Access and instructions</Typography>
        <Typography variant="caption">
          Provide the necessary codes and instructions for each entry point. If
          you don't have key-less entry, leave the code text field empty.
        </Typography>
        {/* Gate code */}
        <div className="mt2">
          <Typography variant="subtitle2">Gate code</Typography>
          <TextField
            name="gate_code"
            color="primary"
            placeholder="e.g., 1234"
            onChange={handleInputChange}
            variant="outlined"
            defaultValue={values.gate_code}
            fullWidth={true}
          />
        </div>
        <div className="mt2">
          <Typography variant="subtitle2">Gate instructions</Typography>
          <TextField
            name="gate_instructions"
            color="primary"
            placeholder="e.g., Enter through the main gate at the front of the property."
            variant="outlined"
            onChange={handleInputChange}
            minRows={3}
            multiline
            defaultValue={values.gate_instructions}
            fullWidth={true}
          />
        </div>
        {/* Main entrance */}
        <div className="mt3">
          <div>
            <Typography variant="subtitle2">Main entrance code</Typography>
            <TextField
              name="lock_code"
              color="primary"
              placeholder="e.g., 5678"
              onChange={handleInputChange}
              variant="outlined"
              defaultValue={values.lock_code}
              fullWidth={true}
            />
          </div>
          <div className="mt2">
            <Typography variant="subtitle2">
              Main entrance access instructions
            </Typography>
            <TextField
              name="access_instructions"
              color="primary"
              placeholder="e.g., Use the code to unlock the front door."
              variant="outlined"
              onChange={handleInputChange}
              minRows={3}
              multiline
              defaultValue={values.access_instructions}
              fullWidth={true}
            />
          </div>
        </div>
        {/* Cleaning closet */}
        <div className="mt3">
          <div>
            <Typography variant="subtitle2">Cleaning closet code</Typography>
            <TextField
              name="maid_closet_code"
              color="primary"
              onChange={handleInputChange}
              placeholder="e.g., 9101"
              variant="outlined"
              defaultValue={values.maid_closet_code}
              fullWidth={true}
            />
          </div>
          <div className="mt2">
            <Typography variant="subtitle2">
              Cleaning closet access instructions
            </Typography>
            <TextField
              name="maid_closet_info"
              color="primary"
              placeholder="e.g., The closet is located in the hallway. Use the code to open the door."
              variant="outlined"
              onChange={handleInputChange}
              minRows={3}
              multiline
              defaultValue={values.maid_closet_info}
              fullWidth={true}
            />
          </div>
        </div>
        {/* Parking instructions */}
        <div className="mt3">
          <Typography variant="subtitle2">Pro parking info</Typography>
          <TextField
            name="parking_info"
            color="primary"
            placeholder="e.g., Street parking available"
            variant="outlined"
            onChange={handleInputChange}
            minRows={2}
            multiline
            defaultValue={values.parking_info}
            fullWidth={true}
          />
        </div>
      </div>
      <div className="mt4">
        <Typography variant="subtitle2">
          Trash access and pickup instructions
        </Typography>
        <Typography variant="caption">
          Provide details on how pros can access trash cans and the trash pickup
          schedule.
        </Typography>
        <div className="mt1">
          <TextField
            name="trash_instructions"
            color="primary"
            placeholder="e.g., Trash cans are located behind the house. Pickup is every Wednesday."
            variant="outlined"
            onChange={handleInputChange}
            minRows={3}
            multiline
            defaultValue={values.trash_instructions}
            fullWidth={true}
          />
        </div>
      </div>
      <div className="mt4">
        <Typography variant="subtitle2">Cleaning checklist link</Typography>
        <Typography variant="caption" style={{ fontSize: 14, opacity: 0.7 }}>
          Ensure the online document link is accessible to anyone with the link.
        </Typography>
        <div className="mt1">
          <TextField
            name="cleaning_guide_url"
            color="primary"
            variant="outlined"
            onChange={handleInputChange}
            defaultValue={values.cleaning_guide_url}
            fullWidth={true}
          />
        </div>
      </div>
      <div
        className="mt4 inline-flex full-width flex-column"
        style={{ justifyContent: "flex-start" }}
      >
        <Typography variant="subtitle2">
          Guest check-in and check-out times
        </Typography>
        <Typography variant="caption">
          Select the check-in and check-out times during which cleaning can be
          done. This dictates the job time available.
        </Typography>
        <div className="full-width pr2 mt2">
          <FormControl className="full-width">
            <InputLabel id="check-in-select-outlined-label" htmlFor="checkIn">
              Check-in time*
            </InputLabel>
            <NativeSelect
              id="check-in-select-outlined-label"
              value={values.checkIn}
              onChange={handleInputChange}
              name="checkIn"
              style={{ marginTop: "18px" }}
              required={true}
              input={<BootstrapInput />}
            >
              <option>Choose check in time</option>
              {hours.map((hour) => (
                <option
                  className="ab-select_options"
                  key={hour.value}
                  value={hour.value}
                >
                  {hour.time}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        </div>
        <div className="full-width mt2">
          <FormControl className="full-width">
            <InputLabel id="check-out-select-outlined-label" htmlFor="checkOut">
              Check-out time*
            </InputLabel>
            <NativeSelect
              id="check-out-select-outlined-label"
              value={values.checkOut}
              onChange={handleInputChange}
              name="checkOut"
              style={{ marginTop: "18px" }}
              required={true}
              input={<BootstrapInput />}
            >
              <option>Choose check out time</option>
              {hours.map((hour) => (
                <option
                  className="ab-select_options"
                  key={hour.value}
                  value={hour.value}
                >
                  {hour.time}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default NewPropertyDetailsField;
