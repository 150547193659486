import React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";

import "./emptyState.scss";

import { PMS_PROPERTIES_NEW } from "../routes";
import { parseQuery } from "../helpers/url";

import { getSubscription } from "../reducers/reducer_subscribe";

import {
  EMPTY_STATE_TYPE_RESERVATIONS,
  EMPTY_STATE_TYPE_PROPERTIES,
  EMPTY_STATE_TYPE_EVENTS,
  EMPTY_STATE_TYPE_HOUSEKEEPER_CLEANINGS,
  JOB_TYPE_PAST,
} from "../constants/apiEnum";

const EmptyStatesDisplay = ({ subscribe, page }) => {
  const {
    property_ids,
    maid_ids,
    channels,
    type,
    note,
    cleaning_status,
    request_payment,
  } = parseQuery();

  const handleClick = (action) => {
    if (action === "listing") {
      window.location.replace(PMS_PROPERTIES_NEW);
    } else if (action === "clear-filters") {
      window.location = `${window.location.origin}${window.location.pathname}${
        type ? `?type=${type}` : ""
      }`;
    }
  };

  if (page === EMPTY_STATE_TYPE_RESERVATIONS) {
    if (property_ids || maid_ids || channels || note) {
      return (
        <div className="text-center">
          <p>You have no Jobs for this filter.</p>
          <br />
          <br />
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleClick("clear-filters")}
          >
            Clear all filters
          </Button>
        </div>
      );
    } else {
      if (subscribe && subscribe.properties_count > 0) {
        return (
          <div className="text-center">
            <p>You have no cleanings</p>
          </div>
        );
      } else {
        return (
          <div className="text-center">
            <p>You have no properties added</p>
            <br />
            <br />
            <Button
              variant="contained"
              color="primary"
              className="host-primary"
              onClick={() => handleClick("listing")}
            >
              Add a new listing
            </Button>
          </div>
        );
      }
    }
  } else if (page === EMPTY_STATE_TYPE_PROPERTIES) {
    return (
      <div className="text-center">
        <p>You have no properties added</p>
        <br />
        <br />
        <Button
          variant="contained"
          color="primary"
          className="host-primary"
          onClick={() => handleClick("listing")}
        >
          Add a new listing
        </Button>
      </div>
    );
  } else if (page === EMPTY_STATE_TYPE_EVENTS) {
    return "no event";
  } else if (page === EMPTY_STATE_TYPE_HOUSEKEEPER_CLEANINGS) {
    if (cleaning_status || request_payment) {
      return (
        <div className="text-center">
          <p>You have no Jobs for this filter.</p>
          <br />
          <br />
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleClick("clear-filters")}
          >
            Clear all filters
          </Button>
        </div>
      );
    } else {
      if (type !== JOB_TYPE_PAST) {
        return "You have no cleaning ToDo.  We will notify you as soon as a cleaning is assigned to you!";
      } else {
        return "You have no completed cleanings.";
      }
    }
  } else {
    return "No content available";
  }
};

const EmptyState = (props) => {
  const { subscribe, type } = props;

  return (
    <div className="empty-state">
      {" "}
      <EmptyStatesDisplay subscribe={subscribe} page={type} />{" "}
    </div>
  );
};

export default connect((state) => ({ subscribe: getSubscription(state).data }))(
  EmptyState
);
