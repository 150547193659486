import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import Moment from "react-moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Material UI
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { useMediaQuery } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Link from "@material-ui/core/Link";
import DescriptionIcon from "@material-ui/icons/Description";

// Components
import Pagination from "../containers/pagination";
import Filter from "../containers/filter";
import ItemsCount from "../components/itemsCount";
import LoadingScreen from "../components/loadingScreen";
import JobActions from "../containers/jobActions";
import NormalizedCleaningStatus from "../components/normalizedCleaningStatus";

// Helpers
import { parseQuery, filterParams } from "../helpers/url";
import history from "../helpers/history";
import { getFormattedCheckInOutTime } from "../helpers/format";
import { isAnyFilterActive } from "../helpers/checkFilters";

// Constants
import {
  JOB_TYPE_PAST,
  JOB_TYPE_TODO,
  CLEANING_CANCELLED,
  CLEANING_STARTED,
  MARKETPLACE,
} from "../constants/apiEnum";

// Actions
import { fetchProsJobs } from "../actions/jobs";

// Reducer
import { getJobs } from "../reducers/jobs";
import { getUser } from "../reducers/user";
import SameDayFlip from "../components/sameDayFlip";

import "../styles/startedJob.scss";

// A11Y MUI -- Check Material-ui documents
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const ProsJobs = (props) => {
  const {
    jobs: { items = {}, page = {}, isLoading = true },
    fetchProsJobs,
  } = props;
  const isMobile = useMediaQuery("(max-width:600px)");

  // Grab value of 'type' from URL
  const { type } = parseQuery();

  // create a new URL based on Tab selection
  let newSearch = null;

  // Reset the query params to remove any filters when swtiching between ToDo and Completed
  const emptyParams = {
    propertyIdsParam: [],
    paymentStatuses: [],
    maidIdsParam: [],
    channelsParam: [],
    cleaningStatus: [],
    noteParam: "false",
    requestPayment: "false",
  };

  // TAB Values
  const JOBS_TODO = 0;
  const JOBS_COMPLETED = 1;

  // Set tab state
  const [tabSelected, setTab] = useState(JOBS_TODO);

  // Fetch APIs
  useEffect(() => {
    fetchProsJobs();

    // Set tab based on URL
    if (type === JOB_TYPE_TODO) {
      setTab(JOBS_TODO);
    } else if (type === JOB_TYPE_PAST) {
      setTab(JOBS_COMPLETED);
    }
  }, []);

  function handleTabSelection(event, newValue) {
    // disable click on avtive tab
    if (tabSelected === newValue) return;

    if (newValue === JOBS_TODO) {
      newSearch = filterParams({
        typeParam: JOB_TYPE_TODO,
        ...emptyParams,
      });
    } else if (newValue === JOBS_COMPLETED) {
      newSearch = filterParams({
        typeParam: JOB_TYPE_PAST,
        ...emptyParams,
      });
    }

    // FILTERS ARE PUSHED TOOO
    history.push({
      search: newSearch,
    });
    setTab(newValue);

    // Fetch jobs based on new URL
    fetchProsJobs();
  }

  function handleJobClick(event, id) {
    history.push(`/job/${id}`);
  }

  // Format job date
  function FormatJobDate({ date, className }) {
    return [
      <Moment className={className} format="ddd, MMM DD" key="format-date">
        {date}
      </Moment>,
    ];
  }

  function TableCellLinked(props) {
    return (
      <TableCell
        onClick={(event) => handleJobClick(event, props.id)}
        {...props}
      />
    );
  }

  const RenderStatus = (props) => {
    const { is_completed: isCompleted, status, payment } = props;
    if (isCompleted) {
      if (!isEmpty(payment)) {
        return (
          <NormalizedCleaningStatus
            status={payment.status}
            human={payment.status_human}
          />
        );
      }
      return <NormalizedCleaningStatus status={status} />;
    } else {
      return <NormalizedCleaningStatus status={status} />;
    }
  };

  const DesktopLayout = () => (
    <Table aria-label="simple table" className="table">
      <TableHead>
        <TableRow>
          <TableCell align="left">Job date</TableCell>
          <TableCell align="left">Job time</TableCell>
          <TableCell align="left">Client</TableCell>
          <TableCell align="left">Location</TableCell>
          <TableCell align="left"></TableCell>
          <TableCell align="left">Status</TableCell>
          <TableCell align="left">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody className="--bk-white">
        {items.map((item) => {
          const {
            id,
            channel,
            job_date,
            address,
            status,
            assignor_name: assignorName,
            host_name: hostName,
            host_phone: hostPhone = "",
            job_time,
            is_flip: isFlip = false,
            property: { title = "", street = "" },
            payment,
            description = {},
            is_completed,
          } = item;

          const isMarketplace = channel === MARKETPLACE;

          // console.log("item: ", item);

          const isCancelled = status === CLEANING_CANCELLED;
          const isStarted = status === CLEANING_STARTED;

          return (
            id && (
              <TableRow
                key={`host-jobs-table-row-key-${id}`}
                className={`job-row-id-${id} tableRow--clickable ${
                  isCancelled ? "cleaning-cancelled" : ""
                } ${isStarted && "table-row--started"} ${
                  type === JOB_TYPE_TODO
                    ? "table-row--upcoming"
                    : "table-row--completed"
                }`} // using Type to bold only ToDo (upcoming) dates
                hover={false}
              >
                <TableCellLinked component="th" scope="row" id={id}>
                  <div className="inline-flex align-center">
                    <FormatJobDate date={job_date} className="bold" />
                    {isFlip && <SameDayFlip tooltip={true} />}
                  </div>
                </TableCellLinked>
                <TableCellLinked id={id}>
                  {getFormattedCheckInOutTime(job_time)}
                </TableCellLinked>
                <TableCellLinked id={id} className="nowrap">
                  <p>{assignorName ? assignorName : "--"}</p>
                </TableCellLinked>
                <TableCellLinked align="left" id={id}>
                  <div
                    title={title}
                    key="table-cell-title"
                    className="text-ellipsis"
                    style={{ width: 300 }}
                  >
                    {title}
                  </div>
                  <div
                    className="label text-ellipsis"
                    title={address}
                    key="table-cell-address"
                    style={{ width: 300 }}
                  >
                    {address}
                  </div>
                </TableCellLinked>
                <TableCellLinked align="left" id={id}>
                  {description && <DescriptionIcon fontSize="small" />}
                </TableCellLinked>
                <TableCellLinked id={id}>
                  <RenderStatus {...item} />
                </TableCellLinked>
                <TableCell className="pros__jobs nowrap">
                  <JobActions {...item} />
                </TableCell>
              </TableRow>
            )
          );
        })}
      </TableBody>
    </Table>
  );

  const MobileLayout = () => {
    return items.map((item) => {
      const {
        id,
        channel,
        job_date,
        job_time = "N/A",
        address,
        host_name: hostName,
        host_phone: hostPhone = "",
        status,
        property: { title = "" },
        payment,
        is_flip: isFlip = false,
        description = {},
        is_completed,
      } = item;

      // TODO IS CANCELLED

      const isCancelled = status === CLEANING_CANCELLED;
      const isStarted = status === CLEANING_STARTED;

      return (
        <div
          key={`jobs-mobile-card-${id}`}
          className={`jobs-card ${isStarted && "jobs-card--started"}`}
        >
          <div onClick={(event) => handleJobClick(event, id)}>
            <div className={`jobs-card__date jobs-card__date--split`}>
              <div className="inline-flex justify-center">
                <FormatJobDate date={job_date} />
                <span
                  className="grey"
                  style={{ fontWeight: 400, fontSize: "14px" }}
                >
                  <span style={{ marginRight: "4px", marginLeft: "4px" }}>
                    at
                  </span>
                  {getFormattedCheckInOutTime(job_time)}
                </span>
              </div>
              <div className="inline-flex align-center">
                {isFlip && <SameDayFlip />}
                {description && (
                  <DescriptionIcon fontSize="small" className="card__float" />
                )}
              </div>
            </div>
            <p className="jobs-card__location">{address}</p>
            <p className="jobs-card__title jobs-card__content--secondary mb2">{`${title} ${
              hostName ? ` - ${hostName}` : "--"
            }`}</p>
          </div>
          <div className="jobs-card__actions pros__jobs">
            {is_completed && !isEmpty(payment) && (
              <div onClick={(event) => handleJobClick(event, id)}>
                <NormalizedCleaningStatus
                  status={payment.status}
                  human={payment.status_human}
                />
              </div>
            )}
            <JobActions {...item} />
          </div>
        </div>
      );
    });
  };

  const JobsEmptyState = () => {
    function clearFilters() {
      const _location = `${window.location.origin}${window.location.pathname}`;
      window.location = _location;
    }

    if (isAnyFilterActive()) {
      return (
        <div
          style={{ height: "calc(100% - 55px)" }}
          className="full-width full-height inline-flex justify-center align-center"
        >
          <div>
            <h1 className="header-3">No results found.</h1>
            <Button
              variant="outlined"
              color="primary"
              className="host-primary"
              disableElevation={true}
              onClick={clearFilters}
            >
              Try Clearing filters
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{ height: "calc(100% - 55px)" }}
          className="full-width full-height inline-flex justify-center align-center"
        >
          <div className="text-center">
            <h3 className="header-3">No Jobs found</h3>
            <Link
              href="https://automatebnb.notion.site/How-do-I-invite-my-host-9e3da40eb7a34c0f8f0a049a5ea6d4cf"
              target="__new"
              title="Get started"
            >
              How do I invite my host?
            </Link>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div
          className="table-wrapper table-wrapper--nav-title table-wrapper--no-padding"
          key="host-jobs-page-content"
        >
          <Tabs
            value={tabSelected}
            onChange={handleTabSelection}
            indicatorColor="primary"
            textColor="primary"
            className="page-content__jobs-tabs"
          >
            <Tab
              {...a11yProps(JOBS_TODO)}
              label="ToDo"
              className="page-content__tab--todo"
            />
            <Tab {...a11yProps(JOBS_COMPLETED)} label="Past" />
          </Tabs>
          {isLoading ? (
            <LoadingScreen />
          ) : isEmpty(items) ? (
            <JobsEmptyState />
          ) : (
            <>
              <div key="pros-jobs-filters" className="mt2 mb2 jobs__filters">
                <Filter displaySelectedValue={true} filterType="prosJobs" />
              </div>
              {isMobile ? (
                <MobileLayout />
              ) : (
                [
                  <ItemsCount
                    onPage={items.length}
                    totalItems={page.total}
                    type="jobs"
                    className="mb1"
                    key="host-jobs-item-count"
                  />,
                  <DesktopLayout key="host-desktop-jobs-layout" />,
                ]
              )}
              {/* Pagination */}
              <Pagination
                nextPage={page.nextPage}
                prevPage={page.prevPage}
                type="prosJobs"
              />
              <div className="intercom-spacer" />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    jobs: getJobs(state),
    user: getUser(state),
  }),
  (dispatch) => bindActionCreators({ fetchProsJobs }, dispatch)
)(ProsJobs);
