import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import amplitude from "amplitude-js";
import DeleteIcon from "@material-ui/icons/Delete";

import Table from "@material-ui/core/Table";
import EventIcon from "@material-ui/icons/Event";
import FlareIcon from "@material-ui/icons/Flare";
import StarRateIcon from "@material-ui/icons/StarRate";
import FiberManualRecordOutlinedIcon from "@material-ui/icons/FiberManualRecordOutlined";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import SyncIcon from "@material-ui/icons/Sync";

import {
  DIALOG_TYPE_PROPERTY_LINK_ADD,
  DT_PROMPT_DELETE_CAL,
} from "../constants/dialogTypes";

import { fetchProperty, resyncCalendar } from "../actions/pmsProperties";
import { showSnackbar } from "../actions/action_snackbar";
import { setDialogOpen, openThisDialog } from "../actions/dialog";

import { getPMSProperty } from "../reducers/pmsProperties";
import { showArticle } from "@intercom/messenger-js-sdk";

const PropertyLink = (props) => {
  const {
    fetchProperty,
    property = {},
    setDialogOpen,
    openThisDialog,
    resyncCalendar,
    showSnackbar,
    propertyId,
  } = props;

  // Extract property
  const { isLoading = true, calendars = {} } = property;

  const [clickedRow, setClickedRow] = useState(null);

  const isMobile = useMediaQuery("(max-width:600px)");

  const handleForceSync = (id, url, channel) => {
    const index = calendars.findIndex((row) => row.id === id);
    setClickedRow(index);
    resyncCalendar({ url: url })
      .then((response) => {
        showSnackbar({
          message: `${channel} channel has synced successfully.`,
        });
        setClickedRow(null);
      })
      .catch((error) => {
        showSnackbar({
          message: `There was an issue syncing ${channel} channel, please contact us at help@automatebnb.com.`,
        });
        setClickedRow(null);
      });
  };

  useEffect(() => {
    if (propertyId) {
      // fetch property information
      fetchProperty(propertyId);
    }
  }, []);

  const handleClick = () => {
    amplitude
      .getInstance()
      .logEvent("clicked.propertyImportCalendarAddACalendar");
    setDialogOpen(DIALOG_TYPE_PROPERTY_LINK_ADD, propertyId);
  };

  const getTurncatedString = (props) => {
    const { url, channel } = props;

    if (url.length < 10) {
      return url;
    }

    if (channel === "airbnb") {
      return "..." + url.slice(37, 62) + "...";
    } else {
      return "..." + url.slice(33, 57) + "...";
    }
  };

  function getFormattedChannelName(name) {
    if (name === "vrbo") {
      return "VRBO";
    } else {
      return name.charAt(0).toUpperCase() + name.slice(1);
    }
  }

  function EmptyStateGraphic() {
    return (
      <div
        style={{
          position: "relative",
          opacity: 0.8,
          width: "150px",
          marginBottom: "16px",
        }}
      >
        <EventIcon style={{ fontSize: "100px" }} />
        <FlareIcon
          style={{ position: "absolute", top: 0, left: 0, opacity: 0.5 }}
        />
        <StarRateIcon
          style={{ position: "absolute", bottom: 0, opacity: 0.5 }}
        />
        <FiberManualRecordOutlinedIcon
          style={{ position: "absolute", top: 0, opacity: 0.3 }}
        />
      </div>
    );
  }

  const openHelpArticle = () => {
    if(showArticle) {
      showArticle(9463243)
    } else {
      // Go to link if Intercom is not available
      window.open("https://help.automatebnb.com/en/articles/9463243-list-of-integrations", "_blank")
    }
  }

  const ReturnCalendarsEmptyState = () => (
    <div className="empty-state">
      <div style={{ marginTop: "64px" }}>
        <EmptyStateGraphic />
        <div className="" style={{ width: "375px" }}>
          <h4 className="header-4">Looks like there are no calendars yet!</h4>
          <p>
            Link your calendar to automatically sync jobs, and we'll promptly update and inform the Pro
            about any new or modified reservations. Our platform supports either Channels or Property
            Management Software (PMS), but not both.
          </p>
          <br />
          <p>
            For more details about supported channels and PMS options, please refer to our <a href="#" className="underline underline-offset-2" onClick={ openHelpArticle }>Help Article.</a>
          </p>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            onClick={() => handleClick()}
            style={{ marginTop: "16px" }}
          >
            Add a calendar
          </Button>
        </div>
      </div>
    </div>
  );

  const CalendarCard = () => {
    return (
      <div className="pt3 pl1 pr1">
        <div className="mt2">
          {calendars.map((cal, index) => {
            return (
              <div
                className="calendar-card mb2"
                style={{
                  border: "1px solid #b5b5b5",
                  padding: "16px",
                  borderRadius: "8px",
                }}
              >
                <p style={{ fontSize: "18px" }}>
                  Channel:{" "}
                  <span className="semibold">
                    {getFormattedChannelName(cal.channel)}
                  </span>
                </p>
                <div className="mt2" style={{ whiteSpace: "nowrap" }}>
                  <Button
                    color="primary"
                    variant="outlined"
                    style={{
                      marginRight: "16px",
                      borderWidth: "1.6px",
                      borderColor: "black",
                    }}
                    onClick={() =>
                      handleForceSync(cal.id, cal.url, cal.channel)
                    }
                    startIcon={
                      <SyncIcon
                        className={
                          clickedRow ===
                            calendars.findIndex((r) => r.id === cal.id) &&
                          "spin-animation"
                        }
                      />
                    }
                  >
                    Force sync
                  </Button>
                  <Button
                    variant="text"
                    style={{ color: "#FF2929" }}
                    onClick={() =>
                      openThisDialog({
                        dialogType: DT_PROMPT_DELETE_CAL,
                        url: cal.url,
                        propertyId: propertyId,
                      })
                    }
                  >
                    Delete calendar
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const CalendarTable = () => {
    return (
      <div className="page-content__body">
        <div className="mt2 mb2">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Channel</TableCell>
                <TableCell align="left">Url</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {calendars.map((cal, index) => {
                return (
                  <TableRow key={cal.id} hover={false}>
                    <TableCell align="left">
                      <span className="text-wrap">{cal.channel}</span>
                    </TableCell>
                    <TableCell align="left">{cal.url}</TableCell>
                    <TableCell align="left">
                      <div style={{ whiteSpace: "nowrap" }}>
                        <Button
                          color="primary"
                          variant="text"
                          style={{ marginRight: "16px" }}
                          startIcon={
                            <SyncIcon
                              className={
                                clickedRow ===
                                  calendars.findIndex((r) => r.id === cal.id) &&
                                "spin-animation"
                              }
                            />
                          }
                          onClick={() =>
                            handleForceSync(cal.id, cal.url, cal.channel)
                          }
                        >
                          {!isMobile && "Force sync"}
                        </Button>
                        <Button
                          variant="text"
                          style={{ color: "#FF2929" }}
                          onClick={() =>
                            openThisDialog({
                              dialogType: DT_PROMPT_DELETE_CAL,
                              url: cal.url,
                              propertyId: propertyId,
                            })
                          }
                        >
                          {isMobile ? (
                            <DeleteIcon style={{ color: "#FF2929" }} />
                          ) : (
                            "Delete"
                          )}
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  };

  const RenderBody = () => {
    return (
      <>
        {isMobile ? <CalendarCard /> : <CalendarTable />}
        <Button
          color="primary"
          variant="contained"
          disableElevation
          style={{ marginLeft: "8px" }}
          onClick={() => handleClick()}
        >
          Add a calendar
        </Button>
      </>
    );
  };

  if (isLoading) {
    return (
      <div
        className="inline-flex justify-center align-center full-width"
        style={{ height: "80%" }}
      >
        <CircularProgress color="primary" />
      </div>
    );
  } else {
    if (isEmpty(calendars)) {
      return <ReturnCalendarsEmptyState />;
    } else {
      return <RenderBody />;
    }
  }
};

export default connect(
  (state) => ({ property: getPMSProperty(state) }),
  (dispatch) =>
    bindActionCreators(
      {
        fetchProperty,
        setDialogOpen,
        showSnackbar,
        resyncCalendar,
        openThisDialog,
      },
      dispatch
    )
)(PropertyLink);
