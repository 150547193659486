import React from "react";
import WifiIcon from "@material-ui/icons/Wifi";

interface WiFiProps {
  network: string | null;
  password: string | null;
}

const JobWifiInstructions: React.FC<WiFiProps> = ({
  network = null,
  password = null,
}) => {
  return (
    network && (
      <>
        <div className="mb-4">
          <div>
            <h3 className="txt-lg">
              <WifiIcon style={{ fontSize: 20, opacity: 0.8 }} /> Wifi
            </h3>
            <div className=" mt-2">
              <div className="flex align-center space-between py-1">
                <p>Network:</p>
                <p>
                  {network ? network : <span className="opacity-50">N/A</span>}
                </p>
              </div>
              <div className="flex align-center space-between py-1">
                <p>Password:</p>
                <p>
                  {password ? (
                    password
                  ) : (
                    <span className="opacity-50">N/A</span>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </>
    )
  );
};

export default JobWifiInstructions;
