import React from "react";
import { Button, Link } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

interface ChecklistProps {
  cleaning_guide_url: string | null;
}

const JobChecklist: React.FC<ChecklistProps> = ({
  cleaning_guide_url = "https://www.google.com",
}) => {
  const handleViewChecklist = () => {
    if (cleaning_guide_url) {
      window.open(cleaning_guide_url, "__blank");
    }
  };
  return (
    cleaning_guide_url && (
      <div className="mb-4 px-4 mt-4 py-4 bg-white rounded-md">
        <h3 className="txt-lg mb-2">Cleaning checklist</h3>
        <Button
          onClick={handleViewChecklist}
          variant="outlined"
          color="primary"
          endIcon={<OpenInNewIcon />}
        >
          View
        </Button>
      </div>
    )
  );
};

export default JobChecklist;
