import mixpanel from "mixpanel-browser";
const MIX_PANEL_TOKEN = process.env.REACT_APP_MIX_PANEL_TOKEN;
const isEnviornmentDev = process.env.REACT_APP_ENV === "dev";
class MixpanelManager {
  static sharedInstance = null;

  constructor() {
    if (!MixpanelManager.sharedInstance) {
      mixpanel.init(MIX_PANEL_TOKEN, {
        batch_requests: true,
        debug: isEnviornmentDev ? true : false,
      });
      mixpanel.set_config({ batch_size: 10 });
      MixpanelManager.sharedInstance = this;
    }

    return MixpanelManager.sharedInstance;
  }

  track(event, properties = {}) {
    mixpanel.track(event, properties);
  }

  identify(userId) {
    mixpanel.identify(userId);
  }

  setUserProperty(key, value) {
    mixpanel.people.set(key, value);
  }

  getPeople() {
    return mixpanel.people;
  }

  register(properties = {}) {
    mixpanel.register(properties);
  }

  timeEvent(name) {
    mixpanel.time_event(name);
  }

  reset() {
    mixpanel.reset();
  }
}

const MixpanelInstance = new MixpanelManager();

export { MixpanelManager, MixpanelInstance };
