import React, { useEffect } from "react";
import Navigation from "../containers/navigation";
import { Button, Typography } from "@material-ui/core";

import { UsernameField } from "../containers/accountFields";
import { isEmpty } from "lodash";
import { JOBS, NEW_USER_SIGNUP } from "../routes";
import { useDispatch } from "react-redux";
import { setOverlayLoadingScreen } from "../actions/overlayLoading";
import { isLoggedIn } from "../actions/user";
import { showSnackbar } from "../actions/action_snackbar";
import history from "../helpers/history";

const UsernameScreen = () => {
  const [username, setUsername] = React.useState(false);
  const dispatch = useDispatch();
  const handleUserStateChange = (username) => {
    setUsername(username);
  };

  useEffect(() => {
    if (localStorage.jwtToken) {
      dispatch(setOverlayLoadingScreen(true));
      dispatch(isLoggedIn())
        .then((response) => {
          if (response.status === 200) {
            window.location.replace(JOBS);
          } else {
            dispatch(setOverlayLoadingScreen(true));
          }
        })
        .catch((err) => {
          dispatch(setOverlayLoadingScreen(true));
        });
    } else {
      dispatch(setOverlayLoadingScreen(false));
    }
  }, []);

  const handleSubmit = () => {
    if (!isEmpty(username)) history.push(NEW_USER_SIGNUP, { param: username });
    // window.location.replace(`${NEW_USER_SIGNUP}?username=${username}`);
  };

  return (
    <div>
      <Navigation type="public" />
      <div
        className="flex align-center justify-center flex-column px2"
        style={{
          height: "calc(100vh - 40px)",
          maxWidth: 400,
          margin: "0 auto",
        }}
      >
        <div className="full-width">
          <Typography variant="h6">Create your account</Typography>
          <Typography variant="body2">
            Choose a username for your page.
          </Typography>
          <div className="mt2">
            <UsernameField handleUsernameChange={handleUserStateChange} />
          </div>
        </div>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          disableElevation
          style={{ marginTop: 8 }}
          disabled={isEmpty(username)}
          onClick={handleSubmit}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
export default UsernameScreen;
