import axios from "../helpers/axios";
import amplitude, { identify } from "amplitude-js";
import { Analytics } from "../services/analyticsService.tsx";

// NOT sure what it does
import { serverError } from "../helpers/helper_serverError";
import setAuthToken from "../helpers/helper_setAuthToken";
import { showSnackbar } from "./action_snackbar";

import {
  JOBS,
  LOG_IN,
  NEW_USER_ACCOUNT_CREATION,
  NEW_USER_MARKETPLACE_PROFILE_BASIC,
  NEW_USER_MARKETPLACE_PROFILE_PRICING,
} from "../routes";

import { fetchSubscription } from "./subscription";
import { setOverlayLoadingScreen } from "./overlayLoading";
import { fetchPayoutAccount } from "./payments";
import { fetchProsCategories } from "./pros";
import { getUserTimezone } from "../helpers/url";

export const SET_CURRENT_USER = "set_current_user";
export const SET_CURRENT_USER_TOKEN = "set_current_user_token";

export function inviteRequest(values) {
  return (dispatch) => {
    return axios
      .post("/v1/invite", values)
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            showSnackbar({ message: res.data.message, hideDuration: 8000 })
          );
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
}

export function validateUsernameAvailability(value) {
  return (dispatch) => {
    return axios.post(`/v1/users/validate_username?username=${value}`);
  };
}

export function inviteToPayment(id) {
  return (dispatch) => {
    return axios.get(`/v1/maids/${id}/invite_to_payments`);
  };
}

export function healthCheck() {
  return (dispatch) => {
    return axios.get("/v1/health");
  };
}

export function isLoggedIn() {
  return (dispatch) => axios.get("/v1/users/status");
}

export function fetchUser(location = null) {
  return (dispatch) => {
    return axios
      .get("/v1/users/status")
      .then((res) => {
        const { status, data } = res;

        if (status === 200) {
          setAuthToken(data.jwt);
          localStorage.setItem("jwtToken", data.jwt);

          dispatch(setUserToken(data));

          const profile_id = data.host_id ? data.host_id : data.pro_id;
          Analytics.identify(profile_id);
          const userType = data.is_host ? "HOST" : data.is_ops ? "OPS" : "MAID";

          Analytics.setUserProperty({
            role: userType,
            name: data.full_name,
            email: data.email,
            timezone: getUserTimezone(),
          });

          Analytics.register({
            user_id: data.id,
            email: data.email,
            role: userType,
            name: data.full_name,
          });

          // User signed up added logic to log an event
          if (location === "signup") {
            Analytics.trackSimple("user_signed_up_action");
          }

          if (data.is_host) {
            dispatch(fetchHost({ setIdentity: true }));
          } else if (data.is_maid || data.is_ops) {
            if (data.first) {
              dispatch(fetchPro({ setIdentity: true }));
              dispatch(fetchPayoutAccount());
            } else {
              // User has skipped account creation flow in onboarding
              const currentPath = window.location.pathname;
              if (currentPath !== NEW_USER_ACCOUNT_CREATION) {
                // if user is not on /user/account path than push the user to create account information first.
                window.location.replace(NEW_USER_ACCOUNT_CREATION);
              }
            }
          }
        }
      })
      .catch((error) => {
        Analytics.trackEvent("user_logged_out_due_to_unauth_api_failure");
        dispatch(serverError(error));
        dispatch(logout());
      });
  };
}

export function createMaidAccount({ first, last, phone, address }) {
  const city = address.city || "";
  const state = address.state || "";
  const zip = address.zip || "";
  const full_address = address.fulladdress;

  // API using address as street name and number
  // Reassigning street to address variable
  address = address.street;

  return (dispatch) => {
    return axios
      .post("/v1/maids", {
        first,
        last,
        phone,
        address,
        city,
        state,
        zip,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchPro({}));
          dispatch(fetchPayoutAccount());
          dispatch(fetchProsCategories());

          window.location.replace(NEW_USER_MARKETPLACE_PROFILE_BASIC);
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
}

export function userSignup({ username, email, password, role = "MAID" }) {
  return (dispatch) => {
    return axios
      .post("/v1/users/signup", { username, email, password, role })
      .then((response) => {
        if (response.status === 200) {
          const { data } = response;

          if (data.jwt) {
            setAuthToken(data.jwt);
            localStorage.setItem("jwtToken", data.jwt);

            window.location.replace(NEW_USER_ACCOUNT_CREATION);
          }
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
}

// Register a Pro (Signup Pros form)
export function registerPro(value) {
  return (dispatch) => {
    return axios
      .post("/v1/maids/signup", { ...value })
      .then((res) => {
        if (res.status === 200) {
          const { data } = res;
          if (data.jwt) {
            setAuthToken(data.jwt);
            localStorage.setItem("jwtToken", data.jwt);
            Analytics.trackSimple("user_signed_up_action");
            dispatch(fetchPro());
            dispatch(fetchPayoutAccount());
            // Gather categories from the API
            dispatch(fetchProsCategories());
            window.location.replace(JOBS);
          }
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
}

// Use this to fetch data to pre-fill it in Pros signup form
export function proPreSignup(inviteCode) {
  return (dispatch) => {
    return axios
      .get("/v1/maids/pre_signup", { params: { invite_code: inviteCode } })
      .then((res) => {
        if (res.status === 200) {
          dispatch(setUser(res.data));
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
}

export function fetchHost(setIdentity = false) {
  return (dispatch) => {
    return axios
      .get("/v1/hosts/1")
      .then((res) => {
        const { status, data } = res;
        if (status === 200) {
          // Setidentify only once.
          if (setIdentity) {
            dispatch(fetchSubscription());
            dispatch(setUser(data));
          }
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
}

// Destructuring assignment to ensure function can be called without passing any argument
export function fetchPro({ setIdentity = false } = {}) {
  return (dispatch) => {
    return axios
      .get(`/v1/maids/1`)
      .then((res) => {
        const { status, data } = res;
        if (status === 200) {
          const isOps = data.is_ops;
          const isMaid = data.is_maid;

          amplitude
            .getInstance()
            .setUserProperties({ account_type: isOps ? "ops" : "housekeeper" });

          dispatch(setUser({ ...data }));
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
}

export function updatePro(values) {
  return (dispatch) => {
    return axios
      .put(`/v1/maids/${values.id}`, values)
      .then((res) => {
        const { status, data } = res;
        if (status === 200) {
          dispatch(
            showSnackbar({ message: "Profile Updated!", toReservation: true })
          );
          dispatch(setUser(data));
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
}

export function updateUser(values) {
  return (dispatch) => {
    return axios
      .put("/v1/users/1", values)
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            showSnackbar({ message: "Password Updated!", toReservation: true })
          );
          dispatch(setUser(res.data));
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
}

export function forgotPassword(values) {
  return (dispatch) => {
    return axios.post("/v1/users/forgot_password", values);
  };
}

export function resetPassword(values) {
  return (dispatch) => {
    return axios.post("/v1/users/reset_password", values);
  };
}

export function logout() {
  return (dispatch) => {
    dispatch(setOverlayLoadingScreen(true));
    localStorage.removeItem("jwtToken");
    setAuthToken(false);
    Analytics.reset();
    window.location.replace(LOG_IN);
  };
}

export const login = (data) => {
  return (dispatch) => {
    dispatch(setOverlayLoadingScreen(true));
    return axios
      .post("/v1/user_token", { auth: data })
      .then((res) => {
        if (res) {
          if (res.status === 200 || res.status === 201) {
            const jwt = res.data.jwt;
            setAuthToken(jwt);
            dispatch(fetchUser());
          }
        }
      })
      .catch((error) => {
        dispatch(setOverlayLoadingScreen(false));
        dispatch(
          serverError(
            error,
            "Sign in failed, please check your email & password and try again!"
          )
        );
      });
  };
};

export const setUser = (user) => ({ type: SET_CURRENT_USER, user });
export const setUserToken = (data) => ({ type: SET_CURRENT_USER_TOKEN, data });
