import React, {useEffect, useState, useRef} from 'react';
import {connect, useSelector} from "react-redux";
import SendbirdChat from "@sendbird/chat";
import {
  GroupChannelFilter, GroupChannelListOrder,
  GroupChannelModule, MessageCollectionInitPolicy, MessageFilter
} from '@sendbird/chat/groupChannel';
import {getUser} from "../reducers/user";

// Containers
import Navigation from "../containers/navigation";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import "./messages.scss";

// UIKIT Sendbird
import "@sendbird/uikit-react/dist/index.css";
import GroupChannel from "@sendbird/uikit-react/GroupChannel";
import {Drawer, useMediaQuery} from "@material-ui/core";

// Components
import {getChatName, ChannelList} from "./components/channelList";
import {sendbirdSelectors, useSendbirdStateContext} from "@sendbird/uikit-react";

let sb;
const MessagesPage = (props) => {

  const globalStore = useSendbirdStateContext();
  const sdkInstance = sendbirdSelectors.getSdk(globalStore);

  const [chatState, updateChatState] = useState({
    applicationUsers: [],
    groupChannelMembers: [],
    currentlyJoinedChannel: null,
    messages: [],
    channels: [],
    messageInputValue: "",
    userNameInputValue: "",
    userIdInputValue: "",
    channelNameUpdateValue: "",
    settingUpUser: true,
    file: null,
    messageToUpdate: null,
    messageCollection: null,
    loading: false,
    error: false
  });

  const isMobile = useMediaQuery("(max-width:600px)");

  //need to access state in message received callback
  const chatStateRef = useRef();
  chatStateRef.current = chatState;

  const channelRef = useRef();

  const channelHandlers = {
    onChannelsAdded: (context, channels) => {
      const updatedChannels = [...channels, ...chatStateRef.current.channels];
      updateChatState({ ...chatStateRef.current, channels: updatedChannels, applicationUsers: [] });
    },
    onChannelsDeleted: (context, channels) => {
      const updatedChannels = chatStateRef.current.channels.filter((channel) => {
        return !channels.includes(channel.url);
      });
      updateChatState({ ...chatStateRef.current, channels: updatedChannels });

    },
    onChannelsUpdated: (context, channels) => {
      const updatedChannels = chatStateRef.current.channels.map((channel) => {
        const updatedChannel = channels.find(incomingChannel => incomingChannel.url === channel.url);
        if (updatedChannel) {
          return updatedChannel;
        } else {
          return channel;
        }
      });

      updateChatState({ ...chatStateRef.current, channels: updatedChannels });
    },
  }

  const messageHandlers = {
    onMessagesAdded: (context, channel, messages) => {
      const updatedMessages = [...chatStateRef.current.messages, ...messages];

      updateChatState({ ...chatStateRef.current, messages: updatedMessages });

    },
    onMessagesUpdated: (context, channel, messages) => {
      const updatedMessages = [...chatStateRef.current.messages];
      for (let i in messages) {
        const incomingMessage = messages[i];
        const indexOfExisting = chatStateRef.current.messages.findIndex(message => {
          return incomingMessage.reqId === message.reqId;
        });

        if (indexOfExisting !== -1) {
          updatedMessages[indexOfExisting] = incomingMessage;
        }
        if (!incomingMessage.reqId) {
          updatedMessages.push(incomingMessage);
        }
      }

      updateChatState({ ...chatStateRef.current, messages: updatedMessages });
    },
    onMessagesDeleted: (context, channel, messageIds) => {
      const updateMessages = chatStateRef.current.messages.filter((message) => {
        return !messageIds.includes(message.messageId);
      });
      updateChatState({ ...chatStateRef.current, messages: updateMessages });

    },
    onChannelUpdated: (context, channel) => {

    },
    onChannelDeleted: (context, channelUrl) => {
    },
    onHugeGapDetected: () => {
    }
  }

  const scrollToBottom = (item, smooth) => {
    if(item)
      item.scrollTo({
        top: item.scrollHeight,
        behavior: smooth
      })
  }

  const channelListColRef = useRef(null);
  const channelContRef = useRef(null);
  const groupChannelCtnr = useRef(null);
  const chatBoxContextRef = useRef(null);

  useEffect(() => {
    const setMessagesCtnrHeight = () => {

      if (channelContRef.current && channelListColRef.current) {
        let headerContentHeight = document.querySelector('header').offsetHeight;
        let bannerHeaderHeight = document.querySelector('.header-banner')?.offsetHeight || 0;
        let trialBannerHeaderHeight = document.querySelector('.trial-banner')?.offsetHeight || 0;
        let chatBoxContextRefEl = chatBoxContextRef.current.offsetHeight || 0;
        channelContRef.current.style.height = `calc(100vh - ${headerContentHeight + bannerHeaderHeight + trialBannerHeaderHeight}px)`;
        channelListColRef.current.style.height = `calc(100vh - ${headerContentHeight + bannerHeaderHeight + trialBannerHeaderHeight}px)`;
        groupChannelCtnr.current.style.maxHeight = `calc(100vh - ${headerContentHeight + bannerHeaderHeight + trialBannerHeaderHeight + chatBoxContextRefEl}px)`;
        window.addEventListener('resize', setMessagesCtnrHeight);
      } else {
        setTimeout(setMessagesCtnrHeight, 100);
      }
    }

    setMessagesCtnrHeight();

    return async () => {
      window.removeEventListener('resize', setMessagesCtnrHeight);
    }
  }, []);

  useEffect(() => {
    setupUser();
  }, [props, sdkInstance]);

  useEffect(() => {
      if(chatState.channels.length > 0 && chatState.currentlyJoinedChannel === null){
        handleJoinChannel(chatState.channels[0].url);
      }
  }, [chatState.channels]);

  useEffect(() => {
    scrollToBottom(channelRef.current)
  }, [chatState.currentlyJoinedChannel])

  useEffect(() => {
    scrollToBottom(channelRef.current, 'smooth')
  }, [chatState.messages])

  const onError = (error) => {
    updateChatState({ ...chatState, error: error.message });
  }

  const handleJoinChannel = async (channelUrl) => {
    if (chatState.messageCollection && chatState.messageCollection.dispose) {
      chatState.messageCollection.dispose();
    }

    if (chatState.currentlyJoinedChannel?.url === channelUrl) {
      return null;
    }
    const { channels } = chatState;
    updateChatState({ ...chatState, loading: true });
    const channel = channels.find((channel) => channel.url === channelUrl);
    const onCacheResult = (err, messages) => {
      updateChatState({ ...chatStateRef.current, currentlyJoinedChannel: channel, messages: messages.reverse(), loading: false })
    }

    const onApiResult = (err, messages) => {
      updateChatState({ ...chatStateRef.current, currentlyJoinedChannel: channel, messages: messages.reverse(), loading: false })
    }

    const collection = loadMessages(channel, messageHandlers, onCacheResult, onApiResult);

    if(isMobile) {
      setChatDrawer(false);
    }

    updateChatState({ ...chatState, messageCollection: collection });
  }

  const setupUser = async () => {

    if(props.user.user_id === undefined || props.user.user_id === ""  || !sdkInstance.groupChannel){
      return;
    }

    sb = sdkInstance;

    updateChatState({ ...chatState, loading: true });
    const [channels, error] = await loadChannels(props.user.user_id, channelHandlers);

    if (error) {
      return onError(error);
    }

    updateChatState({ ...chatState, channels: channels, loading: false, settingUpUser: false });
  }

  const loadChannels = async (userId, channelHandlers) => {
    const groupChannelFilter = new GroupChannelFilter();
    groupChannelFilter.includeEmpty = true;
    groupChannelFilter.setUserIdsFilter([userId], true);
    const groupChannelParams = {
      filter: groupChannelFilter,
      order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
    };
    const collection = sb.groupChannel.createGroupChannelCollection(groupChannelParams);
    collection.setGroupChannelCollectionHandler(channelHandlers);

    const channels = await collection.loadMore();
    const uniqueChannels = channels.filter((channel, index, self) =>
      index === self.findIndex((t) => (
        t.url === channel.url
      ))
    );

    return [uniqueChannels, null];
  }

  const loadMessages = (channel, messageHandlers, onCacheResult, onApiResult) => {
    const messageFilter = new MessageFilter();

    const collection = channel.createMessageCollection({
      filter: messageFilter,
      startingPoint: Date.now(),
      limit: 100
    });

    collection.setMessageCollectionHandler(messageHandlers);
    collection
      .initialize(MessageCollectionInitPolicy.CACHE_AND_REPLACE_BY_API)
      .onCacheResult(onCacheResult)
      .onApiResult(onApiResult);
    return collection;
  }

  const [chatDrawer, setChatDrawer] = useState(false);

  return (
    <>
      <Navigation />
      <div className="page-wrapper chat-wrapper">
        <div className={`page-content flex flex-row`} >

          {isMobile? (<>
            <Drawer
              anchor="left"
              open={chatDrawer}
              className="channel-list-col"
              onClose={() => setChatDrawer(!chatDrawer)}
            >
              <ChannelList
                channels={chatState.channels}
                currentUser={props.user}
                handleJoinChannel={handleJoinChannel}
                channelSelected={chatState.currentlyJoinedChannel?.url}
              />
            </Drawer>
            </>) : (<>
            <div className={`p-3 channel-list-col ${isMobile ? 'is_mobile':'basis-1/4'}`} ref={channelListColRef}>
              <ChannelList
                channels={chatState.channels}
                currentUser={props.user}
                handleJoinChannel={handleJoinChannel}
                channelSelected={chatState.currentlyJoinedChannel?.url}
              />
            </div>
          </>)}

          {chatState.currentlyJoinedChannel != null ? (
            <div className={`flex flex-column channel-content-col grow ${isMobile ? 'is_mobile':''}`} ref={channelContRef}>
              <div className="flex min-h-10 sm:min-h-20 p-3 sm:p-5 font-bold text-lg sm:text-xl align-center" ref={chatBoxContextRef}>
                {isMobile && <>
                  <button
                    className="p-3 hover:cursor-pointer"
                    aria-labelledby="Menu"
                    key="navigation-title-back-button"
                    onClick={() => setChatDrawer(!chatDrawer)}
                  >
                    <ArrowBackIosIcon/>
                  </button>
                </>}
                {getChatName(chatState.currentlyJoinedChannel, props.user.user_id).map(member => member.nickname).join(", ")}
              </div>
              <div ref={groupChannelCtnr} className="grow">
                <GroupChannel channelUrl={chatState.currentlyJoinedChannel?.url}
                              disableUserProfile={true}
                              renderChannelHeader={()=>(<></>)}
                />
              </div>
            </div>
          ) : (
            <div className="align-center text-slate-100">
              Loading...
            </div>
          )}
        </div>
      </div>
    </>
  )

}

export default connect(
  (state) => ({
    user: getUser(state)
  })
)(MessagesPage);
