import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@material-ui/core";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
} from "@material-ui/core";
import PhotoIcon from "@material-ui/icons/Photo";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch, useSelector } from "react-redux";
import { s3UrlMapper } from "../../helpers/s3URLMapper";
import AddOrUpdateSpaceModal from "./AddOrUpdateSpaceModal";
import { LocalModalProvider, useLocalModal } from "./LocalModalContext";
import { isEmpty } from "lodash";
import PhotoCard from "./photoCard";
import AddOrUpdateTaskModal from "./AddOrUpdateTaskModal";
import { Analytics } from "../../services/analyticsService.tsx";
import {
  deleteSpace,
  fetchSpace,
  fetchSpaces,
  deleteTask,
} from "../../actions/staging";

const ManageSpace = ({
  hideModal,
  propertyId,
  spaceId,
  isOnboarding = false,
  newPropertyFlow = false,
}) => {
  const localSpace = useSelector((state) => state.space);
  const dispatch = useDispatch();
  const isMediumScreen = useMediaQuery("(max-width:870px)");
  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    // Get the latest and greatest
    dispatch(fetchSpace({ propertyId, spaceId }));
  }, []);

  const SpaceContainer = ({
    name,
    image_url,
    id,
    property_id,
    description,
  }) => {
    const { showLocalModal, hideLocalModal } = useLocalModal();
    return (
      <div
        className="flex align-center px2 py2"
        style={{
          backgroundColor: "#F6F6F6",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      >
        <PhotoCard
          name={name}
          image_url={image_url}
          width={isMobile ? "100px" : "178px"}
          height={isMobile ? "69px" : "118px"}
          elevation={1}
        />
        <div
          className="flex space-between"
          style={{
            flex: 1,
            marginLeft: 16,
            flexDirection: isMobile ? "column" : "row",
            alignItems: isMobile ? "flex-start" : "space-between",
          }}
        >
          <div>
            <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
              {name}
            </Typography>
            <Typography variant="body2">{description}</Typography>
          </div>
          <div className="flex align-center">
            {isMobile ? (
              <IconButton
                style={{ color: "red" }}
                onClick={() =>
                  showLocalModal(ConfrmRemoveSpaceModal, {
                    hideConfirmModal: hideLocalModal,
                    hideModal,
                    name: name,
                    propertyId: property_id,
                    spaceId: id,
                  })
                }
              >
                <DeleteIcon />
              </IconButton>
            ) : (
              <Button
                variant="text"
                style={{ color: "red", marginRight: 16 }}
                onClick={() =>
                  showLocalModal(ConfrmRemoveSpaceModal, {
                    hideConfirmModal: hideLocalModal,
                    hideModal,
                    name: name,
                    propertyId: property_id,
                    spaceId: id,
                  })
                }
              >
                Remove
              </Button>
            )}
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                showLocalModal(AddOrUpdateSpaceModal, {
                  isUpdateSpace: true,
                  name,
                  description,
                  image_url: s3UrlMapper({ url: image_url, size: "md" }),
                  propertyId: property_id,
                  id,
                  hideModal: hideLocalModal,
                })
              }
            >
              Edit
            </Button>
          </div>
        </div>
      </div>
    );
  };

  function ConfrmRemoveTaskModal({
    name,
    hideConfirmModal,
    taskId,
    propertyId,
    spaceId,
  }) {
    const dispatch = useDispatch();
    return (
      <>
        <DialogTitle>Remove task</DialogTitle>
        <DialogContent>
          You are attempting to remove{" "}
          <span style={{ fontWeight: 500 }}>{name}</span> task. This action
          cannot be undone. Are you sure you want to remove this task?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => hideConfirmModal()}>Cancel</Button>
          <Button
            variant="contained"
            style={{ backgroundColor: "red", color: "white", marginLeft: 16 }}
            disableElevation
            onClick={() =>
              dispatch(deleteTask({ propertyId, spaceId, taskId })).then(
                (res) => {
                  if (res && res.status === 200) {
                    dispatch(fetchSpace({ propertyId, spaceId }));
                    hideConfirmModal();
                  }
                }
              )
            }
          >
            Remove
          </Button>
        </DialogActions>
      </>
    );
  }

  function ConfrmRemoveSpaceModal({
    name,
    hideConfirmModal,
    hideModal,
    propertyId,
    spaceId,
  }) {
    const dispatch = useDispatch();
    return (
      <>
        <DialogTitle>Remove space</DialogTitle>
        <DialogContent>
          You are attempting to remove{" "}
          <span style={{ fontWeight: 500 }}>{name}</span> space. This action
          will remove all the tasks associated to this space. Are you sure you
          want to remove this space?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => hideConfirmModal()}>Cancel</Button>
          <Button
            variant="contained"
            style={{ backgroundColor: "red", color: "white", marginLeft: 16 }}
            disableElevation
            onClick={() =>
              dispatch(deleteSpace({ propertyId, spaceId })).then((res) => {
                if (res && res.status === 200) {
                  // Fetch all spaces for a property
                  dispatch(fetchSpaces(propertyId));
                  // Close manage space full screen modal
                  hideModal();
                }
              })
            }
          >
            Remove
          </Button>
        </DialogActions>
      </>
    );
  }

  const RenderTasks = ({ id, property_id, checklist_tasks }) => {
    const { showLocalModal, hideLocalModal } = useLocalModal();
    if (isEmpty(checklist_tasks)) {
      return (
        <Typography
          variant="body2"
          style={{
            fontStyle: "italic",
            fontSize: "0.75rem",
            marginLeft: 16,
            marginTop: 16,
          }}
        >
          No tasks added
        </Typography>
      );
    } else {
      return (
        <div style={{ marginTop: 16 }}>
          <Typography variant={isMobile ? "subtitle2" : "h6"} className="pl2">
            Tasks
          </Typography>
          {checklist_tasks.map((task, index) => {
            return (
              <div key={index}>
                <div className="flex align-center space-between px2 py1">
                  <PhotoCard
                    name={task.name}
                    image_url={task.image_url}
                    width={isMobile ? "100px" : "130px"}
                    height={isMobile ? "69px" : "90px"}
                    elevation={1}
                  />
                  <div
                    className="flex space-between"
                    style={{
                      flex: 1,
                      marginLeft: 16,
                      flexDirection: isMobile ? "column" : "row",
                      alignItems: isMobile ? "flex-start" : "space-between",
                    }}
                  >
                    <div>
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: 500 }}
                      >
                        {task.name}
                      </Typography>
                      <Typography variant="body2">
                        {task.description}
                      </Typography>
                    </div>
                    <div className="flex align-center">
                      {isMobile ? (
                        <IconButton
                          style={{ color: "red" }}
                          onClick={() =>
                            showLocalModal(ConfrmRemoveTaskModal, {
                              hideConfirmModal: hideLocalModal,
                              name: task.name,
                              taskId: task.id,
                              spaceId: id,
                              propertyId: property_id,
                            })
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      ) : (
                        <Button
                          variant="text"
                          style={{ marginRight: 16, color: "red" }}
                          onClick={() =>
                            showLocalModal(ConfrmRemoveTaskModal, {
                              hideConfirmModal: hideLocalModal,
                              name: task.name,
                              taskId: task.id,
                              spaceId: id,
                              propertyId: property_id,
                            })
                          }
                        >
                          Remove
                        </Button>
                      )}
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                          showLocalModal(AddOrUpdateTaskModal, {
                            name: task.name,
                            description: task.description,
                            propertyId: property_id,
                            spaceId: id,
                            image_url: s3UrlMapper({
                              url: task.image_url,
                              size: "md",
                            }),
                            taskId: task.id,
                            hideModal: hideLocalModal,
                          })
                        }
                      >
                        Edit
                      </Button>
                    </div>
                  </div>
                </div>
                {index < checklist_tasks.length - 1 && (
                  <hr
                    style={{
                      opacity: 0.4,
                      width: "90%",
                      margin: "0 auto",
                      marginTop: 8,
                      marginBottom: 8,
                    }}
                  />
                )}
              </div>
            );
          })}
        </div>
      );
    }
  };

  const RenerTaskPerdictions = ({
    name,
    property_id: propertyId,
    id: spaceId,
  }) => {
    const { showLocalModal, hideLocalModal } = useLocalModal();

    const handleAddTask = (name = "") => {
      const isShortcutName = name ? `_${name.toLowerCase()}_` : null;
      if (newPropertyFlow) {
        if (isOnboarding) {
          Analytics.trackSimple(
            `onboarding${
              isShortcutName ? isShortcutName : "_add_"
            }task_action_from_managing_space`
          );
        } else {
          Analytics.trackSimple(
            `new_property${
              isShortcutName ? isShortcutName : "_add_"
            }task_action_from_managing_space`
          );
        }
      } else {
        Analytics.trackEvent(
          "add_task_action_from_property_details_managing_space",
          { property_id: propertyId }
        );
      }
      showLocalModal(AddOrUpdateTaskModal, {
        name,
        propertyId,
        hideModal: hideLocalModal,
        spaceId,
      });
    };

    return (
      <div className="flex align-center ml2 mt2">
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleAddTask()}
        >
          Add task
        </Button>
      </div>
    );
  };

  const RenderManageSpace = ({ data: space }) => {
    const { showLocalModal, hideLocalModal } = useLocalModal();
    if (localSpace.isLoading) {
      return (
        <div className="flex align-center justify-center">
          <CircularProgress />
        </div>
      );
    } else {
      return (
        <>
          <DialogActions style={{ padding: 0 }}>
            <div
              className="py2 full-width flex space-between"
              style={{
                width: "",
                borderBottom: "1px solid #c1c1c1",
                paddingLeft: 16,
              }}
            >
              <div
                style={{
                  maxWidth: "713px",
                  margin: "0 auto",
                  display: "flex",
                  flex: 1,
                }}
              >
                <Button
                  size="large"
                  variant="text"
                  color="primary"
                  onClick={hideModal}
                  disableElevation
                  startIcon={<ArrowBackIcon />}
                >
                  Back to spaces
                </Button>
              </div>
            </div>
          </DialogActions>
          <DialogContent>
            <div style={{ maxWidth: "700px", margin: "0 auto" }}>
              <Typography variant="h5">Manage space</Typography>
              <Typography variant="body1">
                Ensure the space name is unique and the photo is clear for easy
                identification. Add tasks to guide pros in staging the space for
                guest readiness.
              </Typography>
              <div className="mt3">
                <SpaceContainer {...space} />
                <RenderTasks {...space} />
                <RenerTaskPerdictions {...space} />
              </div>
            </div>
          </DialogContent>
        </>
      );
    }
  };

  return (
    <LocalModalProvider>
      <RenderManageSpace {...localSpace} />
    </LocalModalProvider>
  );
};

export default ManageSpace;
