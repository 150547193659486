export const DIALOG_TYPE_NOTIFICATION_SETTINGS =
  "dialog-type-notification-settings";

export const DIALOG_TYPE_ADD_A_CLEANING_NOTE =
  "dialog-type-add-a-cleaning-note";
export const DIALOG_TYPE_PROMPT_TO_SUBSCIBE = "dialog-type-prompt-to-subscribe";
export const DIALOG_TYPE_PROPERTY_DETAILS_EDIT =
  "dialog-type-property-details-edit";

export const DIALOG_TYPE_PROPERTY_LINK_ADD = "dialog-type-property-link-add";

export const DIALOG_TYPE_PROPERTY_DELETE_CONFIRM =
  "dialog-type-property-delete-confirm";
export const DIALOG_TYPE_FILTER_BY_PROPERTY = "dialog-type-filter-by-property";
export const DIALOG_TYPE_ALL_FILTERS = "dialog-type-all-filters";
export const DIALOG_TYPE_FILTER_BY_HOUSEKEEPERS =
  "dialog-type-filter-by-housekeepers";
export const DIALOG_TYPE_DELAY_CLEANING_AGREEMENT =
  "dialog-type-delay-cleaning-agreement";
export const DIALOG_TYPE_DEACTIVATE_DELAY_CLEANING_PROMPT =
  "dialog-type-deactivate-delay-cleaning-prompt";
export const DIALOG_TYPE_CHANGE_CLEANING_DATE =
  "dialog-type-change-cleaning-date";
export const DIALOG_TYPE_CLEANING_DATE_CONFLICT =
  "dialog-type-cleaning-date-conflict";
export const DIALOG_TYPE_MAKE_CLEANING_PAYMENT =
  "dialog-type-make-cleaning-payment";
export const DIALOG_TYPE_REQUEST_CLEANING_PAYMENT =
  "dialog-type-request-cleaning-payment";
export const DIALOG_TYPE_SUBSCRIBE_TO_MAKE_PAYMENT_PROMPT =
  "dialog-type-subscribe-to-make-payment-prompt";
export const DIALOG_TYPE_CANCEL_PAYMENT_PROMPT =
  "dialog-type-cancel-payment-prompt";
export const DIALOG_TYPE_INVITE_HOUSEKEEPER_TO_PAY =
  "dialog-type-invite-housekeeper-to-pay";

export const DT_MARK_COMPLETED = "dialog-type-mark-cleaning-completed";
export const DT_FILTER_CLEANING_STATUS = "dialog-type-filter-cleaning-status";
export const DT_PROS_CATEGORIES = "dialog-type-pros-categories";

// JOBS page HOST only
export const DT_REQUEST_A_JOB = "dialog-type-request-a-job";
export const DT_ADD_A_PROPERTY = "dialog-type-add-a-property";
export const DT_INVITE_PRO = "dialog-type-invite-pro";
export const DT_JOB_DESCRIPTION = "dialog-type-add-job-description";
export const DT_CHANGE_A_PRO = "dialog-change-a-pro";
export const DT_MAKE_A_PAYMENT = "dialog-make-a-payment";
export const DT_SUBSCRIBE_PROMPT = "dialog-subscribe-prompt";
export const DT_CANCEL_JOB = "dialog-type-cancel-job-prompt";
export const DT_INVITE_TO_PAY = "dialog-type-invite-a-pro-to-pay";
export const DT_UPDATE_JOB_DATE_TIME = "dialog-type-update-job-date-time";
export const DT_GENERIC_DIALOG = "dialog-type-generic-dialog";
export const DT_PROMPT_DELETE_CAL = "dialog-type-delete-calendar";
export const DT_NOTIFICATION_CONFIG = "dialog-type-notification-config";

export const DT_GENERIC_MODAL = "dialog-type-generic-modal";

export const DT_INVITE_OPS_CLIENTS = "dialog-type-ops-clients";
export const DT_DISCREPANCIES_JOBS_RESERVATIONS =
  "dialog-type-discrepancies-between-jobs-reservations";

// Automation
export const DT_PROMPT_UNASSIGN_PRO_FROM_PROPERTY =
  "dialog-type-prompt-unassign-pro-from-property";
export const DT_ASSIGN_PRO_TO_PROPERTY = "dialog-type-assign-pro-to-property";
