import {
  SET_PROMOTION_DIALOG,
  FILTER_PROMOTION_ARRAY
} from "../actions/promotion";

export const getPromotionStatus = ({ promotion }) => promotion;

export const promotion = (state = [], action) => {
  switch (action.type) {
    case SET_PROMOTION_DIALOG:
      const { promotion } = action;
      return promotion;

    default:
      return state;
  }
};
