import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { HOST_SUBSCRIBE, HOST_PAYMENT_METHOD, MANAGE_PROFILE } from "../routes";

import {
  SUBSCRIBE_STATUS_ACTIVE,
  SUBSCRIBE_STATUS_CANCELLED,
  SUBSCRIBE_STATUS_TRIAL_PERIOD,
  SUBSCRIBE_STATUS_TRIAL_EXPIRED,
  SUBSCRIBE_STATUS_PAST_DUE,
} from "../constants/apiEnum";

import "./banner.scss";
import { Button } from "@material-ui/core";

import { updateProfileTallyURLConstructor } from "../helpers/url";

const SUBSCRIBE_MESSAGE_CONTACT_US = "help@automatebnb.com";

const DisplayPaymentBanner = (props) => {
  const { message, routeTo, title } = props;

  return (
    <div key="trial-banner" className="trial-banner">
      <div className="text-center">
        <span key="trial-banner-content-1" className="banner-content">
          {message ? message : "There is a problem with your account."}{" "}
        </span>
        {routeTo ? (
          <Link
            to={routeTo}
            className="banner-button button button-mini button-rounded button-danger"
          >
            {title ? title : "Subscribe"}
          </Link>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const PaymentBanner = ({ userToken, subscribe }) => {
  const { trial_days_left: trialDaysLeft, status } = subscribe;

  const renderPaymentBanner = () => {
    if (subscribe && status && userToken.is_host) {
      if (status === SUBSCRIBE_STATUS_ACTIVE) {
        return <div />;
      } else if (status === SUBSCRIBE_STATUS_CANCELLED) {
        return (
          <DisplayPaymentBanner
            message="Your subscription is canceled."
            routeTo={HOST_SUBSCRIBE}
            title="Subscribe"
          />
        );
      } else if (status === SUBSCRIBE_STATUS_TRIAL_PERIOD) {
        return (
          <DisplayPaymentBanner
            message={`Your trial will end in ${
              trialDaysLeft ? trialDaysLeft : "0"
            } days.`}
            routeTo={HOST_SUBSCRIBE}
            title="Subscribe"
          />
        );
      } else if (status === SUBSCRIBE_STATUS_TRIAL_EXPIRED) {
        return (
          <DisplayPaymentBanner
            message="Your trial has expired. Please subscribe now."
            routeTo={HOST_SUBSCRIBE}
            title="Subscribe"
          />
        );
      } else if (status === SUBSCRIBE_STATUS_PAST_DUE) {
        return (
          <DisplayPaymentBanner
            message="Payment failed. Please update your card."
            routeTo={HOST_PAYMENT_METHOD}
            title="Update"
          />
        );
      } else {
        return (
          <DisplayPaymentBanner
            message={`There is a problem with your account. Contact us: ${SUBSCRIBE_MESSAGE_CONTACT_US}`}
          />
        );
      }
    }
  };

  return renderPaymentBanner();
};

function MarketplaceBanner({ user }) {
  const handleActiveProfile = () => {
    if (user) {
      const profileDetailsUrl = updateProfileTallyURLConstructor({
        ...user,
        tallyId: "3l6K25",
      });

      window.open(profileDetailsUrl, "_blank");
    } else {
      window.open("https://tally.so/r/3l6K25", "_blank");
    }
  };

  return (
    <div
      className="flex align-center justify-center full-width header-banner"
      style={{ paddingTop: 6, paddingBottom: 6, backgroundColor: "#FFFBF5" }}
    >
      <div>
        💰 Establish an online presence and get more jobs!
        <Button
          variant="contained"
          color="primary"
          onClick={handleActiveProfile}
          size="small"
          className="marketplace-tada"
          disableElevation
          style={{ padding: "4px 6px", marginLeft: 8 }}
        >
          Activate profile
        </Button>
      </div>
    </div>
  );
}

const isProOnboarding = window.location.pathname.includes("/user/");

const Banner = () => {
  const user = useSelector((state) => state.user);
  const userToken = useSelector((state) => state.userToken);
  const subscribe = useSelector((state) => state.subscribe).data;

  // UPSELL FOR OPS TO MAKE ONLINE PRESENCE
  // else if (
  //   userToken.is_ops &&
  //   !userToken.visible_in_marketplace &&
  //   !isProOnboarding
  // ) {
  //   return <MarketplaceBanner user={user} />;
  // }

  if (!userToken.jwt) {
    return false;
  } else if (userToken.is_host) {
    if (!subscribe) {
      return false;
    } else {
      return <PaymentBanner userToken={userToken} subscribe={subscribe} />;
    }
  } else {
    return null;
  }
};

export default Banner;
