import { Button } from "@material-ui/core";
import React from "react";
import NoteIcon from "@material-ui/icons/Note";
import { Analytics } from "../services/analyticsService.tsx";
import { openThisDialog } from "../actions/dialog";
import { DT_JOB_DESCRIPTION } from "../constants/dialogTypes";
import { useDispatch } from "react-redux";

interface JobNoeProps {
  id: string;
  description: string | null;
  canEditJobNote: boolean | undefined;
  is_past: boolean;
}

const JobNote: React.FC<JobNoeProps> = ({
  id,
  description,
  canEditJobNote,
  is_past,
}) => {
  const dispatch = useDispatch();

  const handleOnClick = () => {
    Analytics.trackEvent("add_note_action_from_job_details", {
      job_id: id,
    });
    dispatch(
      openThisDialog({
        dialogType: DT_JOB_DESCRIPTION,
        description,
        jobId: id,
      })
    );
  };
  return (
    <div className="mb-4">
      <h3 className="text-md flex align-center">
        <NoteIcon style={{ fontSize: 20, opacity: 0.8, marginRight: 4 }} /> Note
      </h3>
      <div className="mt-2">
        <p className="whitespace-pre-wrap">
          {description ? (
            description
          ) : (
            <span className="italic text-sm opacity-50">Not available</span>
          )}
        </p>
        {canEditJobNote && !is_past && (
          <div className="mt-2">
            <Button onClick={handleOnClick} variant="outlined" color="primary">
              Edit note
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobNote;
