import React from "react";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";

import { delayCleaning } from "../../actions/pmsProperties";

const DelayCleaningAgreement = (props) => {
  const { id, closeDialog } = props;

  const dispatch = useDispatch();

  const handleDialogClose = (type) => {
    if (type === "deactivate") {
      dispatch(delayCleaning(id, false));
    } else if (type === "cancel") {
      closeDialog();
    }
  };

  return (
    <>
      <DialogTitle id="scroll-dialog-title" className="delay-cleaning-modal">
        Deactivate delay cleaning
      </DialogTitle>
      <DialogContent dividers={true}>
        Deactiaviting delay cleaning will reset cleaning date to reservation
        check out date and notify your housekeeper.
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleDialogClose("cancel")}
          variant="contained"
          disableElevation
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleDialogClose("deactivate")}
          variant="contained"
          color="secondary"
          className="button button-danger"
          disableElevation
        >
          Deactivate
        </Button>
      </DialogActions>
    </>
  );
};

export default DelayCleaningAgreement;
