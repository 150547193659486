export const abColors = {
  brand: "#6059F7",
  proBrandMain: "#FFC062",
  proBrandDark: "#E9AB4E",
  proBackground: "#FFEACA",
};

const distanceForJob = [
  { label: "Less than 5 miles", value: "5" },
  { label: "Less than 10 miles", value: "10" },
  { label: "Less than 30 miles", value: "30" },
  { label: "Less than 50 miles", value: "50" },
  { label: "Less than 80 miles", value: "80" },
];

const languageOptions = [
  "English",
  "Spanish",
  "Mandarin",
  "Cantonese",
  "Tagalog (including Filipino)",
  "Vietnamese",
  "Arabic",
  "French",
  "Korean",
  "Russian",
  "German",
];

export function getDistanceInMilesLabel(val) {
  return distanceForJob.map((distance) => distance.value === val);
}

export function getFormattedLanguges(langs) {
  // lowercase langoptions and langs and if the value exists add it to
  // return
}
