import { SET_SUBSCRIPTION } from "../actions/subscription";

const initialState = {};

export const getSubscription = ({ subscribe }) => subscribe;

export const subscribe = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_SUBSCRIPTION:
      return { ...state, ...action.data };

    default:
      return state;
  }
};
