import { Button } from "@material-ui/core";
import React, { useEffect } from "react";
import history from "../helpers/history";

interface StagingGuideProps {
  has_staging_guide: boolean;
  is_host: boolean;
  property_id: string;
  is_past: boolean;
}

const JobStagingGuide: React.FC<StagingGuideProps> = ({
  has_staging_guide = false,
  is_host = false,
  property_id,
  is_past,
}) => {
  const handleViewStagingForHost = () => {
    history.push(`/listings/${property_id}/staging`);
  };
  const RenderLogic = () => {
    if (has_staging_guide) {
      return (
        <div className="">
          <p>
            Staging guide can only be accessed from Automatebnb's iPhone or
            Android app. The guide will help you to match the property
            standards.
          </p>
          <div className="mt-2">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              disableElevation
              onClick={() =>
                window.open(
                  "https://help.automatebnb.com/en/articles/9463162-how-to-create-a-staging-guide",
                  "_blank"
                )
              }
            >
              Learn more
            </Button>
          </div>
          <p className="italic mt-2 text-gray-500 text-sm">
            <a
              href="https://automatebnb.com/downloads?source=direct"
              target="_new"
              className="link"
            >
              Download our free app
            </a>{" "}
            to access this feature
          </p>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col">
          <p>
            Staging guide allows host to create a visual checklist for their
            pros so the property is staged as expected for the next guest.
          </p>
          <div className="mt-2 flex align-center">
            {is_host && (
              <div className="mr-2">
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{ paddingTop: 5, paddingBottom: 5 }}
                  disableElevation
                  onClick={() =>
                    history.push(`/listings/${property_id}/staging`)
                  }
                >
                  Get started
                </Button>
              </div>
            )}
            <Button
              variant="outlined"
              color="primary"
              size="small"
              disableElevation
              onClick={() =>
                window.open(
                  "https://help.automatebnb.com/en/articles/9463162-how-to-create-a-staging-guide",
                  "_blank"
                )
              }
            >
              Learn more
            </Button>
          </div>
        </div>
      );
    }
  };

  return (
    has_staging_guide && (
      <div className="mb-4 px-4 mt-4 py-4 bg-white rounded-md">
        <h3 className="txt-md font-semibold mb-2">Staging guide</h3>
        <RenderLogic />
      </div>
    )
  );
};

export default JobStagingGuide;
