const URLFowardList = [
  {
    old: "/signin",
    new: "/login",
  },
  {
    old: "/reservations",
    new: "/jobs",
  },
  {
    old: "/listings",
    new: "/properties",
  },
  {
    old: "/housekeeper/cleanings",
    new: "/jobs",
  },
  {
    old: "/housekeeper/signup",
    new: "/pros/signup",
  },
  {
    old: "/forgot_password",
    new: "/forgot-password",
  },
  // MUST CHANGE THE EMAIL URL FIRST
  // {
  //   old: "/reset_password",
  //   new: "/reset-password",
  // },
  {
    old: "/listings/new",
    new: "/properties/new",
  },
  {
    old: "/admin/update-profile",
    new: "/manage-account/profile",
  },
  {
    old: "/housekeeper/settings/profile",
    new: "/manage-account/profile",
  },
  {
    old: "/housekeeper/settings/security",
    new: "/manage-account/security",
  },
  {
    old: "/admin/update-password",
    new: "/manage-account/security",
  },
  {
    old: "/admin/manage-subscription",
    new: "/manage-account/subscription",
  },
  {
    old: "/admin/previous-invoices",
    new: "/manage-account/invoices",
  },
  {
    // This was used for housekeepers payouts
    old: "/admin/manage-account",
    new: "/manage-account/payout",
  },
  {
    old: "/admin/notifications",
    new: "/manage-account/notifications",
  },
];

export default URLFowardList;

/*
FOLLOWING ROUTES ARE IN PROGRESS

  
  
  
  
  
  
  
  {
    old: "/admin",
    new: "/manage-account/profile",
  },


*/
