import moment from "moment";
import React, { useState } from "react";
import { isEmpty } from "lodash";

import DoneAllIcon from "@material-ui/icons/DoneAll";

import { useModal } from "../ModalContext";

import RequestPaymentButton from "../containers/requestPaymetnButton.js";

// Actions
import {
  updateJobStatus,
  fetchProsJobs,
  fetchJobDetails,
  fetchHostJobs,
} from "../actions/jobs";
import { fetchOpsJobDetails, fetchOpsJobs } from "../actions/ops";

import { Analytics } from "../services/analyticsService.tsx";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  CLEANER_STATUS_ACCEPTED,
  CLEANER_STATUS_COMPLETED,
  CLEANER_STATUS_DECLINED,
  CLEANING_DATE_CHANGED,
  CLEANING_STARTED,
  PAYMENT_STATUS_PENDING,
  WAITING_TO_ACCEPT,
} from "../constants/apiEnum";
import { PaymentModel } from "../models/PaymentModel.ts";
import { openThisDialog } from "../actions/dialog.js";
import { DIALOG_TYPE_MAKE_CLEANING_PAYMENT } from "../constants/dialogTypes.js";
import { JobModel } from "../models/JobModel.ts";

interface JobActionsProps {
  id: string;
  status: string;
  job_date: string;
  is_payment_request_available: boolean;
  is_past: boolean;
  is_ops: boolean;
  is_host: boolean;
  isJobDetails?: boolean;
  is_assignment: boolean;
  payment: PaymentModel;
  job: JobModel;
}

const JobActions: React.FC<JobActionsProps> = ({
  id,
  status,
  job_date,
  is_host,
  is_past,
  is_ops,
  job,
  payment,
  isJobDetails = true,
  is_payment_request_available,
  is_assignment,
}) => {
  const jobDate = moment(job_date);
  const today = moment();

  const isJobDateTodayOrPast = today >= jobDate;

  const isAccepted = status === CLEANER_STATUS_ACCEPTED;
  const isWaiting = status === WAITING_TO_ACCEPT;
  const isDeclined = status === CLEANER_STATUS_DECLINED;
  const isCompleted = status === CLEANER_STATUS_COMPLETED;
  const isStarted = status === CLEANING_STARTED;
  const isDateChanged = status === CLEANING_DATE_CHANGED;

  const canRequestPayment = is_payment_request_available && !is_assignment;

  const [isLoading, setButtonLoading] = useState({
    accept: false,
    decline: false,
    completed: false,
  });

  const { showModal, hideModal } = useModal();

  const dispatch = useDispatch();

  const updateAPI = (key: any, status) => {
    setButtonLoading((prevState) => ({ ...prevState, [key]: true }));
    dispatch(updateJobStatus(id, status))
      .then((response) => {
        if (response.status === 200) {
          const lowerCaseStatus = status.toLowerCase();
          Analytics.trackEvent(
            `job_${lowerCaseStatus}_action_from_${
              isJobDetails ? "job_details" : "jobs"
            }`,
            { job_id: id }
          );

          if (is_host) {
            dispatch(fetchHostJobs());
          } else {
            dispatch(fetchProsJobs());
          }

          dispatch(fetchJobDetails(id));
          setButtonLoading((prevState) => ({ ...prevState, [key]: false }));
        }
      })
      .catch((err) => {
        setButtonLoading((prevState) => ({ ...prevState, [key]: false }));
      });
  };

  const handleDeclineJob = () => {
    updateAPI("decline", CLEANER_STATUS_DECLINED);
  };
  const handleAcceptJob = () => {
    updateAPI("accept", CLEANER_STATUS_ACCEPTED);
  };

  const handleMarkcomplete = () => {
    updateAPI("completed", CLEANER_STATUS_COMPLETED);
  };

  const ConfirmDeclineModal = () => {
    return (
      <div>
        <h3 className="text-md font-semibold">Are you sure?</h3>
        <p>
          Heads up! All pros assigned to this job will be removed and notified
          of the job cancellation.
        </p>
        <div>
          <Button onClick={hideModal()}>Cancel</Button>
          <Button
            onClick={() => updateAPI("decline", CLEANER_STATUS_COMPLETED)}
          >
            Decline
          </Button>
        </div>
      </div>
    );
  };

  const RenderMarkComplete = () => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="large"
        disableElevation
        onClick={handleMarkcomplete}
        startIcon={<DoneAllIcon />}
      >
        Mark as complete
      </Button>
    );
  };

  const RenderAcceptDecline = ({ status }) => {
    const acceptedStyles = "!bg-green-600 !text-white !border-green-500";
    const declinedStyles = "!bg-red-500 !text-white !border-red-400";

    let acceptButtonLabel = "Accept";
    let declineButtonLabel = "Decline";
    let acceptedButtonStyles = "";
    let declinedButtonStyles = "";

    switch (status) {
      case CLEANER_STATUS_ACCEPTED:
        acceptButtonLabel = "Accepted";
        acceptedButtonStyles = acceptedStyles;
        declinedButtonStyles = "";
        break;
      case CLEANER_STATUS_DECLINED:
        declineButtonLabel = "Declined";
        acceptButtonLabel = "Accept";
        declinedButtonStyles = declinedStyles;
        acceptedButtonStyles = "";
        break;
      default:
        break;
    }

    return (
      <div className="flex align-center ">
        <Button
          variant="outlined"
          color="primary"
          className={declinedButtonStyles}
          onClick={handleDeclineJob}
          style={{ minWidth: "100px" }}
        >
          {isLoading.decline ? <p>Loading..</p> : declineButtonLabel}
        </Button>
        <div className="ml-2">
          <Button
            variant="outlined"
            onClick={handleAcceptJob}
            className={acceptedButtonStyles}
            color="primary"
            style={{ minWidth: "100px" }}
          >
            {acceptButtonLabel}
          </Button>
        </div>
      </div>
    );
  };

  const RenderServiceProviderLogic = () => {
    if (isCompleted) {
      if (canRequestPayment) {
        return (
          <div
            className="border-t pr-6 border-solid border-gray-200 mt-2 py-4 flex justify-center fixed bottom-0 left-0 w-full bg-white"
            id="request-payment-section"
          >
            <RequestPaymentButton
              isJobDetails={isJobDetails}
              className=""
              job={{ is_payment_request_available, id }}
            />
          </div>
        );
      }
      return null;
    }

    // Quick fix until we have mobile adoption
    if (isAccepted) {
      return (
        <div
          className="border-t pr-6 border-solid border-gray-200 mt-2 py-4 flex justify-center fixed bottom-0 left-0 w-full bg-white"
          id="request-payment-section"
        >
          <RenderMarkComplete />
        </div>
      );
    }

    if (is_past) {
      // Fall back to allow the user to mark the job as complete.
      if (!isCompleted && !isDeclined) {
        return (
          <div
            className="border-t pr-6 border-solid border-gray-200 mt-2 py-4 flex justify-center fixed bottom-0 left-0 w-full bg-white"
            id="request-payment-section"
          >
            <RenderMarkComplete />
          </div>
        );
      } else return null;
    }

    if (isDeclined || isWaiting) {
      return (
        <div
          className="border-t pr-6 border-solid border-gray-200 mt-2 py-4 flex justify-center fixed bottom-0 left-0 w-full bg-white"
          id="request-payment-section"
        >
          <RenderAcceptDecline status={status} />
        </div>
      );
    }

    // Default
    return null;
  };

  const RenderHostLogic = () => {
    if (isEmpty(payment)) return null;

    const {
      id: paymentId,
      amount,
      receipt_url: receiptUrl,
      status: paymentStatus,
    } = payment;

    const isPending = paymentStatus === PAYMENT_STATUS_PENDING;

    if (isPending) {
      return (
        <div className="fixed bottom-0 left-0 right-0  flex justify-center py-4 bg-white border-t border-gray-300 border-solid">
          <Button
            onClick={() =>
              dispatch(
                openThisDialog({
                  dialogType: DIALOG_TYPE_MAKE_CLEANING_PAYMENT,
                  job,
                })
              )
            }
            variant="contained"
            disableElevation
            size="large"
            color="secondary"
          >
            Make a payment
          </Button>
        </div>
      );
    } else {
      return (
        <div className="fixed bottom-0 left-0 right-0  flex justify-center py-4 bg-white border-t border-gray-300 border-solid">
          <Button
            onClick={() => window.open(receiptUrl, "_blank")}
            color="primary"
            variant="outlined"
          >
            View Receipt
          </Button>
        </div>
      );
    }
    return null;
  };

  // Fix this logic later later.
  return is_host ? <RenderHostLogic /> : <RenderServiceProviderLogic />;
};

export default JobActions;
