import React from "react";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

function GenericDialog(props) {
  const { closeDialog, title = "Title", body = "Body" } = props;

  return (
    <>
      <DialogTitle
        id="scroll-dialog-title"
        className="pms-dialog__title assign-housekeeper__title"
      >
        {title}
      </DialogTitle>
      <DialogContent dividers={true} style={{ position: "relative" }}>
        {body}
      </DialogContent>
      <DialogActions>
        <div className="inline-flex justify-end full-width">
          <div className="pms-dialog__actions">
            <Button
              onClick={closeDialog}
              variant="contained"
              disableElevation
              className="button"
            >
              Done
            </Button>
          </div>
        </div>
      </DialogActions>
    </>
  );
}

export default GenericDialog;
