import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
  Link,
  CircularProgress,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { resetPassword } from "../actions/user";
import { LOG_IN } from "../routes";
import { serverError } from "../helpers/helper_serverError";
import { PasswordField } from "../containers/accountFields";
import { validateStrongPassword } from "../helpers/strongPassword";

const PasswordReset = (props) => {
  const { resetPassword, serverError } = props;
  const [token, setToken] = useState(null);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [isInvalidPassword, setIsInvalidPassword] = useState(false);
  const [values, setValues] = useState({
    password: "",
    confirmPassword: "",
    showPassword: false,
    showConfirmPassword: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const search = window.location.search;
    const newToken = new URLSearchParams(search).get("reset_digest");
    if (newToken && !token) {
      setToken(newToken);
    }
  }, [token]);

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    const isValidPassword = validateStrongPassword(value);
    setIsInvalidPassword(!isValidPassword);
    setValues({ ...values, password: value });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = () => {
    if (values.password === values.confirmPassword && token) {
      resetPassword({
        password: values.password,
        password_confirmation: values.confirmPassword,
        reset_digest: token,
      })
        .then((res) => {
          if (res.status === 200) {
            setResetSuccess(true);
          }
        })
        .catch((error) => {
          serverError(error);
        });
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
  };

  return (
    <div className="page-wrapper ab-public">
      <div
        className="page-content ab-public__password"
        key="reset-password-body"
      >
        {resetSuccess ? (
          <div className="form ab-custom-mui">
            <h1 className="header-3 semibold text-center">Password reset 🎉</h1>
            <p className="label mb2 text-center">
              Your password has been successfully reset. <br />
              Click below to log in.
            </p>
            <h3 className="text-center pt3 header-3">
              <Link href={LOG_IN} style={{ whiteSpace: "nowrap" }}>
                Log In
              </Link>
            </h3>
          </div>
        ) : (
          <div className="form ab-custom-mui">
            <h1 className="header-3 semibold text-center">Set new password</h1>
            <p className="label mb2">
              Your new password must be different from previously used
              passwords.
            </p>
            <PasswordField
              value={values.password}
              handleChange={handlePasswordChange}
              error={isInvalidPassword}
              label="Password"
              helperText="Password must be at least 6 characters and include numbers, letters, and uppercase letters."
            />
            <div className="mt3">
              <PasswordField
                value={values.confirmPassword}
                handleChange={handleInputChange}
                name="confirmPassword"
                label="Confirm password"
                error={
                  values.confirmPassword !== "" &&
                  values.password !== values.confirmPassword
                }
              />
            </div>
            {values.confirmPassword !== "" &&
              values.password !== values.confirmPassword && (
                <span className="label danger">
                  New password and confirm new password do not match.
                </span>
              )}
            <div className="mt3">
              <Button
                onClick={handleSubmit}
                fullWidth
                disableElevation
                disabled={
                  isInvalidPassword ||
                  values.password === "" ||
                  values.confirmPassword === "" ||
                  values.password !== values.confirmPassword
                }
                color="secondary"
                variant="contained"
              >
                Reset password
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ resetPassword, serverError }, dispatch);

export default connect(null, mapDispatchToProps)(PasswordReset);
