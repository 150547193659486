import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import amplitude from "amplitude-js";

import Button from "@material-ui/core/Button";
import LaunchIcon from "@material-ui/icons/Launch";
import { Typography } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import { parseQuery } from "../helpers/url";
import { stripeConnectUrl, stripeAuthenticate } from "../actions/payments";

import PoweredByStripeLogo from "./poweredByStripeSVG";
import { Analytics } from "../services/analyticsService.tsx";

const PayoutPerferences = (props) => {
  const { account, userToken } = props;

  const { error_msg: accountMsg, payouts_enabled: accountEnabled } = account;

  const { code, state } = parseQuery();
  const dispatch = useDispatch();

  const isOps = userToken.is_ops || false;

  useEffect(() => {
    if (isEmpty(account)) {
      if (code && state) {
        dispatch(stripeAuthenticate({ code, state }));
      }
    }
  }, []);

  const linkAccount = () => {
    Analytics.trackSimple("add_bank_or_card_action_from_payout");
    dispatch(stripeConnectUrl());
  };

  const updateAccount = () => {
    const url = account.stripe_account_url;
    window.open(url, "_blank");
  };

  const showPayoutError = !isEmpty(accountMsg) || !accountEnabled;

  if (isEmpty(account)) {
    return (
      <div className="card">
        <div className="md2">
          <div className="sm-col sm-col-8">
            <Typography variant="h5">Request cleaning payments</Typography>
            <Typography variant="body1">
              <a
                href="https://automatebnb.notion.site/Requesting-a-payment-as-a-Housekeeper-5149d705425f49e5803929205fd4cc67#6cac84bd0e9f4db49eb1743515ef244f"
                target="_new"
                className="link"
              >
                Click here
              </a>{" "}
              to learn how to setup your bank account or debit card.
            </Typography>
          </div>
          <div className="sm-col sm-col-4">
            <Button
              variant="contained"
              className={
                isOps
                  ? "ops-primary add-card-button"
                  : "host-primary add-card-button"
              }
              color="primary"
              disableElevation
              onClick={() => linkAccount()}
            >
              Add bank or card..
            </Button>
          </div>
        </div>
        <br />
        <br />
        <div className="mt4 mb2">
          <ul className="list-reset">
            <li className="pb1">
              <CheckCircleOutlineIcon
                className="mr1"
                style={{
                  fontSize: 18,
                  color: "green",
                  verticalAlign: "bottom",
                }}
              />{" "}
              <Typography variant="inherit">
                Save time and get paid securely.
              </Typography>
            </li>
            <li className="pb1">
              <CheckCircleOutlineIcon
                style={{
                  fontSize: 18,
                  color: "green",
                  verticalAlign: "bottom",
                }}
                className="mr1"
              />{" "}
              <Typography variant="inherit">
                No setup fees, monthly fees, or hidden fees.
              </Typography>
            </li>
            <li className="pb1">
              <CheckCircleOutlineIcon
                className="mr1"
                style={{
                  fontSize: 18,
                  color: "green",
                  verticalAlign: "bottom",
                }}
              />{" "}
              <Typography variant="inherit">
                2.9% + 30¢ per successful cleaning payment.
              </Typography>
            </li>
          </ul>
        </div>
        <PoweredByStripeLogo />
        {!isOps && (
          <p className="pt2 pb2 grey" style={{ fontSize: "14px" }}>
            *Please note that you can request payments for a cleaning{" "}
            <span className="bold black">only from a Host</span>, and not from
            the Operations Manager (Cleaning Businesses).
          </p>
        )}
      </div>
    );
  } else {
    return (
      <div className="card pt3">
        <Typography variant="h6">Payout preferences</Typography>
        <Typography variant="caption">
          There is a transaction fee of{" "}
          <span className="bold">2.9% + 0.30 cents</span> per cleaning payment,
          which will be deducted from your payout.
        </Typography>
        {showPayoutError && isEmpty(accountMsg) ? (
          <div className="mt1">
            <Typography variant="caption" className="account-error">
              Stripe is verifying your account, please check back in 10 to 15
              mins. If problem persists, please click on update account for more
              information
            </Typography>
          </div>
        ) : (
          <div className="mt1">
            <Typography variant="body2" className="account-error">
              {accountMsg}
            </Typography>
          </div>
        )}
        <div className="manage-account--inline">
          <Typography variant="body1">
            <span className="bold">{account.name}</span> {account.last4}
          </Typography>
          {account.type === "card" ? (
            <Typography variant="body1">
              {`${account.exp_month}/${account.exp_year}`}
            </Typography>
          ) : (
            ""
          )}
          <Button
            className="host-primary"
            color="primary"
            disableElevation
            onClick={() => updateAccount()}
          >
            Update account
          </Button>
        </div>
        <div className="mt1">
          <PoweredByStripeLogo />
        </div>
      </div>
    );
  }
};

export default PayoutPerferences;
