import React, { useEffect, useState, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  useHistory,
} from "react-router-dom";
import amplitude from "amplitude-js";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";

import { OptimizelyFeature } from "@optimizely/react-sdk";

import Navigation from "../containers/navigation";

import history from "../helpers/history";

import { PROPERTIES } from "../routes";

import PropertyDetails from "./propertyDetails.js";
import PropertyCalendarSync from "./calendarSync.js";
import PropertyAutomation from "./automation.js";
import StagingChecklist from "./stagingChecklist";

import "./property.scss";
import { openThisDialog } from "../actions/dialog";

import { DIALOG_TYPE_PROPERTY_DELETE_CONFIRM } from "../constants/dialogTypes";

// REDUCERS
import { getPMSProperty } from "../reducers/pmsProperties";
import { SUBSCRIBE_STATUS_ACTIVE } from "../constants/apiEnum.js";
import { Typography } from "@material-ui/core";
import { fetchSpaces } from "../actions/staging.js";
import { Analytics } from "../services/analyticsService.tsx";

const TAB_PROPERTY_DETAILS = 1;
const TAB_LINK = 2;
const TAB_CLEANING = 3;
const TAB_STAGING = 4;

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Property = (props) => {
  const [value, setValue] = React.useState(TAB_PROPERTY_DETAILS);

  const newHistory = useHistory();

  const {
    match: { params },
    location = {},
    openThisDialog,
    fetchSpaces,
    property,
  } = props;

  const path = props.location.pathname;
  const state = props.location.state || {};
  const propertyId = params.id;

  const subscribe = useSelector((state) => state.subscribe)?.data;

  // Relying on passed params in case fetchProperty API response takes longer.
  const propertyStreet = state.street ? state.street : property.street;
  const propertyCity = state.city ? state.city : property.city;
  const propertyTitle = state.title ? state.title : property.title;

  const navigationTitleConsturcted = `${propertyStreet} ${propertyCity}`;
  const propertyPageWrapperRef = useRef(null);

  /*
    TEMP HACK
    Google places autocomplete in modal position works as expected if the body doesn't have a scroll to it.
    So added a scroll to page-wrapper for this specific route. And checking if trial-banner exists, if it does
    then banner height + navigation height is subtracted from viewport of 100vh else, only navigation height is
    subtracted from viewport.
  */

  useEffect(() => {
    const setPropertyPageWrapperHeight = () => {
      if (propertyPageWrapperRef.current) {
        if (subscribe?.status === SUBSCRIBE_STATUS_ACTIVE) {
          propertyPageWrapperRef.current.style.height = `calc(100vh - ${96}px)`;
        } else {
          // Trial banner has a height of 44px
          propertyPageWrapperRef.current.style.height = `calc(100vh - ${96 +
            44}px)`;
        }
        window.addEventListener("resize", setPropertyPageWrapperHeight);
      } else {
        setTimeout(setPropertyPageWrapperHeight, 200);
      }
    };

    setPropertyPageWrapperHeight();

    return async () => {
      window.removeEventListener("resize", setPropertyPageWrapperHeight);
    };
  }, [subscribe]);

  useEffect(() => {
    if (props && path) {
      if (path.includes("cleaning")) {
        setValue(TAB_CLEANING);
      } else if (path.includes("listing-details")) {
        setValue(TAB_PROPERTY_DETAILS);
      } else if (path.includes("link")) {
        setValue(TAB_LINK);
      } else if (path.includes("staging")) {
        setValue(TAB_STAGING);
      }
    }
  }, [path || window.location.pathname]);

  const handleChange = (event, newValue) => {
    if (newValue === TAB_PROPERTY_DETAILS) {
      history.push(`/listings/${propertyId}/listing-details`);
    } else if (newValue === TAB_LINK) {
      history.push(`/listings/${propertyId}/link`);
    } else if (newValue === TAB_CLEANING) {
      history.push(`/listings/${propertyId}/cleaning`);
    } else if (newValue === TAB_STAGING) {
      Analytics.trackEvent("staging_guide_tab_action_from_property_details", {
        property_id: propertyId,
      });
      history.push(`/listings/${propertyId}/staging`);
    }

    setValue(newValue);
  };

  useEffect(() => {
    if (propertyId) {
      // Fetch spaces to render next steps quick actions
      fetchSpaces(propertyId);
    }
  }, [propertyId]);

  const handleDelete = () => {
    Analytics.trackEvent("delete_property_action_from_property_details", {
      property_id: propertyId,
    });
    openThisDialog({
      dialogType: DIALOG_TYPE_PROPERTY_DELETE_CONFIRM,
      propertyId,
      property,
    });
  };

  const RenderDeletePropertyButton = () => {
    return (
      <Button
        style={{ color: "#FF2929", whiteSpace: "nowrap" }}
        onClick={handleDelete}
      >
        Delete property
      </Button>
    );
  };

  return (
    <>
      <Navigation
        hasASubNav={true}
        backToRoute={PROPERTIES}
        pageTitle={navigationTitleConsturcted}
      />
      <Router>
        <div
          className="page-wrapper page-wrapper--tabs"
          ref={propertyPageWrapperRef}
          style={{
            overflowX: "hidden",
            overflowY: "scroll",
          }}
          id="property-wrapper-dynamic-height"
        >
          <div className="page-content">
            <div
              className="inline-flex align-center space-between pt3 pl2 pr2 pb3"
              style={{ width: "calc(100% - 32px)" }}
            >
              <h2
                className="header-2"
                style={{
                  marginBottom: "0px",
                  lineHeight: "120%",
                  marginRight: "8px",
                }}
              >
                {propertyTitle}
              </h2>
              <RenderDeletePropertyButton />
            </div>
            <div className="" style={{ borderBottom: "1px solid #c9c9c9" }}>
              <OptimizelyFeature feature="staging_guide">
                {(isStagingGuideVisible) => (
                  <Tabs
                    orientation="horizontal"
                    variant="scrollable"
                    value={value}
                    color="primary"
                    indicatorColor="primary"
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                  >
                    <Tab
                      label="Property details"
                      value={TAB_PROPERTY_DETAILS}
                      component={Link}
                      to={`/listings/${propertyId}/listing-details`}
                      {...a11yProps(TAB_PROPERTY_DETAILS)}
                    />
                    <Tab
                      label="Import calendar"
                      value={TAB_LINK}
                      component={Link}
                      to={`/listings/${propertyId}/link`}
                      {...a11yProps(TAB_LINK)}
                    />
                    <Tab
                      label="Job automation"
                      value={TAB_CLEANING}
                      component={Link}
                      to={`/listings/${propertyId}/cleaning`}
                      {...a11yProps(TAB_CLEANING)}
                    />
                    {isStagingGuideVisible && (
                      <Tab
                        label={
                          <Typography
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            Staging guide{" "}
                            <span
                              style={{
                                fontSize: 10,
                                backgroundColor: "rgba(66, 0, 255, 1)",
                                color: "white",
                                fontWeight: 500,
                                padding: "2px 4px",
                                borderRadius: 4,
                                marginLeft: 8,
                              }}
                            >
                              New
                            </span>
                          </Typography>
                        }
                        value={TAB_STAGING}
                        component={Link}
                        to={`/listings/${propertyId}/staging`}
                        {...a11yProps(TAB_STAGING)}
                      />
                    )}
                  </Tabs>
                )}
              </OptimizelyFeature>
            </div>
            <Switch>
              <Route exact path={`/listings/${propertyId}/listing-details`}>
                <PropertyDetails propertyId={propertyId} />
              </Route>
              <Route exact path={`/listings/${propertyId}/link`}>
                <PropertyCalendarSync propertyId={propertyId} />
              </Route>
              <Route exact path={`/listings/${propertyId}/cleaning`}>
                <PropertyAutomation propertyId={propertyId} />
              </Route>
              <Route exact path={`/listings/${propertyId}/staging`}>
                <StagingChecklist propertyId={propertyId} />
              </Route>
            </Switch>
          </div>
        </div>
      </Router>
    </>
  );
};

export default connect(
  (state) => ({ property: getPMSProperty(state) }),
  (dispatch) => bindActionCreators({ openThisDialog, fetchSpaces }, dispatch)
)(Property);
