import React, { Component } from "react";
import { connect } from "react-redux";
import { CardElement, injectStripe } from "react-stripe-elements";
import { Link } from "react-router-dom";
import amplitude from "amplitude-js";
import { Button, CircularProgress } from "@material-ui/core";

import Navigation from "../containers/navigation";

import { updateCC } from "../actions/subscription";
import { fetchSubscription } from "../actions/subscription";
import Card from "../components/card";
import { serverError } from "../helpers/helper_serverError";
import { MANAGE_SUBSCRIPTION, JOBS } from "../routes";

import "./styles.scss";

class CheckoutForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      creditCardUpdated: false,
      isLoading: false,
    };
  }

  onPaymentUpdateSucess = () => {
    setTimeout(() => {
      this.setState({
        isLoading: false,
        creditCardUpdated: true,
      });
      this.props.fetchSubscription();
    }, 2000);
  };

  isUpdatePayment = () => {
    const { subscribe } = this.props;

    if (subscribe) {
      if (subscribe.status === "UPDATE_PAYMENT") {
        return true;
      } else {
        return false;
      }
    }
  };

  handleSubmit = (ev) => {
    ev.preventDefault();
    this.setState({ isLoading: true });

    if (this.props.stripe) {
      this.props.stripe
        .createToken({ name: this.state.name })
        .then(({ error, token }) => {
          if (error) {
            this.setState({ isLoading: false });
          } else {
            this.props
              .updateCC(token)
              .then((res) => {
                if (res.status === 200) {
                  this.onPaymentUpdateSucess();
                } else {
                  this.setState({ isLoading: false });
                }
              })
              .catch((err) => {
                this.setState({ isLoading: false });
              });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });

          this.props.serverError(error);
        });
    } else {
      this.setState({ isLoading: false });
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  handleChange = (event) => {
    this.setState({ name: event.target.value });
  };

  render() {
    return [
      <Navigation key="appbar-navigation-subscribe-page" />,
      <div key="subscribe-page" className="payment-method">
        {this.state.creditCardUpdated ? (
          <Card className="card-elevated">
            <h3 key="success-header" className="header-3 trial">
              Credit card updated
            </h3>
            <p key="success-body" className="success-body">
              Your card was successfully updated.
            </p>
            <div
              key="success-actions"
              className="flex align-center space-between"
            >
              <Link
                to={MANAGE_SUBSCRIPTION}
                className="button button-host button-ghost"
              >
                Manage subscription
              </Link>
              <Link to={JOBS} className="button button-host">
                Go home
              </Link>
            </div>
          </Card>
        ) : (
          <Card className="card-elevated">
            <h3 className="header-3">Payment method</h3>
            <div className="subscribe-form">
              <form onSubmit={this.handleSubmit}>
                {window.Stripe ? (
                  [
                    <label key="payment-method-name" className="input-label">
                      Name
                      <input
                        name="name"
                        value={this.state.name}
                        className="input-text"
                        type="text"
                        placeholder="Jane Doe"
                        required
                        onChange={this.handleChange}
                      />
                    </label>,
                    <CardElement key="payment-method-card-elements" />,
                  ]
                ) : (
                  <CircularProgress size={24} className="button-progress" />
                )}
                <div className="flex align-center flex-end">
                  {this.isUpdatePayment() ? (
                    <Button variant="contained" disableElevation>
                      Loading
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      className="host-primary"
                      type="submit"
                      disabled={this.state.isLoading}
                      disableElevation
                    >
                      Update
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </Card>
        )}
      </div>,
    ];
  }
}

function mapStateToProps(state) {
  return {
    subscribe: state.subscribe.data,
  };
}

export default connect(mapStateToProps, {
  updateCC,
  serverError,
  fetchSubscription,
})(injectStripe(CheckoutForm));
