import axios from "../helpers/axios";

// Other Actions
import { setContentLoading } from "./loading";
import { closeThisDialog } from "./dialog";
import { fetchPaymentReminders } from "./payments";
import { showSnackbar } from "./action_snackbar";

// Helper
import { serverError } from "../helpers/helper_serverError";
import { parseQuery } from "../helpers/url";

// Constants
import {
  DT_JOB_DESCRIPTION,
  DT_CHANGE_A_PRO,
  DT_CANCEL_JOB,
  DT_UPDATE_JOB_DATE_TIME,
} from "../constants/dialogTypes";
import { CLEANER_STATUS_COMPLETED } from "../constants/apiEnum";
import { JOBS } from "../routes";

export const SET_JOBS = "set_jobs";
export const RESET_JOBS = "reset_jobs";
export const SET_A_JOB = "set_a_job";
export const RESET_A_JOB = "reset_a_job";
export const SET_WALKTHROUGH_TASKS = "set_walkthrough_tasks";
export const RESET_WALKTHROUGH_TASKS = "reset_walkthrough_tasks";
// Fetch reservations which are essentially JOBS to Displays on HOSTS UI
// Host's JOBS have more filters than PROS JOBS.

export const fetchHostJobs = (next) => {
  const {
    property_ids,
    maid_ids,
    channels,
    note,
    type,
    cleaning_status,
    payment_statuses,
  } = parseQuery();

  let cleaningStatus = "";

  return function action(dispatch) {
    if (!next) {
      dispatch(setContentLoading(true));
      dispatch(resetJobs());
    }

    if (cleaning_status) {
      cleaningStatus += cleaning_status;
    } else {
      cleaningStatus = `ACCEPTED, STARTED, DECLINED, CLEANING DATE CHANGED, WAITING TO ACCEPT, COMPLETED`;

      /* 
        //=====
        // Past Jobs can also be of type Completed: [There is an issue with TimeZones and ToDo/Past jobs where a `completed` Job would disappear on the day off. Therefore the solution is to keep to rely entirly on time.]
        //======
       if (type === "past") {
         cleaningStatus += `, COMPLETED`;
       }
      */
    }

    return axios
      .get(
        `/v1/jobs?${next ? `page=${encodeURIComponent(next)}` : ""}${
          property_ids ? `filter[property_ids]=${property_ids}` : ""
        }${maid_ids ? `&filter[maid_ids]=${maid_ids}` : ""}${
          cleaningStatus ? `&filter[statuses]=${cleaningStatus}` : ""
        }${channels ? `&filter[channels]=${channels}` : ""}${
          note ? `&filter[note]=${note}` : ""
        }${
          payment_statuses
            ? `&filter[payment_statuses]=${payment_statuses}`
            : ""
        }${type === "past" ? `&type=past&sort_by=DESC` : "&type=todo"}`
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(setContentLoading(false));
          dispatch(setJobs(res.data));
        }
      })
      .catch((error) => {
        dispatch(setContentLoading(false));
        dispatch(serverError(error));
      });
  };
};

export function getDamageInspections(jobId) {
  return (dispatch) => {
    return axios.get(`/v1/damage_inspections?job_id=${jobId}`);
  };
}

export function cancelJob({ jobId, view = "jobs" }) {
  return (dispatch) => {
    return axios
      .post(`/v1/jobs/${jobId}/cancel`)
      .then((res) => {
        if (res.status === 200) {
          // fetch all jobs or details of a job based on where the cancel was initiated
          if (view === "jobs") {
            dispatch(fetchHostJobs());
          } else {
            // When cleaning is cancel from show view route the user back to the show page
            window.location = JOBS;
            dispatch(fetchJobDetails());
          }
          // close cancel job confirmation dialog
          dispatch(closeThisDialog({ dialogType: DT_CANCEL_JOB }));

          // showSnackbar success
          dispatch(
            showSnackbar({ message: "Job canceled", toReservation: true })
          );
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
}

export const fetchJobDetails = (id) => {
  return (dispatch) => {
    dispatch(setContentLoading(true));
    dispatch(resetJobDetails());
    return axios
      .get(`/v1/jobs/${id}`)
      .then((res) => {
        dispatch(setContentLoading(false));
        if (res.status === 200) {
          dispatch(setJobDetails(res.data));
          return true;
        }
      })
      .catch((error) => {
        dispatch(setContentLoading(false));
        dispatch(serverError(error));
        return false;
      });
  };
};

export const fetchProsJobDetails = (id) => {
  return (dispatch) => {
    dispatch(setContentLoading(true));
    dispatch(resetJobDetails());
    return axios
      .get(`/v1/jobs/${id}`)
      .then((res) => {
        dispatch(setContentLoading(false));
        if (res.status === 200) {
          dispatch(setJobDetails(res.data));
          return true;
        }
      })
      .catch((error) => {
        dispatch(setContentLoading(false));
        dispatch(serverError(error));
        return false;
      });
  };
};

// Pros Job details
export function fetchProJobDetails(id) {
  return function action(dispatch) {
    dispatch(setContentLoading(true));
    return axios
      .get(`/v1/cleanings/${id}`)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setJobDetails(res.data));
          dispatch(setContentLoading(false));
        }
      })
      .catch((error) => {
        dispatch(setContentLoading(false));
        dispatch(serverError(error));
      });
  };
}

export function fetchProsJobs(
  props = { next: null, renderLoadingScreen: true }
) {
  const { type, request_payment, cleaning_status } = parseQuery();

  return function action(dispatch) {
    // Don't reset Jobs if fetchProsJobs is called from Pagination
    if (!props.next) {
      dispatch(resetJobs());
    }

    // Don't display a loading screen when renderLoadingScreen is set to false.
    if (props.renderLoadingScreen) {
      dispatch(setContentLoading(true));
    }

    let cleaningStatus = "";

    if (cleaning_status) {
      cleaningStatus += cleaning_status;
    } else {
      cleaningStatus = `ACCEPTED, STARTED, DECLINED, CLEANING DATE CHANGED, WAITING TO ACCEPT, COMPLETED`;

      /* 
        //Past Jobs can also be of type Completed
      if (type === "past") {
        cleaningStatus += `, COMPLETED`;
      }

      */
    }

    return axios
      .get(
        `/v1/jobs?${
          props.next ? `page=${encodeURIComponent(props.next)}` : ""
        }${cleaningStatus ? `&filter[statuses]=${cleaningStatus}` : ""}${
          request_payment ? `&request_payment=${request_payment}` : ""
        }${type === "past" ? `&type=past&sort_by=DESC` : "&type=todo"}`
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(setContentLoading(false));
          dispatch(setJobs(res.data));
        }
      })
      .catch((error) => {
        dispatch(setContentLoading(false));
        dispatch(serverError(error));
      });
  };
}

export function requestJob(data) {
  return (dispatch) => {
    return axios
      .post("/v1/jobs", data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchHostJobs());
          dispatch(closeThisDialog());
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
}

export function updateJobDescription({ newNote, checked, jobId }) {
  return (dispatch) => {
    return axios
      .put(`/v1/cleanings/${jobId}/add_note?note=${newNote}&notify=${checked}`)
      .then((res) => {
        if (res) {
          const { status, data } = res;

          if (status === 200) {
            dispatch(closeThisDialog({ dialogType: DT_JOB_DESCRIPTION }));

            if (data && data.id) {
              dispatch(fetchJobDetails(data.id));
              dispatch(fetchHostJobs());
            }
            return true;
          }
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
        return false;
      });
  };
}

export function fetchWalkthroughTasks(jobId) {
  return (dispatch) => {
    dispatch(resetWalkthroughTasks());
    return axios
      .get(`/v1/jobs/${jobId}/walkthrough_tasks`)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setWalkthroughTasks(res.data));
        }
      })
      .catch((error) => {
        dispatch(resetWalkthroughTasks());
        console.warn("Walkthrough API: ", error);
      });
  };
}

export function changeAPro({ jobId, proId }) {
  return (dispatch) => {
    return axios
      .put(`/v1/jobs/${jobId}/assign?pro_id=${proId}`)
      .then((res) => {
        if (res) {
          const winPath = window.location.pathname;

          if (res.status === 200) {
            if (res.data) {
              if (winPath === "/jobs") {
                dispatch(fetchHostJobs());
              } else {
                dispatch(fetchJobDetails(res.data.id));
              }

              dispatch(closeThisDialog({ dialogType: DT_CHANGE_A_PRO }));
            }
          }
        }
      })
      .catch((err) => {
        dispatch(serverError(err));
      });
  };
}

// When Pros 'Accept' or 'Decline' a job
export function updateJobStatus(id, status) {
  return function action(dispatch) {
    return axios.put(`/v1/cleanings/${id}/change_status`, { status: status });
  };
}

export function updateJobDateTime({ jobId, formattedDate, time }) {
  return function action(dispatch) {
    return axios
      .put(
        `/v1/jobs/${jobId}/override_date?job_date=${formattedDate}&job_time=${time}`
      )
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchHostJobs());
          dispatch(fetchJobDetails(jobId));
          dispatch(setContentLoading(false));
          // Close dialog
          dispatch(closeThisDialog({ dialogType: DT_UPDATE_JOB_DATE_TIME }));
        }
      })
      .catch((error) => {
        dispatch(setContentLoading(false));
        dispatch(serverError(error));
      });
  };
}

export const setJobs = (data) => ({ type: SET_JOBS, data });
export const resetJobs = () => ({ type: RESET_JOBS });
export const setJobDetails = (data) => ({ type: SET_A_JOB, data });
export const resetJobDetails = () => ({ type: RESET_A_JOB });
export const resetWalkthroughTasks = () => ({ type: RESET_WALKTHROUGH_TASKS });
export const setWalkthroughTasks = (data) => ({
  type: SET_WALKTHROUGH_TASKS,
  data,
});
