import { SET_CONTENT_LOADING, SET_MODAL_LOADING } from "../actions/loading";

export const getLoading = ({ loading }) => loading;
export const getModalLoading = ({ modalLoading }) => modalLoading;

export const loading = (state = { loading: false }, action) => {
  switch (action.type) {
    case SET_CONTENT_LOADING:
      return action.loading;

    default:
      return state;
  }
};

export const modalLoading = (state = { loading: false }, action) => {
  switch (action.type) {
    case SET_MODAL_LOADING:
      return action.loading;
    default:
      return state;
  }
};
