import axios from "../helpers/axios";
import parse from "url-parse";
import history from "../helpers/history";
import { serverError } from "../helpers/helper_serverError";
import { showSnackbar } from "./action_snackbar";

import { setDialogClose, closeThisDialog, setDialogOpen } from "./dialog";
import {
  DIALOG_TYPE_PROPERTY_DELETE_CONFIRM,
  DIALOG_TYPE_DELAY_CLEANING_AGREEMENT,
} from "../constants/dialogTypes";
import { setModalLoading, setContentLoading } from "./loading";

import { PROPERTIES } from "../routes";
import {
  DIALOG_TYPE_PROPERTY_DETAILS_EDIT,
  DIALOG_TYPE_CLEANING_DATE_CONFLICT,
} from "../constants/dialogTypes";
import { parseQuery } from "../helpers/url";

export const SET_PMS_PROPERTIES = "set_pms_properties";
export const RESET_PROPERTIES = "reset_properties";
export const SET_PMS_PROPERTY = "set_pms_property";
export const SET_NEW_PROPERTY_ID = "set_new_property_id";
export const RESET_OPS_PROPERTIES_FILTER = "reset-ops-properties-filter";
export const SET_OPS_PROPERTIES_FILTER = "set-ops-properties-filter";

const { opu } = parseQuery();

export const fetchProperties = ({
  nextProperties: next,
  allProperties: all,
}) => {
  return function action(dispatch) {
    if (!next) {
      dispatch(resetProperties());
    }

    if (all) {
      dispatch(setModalLoading(true));
    } else {
      dispatch(setContentLoading(true));
    }

    return axios
      .get(
        `/v1/properties?${next ? `page=${encodeURIComponent(next)}` : ""}${
          all ? `per_page=1000` : ""
        }`
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(setProperties(res.data));
          dispatch(setContentLoading(false));
          dispatch(setModalLoading(false));
        }
      })
      .catch((error) => {
        dispatch(setContentLoading(false));
        dispatch(serverError(error));
      });
  };
};

export function resyncCalendar(url) {
  return function action(dispatch) {
    return axios.post("/v1/calendar/resync", url);
  };
}

export function fetchProperty(id) {
  return function action(dispatch) {
    dispatch(setContentLoading(true));

    return axios
      .get(`/v1/properties/${id}`)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setProperty(res.data));
          dispatch(setContentLoading(false));
        }
      })
      .catch((error) => {
        // If user tries to filter reservations by ids from URL
        dispatch(setProperty({ error: "Nothing found" }));
        dispatch(setContentLoading(false));
        dispatch(serverError(error));
      });
  };
}

// REMOVE THIS FUNCTION AND ASSOCIATIONS IS THIS NEEDED?
export function delayCleaning(id, delay) {
  return function action(dispatch) {
    dispatch(setContentLoading(true));
    return axios
      .put(
        `/v1/properties/${id}/delay_cleaning?delay_cleaning=${
          delay ? delay : false
        }`
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchProperty(id));
          dispatch(setDialogClose(DIALOG_TYPE_DELAY_CLEANING_AGREEMENT));

          if (delay) {
            dispatch(
              setDialogOpen(DIALOG_TYPE_CLEANING_DATE_CONFLICT, null, res.data)
            );
          }

          dispatch(setContentLoading(false));
        }
      })
      .catch((error) => {
        // If user tries to filter reservations by ids from URL
        dispatch(setProperty({ error: "Nothing found" }));
        dispatch(setContentLoading(false));
        dispatch(serverError(error));
      });
  };
}

export const createPropertyFromOnboardingFlow = (data) => {
  return (dispatch) => {
    return axios.post(`/v1/properties/`, data);
  };
};

export function updateProperty(data) {
  return (dispatch) => {
    return axios.put(`/v1/properties/${data.id}`, data).then((res) => {
      if (res) {
        const { status, data } = res;
        if (status === 200) {
          dispatch(setDialogClose(DIALOG_TYPE_PROPERTY_DETAILS_EDIT));
          if (data && data.id) {
            dispatch(fetchProperty(data.id));
          }
        }
      }
    });
  };
}

// REMOVE THIS FUNCTION
export function updateAssignRule(data) {
  return (dispatch) => {
    return axios
      .put(
        `/v1/properties/${
          data.id
        }/update_assign_rule?assign_maid_rule=ASSIGN_MAID_AUTO&maid_id=${
          data.maid_id === "unassign" ? "" : data.maid_id
        }`
      )
      .then((res) => {
        if (res && res.status === 200) {
          if (data.onSuccess === "CLOSE_MODAL") {
          } else {
            history.push(PROPERTIES);
          }

          if (res.data && res.data.id) {
            dispatch(fetchProperty(res.data.id));
          }
        }
      })
      .catch((err) => {
        dispatch(serverError(err));
      });
  };
}

export function deleteProperty(id) {
  return (dispatch) => {
    return axios.delete(`/v1/properties/${id}`).then((res) => {
      if (res.status === 204) {
        dispatch(
          closeThisDialog({ type: DIALOG_TYPE_PROPERTY_DELETE_CONFIRM })
        );
        history.replace(PROPERTIES);
        dispatch(
          showSnackbar({
            message: "Property has been delete successfully",
            success: true,
          })
        );
      }
    });
  };
}

export function linkPropertyCalendar(data) {
  return (dispatch) => {
    const parsedURL = parse(data.url);
    const host = parsedURL.host;

    const channels = [
      "vrbo",
      "airbnb",
      "tripadvisor",
      "homeaway",
      "hostfully",
      "hostaway",
      "booking",
    ];

    if (host) {
      const channel = channels.find((c) => host.includes(c)) || "N/A";
      data = { ...data, channel: channel };
    }

    return axios.post("/v1/calendar/link", data);
  };
}

export function deletePropertyCalendar(url) {
  return (dispatch) => {
    return axios
      .delete("/v1/calendar/delete", { params: { url: url } })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
}

export function fetchOpsJobsProperties() {
  return (dispatch) => {
    dispatch(resetOpsPropertiesFilter());
    return axios
      .get("/v1/maids/properties_filter")
      .then((response) => {
        if (response.status === 200) {
          dispatch(setOpsPropertiesFilter(response.data));
        }
      })
      .catch((error) => {});
  };
}

export const setProperties = (data) => ({
  type: SET_PMS_PROPERTIES,
  data,
});
export const setProperty = (data) => ({ type: SET_PMS_PROPERTY, data });

export const setNewPropertyId = (id) => ({ type: SET_NEW_PROPERTY_ID, id });
export const resetProperties = () => ({ type: RESET_PROPERTIES });
export const resetOpsPropertiesFilter = () => ({
  type: RESET_OPS_PROPERTIES_FILTER,
});
export const setOpsPropertiesFilter = (data) => ({
  type: SET_OPS_PROPERTIES_FILTER,
  data,
});
