import axios from "../helpers/axios";
import history from "../helpers/history";

// NOT sure what it does
import { serverError } from "../helpers/helper_serverError";
import { showSnackbar } from "./action_snackbar";

import { SET_PMS_CLEANING } from "../reducers/pmsCleaning";
import { PMS_RESERVATIONS } from "../routes";
import { setDialogClose, closeThisDialog } from "./dialog";
import {
  DIALOG_TYPE_ADD_A_CLEANING_NOTE,
  DT_CANCEL_JOB,
  DT_CHANGE_A_PRO,
  DT_JOB_DESCRIPTION,
} from "../constants/dialogTypes";

//Refactor
import { SHOW_SNACKBAR } from "../constants/action-types";
import { fetchReservation, fetchReservations } from "./pmsReservations";
import { fetchHostJobs } from "./jobs";

export function fetchPMSCleaning(id) {
  return (dispatch) => {
    return axios
      .get(`/v1/reservations/${id}`)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setCleaning(res.data));
          return true;
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
        return false;
      });
  };
}

export function updateCleaningDate({ cleaning_date, id }) {
  return (dispatch) => {
    // return axios
    //   .put(`/v1/cleanings/${cleaingId}/add_note?note=${note}`)
    //   .then((res) => {
    //     if (res) {
    //       const { status, data } = res;
    //       if (status === 200) {
    //         dispatch(setDialogClose(DIALOG_TYPE_ADD_A_CLEANING_NOTE));
    //         if (data && data.reservation_id) {
    //           dispatch(fetchReservation(data.reservation_id));
    //           dispatch(fetchReservations());
    //         }
    //         return true;
    //       }
    //     }
    //   })
    //   .catch((error) => {
    //     dispatch(serverError(error));
    //     return false;
    //   });
  };
}

export function addCleaningNote({ cleaingId, reservationId, note, notify }) {
  return (dispatch) => {
    return axios
      .put(`/v1/cleanings/${cleaingId}/add_note?note=${note}&notify=${notify}`)
      .then((res) => {
        if (res) {
          const { status, data } = res;

          if (status === 200) {
            dispatch(closeThisDialog({ dialogType: DT_JOB_DESCRIPTION }));

            if (data && data.reservation_id) {
              dispatch(fetchReservation(data.reservation_id));
              dispatch(fetchReservations());
            }
            return true;
          }
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
        return false;
      });
  };
}

export function cancelCleaning(id) {
  return (dispatch) => {
    return axios
      .post(`/v1/reservations/${id}/cancel`)
      .then((res) => {
        if (res.status === 200) {
          window.location = PMS_RESERVATIONS;
          dispatch(closeThisDialog({ dialogType: DT_CANCEL_JOB }));
          dispatch(
            showSnackbar({ message: "Cleaning canceled", toReservation: true })
          );
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
}

export function newCleaning(data) {
  return (dispatch) => {
    return axios
      .post("/v1/reservations", data)
      .then((res) => {
        if (res.status === 200) {
          if (res.data && res.data.id) {
            dispatch(fetchPMSCleaning(res.data.id));
          }

          history.replace(PMS_RESERVATIONS);
          dispatch({
            type: SHOW_SNACKBAR,
            message: "Your reservations has been created successfully",
          });
        }
      })
      .catch((error) => {
        dispatch(serverError(error, "Something went wront, please try again."));
      });
  };
}

export function changeHousekeeper(data) {
  return (dispatch) => {
    return axios
      .put(`/v1/cleanings/${data.id}/assign?maid_id=${data.maidId}`)
      .then((res) => {
        if (res) {
          const winPath = window.location.pathname;

          if (res.status === 200) {
            if (res.data && res.data.reservation_id) {
              if (winPath === "/jobs") {
                dispatch(fetchHostJobs());
              } else {
                dispatch(fetchHostJobs(res.data.reservation_id));
              }

              dispatch(closeThisDialog({ dialogType: DT_CHANGE_A_PRO }));
            }
          }
        }
      })
      .catch((err) => {
        dispatch(serverError(err));
      });
  };
}

export const setCleaning = (cleaning) => ({
  type: SET_PMS_CLEANING,
  cleaning,
});
