import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import amplitude from "amplitude-js";

import { bindActionCreators } from "redux";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";

import { requestCleaningPayment } from "../../actions/payments";
import { getPayoutAccount } from "../../reducers/payments";

import { MANAGE_PAYOUT } from "../../routes";

import history from "../../helpers/history";

const RequestCleaningPayment = (props) => {
  const { id, closeDialog, account, requestCleaningPayment } = props;

  const [values, setValues] = useState({
    cleaningFee: "",
    additionalFee: "",
    comment: "",
  });
  const [total, setTotal] = useState(0.0);
  const [isAccountActive, setAccount] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [buttonPressed, setButtonState] = useState(false);

  const handleDialogClose = (type) => {
    if (type === "cancel") {
      closeDialog();
    } else if (type === "submit") {
      if (!isEmpty(values.cleaningFee) && id) {
        amplitude
          .getInstance()
          .logEvent("Clicked.RequestPaymentinRequestPaymentModal");
        setButtonState(true);
        requestCleaningPayment({ ...values, id })
          .then((response) => {
            if (response.status !== 200) {
              setButtonState(false);
            }
            return response;
          })
          .catch((error) => {
            setButtonState(false);
            return error;
          });
      }
    }
  };

  useEffect(() => {
    if (isEmpty(account) || (account && !account.payouts_enabled)) {
      setAccount(false);
    } else {
      setAccount(true);
    }
    setLoading(false);
  }, [account]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClick = () => {
    history.replace(MANAGE_PAYOUT);
    amplitude
      .getInstance()
      .logEvent("Clicked.ManagePaymentFromRequestPaymentModal");
    closeDialog();
  };

  return isLoading ? (
    "..."
  ) : (
    <>
      <DialogContent>
        <Typography variant="h5">Request payment</Typography>
        <Typography variant="subtitle2">
          Max payment request allowed <span className="bold">$475.00</span>
          <br />
          <a
            href="https://automatebnb.notion.site/Requesting-a-payment-as-a-Housekeeper-5149d705425f49e5803929205fd4cc67"
            target="_new"
            className="link"
          >
            Click here
          </a>{" "}
          to learn more about requesting payment.{" "}
        </Typography>
        {!isAccountActive && (
          <div className="mt2 blockquote blockquote--danger">
            <Typography variant="body1" className="mb1">
              You must add your Bank or Debit card to accept payments.
            </Typography>
            <br />
            <Button
              className="button button-danger"
              variant="contained"
              disableElevation
              color="primary"
              onClick={() => handleClick()}
            >
              Manage payment
            </Button>
          </div>
        )}
        <div className="mt4">
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-cleaning-fee">
              Amount*
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-cleaning-fee"
              value={values.cleaningFee}
              onChange={handleChange("cleaningFee")}
              type="number"
              autoFocus
              disabled={!isAccountActive}
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              labelWidth={130}
            />
          </FormControl>
          <div className="mt3">
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-additional-fee">
                Additional amount
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-additional-fee"
                value={values.additionalFee}
                type="number"
                disabled={!isAccountActive}
                onChange={handleChange("additionalFee")}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                labelWidth={130}
              />
            </FormControl>
          </div>
          <div className="mt3">
            <TextField
              id="outlined-multiline-static"
              label="Comment"
              multiline
              minRows={4}
              disabled={!isAccountActive}
              value={values.comment}
              onChange={handleChange("comment")}
              fullWidth
              variant="outlined"
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleDialogClose("cancel")}
          variant="text"
          disableElevation
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleDialogClose("submit")}
          variant="contained"
          color="primary"
          style={{ width: "148px" }}
          disableElevation
          disabled={
            isEmpty(values.cleaningFee) || !isAccountActive || buttonPressed
          }
        >
          {buttonPressed ? (
            <CircularProgress className="white" size={24} />
          ) : (
            "Request payment"
          )}
        </Button>
      </DialogActions>
    </>
  );
};

export default connect(
  (state) => ({ account: getPayoutAccount(state) }),
  (dispatch) => bindActionCreators({ requestCleaningPayment }, dispatch)
)(RequestCleaningPayment);
