const initialState = {}

export default (state = initialState, action = {}) => {
  switch(action.type) {

    case "SET_EVENT":
      return action.data

    default: return state;
  }
}
