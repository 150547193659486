import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import classnames from "classnames";
import amplitude from "amplitude-js";
import { OptimizelyFeature } from "@optimizely/react-sdk";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import Navigation from "../containers/navigation";
import history from "../helpers/history";

import ManageProfile from "./manageProfile";
import ManageSecurity from "./manageSecurity";
import ManageHostSubscription from "./manageHostSubscription";
import NotificationSettings from "./notificationSettings";
import ManageHostInvoices from "./manageHostInvoices";
import ManagePayout from "./managePayout";

import LoadingScreen from "../components/loadingScreen";

import {
  MANAGE_PROFILE,
  MANAGE_SECURITY,
  MANAGE_SUBSCRIPTION,
  MANAGE_INVOICES,
  MANAGE_NOTIFICATIONS,
  MANAGE_PAYOUT,
} from "../routes";
import { Analytics } from "../services/analyticsService.tsx";

const TAB_1 = 1;
const TAB_2 = 2;
const TAB_3 = 3;
const TAB_4 = 4;
const TAB_5 = 5;

const HOST_TABS = [];

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const ManageAccount = (props) => {
  const [value, setValue] = useState(TAB_1);

  const userToken = useSelector((state) => state.userToken);
  const user = useSelector((state) => state.user);

  const path = window.location.pathname;

  useEffect(() => {
    if (path) {
      if (path.includes(MANAGE_PROFILE)) {
        setValue(TAB_1);
      } else if (path.includes(MANAGE_SECURITY)) {
        setValue(TAB_2);
      } else if (path.includes(MANAGE_INVOICES)) {
        setValue(TAB_4);
      } else if (path.includes(MANAGE_NOTIFICATIONS)) {
        setValue(TAB_5);
      }

      // Host & Pros are assigned same Tab value as either user would only see one tab
      // render on the screen.
      if (path.includes(MANAGE_SUBSCRIPTION)) {
        setValue(TAB_3);
      } else if (path.includes(MANAGE_PAYOUT)) {
        setValue(TAB_3);
      }
    }
  }, [path]);

  const handleChange = (event, newValue) => {
    if (newValue === TAB_1) {
      history.push(MANAGE_PROFILE);
    } else if (newValue === TAB_2) {
      history.push(MANAGE_SECURITY);
    } else if (newValue === TAB_3) {
      if (userToken && userToken.is_host) {
        Analytics.trackSimple("subscription_tab_action_from_manage_account");
        history.push(MANAGE_SUBSCRIPTION);
      } else if (userToken) {
        Analytics.trackSimple("payout_tab_action_from_manage_account");
        history.push(MANAGE_PAYOUT);
      }
    } else if (newValue === TAB_4) {
      history.push(MANAGE_INVOICES);
    } else if (newValue === TAB_5) {
      history.push(MANAGE_NOTIFICATIONS);
    }

    setValue(newValue);
  };

  const matches = useMediaQuery("(max-width:600px)");

  return [
    <Navigation key="appbar-navigation-manage-account" />,
    <Router key="router-manage-account">
      <div className="page-wrapper page-wrapper--tabs">
        <div className="page-wrapper__tabs">
          {user && (
            <Tabs
              orientation="horizontal"
              variant="scrollable"
              color="secondary"
              indicatorColor="secondary"
              value={value}
              TabIndicatorProps={{
                style: { background: "rgba(29, 41, 57, 1)" },
              }}
              onChange={handleChange}
              aria-label="Manage account sub-navigation"
            >
              <Tab
                label="Profile"
                value={TAB_1}
                component={Link}
                to={MANAGE_PROFILE}
                {...a11yProps("Profile")}
              />
              <Tab
                label="Security"
                value={TAB_2}
                component={Link}
                to={MANAGE_SECURITY}
                {...a11yProps("Security")}
              />
              {userToken && userToken.is_host ? (
                [
                  <Tab
                    label="Subscription"
                    key="manage-account-tabs-subscription"
                    value={TAB_3}
                    component={Link}
                    to={MANAGE_SUBSCRIPTION}
                    {...a11yProps("Subscription")}
                  />,
                  <Tab
                    label="Invoices"
                    key="manage-account-tabs-invoices"
                    value={TAB_4}
                    component={Link}
                    to={MANAGE_INVOICES}
                    {...a11yProps("Invoices")}
                  />,
                ]
              ) : (
                <Tab
                  label="Payout"
                  value={TAB_3}
                  key="pro-payout-tab"
                  component={Link}
                  to={MANAGE_PAYOUT}
                  {...a11yProps("Payout")}
                />
              )}
              <Tab
                label="Notification settings"
                value={TAB_5}
                component={Link}
                to={MANAGE_NOTIFICATIONS}
                {...a11yProps("Notification settings")}
              />
            </Tabs>
          )}
        </div>
        <div
          className="page-content manage-account__body"
          style={{ paddingTop: "24px" }}
        >
          <Switch>
            <Route exact path={MANAGE_PROFILE}>
              <ManageProfile />
            </Route>
            <Route exact path={MANAGE_SECURITY}>
              <ManageSecurity />
            </Route>
            <Route exact path={MANAGE_PAYOUT}>
              <ManagePayout />
            </Route>
            <Route exact path={MANAGE_SUBSCRIPTION}>
              <ManageHostSubscription />
            </Route>
            <Route exact path={MANAGE_INVOICES}>
              <ManageHostInvoices />
            </Route>
            <Route exact path={MANAGE_NOTIFICATIONS}>
              <NotificationSettings />
            </Route>
          </Switch>
        </div>
      </div>
    </Router>,
  ];
};

export default ManageAccount;
