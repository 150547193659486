import {
  SET_DIALOG_OPEN,
  SET_DIALOG_CLOSE,
  OPEN_DIALOG_ACTION,
  CLOSE_DIALOG_ACTION,
} from "../actions/dialog";

export const getDialogStatus = ({ dialog }) => dialog;
export const getDialogObject = ({ dialogObject }) => dialogObject;

export const dialog = (state = { dialogType: "", isOpen: false }, action) => {
  const { dialogType, id, object } = action;
  switch (action.type) {
    case SET_DIALOG_OPEN:
      return { ...state, dialogType, id, object, isOpen: true };

    case SET_DIALOG_CLOSE:
      return { ...state, dialogType, isOpen: false };

    default:
      return state;
  }
};

export const dialogObject = (state = { dialogOpen: false }, action) => {
  const { type, object } = action;
  switch (type) {
    case OPEN_DIALOG_ACTION:
      return { ...state, ...object, dialogOpen: true };
    case CLOSE_DIALOG_ACTION:
      return { dialogType: null, dialogOpen: false };
    default:
      return state;
  }
};
