import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Link, Typography } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import { CurrencyTextField } from "../containers/accountFields";

import Navigation from "../containers/navigation";
import { isEmpty } from "lodash";
import { NEW_USER_MARKETPLACE_PROFILE_PRICING } from "../routes";

const ChecklistStandardScreen = () => {
  const user = useSelector((state) => state.user);
  const [baseCleaning, setBaseCleaning] = useState(null);

  useEffect(() => {
    if (!isEmpty(user)) {
      setBaseCleaning(user.base_cleaning);
    }
  }, [user]);

  const handleAmountChange = (event) => {
    const value = event.target.value;
    setBaseCleaning(value);
  };

  const handleSubmit = () => {
    if (baseCleaning > 0) {
      window.location.replace(
        NEW_USER_MARKETPLACE_PROFILE_PRICING + `?base_cleaning=${baseCleaning}`
      );
    }
  };

  return (
    <>
      <Navigation type="onboarding" />
      <div
        className="flex align-center justify-center flex-column"
        style={{ marginTop: 24 }}
      >
        <div
          className="flex flex-column"
          style={{
            maxWidth: "350px",
            width: "100%",
            minWidth: "260px",
            textAlign: "center",
          }}
        >
          <Typography variant="h5">Set your base price</Typography>
          <div className="mt1">
            <Typography variant="body2">
              Examine the standard cleaning checklist provided by Automatebnb
              and use it to establish your base price below.
            </Typography>
          </div>
          <div className="mt3 mb4 flex justify-center">
            <Link
              href="https://automatebnb.notion.site/Automatebnb-cleaning-checklist-62d4a8d3d726432985415ebd63b79790"
              target="_new"
              className="flex align-center"
              style={{ fontWeight: 600 }}
              color="primary"
            >
              View checklist{" "}
              <OpenInNewIcon style={{ marginLeft: 8, fontSize: "18px" }} />
            </Link>
          </div>
          <CurrencyTextField
            label="Base cleaning fee"
            currency="USD"
            value={baseCleaning}
            onChange={handleAmountChange}
            name="base_cleaning"
          />
          <div className="mt3">
            <Button
              variant="contained"
              color="primary"
              disableElevation
              disabled={isEmpty(baseCleaning) ? true : baseCleaning < 1.0}
              onClick={handleSubmit}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChecklistStandardScreen;
