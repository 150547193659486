import React from "react";

import moment from "moment";

import SameDayFlip from "../components/sameDayFlip";
import { getFormattedCheckInOutTime } from "../helpers/format";
import { Button } from "@material-ui/core";
import { Analytics } from "../services/analyticsService.tsx";
import { openThisDialog } from "../actions/dialog";
import { DT_UPDATE_JOB_DATE_TIME } from "../constants/dialogTypes";
import { useDispatch } from "react-redux";
import { CLEANER_STATUS_DECLINED } from "../constants/apiEnum";

interface JobHeaderProps {
  id: string;
  status: string;
  is_flip: boolean;
  is_past: boolean;
  job_date: string;
  duration: number;
  job_time: string;
  canEdit?: boolean;
  isMarketplace?: boolean;
  is_maid?: boolean;
  is_host?: boolean;
  is_assignment?: boolean;
}

function pluralize(count, label) {
  return count === 1 ? label : `${label}s`;
}

const JobHeader: React.FC<JobHeaderProps> = ({
  id,
  status,
  is_past,
  is_flip,
  job_date,
  duration,
  is_host,
  job_time,
  is_maid,
  isMarketplace,
  is_assignment,
  canEdit = false,
}) => {
  const formattedDate = moment(job_date).format("ddd, MMMM Do");
  const formattedTime = getFormattedCheckInOutTime(job_time);

  const dispatch = useDispatch();

  const isDisabled = status === CLEANER_STATUS_DECLINED;

  const handleOnClick = () => {
    Analytics.trackEvent("job_update_schedule_action_from_job_details", {
      job_id: id,
    });
    dispatch(
      openThisDialog({
        dialogType: DT_UPDATE_JOB_DATE_TIME,
        jobId: id,
        jobDate: job_date,
        jobTime: job_time,
        view: "job",
      })
    );
  };
  const RenderCTALogic = () => {
    if (!is_past) {
      if (isMarketplace && !(is_host || is_maid) && !is_assignment) {
        return (
          <div className="mt-2">
            <Button variant="outlined" color="primary" disabled={true}>
              Update schedule
            </Button>
            <p className="italic text-sm mt-1 opacity-50">
              Marketplace jobs schedule cannot be changed. Please contact your
              job provider.
            </p>
          </div>
        );
      } else {
        return canEdit ? (
          <div className="mt-4">
            <Button
              disabled={isDisabled}
              onClick={handleOnClick}
              variant="outlined"
              color="primary"
            >
              Update schedule
            </Button>
          </div>
        ) : null;
      }
    } else {
      return null;
    }
  };
  return (
    <div>
      <div className="flex justify-between items-center">
        <div>
          <p className="text-sm opacity-70">Job date</p>
          <h2 className="text-xl mt-1">
            {formattedDate} {formattedTime}
          </h2>
          <p className="text-sm opacity-70 mt-1">
            Guest duration: {duration} {pluralize(duration, "day")}
          </p>
        </div>
        {is_flip && <SameDayFlip />}
      </div>
      <RenderCTALogic />
    </div>
  );
};

export default JobHeader;
