import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import classnames from "classnames";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import { getUser } from "../reducers/user";
import { fetchUser } from "../actions/user";

import { updateUser } from "../actions/user";

import { bindActionCreators } from "redux";

const UserSecurityDisplay = (props) => {
  const { user, fetchUser, updateUser } = props;

  const [values, setValues] = useState({
    id: "",
    email: "",
  });

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (!isEmpty(user)) {
      setValues({
        id: user.id,
        email: user.email,
      });
    }
  }, [user]);

  const isValid = () => {
    return (
      !isEmpty(values.email) && !isEmpty(password) && !isEmpty(confirmPassword)
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newVal = { ...values, [name]: value };

    setValues(newVal);
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);
  };

  const handleConfirmPasswordChange = (e) => {
    const { value } = e.target;
    setConfirmPassword(value);
  };

  const handleSubmit = (value) => {
    if (values && isValid()) {
      updateUser({
        id: values.id,
        email: values.email,
        password: password,
        password_confirmation: confirmPassword,
      });
      setValues({
        id: values.id,
        email: values.email,
      });

      setPassword("");
      setConfirmPassword("");
    }
  };

  return (
    <div className="pms-signin pt2">
      <div className="form">
        <div className="mb3">
          <TextField
            required
            color="primary"
            name="email"
            label="Email"
            fullWidth={true}
            disabled={true}
            variant="outlined"
            value={values.email}
          />
        </div>
        <div className="mb3">
          <TextField
            required
            color="primary"
            name="password"
            label="Password"
            fullWidth={true}
            onChange={handlePasswordChange}
            variant="outlined"
            type="password"
            value={password}
          />
        </div>
        <div className="mb3">
          <TextField
            required
            color="primary"
            name="confirmPassword"
            label="Confirm password"
            fullWidth={true}
            type="password"
            onChange={handleConfirmPasswordChange}
            variant="outlined"
            value={confirmPassword}
          />
        </div>
        <div className="mt3">
          <Button
            onClick={() => handleSubmit()}
            fullWidth={true}
            color="primary"
            size="large"
            disableElevation
            variant="contained"
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({ user: getUser(state) }),
  (dispatch) => bindActionCreators({ fetchUser, updateUser }, dispatch)
)(UserSecurityDisplay);
