import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import classnames from "classnames";
import moment from "moment";

// Material UI - Dialog
import Link from "@material-ui/core/Link";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// Date picker
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

// Progress
import LinearProgress from "@material-ui/core/LinearProgress";

// Helper
import { getFormattedCheckInOutTime } from "../helpers/format";

// Material-UI - Forms
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";

// Reducer
import { getPMSProperties } from "../reducers/pmsProperties";
import { getMyPros } from "../reducers/pros";

// API
import { requestJob } from "../actions/jobs";
import { fetchProperties } from "../actions/pmsProperties";
import { fetchMyPros } from "../actions/pros";

// Constants
import { PMS_PROPERTIES_NEW, MY_PROS } from "../routes";
import { Typography } from "@material-ui/core";

const possibleJobTimes = [
  { value: "8:00", display: "8:00 AM" },
  { value: "9:00", display: "9:00 AM" },
  { value: "10:00", display: "10:00 AM" },
  { value: "11:00", display: "11:00 AM" },
  { value: "12:00", display: "12:00 PM (noon)" },
  { value: "13:00", display: "1:00 PM" },
  { value: "14:00", display: "2:00 PM" },
  { value: "15:00", display: "3:00 PM" },
  { value: "16:00", display: "4:00 PM" },
  { value: "17:00", display: "5:00 PM" },
  { value: "18:00", display: "6:00 PM" },
  { value: "19:00", display: "7:00 PM" },
  { value: "20:00", display: "8:00 PM" },
  { value: "21:00", display: "9:00 PM" },
  { value: "22:00", display: "10:00 PM" },
  { value: "23:00", display: "11:00 PM" },
  { value: "24:00", display: "12:00 PM (midnight)" },
];

function RenderRequestForm(props) {
  const { properties, requestJob, isLoading, closeThisDialog, myPros } = props;
  const [data, setData] = useState({
    property_id: "",
    job_time: "11:00",
    job_date: null,
    job_pay: "",
    description: "",
    pro_id: "",
    is_flip: false,
  });
  const [error, setError] = useState({
    property: false,
    date: false,
  });

  function handleSubmit() {
    // Check if required fields have a value

    if (data.property_id === "" || data.job_date === null) {
      setError({
        property: data.property_id === "",
        date: data.job_date === null,
      });
      return;
    }

    // Call API
    // API is expecting YYYY-MM-DD format only
    let formattedDate = moment(data.job_date).format("YYYY-MM-DD");
    requestJob({ ...data, ["job_date"]: formattedDate });
  }

  function handleClose() {
    closeThisDialog();
  }

  function handleChange(event) {
    const { name, value, checked } = event.target;
    if (name === "property_id" && value) {
      setError({ ...error, property: false });
    }
    if (name === "is_flip") {
      setData({ ...data, [name]: checked });
    } else {
      setData({ ...data, [name]: value });
    }
  }

  // Date picker only returns the new date.
  function handleDateChange(date) {
    // Reset validation if user selects date
    if (error.date && !isEmpty(date)) {
      setError({ ...error, date: false });
    }
    setData({ ...data, ["job_date"]: date });
  }

  // Input number returns value only
  const handleFormChange = (props) => (event) => {
    setData({ ...data, [props]: event.target.value });
  };

  function handleDescription(event) {
    setData({ ...data, ["description"]: event.target.value });
  }
  return (
    <>
      <DialogTitle
        id="scroll-dialog-title"
        className="pms-dialog__title assign-housekeeper__title"
      >
        Job request
        <br />
        <small className="pms-dialog__sub-title small italic">
          We will send SMS to your Pro. Any SMS charges may apply to your Pro.
        </small>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{ position: "relative" }}
        className="ab-dialog"
      >
        <FormControl
          variant="outlined"
          required
          error={error.property}
          className={classnames("full-width mb2", {
            "Mui-filled": data.property_id,
          })}
        >
          {/* Properties selection */}
          <InputLabel id="request-a-job-properties-selection">
            Properties
          </InputLabel>
          <Select
            value={data.property_id}
            name="property_id"
            onChange={handleChange}
            inputProps={{ "aria-label": "Select property" }}
          >
            {properties.map((property) => (
              <MenuItem
                key={`property-id-${property.id}`}
                value={property.id}
                className="list-subheader"
              >
                <p>{`${property.title} `}</p>
                <small className="small grey">{`${property.city}, ${property.state}`}</small>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            disablePast
            autoOk={true}
            required
            error={error.date}
            variant="inline"
            disableToolbar
            margin="normal"
            label="Pick a job date"
            value={data.job_date}
            onChange={handleDateChange}
            className="full-width mb2"
            id="date-picker-dialog"
            inputVariant="outlined"
            format="MM/dd/yyyy"
          />
        </MuiPickersUtilsProvider>
        <FormControl
          variant="outlined"
          className={classnames("full-width mb2", {
            "Mui-filled": data.job_time,
          })}
        >
          <InputLabel id="request-a-job-properties-selection">
            Job time
          </InputLabel>
          <Select
            value={data.job_time}
            name="job_time"
            onChange={handleChange}
            inputProps={{ "aria-label": "Select job time" }}
          >
            {possibleJobTimes.map((time) => (
              <MenuItem
                key={`property-id-${time.value}`}
                value={time.value}
                className="list-subheader"
              >
                <p>{`${time.display} `}</p>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/*
          Holding off on Job pay, these two fields require additional level of work. 
          - Job pay should also be collected at a property level, then pros cannot change the job pay but can add additional pay
        */}
        {/*
        <FormControl
          variant="outlined"
          key="form-control-job-pay-job-request"
          className={classnames("full-width mb2", {
            "Mui-filled": data.job_pay,
          })}
        >
          <InputLabel htmlFor="standard-job-pay">Job pay</InputLabel>
          <OutlinedInput
            id="standard-job-pay"
            type="Number"
            key="request-a-job-field-job-pay"
            value={data.job_pay}
            onChange={handleFormChange("job_pay")}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            labelWidth={60}
          />
        </FormControl>
        */}
        <TextField
          label="Job note"
          multiline
          className="full-width mb2"
          placeholder="e.g., please check if supplies are running low"
          minRows={4}
          value={data.description}
          onChange={handleDescription}
          variant="outlined"
        />
        <FormControl
          variant="outlined"
          className={classnames("full-width mb2", {
            "Mui-filled": data.pro_id,
          })}
        >
          {/* Pros selection */}
          <InputLabel id="request-a-job-pros-selection">
            Assign a pro
          </InputLabel>
          <Select
            value={data.pro_id}
            name="pro_id"
            onChange={handleChange}
            inputProps={{ "aria-label": "Select a pro" }}
          >
            {myPros.map((pro) => {
              const { first, last, category } = pro;

              const fullName = `${first ? first : ""} ${last ? last : ""}`;
              const categoryName = category ? category.name : "--";

              return (
                <MenuItem
                  key={`pro-id-${pro.id}`}
                  value={pro.id}
                  className="list-subheader"
                >
                  <p>
                    {fullName}{" "}
                    {!pro.email && (
                      <small className="small italic grey">Invited</small>
                    )}
                  </p>
                  <small className="small grey">{categoryName}</small>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={data.is_flip}
              onChange={handleChange}
              name="is_flip"
              color="primary"
            />
          }
          label="Request same-day cleaning service"
        />
      </DialogContent>
      <DialogActions>
        <div className="inline-flex justify-end full-width">
          <div className="pms-dialog__actions">
            <Button
              onClick={handleClose}
              variant="text"
              disableElevation
              className="actions actions--default"
            >
              Cancel
            </Button>
            {!isLoading && (
              <Button
                onClick={handleSubmit}
                variant="contained"
                disableElevation
                disabled={isEmpty(data.property_id, data.job_data)}
                color="primary"
              >
                Create
              </Button>
            )}
          </div>
        </div>
      </DialogActions>
    </>
  );
}

const RenderPromptToAddProperties = ({ closeThisDialog }) => {
  return (
    <>
      <DialogTitle
        id="scroll-dialog-title"
        className="pms-dialog__title assign-housekeeper__title"
      >
        No Properties
      </DialogTitle>
      <DialogContent dividers={true} style={{ position: "relative" }}>
        You have no properties available, please add properties first. <br />
        <br />
        <Link href={PMS_PROPERTIES_NEW}>Add property</Link>
      </DialogContent>
      <DialogActions>
        <div className="inline-flex justify-end full-width">
          <div className="pms-dialog__actions">
            <Button
              onClick={closeThisDialog}
              variant="contained"
              disableElevation
              className="actions actions--primary"
            >
              Done
            </Button>
          </div>
        </div>
      </DialogActions>
    </>
  );
};

const RenderPromptToInvitePros = ({ closeThisDialog }) => {
  return (
    <>
      <DialogTitle
        id="scroll-dialog-title"
        className="pms-dialog__title assign-housekeeper__title"
      >
        Invite a pro
      </DialogTitle>
      <DialogContent dividers={true} style={{ position: "relative" }}>
        <Typography variant="body1">
          You must invite a pro before requesting a job.
        </Typography>
        <Link href={MY_PROS}>
          <Typography variant="body1" className="link">
            Go to My Pros
          </Typography>
        </Link>
      </DialogContent>
      <DialogActions>
        <div className="inline-flex justify-end full-width">
          <div className="pms-dialog__actions">
            <Button
              onClick={closeThisDialog}
              variant="contained"
              disableElevation
              className="actions actions--primary"
            >
              Done
            </Button>
          </div>
        </div>
      </DialogActions>
    </>
  );
};

const RequestAJob = (props) => {
  const {
    closeThisDialog,
    fetchProperties,
    fetchMyPros,
    propertiesObj,
    myProsObj,
    requestJob,
  } = props;

  const { items: properties } = propertiesObj;
  const { items: pros } = myProsObj;

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetchProperties({ allProperties: true });
    fetchMyPros();
  }, []);

  useEffect(() => {
    if (!propertiesObj.isLoading && !myProsObj.isLoading) {
      const loaderTime = setTimeout(() => {
        setLoading(false);
      }, 300);
      return () => clearTimeout(loaderTime);
    }
  }, [propertiesObj, myProsObj]);

  if (isLoading) {
    return (
      <div style={{ position: "absolute", top: 0, left: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    );
  } else {
    if (!isEmpty(properties) && !isEmpty(pros)) {
      return (
        // Need to put it outside the main render function because it casuses fields to loose focus
        <RenderRequestForm
          properties={properties}
          myPros={pros}
          isLoading={isLoading}
          closeThisDialog={closeThisDialog}
          requestJob={requestJob}
        />
      );
    } else {
      if (isEmpty(properties)) {
        return (
          <RenderPromptToAddProperties closeThisDialog={closeThisDialog} />
        );
      } else if (isEmpty(pros)) {
        return <RenderPromptToInvitePros closeThisDialog={closeThisDialog} />;
      }
    }
  }
};

export default connect(
  (state) => ({
    propertiesObj: getPMSProperties(state),
    myProsObj: getMyPros(state),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        requestJob,
        fetchProperties,
        fetchMyPros,
      },
      dispatch
    )
)(RequestAJob);
