import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Moment from "react-moment";

// Material Components
import { Button, Chip, Typography } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import Link from "@material-ui/core/Link";
import { useMediaQuery } from "@material-ui/core";

// Local Components
import NormalizedCleaningStatus from "../components/normalizedCleaningStatus";
import LoadingScreen from "../components/loadingScreen";
import AssignedPros from "../containers/jobAssignedPros";

// Helpers
import { getFormattedCheckInOutTime } from "../helpers/format";
import { parseQuery } from "../helpers/url";

// Constants
import {
  DT_JOB_DESCRIPTION,
  DT_CHANGE_A_PRO,
  DIALOG_TYPE_MAKE_CLEANING_PAYMENT,
  DT_CANCEL_JOB,
  DT_UPDATE_JOB_DATE_TIME,
} from "../constants/dialogTypes";
import {
  PAYMENT_STATUS_PENDING,
  CLEANER_STATUS_COMPLETED,
  PAYMENT_STATUS_SUCCESS,
  CLEANING_CANCELLED,
  DATE_FORMAT_FULL,
  DATE_FORMAT,
  MARKETPLACE,
} from "../constants/apiEnum";

// Actions
import { fetchJobDetails, fetchWalkthroughTasks } from "../actions/jobs";
import { openThisDialog, setDialogOpen } from "../actions/dialog";

// Reducer
import { getJobDetails } from "../reducers/jobs";
import JobSchedule from "../components/jobSchedule";
import JobLocation from "../components/jobLocation";
import GuestReservationDetails from "../components/jobGuestReservationDetails";
import { getUser, getUserToken, userToken } from "../reducers/user";
import CleaningImageViewer from "../components/cleaningImageViewer";
import GuestRating from "../components/guestRating";
import LoopIcon from "@material-ui/icons/Loop";
import MarketplaceDetails from "../components/MarketplaceDetails";
import { getWalkthroughTasks } from "../reducers/walkthroughTasks";
import { Analytics } from "../services/analyticsService.tsx";

function RenderJob(props) {
  const {
    setDialogOpen,
    openThisDialog,
    user,
    userToken,
    job = {},
    tasks = {},
  } = props;

  const {
    id: jobId,
    job_date: jobDate,
    property = {},
    pro = {},
    is_past: isPast,
    reservation = {},
    job_fee: jobFee,
    is_flip: isFlip,
    is_completed: isCompleted,
    job_time: jobTime = "11:00",
    status,
    tracking_status,
    additional_fee,
    total_fee,
    cleaning_fee_note: paymentDescription,
    address,
    payment,
    addon_fees,
    special_request,
    description,
    guest_rating,
    guest_review,
  } = job;

  const isMobile = useMediaQuery("(max-width:600px)");

  // Extract properties key,value
  const {
    title = "",
    check_in_time: jobCheckinTime = "2:00",
    check_out_time: jobCheckoutTime = "11:00",
    id: propertyId,
    zip = "00000",
    city = "",
    state = "",
  } = property;
  // Extract Pros key,value
  const {
    id: proId = "",
    name: proName = "",
    phone: proPhoneNumber = "",
    category = {},
  } = pro || {};
  // Extract reservations key,value
  const {
    channel = "",
    check_in: reservationCheckIn = "",
    check_out: reservationCheckOut = "",
    airbnb_reservation_id: airbnbReservationId = "",
    airbnb_reservation_url: airbnbReservationUrl = "",
    homeaway_reservation_id: homeawayReservationId = "",
    ta_reservation_id: tripAdvisorReservationId = "",
    vrbo_reservation_id: vrboReservationId,
    duration = "",
  } = reservation;

  // Check if description is empty
  const isDescriptionEmpty = isEmpty(description);

  // Get a formatted time based on military time
  const formattedCheckoutTime = getFormattedCheckInOutTime(jobTime);
  const formattedCheckinTime = getFormattedCheckInOutTime(jobCheckinTime);
  const isJobCanceled = status === CLEANING_CANCELLED;
  const isJobCompleted = status === CLEANER_STATUS_COMPLETED;

  // Set job as inactive if a job is Completed or Cancelled
  const isJobNotActive = isCompleted || isJobCanceled || isPast;

  const isMarketplace = channel === MARKETPLACE;

  const RenderDateTime = () => {
    return isJobCanceled ? (
      <>
        <Moment format={DATE_FORMAT}>{jobDate}</Moment> {"- Job is canceled"}
      </>
    ) : (
      <>
        <Moment format={DATE_FORMAT}>{jobDate}</Moment>
        {" at "}
        {formattedCheckoutTime}
      </>
    );
  };

  const StayDuration = () => {
    if (duration > 1) {
      return `${duration} nights`;
    } else if (duration === 1) {
      return `${duration} night`;
    } else {
      return "N/A";
    }
  };

  const RenderJobStatus = (props) => {
    const { proJobStatus } = props;
    if (isCompleted) {
      if (!proJobStatus && !isEmpty(payment)) {
        return (
          <NormalizedCleaningStatus
            status={payment.status}
            human={payment.status_human}
          />
        );
      } else {
        return <NormalizedCleaningStatus status={status} />;
      }
    } else {
      return <NormalizedCleaningStatus status={status} />;
    }
  };

  const RenderJobDescription = () => {
    return (
      <>
        {!isDescriptionEmpty && <p>{description}</p>}
        {!isJobNotActive && (
          <Button
            onClick={() => {
              Analytics.trackEvent("add_note_action_from_job_details", {
                job_id: jobId,
              });
              openThisDialog({
                dialogType: DT_JOB_DESCRIPTION,
                description,
                jobId,
              });
            }}
            variant="outlined"
            color="primary"
          >
            {isDescriptionEmpty ? "Add note" : "Update note"}
          </Button>
        )}
      </>
    );
  };

  const RenderSourceInformation = () => {
    if (airbnbReservationUrl) {
      return (
        <Link target="_new" href={airbnbReservationUrl}>
          Airbnb
        </Link>
      );
    } else if (airbnbReservationId) {
      return <p>{`Airbnb (${airbnbReservationId})`}</p>;
    } else if (homeawayReservationId) {
      return <p>{`HomeAway (${homeawayReservationId})`}</p>;
    } else if (tripAdvisorReservationId) {
      return <p>{`TripAdvisor (${tripAdvisorReservationId})`}</p>;
    } else if (vrboReservationId) {
      return <p>{`Vrbo (${vrboReservationId})`}</p>;
    } else {
      return <span>{channel}</span>;
    }
  };

  const RenderReservationDetails = () => {
    return (
      <div className="job__container">
        <div className="border">
          <div className="sm-col sm-col-6 border">
            <p className="label">Checkin</p>
            <p className="">
              <Moment format={DATE_FORMAT}>{reservationCheckIn}</Moment>
              {" @ "}
              <span>{formattedCheckinTime}</span>
            </p>
          </div>
          <div className="sm-col sm-col-6 border">
            <p className="label">Check out</p>
            <p>
              <Moment format={DATE_FORMAT}>{reservationCheckOut}</Moment>
              {" @ "}
              <span>{formattedCheckoutTime}</span>
            </p>
          </div>
        </div>
        <div className="border">
          <div className="sm-col sm-col-6 border mt2">
            <p className="label">Stay duration</p>
            <p>
              <StayDuration />
            </p>
          </div>
          <div className="sm-col sm-col-6 border mt2">
            <p className="label">Source</p>
            <p>
              <RenderSourceInformation />
            </p>
          </div>
        </div>
      </div>
    );
  };

  const RenderJobPro = () => {
    if (!proId) {
      return (
        !isCompleted && (
          <Button
            onClick={() =>
              openThisDialog({ dialogType: DT_CHANGE_A_PRO, jobId, proId })
            }
            variant="outlined"
            color="primary"
            className="button"
          >
            Assign a pro
          </Button>
        )
      );
    } else {
      return (
        <>
          <div className="job__container">
            <div className="border">
              <div className="sm-col sm-col-6 border">
                <p className="label">Name</p>
                <p className="">{proName}</p>
              </div>
              <div className="sm-col sm-col-6 border">
                <p className="label">Status</p>
                <p className="">
                  <RenderJobStatus proJobStatus={true} />
                </p>
              </div>
            </div>
            <div className="border">
              <div className="sm-col sm-col-6 border mt2">
                <p className="label">Category</p>
                <p className="">
                  {category ? category.name : <span className="grey">N/A</span>}
                </p>
              </div>
              <div className="sm-col sm-col-6 border mt2">
                <p className="label">Phone</p>
                <a className="link" href={`tel:${proPhoneNumber}`}>
                  {proPhoneNumber}
                </a>
              </div>
            </div>
          </div>
          {!isJobNotActive && (
            <Button
              onClick={() =>
                openThisDialog({ dialogType: DT_CHANGE_A_PRO, jobId, proId })
              }
              variant="outlined"
              color="primary"
              className="button"
            >
              Change pro
            </Button>
          )}
        </>
      );
    }
  };

  const RenderJobPayment = () => {
    const {
      id: paymentId,
      amount,
      receipt_url: receiptUrl,
      status: paymentStatus,
    } = payment;

    const isPending = paymentStatus === PAYMENT_STATUS_PENDING;

    return (
      <>
        <div className="job__container">
          <div className="border">
            <div className="sm-col sm-col-6 border">
              <p className="label">Total amount</p>
              <p className="bold">{`$${total_fee}`}</p>
            </div>
          </div>
        </div>
        <div className="job__container">
          <div className="border">
            <div className="sm-col sm-col-6 border mt2">
              <p className="label">Job amount</p>
              <p className="">{`$${jobFee}`}</p>
            </div>
            <div className="sm-col sm-col-6 border mt2">
              <p className="label">Additional amount</p>
              <p className="">{`$${additional_fee}`}</p>
            </div>
          </div>
        </div>
        {paymentDescription && (
          <div className="job__container">
            <div className="border">
              <div className="sm-col sm-col-12 border mt2">
                <p className="label">Payment description</p>
                <p className="">{paymentDescription}</p>
              </div>
            </div>
          </div>
        )}

        {isPending ? (
          <Button
            onClick={() =>
              openThisDialog({
                dialogType: DIALOG_TYPE_MAKE_CLEANING_PAYMENT,
                job,
              })
            }
            variant="contained"
            disableElevation
            color="secondary"
          >
            Make a payment
          </Button>
        ) : (
          <a
            href={receiptUrl}
            target="_blank"
            className="button button--hyperlink"
          >
            View Receipt
            <LaunchIcon className="button__icon--right" />
          </a>
        )}
      </>
    );
  };

  return (
    <div className="page-wrapper">
      <div className="page-content pt3">
        <div className="job__header mb2" key="host-job-primary-action">
          {isMobile ? (
            <div key="desktop-page-header-first" className="">
              <h1 className="page-header">
                <RenderDateTime />
              </h1>
              <RenderJobStatus />
            </div>
          ) : (
            [
              <div key="desktop-page-header-first" className="">
                <h1 className="page-header">
                  <RenderDateTime />
                </h1>
              </div>,
              <div key="desktop-page-header-second" className="">
                <RenderJobStatus />
              </div>,
            ]
          )}
        </div>
        <div className="flex items-center ml2">
          {isFlip && (
            <Chip
              icon={<LoopIcon style={{ color: "white" }} />}
              label="Same day flip"
              size="small"
              style={{
                backgroundColor: "#ff2929",
                color: "white",
                marginRight: 4,
              }}
            />
          )}
          {isMarketplace && (
            <Chip color="primary" label="Marketplace job" size="small" />
          )}
        </div>
        {/* If payment render this card at the top of the page */}
        {payment && (
          <div className="job__payment job__border mt3">
            <h3 className="header-3">Payment</h3>
            <RenderJobPayment />
          </div>
        )}
        {/* Completed Jobs: Only render note section iff there was a note left */}
        {isJobNotActive ? (
          !isDescriptionEmpty && (
            <div className={classnames("job__note mt3")}>
              <h3 className="header-3">Job note</h3>
              <RenderJobDescription />
            </div>
          )
        ) : (
          <div
            className={classnames("job__note mt3", {
              "job__note--value": !isDescriptionEmpty,
            })}
          >
            <h3 className="header-3">Job note</h3>
            <RenderJobDescription />
          </div>
        )}
        {isMarketplace && (
          <>
            <div className="job__details mt3">
              <MarketplaceDetails
                addon_fees={addon_fees}
                price={{
                  total_payout_minus_fees: 0,
                  marketplace_fee: 0,
                  total_fee,
                }}
                special_request={special_request}
                viewType="host"
              />
            </div>
          </>
        )}
        <div className="job__details mt4">
          <JobSchedule
            jobDate={jobDate}
            jobTime={jobTime}
            title="Job schedule"
          />

          <div className="mt1">
            {!isJobNotActive && (
              <Button
                onClick={() => {
                  Analytics.trackEvent(
                    "job_update_schedule_action_from_job_details",
                    { job_id: jobId }
                  );
                  openThisDialog({
                    dialogType: DT_UPDATE_JOB_DATE_TIME,
                    jobId,
                    jobDate,
                    jobTime,
                    view: "job",
                  });
                }}
                variant="outlined"
                color="primary"
              >
                Update schedule
              </Button>
            )}
          </div>
        </div>
        <div className="job__details mt4">
          <JobLocation
            property={property}
            rooms={user.rooms}
            isHost={userToken.is_host}
            isMarketplace={isMarketplace}
            className="mt4"
          />
        </div>
        <div className="job__pro mt4">
          <h3 className="header-3">Assigned pro</h3>
          <AssignedPros
            pro={pro}
            jobStatus={status}
            jobId={jobId}
            isPast={isPast}
          />
        </div>
        {// Only render Reservation details if the Job was not created on Automatebnb

        channel !== "job_request" && (
          <div className="job__details mt4">
            {/* <RenderReservationDetails /> */}
            <GuestReservationDetails
              duration={duration}
              reservationDate={reservationCheckIn}
              reservationTime={jobCheckinTime}
              jobDate={jobDate}
              jobTime={jobTime}
              channel={channel}
              title="Guest reservation details"
            />
          </div>
        )}
        {isJobCompleted && guest_rating > 0 && (
          <div className="job__details mt3">
            {/* Requires images array of objects with original, thumbnail and description */}
            <Typography
              variant="h6"
              style={{
                fontSize: "18px",
                fontWeight: "500",
                lineHeight: "140%",
              }}
            >
              Inspection report
            </Typography>
            {/* <Typography variant="subtitle2" style={{ marginBottom: 16 }}>
            Photos showing property condition and damages after guest departure.
          </Typography> */}
            <div style={{ marginTop: 8 }}>
              <GuestRating rate={guest_rating} />
              {guest_review && (
                <div style={{ marginTop: 8 }}>
                  <Typography style={{ fontWeight: 500, fontSize: 14 }}>
                    Note:{" "}
                    <Typography
                      variant="body1"
                      component="span"
                      style={{
                        marginTop: 4,
                        fontWeight: "normal",
                        fontSize: 14,
                      }}
                    >
                      {guest_review}
                    </Typography>
                  </Typography>
                </div>
              )}
            </div>
          </div>
        )}
        {isJobCompleted && !isEmpty(tasks.data) && (
          <div className="job__details mt3">
            <Typography
              variant="h6"
              style={{
                fontSize: "18px",
                fontWeight: "500",
                lineHeight: "140%",
              }}
            >
              Final walkthrough
            </Typography>
            <Typography variant="body2" style={{ marginBottom: 16 }}>
              Photos taken by cleaner after preparing the property for new
              guests.
            </Typography>
            <CleaningImageViewer images={tasks.data} />
          </div>
        )}
        <div className="job__cancel text-center mt3 pb3">
          {!isJobNotActive && (
            <Button
              onClick={() => {
                Analytics.trackEvent("job_cancel_action_from_job_details", {
                  job_id: jobId,
                });
                openThisDialog({
                  dialogType: DT_CANCEL_JOB,
                  jobId,
                  view: "job",
                });
              }}
              variant="text"
              disableElevation
              color="primary"
              className="button button--danger"
            >
              Cancel job
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

const HostJobView = (props) => {
  const {
    fetchJobDetails,
    openThisDialog,
    job = {},
    setDialogOpen,
    fetchWalkthroughTasks,
    user,
    userToken,
    tasks,
  } = props;
  // Extract payment object to test the condition to open modal to make payment
  const { payment = {} } = job;
  const url = window.location.href;

  const { isLoading = true } = job;

  const localId = url.substring(url.lastIndexOf("/") + 1);

  const {
    make_payment: isMakePaymentInUrl = null,
    payment_id: isPaymentIdInUrl = null,
  } = parseQuery();

  useEffect(() => {
    if (localId) {
      fetchJobDetails(localId);
      fetchWalkthroughTasks(localId);
    }
    // localId
  }, []);

  useEffect(() => {
    // Open dialog to allow host to make payment iff host is accessing make payment link directly
    if (isMakePaymentInUrl && isPaymentIdInUrl) {
      if (!isEmpty(job) && !isEmpty(payment)) {
        if (payment.status !== PAYMENT_STATUS_SUCCESS) {
          openThisDialog({
            dialogType: DIALOG_TYPE_MAKE_CLEANING_PAYMENT,
            job,
          });
        }
      }
    }
  }, [job]);
  return isLoading ? (
    <LoadingScreen />
  ) : (
    !isEmpty(job) && (
      <RenderJob
        job={job}
        user={user}
        userToken={userToken}
        openThisDialog={openThisDialog}
        setDialogOpen={setDialogOpen}
        tasks={tasks}
      />
    )
  );
};

export default connect(
  (state) => ({
    job: getJobDetails(state),
    user: getUser(state),
    userToken: getUserToken(state),
    tasks: getWalkthroughTasks(state),
  }),
  (dispatch) =>
    bindActionCreators(
      { fetchJobDetails, openThisDialog, setDialogOpen, fetchWalkthroughTasks },
      dispatch
    )
)(HostJobView);
