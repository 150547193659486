import axios from "../helpers/axios";
import amplitude from "amplitude-js";

// NOT sure what it does
import { serverError } from "../helpers/helper_serverError";
import { showSnackbar } from "./action_snackbar";

import {
  JOBS,
  NEW_USER_MARKETPLACE_PROFILE_CHECKLIST,
  NEW_USER_MARKETPLACE_PROFILE_SERVICES,
  OPERATIONS_CLIENTS,
} from "../routes";

import { setContentLoading } from "./loading";

import { parseQuery } from "../helpers/url";

import { closeThisDialog } from "./dialog";

import {
  DT_CHANGE_A_PRO,
  DT_INVITE_OPS_CLIENTS,
} from "../constants/dialogTypes";

import { SET_A_JOB, RESET_A_JOB, SET_JOBS, RESET_JOBS } from "./jobs";

// JOBS constants are reused defined in Jobs actions
export const SET_OPS_CLIENTS = "set-operations-platform-clients";
export const RESET_OPS_CLIENTS = "reset-operations-platform-clients";
export const SET_OPS_CLIENTS_PROPERTOES =
  "set-operations-platform-clients-properties";
export const RESET_OPS_CLIENTS_PROPERTOES =
  "reset-operations-platform-clients-properties";

export const SET_OPS_TEAM = "set-operations-platform-team";
export const SET_INVITED_CLIENTS = "set_invited_clients";
export const RESET_INVITED_CLIENTS = "reset_invited_clients";

export const fetchOpsJobs = (next) => {
  const {
    property_ids,
    maid_ids,
    note,
    type,
    cleaning_status,
    payment_statuses,
  } = parseQuery();

  let cleaningStatus = "";

  return function action(dispatch) {
    if (!next) {
      dispatch(setContentLoading(true));
      dispatch(resetOpsJobs());
    }

    if (cleaning_status) {
      cleaningStatus += cleaning_status;
    } else {
      cleaningStatus = `ACCEPTED, DECLINED, STARTED, CLEANING DATE CHANGED, WAITING TO ACCEPT, COMPLETED`;

      /* 
          //=====
          // Past Jobs can also be of type Completed: [There is an issue with TimeZones and ToDo/Past jobs where a `completed` Job would disappear on the day off. Therefore the solution is to keep to rely entirly on time.]
          //======
         if (type === "past") {
           cleaningStatus += `, COMPLETED`;
         }
        */
    }

    return axios
      .get(
        `/v1/jobs?${next ? `page=${encodeURIComponent(next)}` : ""}${
          property_ids ? `filter[property_ids]=${property_ids}` : ""
        }${maid_ids ? `&filter[maid_ids]=${maid_ids}` : ""}${
          cleaningStatus ? `&filter[statuses]=${cleaningStatus}` : ""
        }${note ? `&filter[note]=${note}` : ""}${
          payment_statuses
            ? `&filter[payment_statuses]=${payment_statuses}`
            : ""
        }${type === "past" ? `&type=past&sort_by=DESC` : "&type=todo"}`
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(setContentLoading(false));
          dispatch(setOpsJobs(res.data));
        }
      })
      .catch((error) => {
        dispatch(setContentLoading(false));
        dispatch(serverError(error));
      });
  };
};

export const fetchOpsJobDetails = (id) => {
  return (dispatch) => {
    dispatch(setContentLoading(true));
    dispatch(resetOpsJobDetails());
    return axios
      .get(`/v1/jobs/${id}`)
      .then((res) => {
        dispatch(setContentLoading(false));
        if (res.status === 200) {
          dispatch(setOpsJobDetails(res.data));
          return true;
        }
      })
      .catch((error) => {
        dispatch(setContentLoading(false));
        dispatch(serverError(error));
        return false;
      });
  };
};

export function addUpdateAdditionalServices({
  maidId,
  specialty_services,
  visible_in_marketplace = false,
  first_time_activation = false,
}) {
  return (dispatch) => {
    axios
      .put(`/v1/maids/${maidId}`, {
        specialty_services,
        visible_in_marketplace,
        first_time_activation,
      })
      .then((response) => {
        if (response.status === 200) {
          window.location.replace(JOBS);
        }
      });
  };
}

export function addUpdateProfile({ maidId, formData }) {
  return (dispatch) => {
    axios
      .put(`/v1/maids/${maidId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          window.location.replace(NEW_USER_MARKETPLACE_PROFILE_CHECKLIST);
        }
      });
  };
}

export function updateRateSheet(props) {
  return (dispatch) => {
    return axios
      .put(`/v1/rate_sheets/update`, { ...props })
      .then((response) => {
        if (response.status === 200) {
          window.location.replace(NEW_USER_MARKETPLACE_PROFILE_SERVICES);
        }
      });
  };
}

export function assignAPro({ jobId, proId }) {
  return (dispatch) => {
    return axios
      .put(`/v1/jobs/${jobId}/assign?pro_id=${proId}`)
      .then((res) => {
        if (res) {
          const winPath = window.location.pathname;

          if (res.status === 200) {
            if (res.data) {
              if (winPath === "/jobs") {
                dispatch(fetchOpsJobs());
              } else {
                dispatch(fetchOpsJobDetails(res.data.id));
              }

              dispatch(closeThisDialog({ dialogType: DT_CHANGE_A_PRO }));
            }
          }
        }
      })
      .catch((err) => {
        dispatch(serverError(err));
      });
  };
}

export function inviteAClient({ email, note }) {
  const data = { email, note };
  return (dispatch) => {
    return axios
      .post(`/v1/clients/invite`, data)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            dispatch(closeThisDialog({ dialogType: DT_INVITE_OPS_CLIENTS }));
          }
        }
      })
      .catch((err) => {
        dispatch(serverError(err));
      });
  };
}

export const fetchInvitedClients = () => {
  return (dispatch) => {
    dispatch(resetInvitedClients());
    return axios
      .get("/v1/clients/invited_list")
      .then((res) => {
        if (res.status === 200) {
          dispatch(setInvitedClients(res.data));
          return true;
        }
      })
      .catch((error) => {
        dispatch(setContentLoading(false));
        // dispatch(serverError(error));
        return false;
      });
  };
};

export function removeAPro(jobId, proId) {
  return (dispatch) => {
    return axios
      .put(`/v1/jobs/${jobId}/unassign?pro_id=${proId}`)
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchOpsJobDetails(jobId));
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
}

export function fetchOpsClients() {
  return (dispatch) => {
    dispatch(resetOpsClients());
    return axios
      .get(`/v1/clients`)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setOpsClients(res.data));
          // showSnackbar({ message: res.data.message, hideDuration: 8000 })
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
}

export function fetchOpsClientProperties(id) {
  return (dispatch) => {
    dispatch(resetOpsClientProperties());
    return axios
      .get(`/v1/clients/${id}/properties`)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setOpsClientProperties(res.data));
          // showSnackbar({ message: res.data.message, hideDuration: 8000 })
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
}

// export const setUser = (user) => ({ type: SET_CURRENT_USER, user });
export const setOpsJobs = (data) => ({ type: SET_JOBS, data });
export const resetOpsJobs = () => ({ type: RESET_JOBS });
export const resetOpsJobDetails = () => ({ type: RESET_A_JOB });
export const setOpsJobDetails = (data) => ({ type: SET_A_JOB, data });
export const setOpsClients = (data) => ({ type: SET_OPS_CLIENTS, data });
export const resetOpsClients = () => ({ type: RESET_OPS_CLIENTS });
export const setOpsClientProperties = (data) => ({
  type: SET_OPS_CLIENTS_PROPERTOES,
  data,
});
export const resetOpsClientProperties = () => ({
  type: RESET_OPS_CLIENTS_PROPERTOES,
});

export const setInvitedClients = (data) => ({
  type: SET_INVITED_CLIENTS,
  data,
});

export const resetInvitedClients = () => ({
  type: RESET_INVITED_CLIENTS,
});
