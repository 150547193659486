import React, { useEffect, useState, useMemo } from "react";
import { isEmpty, property } from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Material UI - Dialog
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Typography, useMediaQuery } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Radio from "@material-ui/core/Radio";
import Link from "@material-ui/core/Link";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import CircularProgress from "@material-ui/core/CircularProgress";

import { fetchMyPros } from "../actions/pros";
import { assignProToProperty } from "../actions/properties";
import { getMyPros } from "../reducers/pros";

import { MY_PROS } from "../routes";

let assignmentBehavior = false;

function pluralize(count, singular, plural) {
  return count === 1 ? singular : plural;
}

const AssignProToPropertyDialog = (props) => {
  const {
    closeThisDialog,
    propertyId,
    assignProToProperty,
    property,
    fetchMyPros,
    pros = {},
  } = props;

  const upcoming_cleanings = 0;

  const { title = null, auto_assigned_maid: assignedPro = null } = property;

  const prosList = pros ? pros.items : [];
  const isProAssigned = !isEmpty(assignedPro);
  const assignedProId = isProAssigned ? assignedPro.id : null;
  const existingJobsCount = isProAssigned
    ? assignedPro.upcoming_cleanings
    : null;

  const [selected, setSelected] = useState(assignedPro || []);
  const [selectedBehavior, setSelectedBehavior] = useState(null);
  const [activeStep, setActiveStep] = React.useState(0);

  const isMobile = useMediaQuery("(max-width:600px)");

  const steps = ["Select pro", "Assignment behavior"];

  const dialogContentStyles = {
    position: "relative",
    lineHeight: "140%",
    paddingTop: 0,
    width: isMobile ? "100%" : "calc(100% - 48px)",
  };

  useEffect(() => {
    fetchMyPros();
  }, []);

  useMemo(() => {
    if (assignedProId === selected.id && assignmentBehavior) {
      assignmentBehavior = false;
    } else if (assignedProId !== selected.id && !assignmentBehavior) {
      assignmentBehavior = true;
    }
  }, [selected]);

  function handleClose() {
    closeThisDialog();
  }

  const handleAssignmentBehaviorChange = (event) => {
    setSelectedBehavior(event.target.value);
  };

  const handleRowSelect = (event, id) => {
    const index = prosList.findIndex((pro) => pro.id === id);
    console.log(prosList[index]);
    setSelected(prosList[index]);
  };

  const isSelected = (id) => {
    const selectedId = selected.id;
    return selectedId === id;
  };

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBackStep = () => {
    // assignmentBehavior = true;
    setSelectedBehavior(null);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleResetStep = () => {
    setActiveStep(0);
  };

  function getFullName(obj) {
    return `${obj.first} ${obj.last}`;
  }

  const handleChangePro = () => {
    const proId = selected.id;

    if (existingJobsCount > 0) {
      if (selectedBehavior !== null) {
        const assignExistingJobs = selectedBehavior === "true";

        assignProToProperty({
          propertyId,
          proId,
          assignExistingJobs,
        }).catch((error) => {});
      }
    } else {
      const assignExistingJobs = "true";
      assignProToProperty({
        propertyId,
        proId,
        assignExistingJobs,
      }).catch((error) => {});
    }
  };

  const handleAssignPro = () => {
    // First time assignment should be all existing and newly jobs.
    const assignExistingJobs = true;
    const proId = selected.id;
    assignProToProperty({ propertyId, proId, assignExistingJobs });
  };

  const RenderProList = () => {
    return (
      <>
        <DialogContent
          style={dialogContentStyles}
          className="ab-dialog"
          dividers={true}
        >
          <TableContainer>
            <Table aria-label="Pros list table">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Category</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {prosList.map((pro, index) => {
                  const isItemSelected = isSelected(pro.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const category = pro.category ? pro.category : "N/A";
                  return (
                    <TableRow
                      key={pro.id}
                      onClick={(event) => handleRowSelect(event, pro.id)}
                      className="cursor-pointer"
                      selected={isItemSelected}
                    >
                      <TableCell component="th" scope="row" padding="checkbox">
                        <Radio
                          checked={isItemSelected}
                          color="primary"
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <div>
                          <p>
                            {`${pro.first} ${pro.last}`}{" "}
                            {pro.id === assignedProId && "(current)"}
                          </p>
                          <small className="small grey">
                            {pro.phone ? pro.phone : ""}
                          </small>
                        </div>
                      </TableCell>
                      <TableCell align="left">{category}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions
          style={{
            width: "calc(100% - 16px)",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={handleClose}
            variant="text"
            style={{ marginRight: "8px" }}
            disableElevation
          >
            Cancel
          </Button>
          <Button
            onClick={handleAssignPro}
            variant="contained"
            color="primary"
            disableElevation
            disabled={isEmpty(selected)}
          >
            Assign pro
          </Button>
        </DialogActions>
      </>
    );
  };

  const RenderStepper = () => {
    const RenderSelectPro = () => {
      return (
        <>
          <DialogContent style={dialogContentStyles} className="ab-dialog">
            <TableContainer>
              <Table aria-label="Pros list table">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Category</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {prosList.map((pro, index) => {
                    const isItemSelected = isSelected(pro.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const category = pro.category ? pro.category : "N/A";
                    return (
                      <TableRow
                        key={pro.id}
                        onClick={(event) => handleRowSelect(event, pro.id)}
                        className="cursor-pointer"
                        selected={isItemSelected}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          padding="checkbox"
                        >
                          <Radio
                            checked={isItemSelected}
                            color="primary"
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <div>
                            <p>
                              {`${pro.first} ${pro.last}`}{" "}
                              {pro.id === assignedProId && "(current)"}
                            </p>
                            <small className="small grey">
                              {pro.phone ? pro.phone : ""}
                            </small>
                          </div>
                        </TableCell>
                        <TableCell align="left">{category}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions
            style={{
              width: "calc(100% - 16px)",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={handleClose}
              variant="text"
              style={{ marginRight: "8px" }}
              disableElevation
            >
              Cancel
            </Button>
            <Button
              onClick={handleNextStep}
              variant="contained"
              color="primary"
              disableElevation
              disabled={!assignmentBehavior}
            >
              Next
            </Button>
          </DialogActions>
        </>
      );
    };

    const RenderAssignmentBehaviorBody = () => {
      const existingProFullName = assignedPro
        ? `${assignedPro.first} ${assignedPro.last}`
        : null;
      if (existingJobsCount > 0) {
        return (
          <>
            <Typography variant="body1" style={{ marginTop: "16px" }}>
              There {pluralize(existingJobsCount, "is", "are")}{" "}
              <span className="semibold">
                {existingJobsCount} existing{" "}
                {pluralize(existingJobsCount, "job", "jobs")}
              </span>{" "}
              assigned to your current pro. Would you like to assign{" "}
              {pluralize(existingJobsCount, "this", "these")} to{" "}
              <span className="semibold">{getFullName(selected)}</span>:
            </Typography>
            <br />
            <RadioGroup
              aria-label="assignment behavior"
              name="assignmentBehavior"
              value={selectedBehavior}
              onChange={handleAssignmentBehaviorChange}
            >
              <FormControlLabel
                value="true"
                control={<Radio color="primary" />}
                label={
                  <span>Yes, assign existing and newly created jobs.</span>
                }
              />
              <FormControlLabel
                value="false"
                control={<Radio color="primary" />}
                label={<span>No, only assign newly created jobs.</span>}
              />
            </RadioGroup>
          </>
        );
      } else {
        return (
          <Typography variant="body1" style={{ marginTop: "16px" }}>
            There are no existing jobs assigned to {existingProFullName}. Would
            you like to automatically assign all new jobs to{" "}
            <span className="semibold">{getFullName(selected)}</span>?
          </Typography>
        );
      }
    };

    const RenderAssignmentBehavior = () => {
      return (
        <>
          <DialogContent style={dialogContentStyles} className="ab-dialog">
            <RenderAssignmentBehaviorBody />
          </DialogContent>
          <DialogActions
            style={{
              width: "calc(100% - 16px)",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "16px",
            }}
          >
            <Button onClick={handleBackStep}>Back</Button>
            <div className="pms-dialog__actions inline-flex nowrap">
              <Button
                onClick={handleClose}
                variant="text"
                style={{ marginRight: "8px" }}
                disableElevation
              >
                Cancel
              </Button>
              <Button
                onClick={handleChangePro}
                variant="contained"
                color="primary"
                disableElevation
              >
                Change pro
              </Button>
            </div>
          </DialogActions>
        </>
      );
    };
    return (
      <div>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};

            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {activeStep === 0 ? (
            <RenderSelectPro />
          ) : (
            <RenderAssignmentBehavior />
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <DialogTitle
        id="scroll-dialog-title"
        className="client-modal"
        style={{ paddingBottom: 0 }}
      >
        {isProAssigned ? "Change a pro" : "Assign a pro"}
        {activeStep === 0 && (
          <Typography variant="body2" style={{ paddingTop: "4px" }}>
            To invite new pros, navigate to{" "}
            <Link style={{ color: "#3a66ff" }} href={MY_PROS}>
              My Pros
            </Link>
            .
          </Typography>
        )}
      </DialogTitle>
      {isProAssigned ? <RenderStepper /> : <RenderProList />}
    </>
  );
};

export default connect(
  (state) => ({ pros: getMyPros(state) }),
  (dispatch) =>
    bindActionCreators({ fetchMyPros, assignProToProperty }, dispatch)
)(AssignProToPropertyDialog);
