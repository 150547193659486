import React from "react";

export const ChannelList = ({ channels,
                       handleJoinChannel,
                       channelSelected,
                       currentUser
                     }) => {

  const channelsToDisplay = [];
  channelsToDisplay.push(channels.filter((channel) => channel.customType === "HOST_PRO"));
  channelsToDisplay.push(channels.filter((channel) => channel.customType === "OPS_TEAM" || channel.customType === "OPS_PRO"));
  channelsToDisplay.push(channels.filter((channel) => channel.customType === "OTHER"));

  const myCurrentChatUser = channels[0]?.members?.filter((member) => member.userId === currentUser.user_id)[0] || null;
  const getSectionName = (customType) => {
    switch (customType) {
      case "HOST_PRO":
        if(myCurrentChatUser?.metaData?.is_host?.toLowerCase() === "true") {
          return "Pros";
        }
        return "Hosts";
      case "OPS_TEAM":
      case "OPS_PRO":
        return "Team";
      case "OTHER":
      default:
        return "Other";
    }
  }

  return (
    <div className='channel-list'>
      {channels.length === 0 ? (
        <div className="flex justify-center items-center min-h-96 text-sm text-slate-500 p-10 text-center">
          No message channels to display
        </div>
      ) : (
        <>
          {channelsToDisplay.map((secChannels, index) => {
            return (<div key={"secCh-"+index}>
              {secChannels.length > 0 && (
                <>
                  <div className="channel-type p-3 mt-5">
                    {getSectionName(secChannels[0].customType)}
                  </div>
                  {secChannels.map(channel => {
                    return (
                      <div key={channel.url} className={`flex items-center justify-between channel-list-item hover:cursor-pointer rounded ${channel.url === channelSelected ? 'active' : ''}`}
                           onClick={() => {
                             handleJoinChannel(channel.url)
                           }}>
                        <div className="channel-list-item-name p-3">
                          <ChannelName channel={channel} currentUserId={currentUser.user_id}/>
                        </div>
                        {channel.unreadMessageCount > 0 ? (<div className="mr-2 px-3 unread-messages">{channel.unreadMessageCount}</div>) : ''}
                      </div>
                    );
                  })}
                </>
              )}
            </div>)
          })}
        </>
      )}

    </div>);
}

const ChannelName = ({channel, currentUserId}) => {

  return <>
    <span>{getChatName(channel, currentUserId).map(m => m.nickname).join(",")}</span>
  </>
}

export const getChatName = (channel, currentUserId) => {
  const automateBNBChatUser = process.env.REACT_APP_AUTOMATE_BNB_CHAT_USER;
  let members = [];
  switch (channel.customType) {
    case "HOST_PRO":
      members = channel.members.filter((member) => {
        return !(member.userId === currentUserId || member.userId === automateBNBChatUser);
      });
      break;
    case "OPS_TEAM":
      members = ["#myTeam"]
      break;
    case "OPS_PRO":
      members = channel.members.filter((member) => {
        return !(member.userId === currentUserId || member.userId === automateBNBChatUser);
      });
      break;
    case "OTHER":
      members = channel.members.filter((member) => {
        return !(member.userId === currentUserId || member.userId === automateBNBChatUser);
      });
      break;
    default:
      return channel.name;
  }
  return members;
}

export default {getChatName, ChannelList};
