import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import {
  parseQuery,
  sanitizeQueryParams,
  filterParams,
} from "../../helpers/url";

import history from "../../helpers/history";

import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Checkbox from "@material-ui/core/Checkbox";

import { fetchProsJobs } from "../../actions/jobs";

const CleaningStatusFilterDialog = (props) => {
  const { closeDialog } = props;

  const [cleaningStatusSelected, setCleaningStatus] = useState([]);

  const { cleaning_status } = parseQuery();

  const cleaningStatusToArray = sanitizeQueryParams(cleaning_status, true);
  const dispatch = useDispatch();

  let _location = null;

  useEffect(() => {
    if (cleaningStatusToArray) {
      setCleaningStatus(cleaningStatusToArray);
    }
  }, []);

  const handleFilterClear = () => {
    setCleaningStatus([]);
  };

  const handleClose = () => {
    closeDialog();
  };

  const handleSearchResults = (type) => {
    _location = filterParams({
      cleaningStatus: cleaningStatusSelected,
    });

    if (_location) {
      history.push({
        search: _location,
      });

      dispatch(fetchProsJobs());
      handleClose();
    }
  };

  const handleCleaningStatusSelection = (event, name) => {
    const selectedIndex = cleaningStatusSelected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(cleaningStatusSelected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(cleaningStatusSelected.slice(1));
    } else if (selectedIndex === cleaningStatusSelected.length - 1) {
      newSelected = newSelected.concat(cleaningStatusSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        cleaningStatusSelected.slice(0, selectedIndex),
        cleaningStatusSelected.slice(selectedIndex + 1)
      );
    }

    setCleaningStatus(newSelected);
  };

  const isCleaningStatusSelected = (name) =>
    cleaningStatusSelected.indexOf(name) !== -1;
  const maxHeight = window.innerHeight / 2 > 260 ? window.innerHeight / 2 : 260;

  return (
    <>
      <DialogTitle
        id="scroll-dialog-title"
        className="assign-housekeeper__title"
      >
        <div className="inline-flex full-width space-between align-center pr pl">
          <CloseIcon className="cursor-pointer" onClick={() => handleClose()} />
          <span></span>
          <div />
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div className="scroll-dialog" style={{ height: maxHeight }}>
          <h4 key="cleaning-status-title" className="header-4">
            Job status
          </h4>
          <TableContainer
            key="cleaning-status-table"
            className="table--max-height-64"
          >
            <Table className="" aria-label="simple table">
              <TableBody>
                <TableRow
                  key="ACCEPTED"
                  onClick={(event) =>
                    handleCleaningStatusSelection(event, "ACCEPTED")
                  }
                  role="checkbox"
                  className="cursor-pointer"
                  aria-checked={isCleaningStatusSelected("ACCEPTED")}
                >
                  <TableCell component="th" scope="row" padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isCleaningStatusSelected("ACCEPTED")}
                      inputProps={{
                        "aria-labelledby": "enhanced-table-checkbox-ACCEPTED",
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">Accepted</TableCell>
                </TableRow>
                <TableRow
                  key="DECLINED"
                  onClick={(event) =>
                    handleCleaningStatusSelection(event, "DECLINED")
                  }
                  role="checkbox"
                  className="cursor-pointer"
                  aria-checked={isCleaningStatusSelected("DECLINED")}
                >
                  <TableCell component="th" scope="row" padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isCleaningStatusSelected("DECLINED")}
                      inputProps={{
                        "aria-labelledby": "enhanced-table-checkbox-DECLINED",
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">Declined</TableCell>
                </TableRow>
                <TableRow
                  key="cs-CLEANING-DATE-CHANGED"
                  onClick={(event) =>
                    handleCleaningStatusSelection(
                      event,
                      "CLEANING DATE CHANGED"
                    )
                  }
                  role="checkbox"
                  className="cursor-pointer"
                  aria-checked={isCleaningStatusSelected(
                    "CLEANING DATE CHANGED"
                  )}
                >
                  <TableCell component="th" scope="row" padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isCleaningStatusSelected(
                        "CLEANING DATE CHANGED"
                      )}
                      inputProps={{
                        "aria-labelledby":
                          "enhanced-table-checkbox-CLEANING-DATE-CHANGED",
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">Job date changed</TableCell>
                </TableRow>
                <TableRow
                  key="WAITING-TO-ACCEPT"
                  onClick={(event) =>
                    handleCleaningStatusSelection(event, "WAITING TO ACCEPT")
                  }
                  role="checkbox"
                  className="cursor-pointer"
                  aria-checked={isCleaningStatusSelected("WAITING TO ACCEPT")}
                >
                  <TableCell component="th" scope="row" padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isCleaningStatusSelected("WAITING TO ACCEPT")}
                      inputProps={{
                        "aria-labelledby":
                          "enhanced-table-checkbox-WAITING-TO-ACCEPT",
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">Waiting to accept</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="full-width inline-flex align-center space-between">
          <Button onClick={() => handleFilterClear()} disableElevation>
            Clear filters
          </Button>
          <Button
            onClick={() => handleSearchResults()}
            variant="contained"
            color="primary"
            disableElevation
          >
            Apply filters
          </Button>
        </div>
      </DialogActions>
    </>
  );
};

export default CleaningStatusFilterDialog;
