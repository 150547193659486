import React, { useState, useRef, useEffect } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  useMediaQuery,
  CircularProgress,
  Link,
} from "@material-ui/core";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { GeneralTextField } from "../../containers/accountFields";
import { s3UrlMapper } from "../../helpers/s3URLMapper";
import { useDispatch } from "react-redux";
import {
  addOrUpdateSpace,
  deleteSpace,
  fetchSpace,
  fetchSpaces,
} from "../../actions/staging";

const AddOrUpdateSpaceModal = (props = {}) => {
  const dispatch = useDispatch();
  const { isUpdateSpace = false } = props;
  const [values, setValues] = useState({
    name: props?.name || "",
    image_url: props?.image_url || "",
    description: props?.description || "",
  });
  const [isFormCTADisabled, setFormCTADisabled] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const PUBLIC_S3_URL = process.env.REACT_APP_PUBLIC_S3_URL;
  const isMobile = useMediaQuery("(max-width:600px)");

  const textFieldRef = useRef(null);
  const imgRef = useRef(null);

  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (values.name && values.image_url) {
      setFormCTADisabled(false);
    } else {
      setFormCTADisabled(true);
    }
  }, [values]);

  const handleSpaceFieldChange = (e) => {
    e.persist(); // Persist the event to prevent it from being nullified
    const { name, value } = e.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () =>
        setValues((prev) => ({ ...prev, image_url: reader.result }));
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveSpace = () => {
    if (props?.propertyId) {
      dispatch(deleteSpace());
    }
  };

  const handleModalClose = () => {
    props.hideModal();
  };

  const handleAddSpace = () => {
    if (props?.propertyId) {
      if (values?.name) {
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("order", 1);
        formData.append("space_type", "space");
        formData.append("description", values.description);
        if (props?.id) {
          formData.append("space_id", props?.id);
        }
        setLoading(true);
        if (values.image_url.includes(PUBLIC_S3_URL)) {
          dispatch(
            addOrUpdateSpace({
              propertyId: props.propertyId,
              formData,
            })
          ).then((res) => {
            if (res && res.status === 200) {
              setLoading(false);
              handleModalClose();
              dispatch(fetchSpaces(props.propertyId));
              if (props.id) {
                // This will only be true when updating a space
                dispatch(
                  fetchSpace({
                    propertyId: props.propertyId,
                    spaceId: props.id,
                  })
                );
              }
            }
          });
        } else {
          const img = new Image();
          img.src = values.image_url;
          img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            canvas.toBlob(
              (blob) => {
                formData.append("image", blob);
                dispatch(
                  addOrUpdateSpace({
                    propertyId: props.propertyId,
                    formData,
                  })
                ).then((res) => {
                  if (res && res.status === 200) {
                    setLoading(false);
                    handleModalClose();
                    dispatch(fetchSpaces(props.propertyId));
                    if (props.id) {
                      // This will only be true when updating a space
                      dispatch(
                        fetchSpace({
                          propertyId: props.propertyId,
                          spaceId: props.id,
                        })
                      );
                    }
                  }
                });
              },
              "image/png",
              1
            );
          };
        }
      }
    }
  };
  return (
    <>
      <DialogContent>
        <div style={{ marginBottom: 24 }}>
          <Typography variant="h5">
            {isUpdateSpace ? "Update space" : "Add space"}
          </Typography>
          <Typography variant="body2">
            Think of spaces as different parts of your property. For example:
            main bedroom, main bathroom, living room, gazebo, patio, etc.{" "}
            <Link
              href="https://help.automatebnb.com/en/articles/9463162-how-to-create-a-staging-guide#h_1a55a90bb8"
              target="__blank"
            >
              Learn more
            </Link>
          </Typography>
        </div>
        <GeneralTextField
          name="name"
          label="Name"
          labelInFieldset={false}
          helperText="Keep space names concise, 1-2 words recommended, and ensure they are unique."
          value={values.name}
          inputRef={textFieldRef}
          placeholder="e.g. Main bedroom, Kitchen, Backyard patio"
          handleChange={handleSpaceFieldChange}
        />
        <div style={{ marginTop: 16 }}>
          <GeneralTextField
            name="description"
            label="Description"
            placeholder="e.g. Main bedroom is on the second floor left of stairs"
            labelInFieldset={false}
            value={values.description}
            handleChange={handleSpaceFieldChange}
          />
        </div>
        <div style={{ margin: "20px 0" }}>
          <Typography variant="body1" style={{ fontWeight: 500 }}>
            Space reference photo*
          </Typography>
          <Typography variant="body2">
            Photo should be clear, helping pros identify the area in the
            property easily. For ex: take a full bedroom photo from the door.
          </Typography>
          <div
            style={{
              width: isMobile ? "200px" : "288px",
              height: isMobile ? "138px" : "200px",
              border: "2px dashed #ccc",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#f5f5f5",
              cursor: "pointer",
              position: "relative",
              marginTop: 10,
            }}
            onClick={() => document.getElementById("fileInput").click()}
          >
            {values.image_url ? (
              <img
                src={values.image_url}
                alt="Uploaded"
                ref={imgRef}
                style={{ width: "auto", height: "100%", borderRadius: "8px" }}
              />
            ) : (
              <div style={{ textAlign: "center" }}>
                <CameraAltIcon />
                <p>Add photo</p>
              </div>
            )}
            <input
              type="file"
              accept="image/*"
              id="fileInput"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: "flex-end" }}>
        <div>
          <Button onClick={handleModalClose}>Cancel</Button>
          <Button
            onClick={handleAddSpace}
            variant="contained"
            color="primary"
            disabled={isFormCTADisabled || isLoading}
            disableElevation
            style={{
              marginLeft: 8,
              padding: "8px 24px",
            }}
          >
            {isLoading && (
              <CircularProgress size={24} style={{ marginRight: 8 }} />
            )}
            Save
          </Button>
        </div>
      </DialogActions>
    </>
  );
};

export default AddOrUpdateSpaceModal;
