import React, { Component } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import Moment from "react-moment";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
} from "@material-ui/core";

import Card from "../components/card";
import { cancelSubscription, fetchSubscription } from "../actions/subscription";
import { DATE_FORMAT_FULL, HOST_STRIPE_FREE_PLAN } from "../constants/apiEnum";

import history from "../helpers/history";

import { HOST_SUBSCRIBE, HOST_PAYMENT_METHOD } from "../routes";
import { isEmpty } from "lodash";
import { Analytics } from "../services/analyticsService.tsx";

// TODO: Change it to functional

// const ManageHostSubscription = () => {}

class ManageSubscription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subscribed: false,
      dialogOpen: false,
    };
  }

  componentDidMount() {
    if (localStorage.jwtToken) {
      this.props.fetchSubscription();
    }
  }

  handleCancel = () => {
    this.setState({ dialogOpen: false });
    this.props.cancelSubscription();
  };

  handleClose = () => {
    this.setState({ dialogOpen: false });
  };

  openDialog = () => {
    this.setState({ dialogOpen: true });
  };

  isSubscribed(subscribe) {
    let subscribed = false;
    if (subscribe) {
      if (
        subscribe.status === "ACTIVE" ||
        subscribe.status === "UPDATE_PAYMENT" ||
        subscribe.status === "PAST_DUE"
      ) {
        subscribed = true;
      } else {
        subscribed = false;
      }
    } else {
      subscribed = false;
    }

    return subscribed;
  }

  isSubscriptionBugy(subscribe) {
    let status = subscribe.status;

    if (status) {
      if (
        status === "ACTIVE" ||
        status === "CANCELLED" ||
        status === "TRIAL_PERIOD" ||
        status === "TRIAL_EXPIRED" ||
        status === "PAST_DUE" ||
        status === "UPDATE_PAYMENT"
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  isCancelled(subscribe) {
    let cancelled = false;

    if (subscribe) {
      if (subscribe.status === "CANCELLED") {
        cancelled = true;
      }
    }

    return cancelled;
  }

  handleSubscribe = () => {
    Analytics.trackSimple("subscribe_action_from_subscription");
    window.location.replace(HOST_SUBSCRIBE);
  };

  upcomingPayment = (subscribe) => {
    const isOnClientPaidPlan = subscribe.stripe_plan === HOST_STRIPE_FREE_PLAN;

    return (
      !isOnClientPaidPlan && (
        <Card key="card-upcoming-payment" className="mb2 subscribe-card">
          <h4 className="header-4 card-header mt1">Upcoming payment</h4>
          <small className="small card-subheading">
            Your card will automatically be charged on the due date.{" "}
          </small>
          <table
            className=" full-width table table-borderless table-no-hover card-table"
            style={{ marginTop: "8px" }}
          >
            <thead className="thead thead-small">
              <tr className="thead-tr">
                <td>Listings</td>
                <td>Total amount</td>
                <td>Due date</td>
              </tr>
            </thead>
            <tbody className="tbody">
              <tr className="tbody-tr">
                <td className="tbody-td">
                  {subscribe ? subscribe.properties_count : "n/a"}
                </td>
                <td className="tbody-td">
                  <div>${subscribe ? subscribe.upcoming_amount : "n/a"}</div>
                </td>
                <td className="tbody-td">
                  <Moment format={DATE_FORMAT_FULL}>
                    {subscribe ? subscribe.upcoming_due_date : "n/a"}
                  </Moment>
                </td>
              </tr>
            </tbody>
          </table>
        </Card>
      )
    );
  };

  renderCallToSubscribe = (subscribe) => (
    <Card key="card-call-to-subscribe" className=" full-width subscribe-card">
      <div className="subscribe-action mt1">
        <Button
          variant="contained"
          onClick={() => this.handleSubscribe()}
          color="secondary"
          disableElevation
          size="large"
        >
          Subscribe
        </Button>
        {this.isCancelled(subscribe) ? (
          ""
        ) : (
          <span className="trial-days ml1">
            You have {subscribe ? subscribe.trial_days_left : "no"} days left in
            your free-trial
          </span>
        )}
      </div>
      <div className="subscribe-body">
        <br />
        <h5 className="header-5">Monthly payment</h5>
        <h2 className="header-2">
          ${subscribe ? subscribe.total_monthly_fee : "0"}
          <span className="small">/month</span>
        </h2>
        <div>
          <b>{subscribe ? subscribe.properties_count : "No"} listings added.</b>
        </div>
        <small className="small">Flat rate: $5/listing/month</small>
      </div>
    </Card>
  );

  renderPendingPayment(subscribe) {
    return (
      <Card
        key="card-pending-payment"
        className="card-elevated full-width subscribe-card"
      >
        <h4 className="header-4 mt1">Past due payment</h4>
        <table className="table table-borderless">
          <thead className="thead thead-small">
            <tr className="thead-tr">
              <td>Listings</td>
              <td>Total amount</td>
              <td>Due date</td>
            </tr>
          </thead>
          <tbody className="tbody">
            <tr className="tbody-tr">
              <td className="tbody-td">
                {subscribe ? subscribe.properties_count : "n/a"}
              </td>
              <td className="tbody-td">
                {subscribe ? subscribe.past_due_amount : "n/a"}
              </td>
              <td className="tbody-td danger-text">
                {subscribe ? subscribe.past_due_date : "n/a"}
              </td>
            </tr>
          </tbody>
        </table>
      </Card>
    );
  }

  handlePaymentMethod = (type) => {
    Analytics.trackSimple("update_payment_method_action_from_subscription");
    history.push(HOST_PAYMENT_METHOD);
  };

  renderPaymentMethod = (subscribe) => {
    const isOnClientPaidPlan = subscribe.stripe_plan === HOST_STRIPE_FREE_PLAN;
    return (
      <Card key="card-payment-method" className=" subscribe-card">
        <h4 className="header-4 mt1" style={{ marginBottom: "4px" }}>
          Payment method
        </h4>
        {isOnClientPaidPlan && (
          <p className="mb2">
            Your subscription is paid by your cleaning business and there's no
            additional cost to you. However, in order to pay your pros for
            cleaning jobs, please add your credit card. Automatebnb will use
            this credit card to process cleaning payments.
          </p>
        )}
        {subscribe.status === "PAST_DUE" ? (
          [
            <small key="past-due-text" className="text-danger">
              Payment failed. Please update your card.
            </small>,
            <div key="past-due-cta" className="flex space-between align-center">
              <h4 key="card-name">
                {subscribe ? subscribe.card_name : "No name"}
              </h4>
              <h4 key="card-last4">
                {subscribe ? subscribe.card_last4 : "****"}
              </h4>
              <Button
                key="card-cta"
                className="host-primary"
                variant="outlined"
                color="primary"
                disableElevation
                onClick={() => {
                  this.handlePaymentMethod("update");
                }}
              >
                Update
              </Button>
            </div>,
          ]
        ) : (
          <div key="past-due-cta" className="flex space-between align-center">
            {subscribe.card_last4 ? (
              [
                <h4 key="card-name">
                  {subscribe ? subscribe.card_name : "No name"}
                </h4>,
                <h4 key="card-last4">
                  {subscribe ? subscribe.card_last4 : "****"}
                </h4>,
                <Button
                  key="card-cta"
                  className="host-primary"
                  variant="outlined"
                  color="primary"
                  onClick={() => this.handlePaymentMethod("update")}
                >
                  Update
                </Button>,
              ]
            ) : (
              <Button
                key="card-cta"
                className="host-primary"
                variant="contained"
                color="primary"
                disableElevation={true}
                onClick={() => this.handlePaymentMethod("add")}
              >
                Add card
              </Button>
            )}
          </div>
        )}
      </Card>
    );
  };

  renderCancelSubscription = (subscribe) => {
    const isOnClientPaidPlan = subscribe.stripe_plan === HOST_STRIPE_FREE_PLAN;
    return (
      !isOnClientPaidPlan && (
        <div
          key="cancel-subscription-section"
          className="flex space-between align-center pt pb ml4 mr4 mt2"
        >
          <small className="secondary-text italic grey">
            Cancel anytime. No contract.
          </small>

          <Button
            color="secondary"
            variant="text"
            style={{ color: "red" }}
            onClick={this.openDialog}
          >
            Cancel subscription
          </Button>
        </div>
      )
    );
  };

  renderSubscriptionError = () => (
    <Card key="subscription-error" className="subscribe-card">
      <p>
        There is a problem with your account. Please contact us:{" "}
        <span className="link">help@automatebnb.com</span>
      </p>
    </Card>
  );

  renderBody = (subscribe) => {
    let isPastDue = false;

    if (subscribe) {
      if (subscribe.past_due_amount) {
        isPastDue = true;
      }
    }

    return this.isSubscribed(subscribe)
      ? [
          this.upcomingPayment(subscribe),
          this.renderPaymentMethod(subscribe),
          isPastDue ? this.renderPendingPayment(subscribe) : "",
          this.renderCancelSubscription(subscribe),
        ]
      : this.isSubscriptionBugy(subscribe)
      ? this.renderSubscriptionError()
      : this.renderCallToSubscribe(subscribe);
  };

  render() {
    const { subscribe, isLoading } = this.props;

    const _subscriptionObject = subscribe?.data;

    const isIAP = _subscriptionObject?.subscription_type === "iap";

    return isEmpty(_subscriptionObject) ? (
      <CircularProgress size={24} className="button-progress" />
    ) : (
      <div key="host-account" className="host-account account full-page">
        <div className="flex row admin">
          {isIAP ? (
            <div className="admin-body mt2 pl2 pr2 full-width">
              <h1 className="header-3">Apple App Store subscription</h1>
              <p>
                You are subscribed to Automatebnb on your iPhone. To make
                changes to your subscription please open the Automatebnb iPhone
                app and to cancel your subscription please open the Settings app
                on your iPhone.
              </p>
              <br />
              <br />
              <a
                href="https://automatebnb.notion.site/How-to-change-your-subscription-on-your-iPhone-38811c6ba7854360b3b23c310c1110cc"
                target="_new"
              >
                Change your subscription on Automatebnb iPhone App
              </a>
              <br />
              <a href="https://support.apple.com/en-us/HT202039" target="_new">
                Cancel Apple subscription
              </a>
            </div>
          ) : (
            <div className="admin-body mt2 pl2 pr2 full-width">
              <h1 className="header-3">Manage subscription</h1>
              {subscribe.status === 200 ? (
                this.renderBody(subscribe.data)
              ) : (
                <CircularProgress size={24} className="button-progress" />
              )}
            </div>
          )}
        </div>
        <Dialog
          open={this.state.dialogOpen}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="mobile-custom-dialog-class"
        >
          <DialogContent className="body-line">
            You are attempting to cancel your subscription. Syncing will be
            terminated for all listings. Your final invoice will be sent to you
            as soon as it is available.
          </DialogContent>
          <DialogActions>
            <button onClick={this.handleClose} className="button">
              Cancel
            </button>
            <button
              onClick={this.handleCancel}
              className="button button-danger"
            >
              Cancel subscription
            </button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    subscribe: state.subscribe,
    isLoading: state.loading,
  };
}

export default connect(mapStateToProps, {
  cancelSubscription,
  fetchSubscription,
})(ManageSubscription);
