import React from "react";
import Link from "@material-ui/core/Link";
import { useMediaQuery } from "@material-ui/core";

import { DATE_FORMAT } from "../constants/apiEnum";

import RenderDateTime from "./renderDateTime";

export default ({
  reservationDate = "",
  jobDate = "",
  jobTime = "",
  duration = "",
  reservationTime = "",
  format = DATE_FORMAT,
  airbnbReservationUrl = null,
  airbnbReservationId = null,
  homeawayReservationId = null,
  tripAdvisorReservationId = null,
  vrboReservationId = null,
  channel = null,
  title = "Guest reservation details",
}) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const StayDuration = () => {
    if (duration > 1) {
      return `${duration} nights`;
    } else if (duration === 1) {
      return `${duration} night`;
    } else {
      return "N/A";
    }
  };

  const Source = () => {
    if (airbnbReservationUrl) {
      return (
        <Link target="_new" href={airbnbReservationUrl}>
          Airbnb
        </Link>
      );
    } else if (airbnbReservationId) {
      return <p>{`Airbnb (${airbnbReservationId})`}</p>;
    } else if (homeawayReservationId) {
      return <p>{`HomeAway (${homeawayReservationId})`}</p>;
    } else if (tripAdvisorReservationId) {
      return <p>{`TripAdvisor (${tripAdvisorReservationId})`}</p>;
    } else if (vrboReservationId) {
      return <p>{`Vrbo (${vrboReservationId})`}</p>;
    } else {
      return <p>{channel ? channel : "N/A"}</p>;
    }
  };

  return (
    <div
      className={
        isMobile ? "job-details job-details--mobile" : "job-details pb3"
      }
    >
      <h3 className="job-details__title">{title}</h3>
      <div className="job-details__wrapper">
        <div className="job-details__group">
          <p className="job-details__label">Check in</p>
          <div className="job-details__body">
            <RenderDateTime
              date={reservationDate}
              time={reservationTime}
              format={DATE_FORMAT}
            />
          </div>
        </div>
        <div className="job-details__group">
          <p className="job-details__label">Check out</p>
          <div className="job-details__body nowrap">
            <RenderDateTime date={jobDate} time={jobTime} format={format} />
          </div>
        </div>
      </div>
      <div className="job-details__wrapper job-details__wrapper--mt2">
        <div className="job-details__group">
          <p className="job-details__label">Stay duration</p>
          <div className="job-details__body">
            <StayDuration />
          </div>
        </div>
        <div className="job-details__group">
          <p className="job-details__label">Source</p>
          <div className="job-details__body">
            <Source />
          </div>
        </div>
      </div>
    </div>
  );
};
