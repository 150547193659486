import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, TextField } from "@material-ui/core";
import { Button, Typography } from "@material-ui/core";
import Navigation from "../containers/navigation";
import { userSignup } from "../actions/user";
import history from "../helpers/history";
import { validateStrongPassword } from "../helpers/strongPassword";

const NewSignup = () => {
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();
  const username = history.location.state.param || null;

  const [credentials, setCredentials] = useState({
    username: username,
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({ email: "", password: "" });

  const usernameDivStyle = {
    backgroundColor: "#F0F0FF",
    border: "1px solid #6059F7",
    borderRadius: 4,
    padding: "24px 24px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    marginBottom: "40px",
  };

  // Email validation
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;

    // Validate email on blur
    if (name === "email") {
      const isValidEmail = validateEmail(value);
      setErrors({
        ...errors,
        email: isValidEmail ? "" : "Email is not in correct format",
      });
    }
  };

  // Continue to validate password on change
  const handlePasswordChange = (e) => {
    const { value } = e.target;
    const isValidPassword = validateStrongPassword(value);
    setErrors({
      ...errors,
      password: isValidPassword
        ? ""
        : "Password must be at least 6 characters and include numbers, letters, and uppercase letters.",
    });
  };

  const isFormValid =
    validateEmail(credentials.email) &&
    validateStrongPassword(credentials.password);

  // const usernameURL = username && ABNB_PREFIX_URL + username;

  const handleSubmit = () => {
    dispatch(userSignup({ ...credentials }));
  };

  const isUsername = credentials.username;

  return (
    <div>
      <Navigation type="public" />
      <div
        className="flex align-center justify-center flex-column"
        style={{ height: "calc(100vh - 40px)" }}
      >
        <div className="flex flex-column" style={{ width: "350px" }}>
          {isUsername && (
            //  style={usernameDivStyle}
            <div style={{ textAlign: "center ", marginBottom: "32px" }}>
              <Typography variant="h5">
                Welcome, {credentials.username}
              </Typography>
              <Typography variant="body2">
                Secure your exclusive username now – don't miss out!
              </Typography>
            </div>
          )}
          <TextField
            variant="outlined"
            label="Email"
            name="email"
            fullWidth
            value={credentials.email}
            alue={credentials.email}
            onChange={handleChange}
            onBlur={handleBlur} // Only validate email on blur
            error={!!errors.email}
            helperText={errors.email}
          />
          <div className="mt2">
            <TextField
              variant="outlined"
              name="password"
              label="Password"
              fullWidth
              value={credentials.password}
              type="password"
              onInput={handlePasswordChange} // Validate password on inpu
              onChange={handleChange}
              error={!!errors.password}
              helperText={errors.password}
            />
          </div>
        </div>
        <div className="mt3" style={{ width: 350 }}>
          <Typography
            variant="body2"
            style={{ fontSize: "12px", textAlign: "center" }}
          >
            By clicking on signing up, you are agreeing to{" "}
            <Link
              href="https://sites.google.com/view/automatebnb-terms/home"
              style={{ textDecoration: "underline" }}
              target="_blank"
            >
              Automatebnb Agreement
            </Link>{" "}
            and{" "}
            <Link
              href="https://sites.google.com/view/automatebnb-privacy/home"
              style={{ textDecoration: "underline" }}
              target="_blank"
            >
              Privacy Policy
            </Link>
            .
          </Typography>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disableElevation
            style={{ marginTop: 16 }}
            disabled={!isFormValid}
            onClick={handleSubmit}
          >
            Sign up
          </Button>
        </div>
      </div>
    </div>
  );
};
export default NewSignup;
