import React, { createContext, useState, useContext, useCallback } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core";

const LocalModalContext = createContext();

export const useLocalModal = () => useContext(LocalModalContext);

export const LocalModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [ModalComponent, setModalComponent] = useState(null);
  const [modalProps, setModalProps] = useState({});

  const showLocalModal = useCallback((Component, props) => {
    setModalComponent(() => Component);
    setModalProps(props);
    setIsOpen(true);
  }, []);

  const hideLocalModal = useCallback(() => {
    setIsOpen(false);
    setModalComponent(null);
  }, []);

  return (
    <LocalModalContext.Provider value={{ showLocalModal, hideLocalModal }}>
      {children}
      <Dialog open={isOpen} onClose={hideLocalModal}>
        {ModalComponent && <ModalComponent {...modalProps} />}
      </Dialog>
    </LocalModalContext.Provider>
  );
};
