import React from "react";

import LoopIcon from "@material-ui/icons/Loop";
import Tooltip from "@material-ui/core/Tooltip";

import "./sameDayFlip.scss";

const SameDayFlip = (props) => {
  const { tooltip = false, label = "Same day Flip", past = false } = props;

  if (tooltip) {
    return (
      <Tooltip title={label} placement="right">
        <div
          className={`job-flip job-flip--condenced ${past && "job-flip--past"}`}
        >
          <LoopIcon className="job-flip__icon" />
        </div>
      </Tooltip>
    );
  } else {
    return (
      <div className={`job-flip ${past && "job-flip--past"}`}>
        <LoopIcon className="job-flip__icon" />
        <span className="job-flip__label">{label}</span>
      </div>
    );
  }
};

export default SameDayFlip;
