import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Button, Typography, useMediaQuery } from "@material-ui/core";

import { useModal } from "../../ModalContext";
import { fetchSpaces, resetSpace } from "../../actions/staging";
import AddOrUpdateSpaceModal from "./AddOrUpdateSpaceModal";
import { s3UrlMapper } from "../../helpers/s3URLMapper";

import ManageSpace from "./manageSpace";
import PhotoCard from "./photoCard";
import { Analytics } from "../../services/analyticsService.tsx";

const SpacesSelector = ({
  propertyId,
  isOnboarding = false,
  newPropertyFlow = false,
}) => {
  const dispatch = useDispatch();
  const spacesState = useSelector((state) => state.spaces);

  const isLoading = spacesState.isLoading;
  const spacesArray = spacesState?.data || [];

  const isMobile = useMediaQuery("(max-width:600px)");

  const RenderSpaces = ({ spaces }) => {
    const SpaceCard = ({
      name,
      image_url,
      property_id,
      id,
      checklist_tasks,
      description,
    }) => {
      const { showModal, hideModal } = useModal();
      const handleSpaceClicked = () => {
        dispatch(resetSpace()); // Resets the state so fresh data is loaded, until then Loading kicks in

        showModal(ManageSpace, {
          propertyId: property_id,
          spaceId: id,
          fullScreen: true,
          hideModal,
          isOnboarding,
          newPropertyFlow,
        });
      };
      return (
        <div
          style={{
            textAlign: "center",
            width: isMobile ? "124px" : "176px",
            cursor: "pointer",
          }}
          onClick={() => handleSpaceClicked()}
        >
          <div style={{ marginBottom: 16 }}>
            <PhotoCard
              name={name}
              image_url={image_url}
              width={isMobile ? "120px" : "178px"}
              height={isMobile ? "79px" : "118px"}
            />
          </div>
          <Typography variant="subtitle2">{name}</Typography>
          {checklist_tasks.length === 0 ? (
            <Typography variant="caption" color="error">
              No tasks added
            </Typography>
          ) : (
            <div>
              <Typography variant="caption">
                {checklist_tasks.length} tasks
              </Typography>
            </div>
          )}
        </div>
      );
    };

    if (!isEmpty(spaces)) {
      return spaces.map((space, index) => <SpaceCard {...space} key={index} />);
    } else return null;
  };

  const RenerSpacePerdictions = ({ propertyId }) => {
    const { showModal, hideModal } = useModal();

    const handleAddSpace = (name = "") => {
      const isShortcutName = name ? `_${name.toLowerCase()}_` : null;
      if (newPropertyFlow) {
        if (isOnboarding) {
          Analytics.trackSimple(
            `onboarding${
              isShortcutName ? isShortcutName : "_add_"
            }space_action_from_staging_guide`
          );
        } else {
          Analytics.trackSimple(
            `new_property${
              isShortcutName ? isShortcutName : "_add_"
            }space_action_from_staging_guide`
          );
        }
      } else {
        Analytics.trackEvent(
          "add_space_action_from_property_details_staging_guide",
          { property_id: propertyId }
        );
      }
      showModal(AddOrUpdateSpaceModal, { name, propertyId, hideModal });
    };

    return (
      <div className="flex align-center">
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleAddSpace()}
        >
          Add space
        </Button>
        <div style={{ display: "block", width: "8px" }} />
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleAddSpace("Bedroom")}
        >
          Bedroom
        </Button>
        <div style={{ display: "block", width: "8px" }} />
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleAddSpace("Bathroom")}
        >
          Bathroom
        </Button>
      </div>
    );
  };

  useEffect(() => {
    dispatch(fetchSpaces(propertyId));
  }, []);

  if (!isLoading) {
    return (
      <div style={{ marginBottom: 48 }}>
        <Typography variant="h5">Spaces</Typography>
        {!isEmpty(spacesArray) && (
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              gap: "24px",
            }}
          >
            <RenderSpaces spaces={spacesArray} />
          </div>
        )}
        <div style={{ marginTop: 24 }}>
          <RenerSpacePerdictions propertyId={propertyId} />
        </div>
      </div>
    );
  } else return null;
};

export default SpacesSelector;
