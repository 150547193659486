import React, { useRef, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import ImageGallery from "react-image-gallery";

import "./cleaningImageViewer.scss";
import { isEmpty } from "lodash";
import { s3UrlMapper } from "../helpers/s3URLMapper";

const CleaningImageViewer = ({
  images = [],
  noImagesLabel = "No attachments",
}) => {
  const [open, setOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  function getFormattedDescription(object) {
    const { space_name, task_name } = object;
    if (space_name && task_name) {
      return (
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          {space_name}{" "}
          <ChevronRightIcon
            style={{ fontSize: 16, marginLeft: 4, marginRight: 4 }}
          />{" "}
          {task_name}{" "}
        </div>
      );
    } else {
      return "";
    }
  }

  function CleaningFullScreenImageViewer({ images, startIndex = 0 }) {
    return (
      <ImageGallery
        items={images.map((image) => ({
          original: s3UrlMapper({ url: image.image_url_original, size: "lg" }),
          description: getFormattedDescription(image),
        }))}
        startIndex={startIndex}
        fullscreen={true}
        showThumbnails={false}
        showPlayButton={false}
      />
    );
  }

  const ThumbnailCarousel = ({ images }) => {
    const containerRef = useRef(null);
    const [isBouncing, setIsBouncing] = useState(false);

    const handleScroll = (event) => {
      const { scrollLeft, scrollWidth, clientWidth } = event.target;
      const maxScrollLeft = scrollWidth - clientWidth;

      if (scrollLeft < 0 || scrollLeft > maxScrollLeft) {
        if (!isBouncing) {
          setIsBouncing(true);
          event.target.style.scrollBehavior = "smooth";
        }
      } else if (isBouncing) {
        setIsBouncing(false);
        event.target.style.scrollBehavior = "auto";
      }
    };

    const handleScrollEnd = () => {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
      const maxScrollLeft = scrollWidth - clientWidth;

      if (scrollLeft < 0) {
        containerRef.current.scrollLeft = 0;
      } else if (scrollLeft > maxScrollLeft) {
        containerRef.current.scrollLeft = maxScrollLeft;
      }
    };

    return (
      !isEmpty(images) && (
        <div
          ref={containerRef}
          onScroll={handleScroll}
          onTouchEnd={handleScrollEnd}
          onMouseUp={handleScrollEnd}
          className="cleaning-imgs-carousel"
        >
          {images.map((image, index) => (
            <div
              key={index}
              className="cleaning-imgs-carousel__image-container"
            >
              <img
                key={index}
                className="cleaning-imgs-carousel__image"
                src={s3UrlMapper({
                  url: image.image_url_original,
                  size: "thumb",
                })}
                alt={`${image.space_name} - ${image.task_name}`}
                onClick={() => openModal(index)}
              />
            </div>
          ))}
        </div>
      )
    );
  };

  const openModal = (image) => {
    setImageIndex(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Images is empty this should not load.
  if (isEmpty(images)) {
    return <p className="italic text-gray-500 text-sm">{noImagesLabel}</p>;
  } else {
    return (
      <>
        <ThumbnailCarousel images={images} />
        <Dialog fullScreen open={open} onClose={handleClose}>
          <div className="cleaning-img-dialog">
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              className="cleaning-img-dialog__close-icon"
            >
              <CloseIcon />
            </IconButton>
            <div className="cleaning-img-dialog__container">
              <CleaningFullScreenImageViewer
                images={images}
                startIndex={imageIndex}
              />
            </div>
          </div>
        </Dialog>
      </>
    );
  }
};

export default CleaningImageViewer;
