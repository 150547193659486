import React, { useEffect } from "react";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import { useMediaQuery } from "@material-ui/core";

import { OptimizelyFeature } from "@optimizely/react-sdk";

import Navigaton from "../containers/navigation";

import ImportCalendar from "./newProperty/importCalendar";
import NewPropertyDetails from "./newProperty/newPropertyDetails";
import NewJobAutomation from "./newProperty/newJobAutomation";
import StagingChecklist from "./stagingChecklist/index";

import { parseQuery } from "../helpers/url";

import "./newProperty.scss";
import { isEmpty } from "lodash";

function getSteps() {
  return [
    "Property details",
    "Import calendar",
    "Job automation",
    "Staging guide",
  ];
}

function getFeatureFlagSteps() {
  return ["Property details", "Import calendar", "Job automation"];
}

const NewProperty = (props) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [localPropertyId, setLocalPropertyId] = React.useState(null);

  const featureEnabledSteps = getSteps();
  const featureDisabledSteps = getFeatureFlagSteps();

  const { location = {} } = props;
  const { state = {} } = location;

  const isMobile = useMediaQuery("(max-width:600px)");

  const { opu } = parseQuery();

  const isOnboarding = opu === "onboarding";

  const styles = {
    body: {
      maxWidth: isMobile ? "500px" : "700px",
      margin: "0 auto",
      paddingTop: isMobile ? "24px" : "32px",
    },
    stepperBody: {
      maxWidth: isMobile ? "300px" : "500px",
      margin: "0 auto",
      marginTop: "32px",
    },
  };

  const handleNext = (id) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  useEffect(() => {
    // Route the user to proper step if state has value
    if (!isEmpty(state)) {
      if (state.nextStep === "calendar") {
        setActiveStep(1);
        setLocalPropertyId(state.id);
      } else if (state.nextStep === "automation") {
        setActiveStep(2);
        setLocalPropertyId(state.id);
      } else if (state.nextStep === "staging") {
        setActiveStep(3);
        setLocalPropertyId(state.id);
      } else {
        setActiveStep(0);
        setLocalPropertyId(state.id);
      }
    } else {
      setActiveStep(0);
    }
  }, [state]);

  const setPropertyId = (id) => {
    setLocalPropertyId(id);
  };

  const isStepOptional = () => {};
  const isStepSkipped = () => {};

  const StepsBody = ({ activeStep }) => {
    return (
      <div style={styles.stepperBody}>
        <div>
          <div>
            {activeStep === 0 ? (
              <>
                <NewPropertyDetails />
                <div className="full-width text-center price mt2 pb2">
                  <span style={{ fontSize: "16px" }}>
                    $5
                    <span
                      className="period"
                      style={{ fontSize: "14px", color: "#888888" }}
                    >
                      /property/month
                    </span>
                  </span>
                </div>
              </>
            ) : activeStep === 1 ? (
              <ImportCalendar propertyId={state.id} />
            ) : activeStep === 2 ? (
              <NewJobAutomation propertyId={state.id} styles={styles} />
            ) : activeStep === 3 ? (
              <StagingChecklist
                propertyId={state.id}
                isOnboarding={isOnboarding}
                newPropertyFlow
              />
            ) : (
              "Unknown step"
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Navigaton key="appbar-navigation-new-property-desktop" />
      <OptimizelyFeature feature="staging_guide">
        {(isStagingGuideVisible) =>
          isStagingGuideVisible ? (
            <div className="page-wrapper">
              <div className="page-content">
                <div style={styles.body}>
                  <Stepper
                    orientation={isMobile ? "vertical" : "horizontal"}
                    activeStep={activeStep}
                  >
                    {featureEnabledSteps.map((label, index) => {
                      const stepProps = {};
                      const labelProps = {};
                      if (isStepOptional(index)) {
                        labelProps.optional = <p>Optional</p>;
                      }
                      if (isStepSkipped(index)) {
                        stepProps.completed = false;
                      }

                      return (
                        <Step key={label} {...stepProps}>
                          <StepLabel {...labelProps}>{label}</StepLabel>
                          {isMobile && (
                            <StepContent>
                              <StepsBody activeStep={activeStep} />
                            </StepContent>
                          )}
                        </Step>
                      );
                    })}
                  </Stepper>
                  {!isMobile && <StepsBody activeStep={activeStep} />}
                </div>
              </div>
            </div>
          ) : (
            <div className="page-wrapper">
              <div className="page-content">
                <div style={styles.body}>
                  <Stepper
                    orientation={isMobile ? "vertical" : "horizontal"}
                    activeStep={activeStep}
                  >
                    {featureDisabledSteps.map((label, index) => {
                      const stepProps = {};
                      const labelProps = {};
                      if (isStepOptional(index)) {
                        labelProps.optional = <p>Optional</p>;
                      }
                      if (isStepSkipped(index)) {
                        stepProps.completed = false;
                      }

                      return (
                        <Step key={label} {...stepProps}>
                          <StepLabel {...labelProps}>{label}</StepLabel>
                          {isMobile && (
                            <StepContent>
                              <StepsBody activeStep={activeStep} />
                            </StepContent>
                          )}
                        </Step>
                      );
                    })}
                  </Stepper>
                  {!isMobile && <StepsBody activeStep={activeStep} />}
                </div>
              </div>
            </div>
          )
        }
      </OptimizelyFeature>
    </>
  );
};

export default NewProperty;
