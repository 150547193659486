import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import moment from "moment";

// Material Components
import DialogContent from "@material-ui/core/DialogContent";
import { TextField } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import DateFnsUtils from "@date-io/date-fns";
import MenuItem from "@material-ui/core/MenuItem";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

// Actions
import { updateJobDateTime } from "../actions/jobs";

const hours = [
  { value: "8:00", time: "8AM" },
  { value: "9:00", time: "9AM" },
  { value: "10:00", time: "10AM" },
  { value: "11:00", time: "11AM" },
  { value: "12:00", time: "12PM (noon)" },
  { value: "13:00", time: "1PM" },
  { value: "14:00", time: "2PM" },
  { value: "15:00", time: "3PM" },
  { value: "16:00", time: "4PM" },
  { value: "17:00", time: "5PM" },
  { value: "18:00", time: "6PM" },
  { value: "19:00", time: "7PM" },
  { value: "20:00", time: "8PM" },
  { value: "21:00", time: "9PM" },
  { value: "22:00", time: "10PM" },
  { value: "23:00", time: "11PM" },
  { value: "24:00", time: "12AM (midnight)" },
  { value: "1:00", time: "1AM (next day)" },
];

const UpdateJobDateTime = (props) => {
  const {
    jobDate,
    jobTime = "11:00",
    jobId,
    closeThisDialog,
    updateJobDateTime,
  } = props;

  const [date, setDate] = useState(moment(jobDate).format("MM/DD/YYYY"));
  const [time, setTime] = useState(jobTime);

  const handleInputChange = (event) => {
    setTime(event.target.value);
  };

  const handleSubmit = () => {
    let formattedDate = moment(date).format("YYYY-MM-DD");
    updateJobDateTime({ jobId, formattedDate, time });
  };

  return (
    <>
      <DialogTitle
        id="scroll-dialog-title"
        className="assign-housekeeper__title"
      >
        Change Job Date and/or time
      </DialogTitle>
      <DialogContent dividers={true} className="ab-dialog__body">
        <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              disablePast
              autoOk={true}
              required
              variant="inline"
              disableToolbar
              margin="normal"
              label="Job date"
              value={date}
              onChange={setDate}
              className="full-width mb2"
              id="date-picker-dialog"
              inputVariant="outlined"
              format="MM/dd/yyyy"
            />
          </MuiPickersUtilsProvider>
          <FormControl variant="outlined" className="full-width">
            <InputLabel htmlFor="checkIn">Job time</InputLabel>
            <Select
              value={time}
              variant="outlined"
              className="mr"
              required={true}
              onChange={handleInputChange}
              inputProps={{
                name: "time",
              }}
            >
              <MenuItem value="">
                <em>Choose job time</em>
              </MenuItem>
              {hours.map((hour) => (
                <MenuItem key={hour.value} value={hour.value}>
                  {hour.time}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeThisDialog} variant="contained" disableElevation>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disableElevation
        >
          Update
        </Button>
      </DialogActions>
    </>
  );
};
export default connect(
  (state) => ({}),
  (dispatch) =>
    bindActionCreators(
      {
        updateJobDateTime,
      },
      dispatch
    )
)(UpdateJobDateTime);
