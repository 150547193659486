import React from "react";
import { isEmpty } from "lodash";
import { Avatar, Button } from "@material-ui/core";

import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import { ProModel } from "../models";
import { useModal } from "../ModalContext.js";

import { getInitials } from "../helpers/format.js";

import { removeAPro } from "../actions/ops";
import { openThisDialog } from "../actions/dialog";
import { Analytics } from "../services/analyticsService.tsx";
import { getNormalizedStatus, getStatusColor } from "../helpers/jobStatuses.js";
import { useDispatch } from "react-redux";
import { DT_CHANGE_A_PRO } from "../constants/dialogTypes.js";
import {
  CLEANER_STATUS_COMPLETED,
  CLEANING_STARTED,
} from "../constants/apiEnum.js";

interface AssignmentModel {
  status: string;
  pro: ProModel;
}

interface JobAssignedProsProps {
  id: string;
  canAssignMultiplePros: boolean | undefined;
  pro: ProModel;
  is_assignment: boolean | null;
  assignments: AssignmentModel[];
  is_host: boolean;
  isMarketplace: boolean;
  isAccepted: boolean;
  status: string;
  is_past?: boolean;
  isDeclined: boolean;
  isCompleted?: boolean;
  isStarted?: boolean;
}

interface ProToShowProps {
  name: string | null;
  id: string | null;
  phone: string | null;
  initials: string | null;
  status: string | null;
}

const JobAssignedPros: React.FC<JobAssignedProsProps> = ({
  id,
  canAssignMultiplePros,
  pro,
  assignments,
  is_assignment,
  isMarketplace,
  isAccepted,
  isDeclined,
  is_past = false,
  is_host = false,
  isCompleted,
  isStarted,
  status,
}) => {
  const { showModal, hideModal } = useModal();
  const dispatch = useDispatch();

  const handleClick = () => {
    Analytics.trackEvent("assign_pro_action_from_job_details", {
      job_id: id,
    });
    dispatch(
      openThisDialog({
        dialogType: DT_CHANGE_A_PRO,
        jobId: id,
        multiple: canAssignMultiplePros,
      })
    );
  };

  // Marketplace jobs must be accepted before assigning to others.
  const isMarketplaceJobAccepted = isAccepted && isMarketplace;

  const CTALabel = canAssignMultiplePros
    ? "Assign pro"
    : is_host
    ? isEmpty(pro)
      ? "Assign pro"
      : "Change pro"
    : null;

  const prosToShow: ProToShowProps[] = [];

  if (is_host) {
    !isEmpty(pro) &&
      prosToShow.push({
        name: pro.name,
        initials: getInitials(pro.first, pro.last),
        id: pro.id,
        phone: pro?.phone,
        status: status,
      });
  } else {
    !isEmpty(assignments) &&
      assignments.map((assignment, index) => {
        const proName = `${assignment.pro.first} ${assignment.pro.last}`;
        prosToShow.push({
          name: proName,
          initials: getInitials(assignment.pro.first, assignment.pro.last),
          id: assignment.pro.id,
          phone: assignment.pro.phone,
          status: assignment.status,
        });
        return null;
      });
  }

  const PromptToConfirmBeforeRemove = (props) => {
    const { proRendered } = props;
    return (
      <div className="px-4 py-4">
        <h3 className="text-l font-medium">Are you sure?</h3>
        <p>A notification of job cancellation will be sent to your pro.</p>
        <div className="mt-2 flex flex-end">
          <Button onClick={() => hideModal()}>Cancel</Button>
          <Button
            disableElevation
            style={{ backgroundColor: "red", color: "white" }}
            variant="contained"
            onClick={() => {
              dispatch(removeAPro(id, proRendered.id));
              hideModal();
            }}
          >
            Remove
          </Button>
        </div>
      </div>
    );
  };

  const handleProRemoval = (proRendered) => {
    showModal(PromptToConfirmBeforeRemove, { proRendered });
  };

  const canAssign = [CLEANER_STATUS_COMPLETED, CLEANING_STARTED];
  const RenderCTALogic = () => {
    if (!is_past) {
      if (isDeclined) {
        return (
          <div className="mt-2">
            <Button variant="contained" disabled={true} disableElevation>
              Assign pro
            </Button>
            <p className="italic opacity-50 text-sm mt-1">
              To assign a pro, you must first Accept the job.
            </p>
          </div>
        );
      }

      if (!canAssign.includes(status)) {
        return (
          <div className="mt-2">
            <Button
              onClick={handleClick}
              variant="contained"
              disableElevation
              color="primary"
            >
              {CTALabel}
            </Button>
          </div>
        );
      } else return null;
    } else return null;
  };

  return (
    <div className="mb-4">
      <h3 className="text-md flex align-center">
        <PeopleAltIcon style={{ fontSize: 20, opacity: 0.8, marginRight: 4 }} />{" "}
        Assigned pros
      </h3>

      <div className="mt-2">
        <div>
          {!isEmpty(prosToShow)
            ? prosToShow.map((proToShow, index) => {
                return (
                  <div
                    key={`pro-to-show-${index}`}
                    className="flex space-between py-2"
                  >
                    <div className="flex">
                      <Avatar className="mr-2">{proToShow.initials}</Avatar>
                      <div>
                        <p style={{ margin: 0 }}>{proToShow.name}</p>
                        <small
                          className={`${getStatusColor(proToShow.status)}`}
                        >
                          {getNormalizedStatus(proToShow.status)}
                        </small>
                      </div>
                    </div>

                    {canAssignMultiplePros && !is_past && (
                      <Button onClick={() => handleProRemoval(proToShow)}>
                        Remove
                      </Button>
                    )}
                  </div>
                );
              })
            : is_past && (
                <p className="opacity-50 italic text-s">Not assigned</p>
              )}
        </div>

        <RenderCTALogic />
      </div>
    </div>
  );
};

export default JobAssignedPros;
