export const SET_PMS_CLEANING = "set_pms_cleaning";

export const getPMSCleaning = ({ pmsCleaning }) => pmsCleaning;

export const pmsCleaning = (state = {}, action) => {
  switch (action.type) {
    case SET_PMS_CLEANING:
      const { cleaning } = action;
      return {
        ...state,
        ...cleaning
      };

    default:
      return state;
  }
};
