export const validateStrongPassword = (password) => {
  const hasLowerCase = /[a-z]/;
  const hasUpperCase = /[A-Z]/;
  const hasNumber = /\d/;

  return (
    password.length >= 6 &&
    hasLowerCase.test(password) &&
    hasUpperCase.test(password) &&
    hasNumber.test(password)
  );
};
