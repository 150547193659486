import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import { Button, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { CurrencyTextField } from "../containers/accountFields";

import { addUpdateAdditionalServices } from "../actions/ops";

import Navigation from "../containers/navigation";
import { JOBS } from "../routes";

const servicesObject = {
  Cleaning: ["Deep cleaning", "Pool cleaning"],
  "White glove services": [
    "Linen rental",
    "Guest supplies",
    "Laundry service (offsite)",
    "Package delivery",
    "Provide guest supplies",
  ],
  Repair: ["Furniture assembly", "Handyperson (repairs)"],
};

const CleaningServiceScreen = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  // Initialize services as a Set
  const [services, setServices] = useState(new Set());
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (!isEmpty(user) && !isEmpty(user.specialty_services)) {
      // Convert array to Set for easy management
      setServices(new Set(user.specialty_services));
    }
  }, [user]);

  const isChecked = (service) => services.has(service);

  const handleChange = (event) => {
    const service = event.target.name;
    setServices((prevServices) => {
      const updatedServices = new Set(prevServices);
      if (updatedServices.has(service)) {
        updatedServices.delete(service);
      } else {
        updatedServices.add(service);
      }
      return updatedServices;
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (publish) => {
    const specialty_services = Array.from(services).join(", ");
    const maidId = user.id;
    if ((specialty_services || specialty_services === "") && maidId) {
      callAPIToUpdateServices({
        maidId,
        specialty_services,
        visible_in_marketplace: publish,
        first_time_activation: true,
      });
    }
  };

  const callAPIToUpdateServices = (props) => {
    dispatch(addUpdateAdditionalServices(props));
  };

  const handleSkipGoToJobs = () => {
    window.location.replace(JOBS);
  };

  const handleSkip = () => {
    if (user.specialty_services !== services) {
      setOpen(true);
    } else {
      window.location.replace(JOBS);
    }
  };

  return (
    <>
      <Navigation type="onboarding" />
      <div
        className="flex align-center justify-center flex-column"
        style={{ marginTop: 24 }}
      >
        <div
          className="flex flex-column"
          style={{ maxWidth: "350px", width: "100%", minWidth: "260px" }}
        >
          <div className="mb3 text-center">
            <Typography variant="h6">
              Highlight your additional services
            </Typography>
            <Typography variant="body2" style={{ marginTop: 4 }}>
              Choose all the extra services your business provides. These will
              be showcased on your profile, allowing clients to directly ask you
              for pricing and details. Feel free to select all the services that
              are relevant to you. This is great way to upsell and increase your
              revenue!
            </Typography>
          </div>
          {Object.entries(servicesObject).map(([group, serviceList]) => (
            <div key={group} className="mb3">
              <h3>{group}</h3>
              <FormGroup>
                {serviceList.map((service) => (
                  <FormControlLabel
                    key={service}
                    control={
                      <Checkbox
                        name={service}
                        onChange={handleChange}
                        checked={isChecked(service)}
                        color="primary"
                      />
                    }
                    label={service}
                  />
                ))}
              </FormGroup>
            </div>
          ))}
          <div className="mt2">
            <Button
              variant="contained"
              color="primary"
              disableElevation
              fullWidth
              onClick={() => handleSubmit(true)}
            >
              Publish
            </Button>

            {/* <Button
              variant="text"
              style={{ marginTop: 16 }}
              color="primary"
              disableElevation
              fullWidth
              onClick={() => handleSkip()}
            >
              Skip, I&apos;m not ready
            </Button> */}
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Save changes</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Would you like to save your changes to the additional cleaning
            services? Remember, you can publish your profile anytime by
            navigating to 'Manage Account'.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSkipGoToJobs} color="primary">
            No, go to Jobs
          </Button>
          <Button
            onClick={() => handleSubmit(false)}
            variant="contained"
            color="primary"
            autoFocus
            disableElevation
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CleaningServiceScreen;
