import React from "react";

import RatingAwfulActive from "../images/ratings/rating_awful_active.svg";
import RatingAwfulDisabled from "../images/ratings/rating_awful_disabled.svg";

import RatingBadActive from "../images/ratings/rating_bad_active.svg";
import RatingBadDisabled from "../images/ratings/rating_bad_disabled.svg";

import RatingGoodActive from "../images/ratings/rating_good_active.svg";
import RatingGoodDisabled from "../images/ratings/rating_good_disabled.svg";

import RatingGreatActive from "../images/ratings/rating_great_active.svg";
import RatingGreatDisabled from "../images/ratings/rating_great_disabled.svg";

import RatingOkayActive from "../images/ratings/rating_okay_active.svg";
import RatingOkayDisabled from "../images/ratings/rating_okay_disabled.svg";

const ratings = [
  {
    type: 1,
    label: "Awful",
    active: RatingAwfulActive,
    disabled: RatingAwfulDisabled,
  },
  {
    type: 2,
    label: "Bad",
    active: RatingBadActive,
    disabled: RatingBadDisabled,
  },

  {
    type: 3,
    label: "Okay",
    active: RatingOkayActive,
    disabled: RatingOkayDisabled,
  },
  {
    type: 4,
    label: "Good",
    active: RatingGoodActive,
    disabled: RatingGoodDisabled,
  },
  {
    type: 5,
    label: "Great",
    active: RatingGreatActive,
    disabled: RatingGreatDisabled,
  },
];

const GuestRating = ({ rate }) => {
  return (
    <div style={{ display: "inline-flex", alignItems: "center" }}>
      <div style={{ display: "inline-flex", alignItems: "center" }}>
        {ratings.map((rating, index) => (
          <img
            key={index}
            src={rating.type === rate ? rating.active : rating.disabled}
            alt={`${rating.label} rating icon`}
            width={32}
            height={32}
            style={{ marginRight: "4px" }}
          />
        ))}
      </div>
      <div style={{ marginLeft: 16 }}>
        {ratings.find((rating) => rating.type === rate)?.label}
      </div>
    </div>
  );
};

export default GuestRating;
