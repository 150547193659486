import {
  SET_MY_PROS,
  RESET_MY_PROS,
  SET_PROS_CATEGORIES,
} from "../actions/pros";

export const getMyPros = ({ myPros }) => myPros;
export const getProsCategories = ({ categories }) => categories;

export const myPros = (state = { page: { total: 0 }, items: [] }, action) => {
  const { data } = action;
  switch (action.type) {
    case RESET_MY_PROS:
      return { page: { total: 0 }, items: [], isLoading: true };
    case SET_MY_PROS:
      return {
        ...state,
        items: [...state.items, ...data.items],
        page: {
          total: data.total,
          currentPage: data.current_page,
          nextPage: data.next_page,
          prevPage: data.previous_page,
          previousPage: data.previous_page,
        },
        isLoading: false,
      };

    default:
      return state;
  }
};

export const categories = (state = {}, action) => {
  const { data } = action;
  switch (action.type) {
    case SET_PROS_CATEGORIES:
      return data;

    default:
      return state;
  }
};
