import parse from "url-parse";

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export function calendarURL(url) {
  let parsedURL = parse(url);

  return (
    parsedURL.pathname.includes("ics") ||
    parsedURL.pathname.includes("ical") ||
    parsedURL.pathname.includes("icalendar") ||
    parsedURL.hostname.includes("ical")
  );

}

export function channelValidate(url) {
  const parsedURL = parse(url);

  const host = parsedURL.host;

  if (host) {
    if (host.includes("vrbo")) {
      return true;
    } else if (host.includes("airbnb")) {
      return true;
    } else if (host.includes("tripadvisor")) {
      return true;
    } else if (host.includes("homeaway")) {
      return true;
    } else if (host.includes("hostaway")) {
      return true;
    } else return false;
  }
}
