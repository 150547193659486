import React from "react";
import CreditCardIcon from "@material-ui/icons/CreditCard";

import { useModal } from "../ModalContext";
import { Button } from "@material-ui/core";
import { PaymentModel } from "../models";

interface AddOns {}

interface PayoutProps {
  total_fee?: number;
  additional_fee?: number;
  job_fee?: number;
  is_payment_request_available?: boolean;
  marketplace_fee?: number;
  isStarted?: boolean;
  addon_fees?: AddOns[];
  payment: PaymentModel;
  total_payout_minus_fees?: string | undefined;
  cleaning_fee_note: string;
  isMarketplace: boolean;
  is_host: boolean;
}

const formatCurrency = (
  value: number,
  locale: string = "en-US",
  currency: string = "USD"
): string => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(value);
};

const JobPayout: React.FC<PayoutProps> = ({
  total_fee = 0,
  additional_fee = 0,
  is_payment_request_available = false,
  job_fee = 0,
  marketplace_fee = 0,
  isStarted = false,
  is_host,
  addon_fees,
  payment,
  total_payout_minus_fees,
  cleaning_fee_note,
  isMarketplace = false,
}) => {
  const { showModal, hideModal } = useModal();

  const linkStyles = isStarted ? "text-white" : "text-blue-500";

  const PriceBreakDown = () => {
    // marketplace_fee
    // additional_fee
    // total_fee
    // addon_fees
    // const stripeFee = payment?.stripe_fee;

    const RenderHostBreakdown = () => {};
    const RenderServiceProviderBreakdown = () => {};

    return (
      <div>
        <h3 className="text-lg font-semibold">Payment breakdown</h3>
        <div></div>
        <div>
          <Button onClick={hideModal} variant="outlined" color="primary">
            Close
          </Button>
        </div>
      </div>
    );
  };

  return (
    total_payout_minus_fees && (
      <div className="flex flex-col">
        <div className="flex justify-between items-center">
          <div>
            <h2 className="text-xl">
              <CreditCardIcon style={{ fontSize: 20, opacity: 0.8 }} /> Total{" "}
              {is_host ? "payment" : "payout"}
            </h2>
            <span className="text-sm italic text-gray-500">
              Any fees are already deducted.
            </span>
          </div>
          <h2 className="text-xl font-semibold">
            {is_host
              ? formatCurrency(total_fee)
              : formatCurrency(total_payout_minus_fees)}
          </h2>
        </div>
      </div>
    )
  );
};

export default JobPayout;
