import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

import {
  deletePropertyCalendar,
  fetchProperty,
} from "../../actions/pmsProperties";
import { showSnackbar } from "../../actions/action_snackbar";

function DeleteCalendarConfirm(props) {
  const {
    deletePropertyCalendar,
    fetchProperty,
    closeThisDialog,
    url = null,
    propertyId = null,
  } = props;
  const dispatch = useDispatch();

  const handleCalendarDelete = () => {
    deletePropertyCalendar(url).then((response) => {
      if (response.status === 200) {
        fetchProperty(propertyId);
        closeThisDialog();
      }
    });
  };

  return (
    <>
      <DialogTitle
        id="scroll-dialog-title"
        className="pms-dialog__title assign-housekeeper__title"
      >
        Delete calendar?
      </DialogTitle>
      <DialogContent dividers={true} style={{ position: "relative" }}>
        <p style={{ marginBottom: "4px" }}>
          <span className="bold">Heads up!</span> All the Jobs associated with
          this calendar from today onward will be deleted.
        </p>
        <br />
        <p>This action cannot be undone!</p>
        <br />
        <p>Are you sure you want to delete this calendar?</p>
      </DialogContent>
      <DialogActions>
        <div className="inline-flex justify-end full-width">
          <div className="pms-dialog__actions">
            <Button
              onClick={closeThisDialog}
              variant="contained"
              disableElevation
              className="button"
              style={{ marginRight: "8px" }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleCalendarDelete}
              variant="contained"
              disableElevation
              style={{ backgroundColor: "red", color: "white" }}
            >
              Delete
            </Button>
          </div>
        </div>
      </DialogActions>
    </>
  );
}

export default connect(
  (state) => ({}),
  (dispatch) =>
    bindActionCreators(
      { showSnackbar, deletePropertyCalendar, fetchProperty },
      dispatch
    )
)(DeleteCalendarConfirm);
