import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import history from "../../helpers/history";

import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Checkbox from "@material-ui/core/Checkbox";

import { filterParams } from "../../helpers/url";

import { getPMSMyHousekeepers } from "../../reducers/pmsMyHousekeepers";
import { fetchHostJobs } from "../../actions/jobs";

import { fetchMyPros } from "../../actions/pros";

import { getLoading } from "../../reducers/loading";
import { CircularProgress } from "@material-ui/core";
import { getMyPros } from "../../reducers/pros";

const FilterByProperties = (props) => {
  const {
    fetchMyPros,
    myPros,
    housekeepers,
    fetchHostJobs,
    closeDialog,
    isLoading,
    housekeepersSelected,
  } = props;

  const [selected, setSelected] = useState([]);
  const [isDisabled, setDisabled] = useState(false);

  let _location = null;

  useEffect(() => {
    if (isEmpty(myPros)) {
      fetchMyPros();
    }

    if (housekeepersSelected) {
      setSelected(housekeepersSelected);
    }
  }, []);

  const handleClose = () => {
    closeDialog();
  };

  const handleSearchResults = (type) => {
    _location = filterParams({ maidIdsParam: selected });

    if (_location) {
      history.push({
        search: _location,
      });

      fetchHostJobs();
      handleClose();
    }
  };

  const handleFilterClear = () => {
    setSelected([]);
  };

  const handleRowSelect = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return isLoading ? (
    <div className="text-center">
      <CircularProgress />
    </div>
  ) : (
    <>
      <DialogTitle
        id="scroll-dialog-title"
        className="assign-housekeeper__title"
      >
        <div className="inline-flex full-width space-between align-center pr pl">
          <CloseIcon className="cursor-pointer" onClick={() => handleClose()} />
          <span>Filter by Pros</span>
          <div />
        </div>
      </DialogTitle>
      <DialogContent>
        {isEmpty(myPros) ? (
          "No Pros available to show"
        ) : (
          <TableContainer className="table--max-height-64">
            <Table className="" aria-label="simple table">
              <TableBody>
                {myPros.map((pro, index) => {
                  const isItemSelected = isSelected(pro.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      key={pro.id}
                      onClick={(event) => handleRowSelect(event, pro.id)}
                      role="checkbox"
                      className="cursor-pointer"
                      aria-checked={isItemSelected}
                    >
                      <TableCell component="th" scope="row" padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          color="primary"
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <div>
                          <p>{`${pro.first} ${pro.last}`}</p>
                          <small className="small grey">
                            {pro.phone ? pro.phone : ""}
                          </small>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions>
        <div className="full-width inline-flex align-center space-between">
          <Button onClick={() => handleFilterClear()} disableElevation>
            Clear filters
          </Button>
          <Button
            onClick={() => handleSearchResults()}
            variant="contained"
            color="primary"
            disableElevation
            disabled={isDisabled}
          >
            Apply filters
          </Button>
        </div>
      </DialogActions>
    </>
  );
};

export default connect(
  (state) => ({
    housekeepers: getPMSMyHousekeepers(state),
    isLoading: getLoading(state),
    myPros: getMyPros(state).items,
  }),
  (dispatch) => bindActionCreators({ fetchMyPros, fetchHostJobs }, dispatch)
)(FilterByProperties);
