import {
  SET_ONBOARDING_ANALYTICS,
  RESET_ONBOARDING_ANALYTICS,
} from "../actions/misc";

export const getOnboardingAnalytics = ({ onboardingAnalytics }) =>
  onboardingAnalytics;

export const onboardingAnalytics = (state = {}, action) => {
  switch (action.type) {
    case SET_ONBOARDING_ANALYTICS:
      return { ...state, ...action.data };

    case RESET_ONBOARDING_ANALYTICS:
      return {};

    default:
      return state;
  }
};
