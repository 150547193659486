export const formatMoney = (amount) => {
  const decimalCount = 2;
  const decimal = ".";
  const thousands = ",";

  const dollarSign = "$";

  let i = parseInt(
    (amount = Math.abs(Number(amount) || 0).toFixed(2))
  ).toString();
  let j = i.length > 3 ? i.length % 3 : 0;

  return (
    dollarSign +
    (j ? i.substr(0, j) + thousands : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
    (decimalCount
      ? decimal +
        Math.abs(amount - i)
          .toFixed(decimalCount)
          .slice(2)
      : "")
  );
};

const propertyTime = {
  "8:00": "8:00 AM",
  "9:00": "9:00 AM",
  "10:00": "10:00 AM",
  "11:00": "11:00 AM",
  "12:00": "12:00 PM (noon)",
  "13:00": "1:00 PM",
  "14:00": "2:00 PM",
  "15:00": "3:00 PM",
  "16:00": "4:00 PM",
  "17:00": "5:00 PM",
  "18:00": "6:00 PM",
  "19:00": "7:00 PM",
  "20:00": "8:00 PM",
  "21:00": "9:00 PM",
  "22:00": "10:00 PM",
  "23:00": "11:00 PM",
  "24:00": "12:00 PM (midnight)",
  "1:00": "1:00 AM (next day)",
};

export function getFormattedCheckInOutTime(time) {
  if (propertyTime[time] === undefined) {
    return "N/A";
  } else {
    return propertyTime[time];
  }
}

export const stripPhoneNumberFormatting = (phone) => {
  return phone.replace(/[^\d]/g, "");
};

export const getCommaSeparatedNumber = (value) => {
  if (value === undefined || value === null) {
    return "";
  }
  value = value.toString();
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function getInitials(first, last) {
  const firstInitial = first ? Array.from(`${first}`)[0] : "";
  const lastInitial = last ? Array.from(`${last}`)[0] : "";
  return `${firstInitial}${lastInitial}`;
}

export const getInitialsFromFullName = (fullName) => {
  const nameParts = fullName.trim().split(" ");

  if (nameParts.length === 1) {
    return getInitials(nameParts[0], "");
  }

  return getInitials(nameParts[0], nameParts[nameParts.length - 1]);
};
