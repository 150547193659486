import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import {
  parseQuery,
  sanitizeQueryParams,
  filterParams,
} from "../../helpers/url";

import {
  PAYMENT_STATUS_PENDING,
  PAYMENT_STATUS_SUCCESS,
  PAYMENT_STATUS_PROCESSING,
  PAYMENT_STATUS_FAILED,
} from "../../constants/apiEnum";

import history from "../../helpers/history";

import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";

import { fetchHostJobs } from "../../actions/jobs";

const AllFiltersDialog = (props) => {
  const { closeDialog } = props;

  const [channelsSelected, setChannels] = useState([]);
  const [cleaningStatusSelected, setCleaningStatus] = useState([]);
  const [noteSelected, setNote] = useState(false);
  const [paymentStatusSelected, setPaymentStatus] = useState([]);

  const {
    channels,
    note,
    type,
    cleaning_status,
    payment_statuses,
  } = parseQuery();

  const channelsToArray = sanitizeQueryParams(channels, true);
  const cleaningStatusToArray = sanitizeQueryParams(cleaning_status, true);
  const paymentStatusToArray = sanitizeQueryParams(payment_statuses, true);

  const dispatch = useDispatch();

  let _location = null;

  useEffect(() => {
    if (channelsToArray) {
      setChannels(channelsToArray);
    }

    if (cleaningStatusToArray) {
      setCleaningStatus(cleaningStatusToArray);
    }

    if (paymentStatusToArray) {
      setPaymentStatus(paymentStatusToArray);
    }

    if (note === "true") {
      setNote(true);
    }
  }, []);

  const handleFilterClear = () => {
    setChannels([]);
    setCleaningStatus([]);
    setNote(false);
    setPaymentStatus([]);
  };

  const handleClose = () => {
    closeDialog();
  };

  const handleSearchResults = (type) => {
    _location = filterParams({
      noteParam: noteSelected ? "true" : "false",
      channelsParam: channelsSelected,
      cleaningStatus: cleaningStatusSelected,
      paymentStatuses: paymentStatusSelected,
    });

    if (_location) {
      history.push({
        search: _location,
      });

      dispatch(fetchHostJobs());
      handleClose();
    }
  };

  const handleNoteRowSelect = () => {
    setNote(!noteSelected);
  };

  const handleCleaningStatusSelection = (event, name) => {
    const selectedIndex = cleaningStatusSelected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(cleaningStatusSelected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(cleaningStatusSelected.slice(1));
    } else if (selectedIndex === cleaningStatusSelected.length - 1) {
      newSelected = newSelected.concat(cleaningStatusSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        cleaningStatusSelected.slice(0, selectedIndex),
        cleaningStatusSelected.slice(selectedIndex + 1)
      );
    }

    setCleaningStatus(newSelected);
  };

  const handleChannelRowSelect = (event, name) => {
    const selectedIndex = channelsSelected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(channelsSelected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(channelsSelected.slice(1));
    } else if (selectedIndex === channelsSelected.length - 1) {
      newSelected = newSelected.concat(channelsSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        channelsSelected.slice(0, selectedIndex),
        channelsSelected.slice(selectedIndex + 1)
      );
    }

    setChannels(newSelected);
  };

  const handlePaymentStatusSelected = (event, name) => {
    const selectedIndex = paymentStatusSelected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(paymentStatusSelected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(paymentStatusSelected.slice(1));
    } else if (selectedIndex === paymentStatusSelected.length - 1) {
      newSelected = newSelected.concat(paymentStatusSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        paymentStatusSelected.slice(0, selectedIndex),
        paymentStatusSelected.slice(selectedIndex + 1)
      );
    }

    setPaymentStatus(newSelected);
  };

  const isChannelSelected = (name) => channelsSelected.indexOf(name) !== -1;
  const isPaymentStatusSelected = (name) =>
    paymentStatusSelected.indexOf(name) != -1;
  const isCleaningStatusSelected = (name) =>
    cleaningStatusSelected.indexOf(name) !== -1;
  const maxHeight = window.innerHeight / 2 > 260 ? window.innerHeight / 2 : 260;

  return (
    <>
      <DialogTitle
        id="scroll-dialog-title"
        className="assign-housekeeper__title"
      >
        <div className="inline-flex full-width space-between align-center pr pl">
          <CloseIcon className="cursor-pointer" onClick={() => handleClose()} />
          <span></span>
          <div />
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div className="scroll-dialog" style={{ height: maxHeight }}>
          {type === "past" && [
            <h4 key="payment-status-title" className="header-4 mb1">
              Payment status
            </h4>,
            <TableContainer
              className="table--max-height-64"
              key="payment-status-tabel"
            >
              <Table className="" aria-label="simple table">
                <TableBody>
                  <TableRow
                    key={PAYMENT_STATUS_PENDING}
                    onClick={(event) =>
                      handlePaymentStatusSelected(event, PAYMENT_STATUS_PENDING)
                    }
                    role="checkbox"
                    className="cursor-pointer"
                    aria-checked={isPaymentStatusSelected(
                      PAYMENT_STATUS_PENDING
                    )}
                  >
                    <TableCell component="th" scope="row" padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isPaymentStatusSelected(
                          PAYMENT_STATUS_PENDING
                        )}
                        inputProps={{
                          "aria-labelledby": `enhanced-table-checkbox-${PAYMENT_STATUS_PENDING}`,
                        }}
                      />
                    </TableCell>
                    <TableCell align="left">Pending</TableCell>
                  </TableRow>
                  <TableRow
                    key={PAYMENT_STATUS_SUCCESS}
                    onClick={(event) =>
                      handlePaymentStatusSelected(event, PAYMENT_STATUS_SUCCESS)
                    }
                    role="checkbox"
                    className="cursor-pointer"
                    aria-checked={isPaymentStatusSelected(
                      PAYMENT_STATUS_SUCCESS
                    )}
                  >
                    <TableCell component="th" scope="row" padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isPaymentStatusSelected(
                          PAYMENT_STATUS_SUCCESS
                        )}
                        inputProps={{
                          "aria-labelledby": `enhanced-table-checkbox-${PAYMENT_STATUS_SUCCESS}`,
                        }}
                      />
                    </TableCell>
                    <TableCell align="left">Paid</TableCell>
                  </TableRow>
                  <TableRow
                    key={PAYMENT_STATUS_PROCESSING}
                    onClick={(event) =>
                      handlePaymentStatusSelected(
                        event,
                        PAYMENT_STATUS_PROCESSING
                      )
                    }
                    role="checkbox"
                    className="cursor-pointer"
                    aria-checked={isPaymentStatusSelected(
                      PAYMENT_STATUS_PROCESSING
                    )}
                  >
                    <TableCell component="th" scope="row" padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isPaymentStatusSelected(
                          PAYMENT_STATUS_PROCESSING
                        )}
                        inputProps={{
                          "aria-labelledby": `enhanced-table-checkbox-${PAYMENT_STATUS_PROCESSING}`,
                        }}
                      />
                    </TableCell>
                    <TableCell align="left">Processing</TableCell>
                  </TableRow>
                  <TableRow
                    key={PAYMENT_STATUS_FAILED}
                    onClick={(event) =>
                      handlePaymentStatusSelected(event, PAYMENT_STATUS_FAILED)
                    }
                    role="checkbox"
                    className="cursor-pointer"
                    aria-checked={isPaymentStatusSelected(
                      PAYMENT_STATUS_FAILED
                    )}
                  >
                    <TableCell component="th" scope="row" padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isPaymentStatusSelected(PAYMENT_STATUS_FAILED)}
                        inputProps={{
                          "aria-labelledby": `enhanced-table-checkbox-${PAYMENT_STATUS_FAILED}`,
                        }}
                      />
                    </TableCell>
                    <TableCell align="left">Failed</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>,
            <div key="payment-status-space" className="mt4" />,
          ]}
          <h4 className="header-4">Job status</h4>
          <TableContainer className="table--max-height-64">
            <Table className="" aria-label="simple table">
              <TableBody>
                <TableRow
                  key="ACCEPTED"
                  onClick={(event) =>
                    handleCleaningStatusSelection(event, "ACCEPTED")
                  }
                  role="checkbox"
                  className="cursor-pointer"
                  aria-checked={isCleaningStatusSelected("ACCEPTED")}
                >
                  <TableCell component="th" scope="row" padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isCleaningStatusSelected("ACCEPTED")}
                      inputProps={{
                        "aria-labelledby": "enhanced-table-checkbox-ACCEPTED",
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">Accepted</TableCell>
                </TableRow>
                <TableRow
                  key="DECLINED"
                  onClick={(event) =>
                    handleCleaningStatusSelection(event, "DECLINED")
                  }
                  role="checkbox"
                  className="cursor-pointer"
                  aria-checked={isCleaningStatusSelected("DECLINED")}
                >
                  <TableCell component="th" scope="row" padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isCleaningStatusSelected("DECLINED")}
                      inputProps={{
                        "aria-labelledby": "enhanced-table-checkbox-DECLINED",
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">Declined</TableCell>
                </TableRow>
                <TableRow
                  key="cs-CLEANING-DATE-CHANGED"
                  onClick={(event) =>
                    handleCleaningStatusSelection(
                      event,
                      "CLEANING DATE CHANGED"
                    )
                  }
                  role="checkbox"
                  className="cursor-pointer"
                  aria-checked={isCleaningStatusSelected(
                    "CLEANING DATE CHANGED"
                  )}
                >
                  <TableCell component="th" scope="row" padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isCleaningStatusSelected(
                        "CLEANING DATE CHANGED"
                      )}
                      inputProps={{
                        "aria-labelledby":
                          "enhanced-table-checkbox-CLEANING-DATE-CHANGED",
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">Job date changed</TableCell>
                </TableRow>
                <TableRow
                  key="WAITING-TO-ACCEPT"
                  onClick={(event) =>
                    handleCleaningStatusSelection(event, "WAITING TO ACCEPT")
                  }
                  role="checkbox"
                  className="cursor-pointer"
                  aria-checked={isCleaningStatusSelected("WAITING TO ACCEPT")}
                >
                  <TableCell component="th" scope="row" padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isCleaningStatusSelected("WAITING TO ACCEPT")}
                      inputProps={{
                        "aria-labelledby":
                          "enhanced-table-checkbox-WAITING-TO-ACCEPT",
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">Waiting to accept</TableCell>
                </TableRow>
                {/* Only show completed cleaning filter in Past Jobs */}
                {type === "past" && (
                  <TableRow
                    key="COMPLETED"
                    onClick={(event) =>
                      handleCleaningStatusSelection(event, "COMPLETED")
                    }
                    role="checkbox"
                    className="cursor-pointer"
                    aria-checked={isCleaningStatusSelected("COMPLETED")}
                  >
                    <TableCell component="th" scope="row" padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isCleaningStatusSelected("COMPLETED")}
                        inputProps={{
                          "aria-labelledby":
                            "enhanced-table-checkbox-COMPLETED",
                        }}
                      />
                    </TableCell>
                    <TableCell align="left">Completed</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div key="cleaning-status-space" className="mt4" />
          <h4 className="header-4 mb1">Channels</h4>
          <TableContainer className="table--max-height-64">
            <Table className="" aria-label="simple table">
              <TableBody>
                <TableRow
                  key="airbnb"
                  onClick={(event) => handleChannelRowSelect(event, "airbnb")}
                  role="checkbox"
                  className="cursor-pointer"
                  aria-checked={isChannelSelected("airbnb")}
                >
                  <TableCell component="th" scope="row" padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isChannelSelected("airbnb")}
                      inputProps={{
                        "aria-labelledby": "enhanced-table-checkbox-airbnb",
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">Airbnb</TableCell>
                </TableRow>
                <TableRow
                  key="VRBO"
                  onClick={(event) => handleChannelRowSelect(event, "VRBO")}
                  role="checkbox"
                  className="cursor-pointer"
                  aria-checked={isChannelSelected("VRBO")}
                >
                  <TableCell component="th" scope="row" padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isChannelSelected("VRBO")}
                      inputProps={{
                        "aria-labelledby": "enhanced-table-checkbox-airbnb",
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">Vrbo</TableCell>
                </TableRow>
                <TableRow
                  key="homeaway"
                  onClick={(event) => handleChannelRowSelect(event, "homeaway")}
                  role="checkbox"
                  className="cursor-pointer"
                  aria-checked={isChannelSelected("homeaway")}
                >
                  <TableCell component="th" scope="row" padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isChannelSelected("homeaway")}
                      inputProps={{
                        "aria-labelledby": "enhanced-table-checkbox-airbnb",
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">HomeAway</TableCell>
                </TableRow>
                <TableRow
                  key="tripadvisor"
                  onClick={(event) =>
                    handleChannelRowSelect(event, "tripadvisor")
                  }
                  role="checkbox"
                  className="cursor-pointer"
                  aria-checked={isChannelSelected("tripadvisor")}
                >
                  <TableCell component="th" scope="row" padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isChannelSelected("tripadvisor")}
                      inputProps={{
                        "aria-labelledby": "enhanced-table-checkbox-airbnb",
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">TripAdvisor</TableCell>
                </TableRow>
                <TableRow
                  key="job_request"
                  onClick={(event) =>
                    handleChannelRowSelect(event, "job_request")
                  }
                  role="checkbox"
                  className="cursor-pointer"
                  aria-checked={isChannelSelected("job_request")}
                >
                  <TableCell component="th" scope="row" padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isChannelSelected("job_request")}
                      inputProps={{
                        "aria-labelledby": "enhanced-table-checkbox-airbnb",
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">Job request</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <h4 className="header-4 mt4 mb1">Note</h4>
          <div className="full-width inline-flex align-center space-between mb3">
            <p>Show job with description added.</p>

            <Switch
              checked={noteSelected}
              onChange={handleNoteRowSelect}
              value="note"
              color="primary"
              inputProps={{ "aria-label": "note" }}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="full-width inline-flex align-center space-between">
          <Button onClick={() => handleFilterClear()} disableElevation>
            Clear filters
          </Button>
          <Button
            onClick={() => handleSearchResults()}
            variant="contained"
            color="primary"
            disableElevation
          >
            Apply filters
          </Button>
        </div>
      </DialogActions>
    </>
  );
};

export default AllFiltersDialog;
