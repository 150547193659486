import axios from "../helpers/axios";
import { serverError } from "../helpers/helper_serverError";

export const RESET_STAGING_SPACES = "reset_staging_spaces";
export const SET_STAGING_SPACES = "set_staging_spaces";

export const RESET_STAGING_SPACE = "reset_staging_space";
export const SET_STAGING_SPACE = "set_staging_space";

export const RESET_SPACE_TASKS = "reset_space_tasks";
export const SET_SPACE_TASKS = "set_space_tasks";

export function fetchSpaces(propertyId) {
  return (dispatch) => {
    return axios
      .get(`/v1/properties/${propertyId}/get_spaces?is_stage=false`)
      .then((res) => {
        if (res && res.status === 200) {
          dispatch(setSpaces(res.data));
        }
      })
      .catch((err) => {
        dispatch(serverError(err));
      });
  };
}
export function fetchSpace({ propertyId, spaceId }) {
  return (dispatch) => {
    return axios
      .get(`/v1/properties/${propertyId}/get_space?space_id=${spaceId}`)
      .then((res) => {
        if (res && res.status === 200) {
          dispatch(setSpace(res.data));
        }
      })
      .catch((err) => {
        dispatch(serverError(err));
      });
  };
}

export function addOrUpdateSpace({ propertyId, formData }) {
  return (dispatch) => {
    return axios
      .put(`/v1/properties/${propertyId}/create_or_update_space`, formData)
      .catch((err) => {
        dispatch(serverError(err));
      });
  };
}

export function deleteSpace({ propertyId, spaceId }) {
  return (dispatch) => {
    return axios
      .delete(`/v1/properties/${propertyId}/delete_space/${spaceId}`)
      .catch((err) => {
        dispatch(serverError(err));
      });
  };
}

export function addOrUpdateTask({ propertyId, formData }) {
  return (dispatch) => {
    return axios
      .put(
        `/v1/properties/${propertyId}/create_or_update_task?is_stage=true&picture_required=true`,
        formData
      )
      .catch((err) => {
        dispatch(serverError(err));
      });
  };
}

export function deleteTask({ propertyId, spaceId, taskId }) {
  return (dispatch) => {
    return axios
      .delete(`/v1/properties/${propertyId}/space/${spaceId}/task/${taskId}`)
      .catch((err) => {
        dispatch(serverError(err));
      });
  };
}

export const setSpaces = (data) => ({
  type: SET_STAGING_SPACES,
  data,
});

export const setSpace = (data) => ({
  type: SET_STAGING_SPACE,
  data,
});

export const resetSpace = () => ({ type: RESET_STAGING_SPACE });
export const resetSpaces = () => ({ type: RESET_STAGING_SPACES });
