import React, { useState, useRef, useEffect } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  useMediaQuery,
  Link,
} from "@material-ui/core";
import { showArticle } from "@intercom/messenger-js-sdk";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { GeneralTextField } from "../../containers/accountFields";
import { s3UrlMapper } from "../../helpers/s3URLMapper";
import { useDispatch } from "react-redux";
import {
  addOrUpdateTask,
  deleteSpace,
  fetchSpace,
  fetchSpaces,
} from "../../actions/staging";

const AddOrUpdateTaskModal = (props = {}) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    name: props?.name || "",
    image_url: props?.image_url || "",
    summary: props?.description || "",
  });
  const [isFormCTADisabled, setFormCTADisabled] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const PUBLIC_S3_URL = process.env.REACT_APP_PUBLIC_S3_URL;
  const isMobile = useMediaQuery("(max-width:600px)");

  const isUpdateTask = props.taskId || false; // New tasks will not have taskId

  const textFieldRef = useRef(null);
  const imgRef = useRef(null);

  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (values.name && values.image_url) {
      setFormCTADisabled(false);
    } else {
      setFormCTADisabled(true);
    }
  }, [values]);

  const handleTaskFieldChange = (e) => {
    e.persist(); // Persist the event to prevent it from being nullified
    const { name, value } = e.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () =>
        setValues((prev) => ({ ...prev, image_url: reader.result }));
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveTask = () => {
    if (props?.propertyId) {
      dispatch(deleteSpace());
    }
  };

  const handleModalClose = () => {
    props.hideModal();
  };

  const handleAddSpace = () => {
    if (props?.propertyId) {
      if (values?.name) {
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("description", values.summary);
        formData.append("space_id", props?.spaceId);
        if (props?.taskId) {
          formData.append("task_id", props?.taskId);
        }

        setLoading(true);
        if (values.image_url.includes(PUBLIC_S3_URL)) {
          dispatch(
            addOrUpdateTask({
              propertyId: props.propertyId,
              formData,
            })
          ).then((res) => {
            if (res && res.status === 200) {
              setLoading(false);
              handleModalClose();
              dispatch(
                fetchSpace({
                  propertyId: props.propertyId,
                  spaceId: props.spaceId,
                })
              );
              dispatch(fetchSpaces(props.propertyId));
            }
          });
        } else {
          const img = new Image();
          img.src = values.image_url;
          img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            canvas.toBlob(
              (blob) => {
                formData.append("image", blob);
                dispatch(
                  addOrUpdateTask({
                    propertyId: props.propertyId,
                    formData,
                  })
                ).then((res) => {
                  if (res && res.status === 200) {
                    setLoading(false);
                    handleModalClose();
                    dispatch(
                      fetchSpace({
                        propertyId: props.propertyId,
                        spaceId: props.spaceId,
                      })
                    );
                    dispatch(fetchSpaces(props.propertyId));
                  }
                });
              },
              "image/png",
              1
            );
          };
        }
      }
    }
  };
  return (
    <>
      <DialogContent>
        <div style={{ marginBottom: 24 }}>
          <Typography variant="h5">
            {isUpdateTask ? "Update task" : "Add task"}
          </Typography>
          <Typography variant="body2">
            Avoid combining many actions into one task. Create multiple tasks
            within a space, if needed.{" "}
            <Link
              href="https://help.automatebnb.com/en/articles/9463162-how-to-create-a-staging-guide#h_06960edb73"
              target="__blank"
            >
              Learn more
            </Link>
          </Typography>
        </div>
        <GeneralTextField
          name="name"
          label="Name"
          labelInFieldset={false}
          helperText="Keep space names concise, 1-2 words recommended, and ensure they are unique."
          placeholder="e.g. Under the bed, Open oven"
          value={values.name}
          inputRef={textFieldRef}
          handleChange={handleTaskFieldChange}
        />
        <div style={{ marginTop: 16 }}>
          <GeneralTextField
            name="summary"
            label="Summary"
            labelInFieldset={false}
            value={values.summary}
            placeholder="e.g. Ensure bed sheets don't smell"
            handleChange={handleTaskFieldChange}
          />
        </div>
        <div style={{ margin: "20px 0" }}>
          <Typography variant="body1" style={{ fontWeight: 500 }}>
            Task reference photo*
          </Typography>
          <Typography variant="body2">
            Reference photo should clearly show how the task should look after
            cleaning and staging.
          </Typography>
          <div
            style={{
              width: isMobile ? "200px" : "288px",
              height: isMobile ? "138px" : "200px",
              border: "2px dashed #ccc",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#f5f5f5",
              cursor: "pointer",
              position: "relative",
              marginTop: 10,
            }}
            onClick={() => document.getElementById("fileInput").click()}
          >
            {values.image_url ? (
              <img
                src={values.image_url}
                alt="Uploaded"
                ref={imgRef}
                style={{ width: "auto", height: "100%", borderRadius: "8px" }}
              />
            ) : (
              <div style={{ textAlign: "center" }}>
                <CameraAltIcon />
                <p>Add photo</p>
              </div>
            )}
            <input
              type="file"
              accept="image/*"
              id="fileInput"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: "flex-end" }}>
        <div>
          <Button onClick={handleModalClose}>Cancel</Button>
          <Button
            onClick={handleAddSpace}
            variant="contained"
            color="primary"
            disabled={isFormCTADisabled || isLoading}
            disableElevation
            style={{ marginLeft: 8, padding: "8px 24px" }}
          >
            {isLoading && (
              <CircularProgress size={24} style={{ marginRight: 8 }} />
            )}
            Save
          </Button>
        </div>
      </DialogActions>
    </>
  );
};

export default AddOrUpdateTaskModal;
