import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";

import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Checkbox from "@material-ui/core/Checkbox";
import { CircularProgress } from "@material-ui/core";

import history from "../../helpers/history";
import { filterParams, parseQuery } from "../../helpers/url";

import { getProsCategories } from "../../reducers/pros";
import { fetchMyPros, fetchProsCategories } from "../../actions/pros";
import { getModalLoading } from "../../reducers/loading";

const FilterByProperties = (props) => {
  const { fetchProsCategories, fetchMyPros, categories, closeDialog } = props;
  const { categories: categoriesParam } = parseQuery();

  let categoriesParamArray = categoriesParam && categoriesParam.split(",");

  const [selected, setSelected] = useState(
    categoriesParamArray ? categoriesParamArray : []
  );
  const [isDisabled, setDisabled] = useState(false);

  let _location = null;

  useEffect(() => {
    // Refreshes the categories if the client app is stale.
    fetchProsCategories();
  }, []);

  const handleSubmit = (type) => {
    _location = filterParams({ prosCategories: selected });

    if (_location || _location === "") {
      history.push({
        search: _location,
      });

      fetchMyPros();
      closeDialog();
    }
  };

  const handleFilterClear = () => {
    setSelected([]);
  };

  const handleRowSelect = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return isEmpty(categories) ? (
    <div className="text-center">
      <CircularProgress />
    </div>
  ) : (
    <>
      <DialogTitle
        id="scroll-dialog-title"
        className="assign-housekeeper__title"
      >
        <div className="inline-flex full-width space-between align-center pr pl">
          <span>Filter Pros by categories</span>
          <CloseIcon className="cursor-pointer" onClick={() => closeDialog()} />
          <div />
        </div>
      </DialogTitle>
      <DialogContent>
        <TableContainer className="table--max-height-64">
          <Table className="" aria-label="simple table">
            <TableBody>
              {categories.map((category, index) => {
                const isItemSelected = isSelected(category.name);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    key={category.name}
                    onClick={(event) => handleRowSelect(event, category.name)}
                    role="checkbox"
                    className="cursor-pointer"
                    aria-checked={isItemSelected}
                  >
                    <TableCell component="th" scope="row" padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </TableCell>
                    <TableCell align="left">{category.name}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <div className="full-width inline-flex align-center space-between">
          <Button onClick={() => handleFilterClear()} disableElevation>
            Clear all
          </Button>
          <Button
            onClick={() => handleSubmit()}
            variant="contained"
            color="primary"
            disableElevation
            disabled={isDisabled}
          >
            Apply filters
          </Button>
        </div>
      </DialogActions>
    </>
  );
};

export default connect(
  (state) => ({
    categories: getProsCategories(state),
  }),
  (dispatch) =>
    bindActionCreators({ fetchProsCategories, fetchMyPros }, dispatch)
)(FilterByProperties);
