import React, { useEffect } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Moment from "react-moment";
import { bindActionCreators } from "redux";

// Material
import Table from "@material-ui/core/Table";
import { useMediaQuery } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import TableRow from "@material-ui/core/TableRow";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";

import history from "../helpers/history";

// Reducers
import { getUserToken } from "../reducers/user";

// Actions
import { fetchOpsClients, fetchInvitedClients } from "../actions/ops";
import { openThisDialog } from "../actions/dialog";

// Reducers
import { getInvitedClients, getOpsClients } from "../reducers/clients";

// Components
import LoadingScreen from "../components/loadingScreen";
import ItemsCount from "../components/itemsCount";

// Containters
import Navigation from "../containers/navigation";
import Filter from "../containers/filter";
import Pagination from "../containers/pagination";

// Constants
import { DATE_FORMAT, DATE_FORMAT_FULL } from "../constants/apiEnum";
import { INVITED_CLIENTS } from "../routes";
import { DT_INVITE_OPS_CLIENTS } from "../constants/dialogTypes";
import { Analytics } from "../services/analyticsService.tsx";

// Styles

const OpsClients = (props) => {
  const {
    clients,
    fetchOpsClients,
    fetchInvitedClients,
    openThisDialog,
    invitedClients: { items: invitedClientsItems = [] },
  } = props;

  const { items = {}, page = {}, isLoading = true } = clients;
  const isMobile = useMediaQuery("(max-width:600px)");

  const invitedClientsTotal =
    (!isEmpty(invitedClientsItems) && invitedClientsItems.length) || 0;

  useEffect(() => {
    fetchOpsClients();
    fetchInvitedClients();
  }, []);

  const ClientsTable = () => {
    const handleClientClick = (event, props) => {
      Analytics.trackEvent("host_details_action_from_hosts", {
        client_id: props.id,
      });
      const constructedPathName = `/clients/${props.id}/properties`;
      history.push({
        pathname: constructedPathName,
        state: { ...props },
      });
    };

    const handleInviteClientsClick = () => {
      history.push(INVITED_CLIENTS);
    };

    const RenderInvitedClientsChip = () => {
      return (
        invitedClientsTotal > 0 && (
          <Chip
            label={`${invitedClientsTotal} invited hosts`}
            onClick={handleInviteClientsClick}
            size="small"
          />
        )
      );
    };

    const RenderHeader = () => {
      return (
        <div
          className={`inline-flex align-center mt2 mb2 ${
            isMobile ? "pl2" : "pl3"
          }`}
        >
          <div className="inline-flex align-center">
            <h1 className="page-header mr1">Hosts</h1>
            <RenderInvitedClientsChip />
          </div>
        </div>
      );
    };

    if (isLoading) {
      return <LoadingScreen />;
    } else {
      if (isEmpty(items)) {
        return (
          <div className="page-wrapper">
            <div className="page-content">
              <div
                style={{ height: "calc(100% - 55px)" }}
                className="full-width full-height inline-flex justify-center align-center mt3"
              >
                <div className="text-center">
                  <h1 className="header-3">No hosts found.</h1>
                  <div className="mt3">
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation={true}
                      onClick={() =>
                        openThisDialog({ dialogType: DT_INVITE_OPS_CLIENTS })
                      }
                    >
                      Invite a host
                    </Button>
                  </div>
                  {invitedClientsTotal > 0 && (
                    <div className="mt2">
                      <Button
                        variant="text"
                        onClick={() => handleInviteClientsClick()}
                      >
                        View {invitedClientsTotal} invited hosts
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="page-wrapper">
            <div className="page-content">
              <RenderHeader />
              {/********************
               * FILTERS
               * *******************
              <div key="host-jobs-filters" className="pt2 mb2 filters">
                <Filter displaySelectedValue={true} filterType="clients" />
              </div> */}
              <ItemsCount
                onPage={items.length}
                totalItems={page.total}
                type="hosts"
                className="page__counter mb1"
                key="host-jobs-item-count"
              />
              <Table aria-label="enhanced table" className="table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Hosts</TableCell>
                    <TableCell align="left">Phone number</TableCell>
                    {!isMobile && (
                      <TableCell align="left">Customer since</TableCell>
                    )}
                    <TableCell align="left">Auto-assigned properties</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="--bk-white">
                  {items.map((item) => {
                    const {
                      first = null,
                      last = null,
                      id = null,
                      phone = null,
                      total_assigned_properties: totalAssignedProperties = null,
                      client_since,
                    } = item;
                    return (
                      <TableRow
                        key={id}
                        hover={true}
                        className="tableRow--clickable"
                        onClick={(event) =>
                          handleClientClick(event, { ...item })
                        }
                      >
                        <TableCell component="th" scope="row">
                          {`${first} ${last}`}
                        </TableCell>
                        <TableCell align="left">{phone}</TableCell>
                        {!isMobile && (
                          <TableCell align="left">
                            <Moment format="MMMM DD, YYYY">
                              {client_since}
                            </Moment>
                          </TableCell>
                        )}
                        <TableCell align="left">
                          {totalAssignedProperties
                            ? `${totalAssignedProperties} properties`
                            : "None"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <Pagination
                nextPage={page.nextPage}
                prevPage={page.prevPage}
                type="clients"
              />
              <div className="intercom-spacer" />
            </div>
          </div>
        );
      }
    }
  };
  return (
    <>
      <Navigation />
      <ClientsTable />
    </>
  );
};

export default connect(
  (state) => ({
    userToken: getUserToken(state),
    clients: getOpsClients(state),
    invitedClients: getInvitedClients(state),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        fetchOpsClients,
        fetchInvitedClients,
        getInvitedClients,
        getOpsClients,
        openThisDialog,
      },
      dispatch
    )
)(OpsClients);
