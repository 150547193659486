import React from "react";
import { PropertyModel } from "../models";
import { Link } from "@material-ui/core";
import DriveEtaIcon from "@material-ui/icons/DriveEta";

interface JobLocationProps {
  address: string | null;
  property: PropertyModel;
  is_host: boolean | undefined;
}

const JobLocation: React.FC<JobLocationProps> = ({
  address,
  property,
  is_host,
}) => {
  const { title, parking_info, id } = property;
  const propertyLink = `/listings/${id}/listing-details`;
  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    address
  )}`;
  return (
    <div className="mb-4">
      <h3 className="text-md flex align-center">
        <DriveEtaIcon style={{ fontSize: 20, opacity: 0.8, marginRight: 4 }} />{" "}
        Location
      </h3>
      {title &&
        (is_host ? (
          <div className="mt-2">
            <Link href={propertyLink} className="">
              <p>{title}</p>
            </Link>
            <p className="mt-2">{address}</p>
          </div>
        ) : (
          <div className="mt-2">
            <p className="mb-1">{title}</p>
            <Link href={googleMapsUrl} target="__blank">
              {address}
            </Link>
          </div>
        ))}
      {parking_info && <p>{parking_info}</p>}
    </div>
  );
};

export default JobLocation;
