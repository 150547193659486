import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import DialogContent from "@material-ui/core/DialogContent";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

import { getDialogStatus } from "../../reducers/dialog";

import { updateNotificationSettings } from "../../actions/notifications";

import "./notificationSettingsDialogBody.scss";

import {
  NOTIFICATION_TYPE_OFF,
  NOTIFICATION_TYPE_SMS,
  NOTIFICATION_TYPE_EMAIL
} from "../../constants/apiEnum";
import { notifications } from "../../reducers/notifications";

const NotificationSettings = props => {
  const { id, closeDialog, notification, updateNotificationSettings } = props;

  const [value, setValue] = useState(
    notification.notify ? notification.notify : NOTIFICATION_TYPE_OFF
  );

  const handleDialogClose = type => {
    if (type === "update") {
      updateNotificationSettings({ id, notify: value });
      closeDialog();
    } else if (type === "cancel") {
      closeDialog();
    }
  };

  const notificationTitle = notification.notification_rule
    ? notification.notification_rule.title
    : "Title missing";

  const handleChange = event => {
    const { value } = event.target;
    setValue(value);
  };

  return (
    <>
      <DialogTitle
        id="scroll-dialog-title"
        className="assign-housekeeper__title"
      >
        Notification
      </DialogTitle>
      <DialogContent dividers={true}>
        <div className="notification-settings__wrapper">
          <div>{notificationTitle}</div>
          <div>
            <FormControl variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                className="notification-settings__select"
                value={value}
                onChange={event => handleChange(event)}
              >
                <MenuItem value={NOTIFICATION_TYPE_OFF}>
                  {NOTIFICATION_TYPE_OFF}
                </MenuItem>
                <MenuItem value={NOTIFICATION_TYPE_SMS}>
                  {NOTIFICATION_TYPE_SMS}
                </MenuItem>
                <MenuItem value={NOTIFICATION_TYPE_EMAIL}>
                  {NOTIFICATION_TYPE_EMAIL}
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleDialogClose("cancel")}
          variant="contained"
          disableElevation
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleDialogClose("update")}
          variant="contained"
          color="primary"
          disableElevation
        >
          Update
        </Button>
      </DialogActions>
    </>
  );
};

export default connect(
  state => ({ notification: getDialogStatus(state).object }),
  dispatch => bindActionCreators({ updateNotificationSettings }, dispatch)
)(NotificationSettings);
