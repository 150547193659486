import React, { createContext, useContext, useState, useCallback } from "react";
import { Dialog } from "@material-ui/core";

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [modalComponent, setModalComponent] = useState(null);
  const [modalProps, setModalProps] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const showModal = useCallback((Component = null, props = {}) => {
    setModalComponent(() => Component);
    setModalProps(props);
    setIsOpen(true);
  }, []);

  const hideModal = useCallback(() => {
    setModalComponent(null);
    setModalProps({});
    setIsOpen(false);
  }, []);

  const handleClose = () => {
    if (modalProps.allowClose) {
      hideModal();
    }
  };

  const ModalComponent = modalComponent;

  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      {children}
      <Dialog
        open={isOpen}
        onClose={handleClose}
        fullScreen={modalProps.fullScreen}
      >
        {ModalComponent && <ModalComponent {...modalProps} />}
      </Dialog>
    </ModalContext.Provider>
  );
};
