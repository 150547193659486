import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import amplitude from "amplitude-js";

import { bindActionCreators } from "redux";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";

import {
  fetchPayment,
  fetchPaymentMethod,
  makePayment,
} from "../../actions/payments";
import { getPaymentMethod } from "../../reducers/payments";

import {
  HOST_STRIPE_FREE_PLAN,
  SUBSCRIBE_STATUS_ACTIVE,
} from "../../constants/apiEnum";
import { HOST_SUBSCRIBE } from "../../routes";
import history from "../../helpers/history";
import { HOST_PAYMENT_METHOD } from "../../routes";
import { getSubscription } from "../../reducers/reducer_subscribe";

const MakeCleaningPayment = (props) => {
  const {
    job,
    fetchPayment,
    fetchPaymentMethod,
    method,
    makePayment,
    subscribe,
    closeThisDialog,
  } = props;

  const {
    pro = {},
    cleaning_fee_note: jobFeeNote = null,
    job_fee = null,
    additional_fee = null,
    total_fee = null,
    payment = {},
  } = job;

  const id = job.id;

  const paymendId = payment ? payment.id : null;

  const isAccountActive =
    subscribe && subscribe.status === SUBSCRIBE_STATUS_ACTIVE;

  const [buttonPressed, setButtonState] = useState(false);

  const isOnClientPaidPlan = subscribe
    ? subscribe.stripe_plan === HOST_STRIPE_FREE_PLAN
    : null;
  const hasCardLast4 = subscribe ? !isEmpty(subscribe.card_last4) : null;

  const { first = "", last = "" } = pro;

  const assignedProFullName = `${first} ${last}`;

  useEffect(() => {
    if (paymendId) {
      fetchPayment(paymendId);
    }
    fetchPaymentMethod();
  }, []);

  const handleDialogClose = (type) => {
    if (type === "cancel") {
      closeThisDialog();
    } else if (type === "submit") {
      setButtonState(true);
      makePayment(paymendId)
        .then((response) => {
          if (response.status !== 200) {
            setButtonState(true);
          }
          return response;
        })
        .catch((error) => {
          setButtonState(true);
          return error;
        });
    }
  };

  const handleClick = (route) => {
    history.replace(route);
    closeThisDialog();
  };

  const SubscriptionValidation = () => {
    //
    if (!isAccountActive) {
      // Subscribe
      return (
        <div
          className="mt2 blockquote blockquote--danger"
          style={{
            backgroundColor: "#f4f4f4",
            borderRadius: "8px",
            padding: "8px",
            paddingLeft: "12px",
            paddingRight: "12px",
            display: "inline-flex",
            maxWidth: "450px",
            marginTop: "16px",
          }}
        >
          <Typography variant="body2">
            Please subscribe to Automatebnb in order to make payments.
          </Typography>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={() => handleClick(HOST_SUBSCRIBE)}
          >
            Subscribe
          </Button>
        </div>
      );
    } else if (!hasCardLast4) {
      return (
        <div className="mt2 blockquote blockquote--danger">
          <div className="mb1">
            <Typography variant="body1">
              Please add your credit card in order to make payments.
            </Typography>
          </div>
          <Button
            color="secondary"
            variant="contained"
            disableElevation
            fullWidth
            onClick={() => handleClick(HOST_PAYMENT_METHOD)}
          >
            Add card
          </Button>
          <hr className="mt2" style={{ opacity: 0.5 }} />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    !isEmpty(job) && (
      <>
        <DialogContent>
          <Typography variant="h5">Job payment</Typography>
          <div
            className="job-details job-details--mobile"
            style={{ marginTop: "16px" }}
          >
            <div className="job-details__wrapper">
              <div className="job-details__group">
                <p className="job-details__label">Pay to:</p>
                <div className="job-details__body">{assignedProFullName}</div>
              </div>
              <div className="job-details__group">
                <p className="job-details__label">Job amount</p>
                <div className="job-details__body">${job_fee}</div>
              </div>
              <div className="job-details__group">
                <div className="job-details__label">
                  Additional amount
                  <p style={{ fontSize: 14, paddingTop: -4 }}>{jobFeeNote}</p>
                </div>
                <div className="job-details__body">${additional_fee}</div>
              </div>
              <div style={{ borderTop: "1px solid lightGrey" }} />
              <div className="job-details__group">
                <p className="job-details__label job-details__body--bold">
                  Total Amount
                </p>
                <div className="job-details__body job-details__body--bold">
                  ${total_fee}
                </div>
              </div>
            </div>
            {!isEmpty(method) && (
              <div
                className="job-details__group full-width"
                style={{ marginTop: "20px", marginBottom: "20px" }}
              >
                <p className="job-details__label">Payment method</p>
                <div className="job-details__body">
                  {`${method[0].brand} ${method[0].last4}`}
                </div>
              </div>
            )}
          </div>

          <SubscriptionValidation />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleDialogClose("cancel")}
            variant="contained"
            disableElevation
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleDialogClose("submit")}
            variant="contained"
            color="secondary"
            style={{ width: "140px" }}
            disableElevation
            disabled={!isAccountActive || buttonPressed}
          >
            {buttonPressed ? (
              <CircularProgress className="white" size={24} />
            ) : (
              "Make payment"
            )}
          </Button>
        </DialogActions>
      </>
    )
  );
};

export default connect(
  (state) => ({
    method: getPaymentMethod(state),
    subscribe: getSubscription(state).data,
  }),
  (dispatch) =>
    bindActionCreators(
      { fetchPayment, makePayment, fetchPaymentMethod },
      dispatch
    )
)(MakeCleaningPayment);
