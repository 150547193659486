import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import classnames from "classnames";

import ButtonBase from "@material-ui/core/ButtonBase";
import Button from "@material-ui/core/Button";

import {
  parseQuery,
  parseURLQuery,
  sanitizeQueryParams,
  filterParams,
} from "../helpers/url";
import history from "../helpers/history";
import { getPMSProperty } from "../reducers/pmsProperties";
import { getPMSMyHousekeeper } from "../reducers/pmsMyHousekeepers";
import { getUserToken } from "../reducers/user";

import { setDialogOpen, openThisDialog } from "../actions/dialog";
import { fetchProperty } from "../actions/pmsProperties";
import { fetchMyPros } from "../actions/pros";
import { fetchHostJobs, fetchProsJobs } from "../actions/jobs";

import {
  DIALOG_TYPE_FILTER_BY_HOUSEKEEPERS,
  DIALOG_TYPE_FILTER_BY_PROPERTY,
  DIALOG_TYPE_ALL_FILTERS,
  DT_FILTER_CLEANING_STATUS,
  DT_PROS_CATEGORIES,
} from "../constants/dialogTypes";

import {
  CLEANER_STATUS_ACCEPTED,
  CLEANER_STATUS_DECLINED,
  CLEANING_DATE_CHANGED,
  WAITING_TO_ACCEPT,
} from "../constants/apiEnum";

import "./filter.scss";
import { getProsCategories } from "../reducers/pros";
import { fetchOpsJobs } from "../actions/ops";

function turncateString(string, chrCount) {
  return string.length >= chrCount ? string.slice(0, chrCount) + "..." : string;
}

const cleaningStatusLabel = (value) => {
  switch (value) {
    case CLEANER_STATUS_ACCEPTED:
      return "Accepted";
    case CLEANER_STATUS_DECLINED:
      return "Declined";
    case CLEANING_DATE_CHANGED:
      return "Date changed";
    case WAITING_TO_ACCEPT:
      return "Waiting to accept";
    default:
      return "Cleaning status";
  }
};

const Filter = (props) => {
  const {
    property,
    housekeeper,
    properties,
    fetchProperty,
    fetchHostJobs,
    fetchProsJobs,
    fetchMyPros,
    setDialogOpen,
    filterCleanings,
    openThisDialog,
    label,
    fetchOpsJobs,
    userToken,
    categories,
    pluralLabel,
    filterMyPros,
    filterType,
  } = props;

  const {
    property_ids,
    maid_ids,
    note,
    channels,
    request_payment,
    type,
    payment_statuses,
    cleaning_status,
  } = parseQuery();

  const _filters = parseQuery();

  const propertyIdsArray = sanitizeQueryParams(property_ids, true);
  const housekeeperIdsArray = sanitizeQueryParams(maid_ids, true);
  const notesIdsArray = sanitizeQueryParams(note);
  const channelsArray = sanitizeQueryParams(channels, true);
  const cleaningStatusArray = sanitizeQueryParams(cleaning_status, true);
  const paymentStatusArray = sanitizeQueryParams(payment_statuses, true);

  // CONSTS
  const FILTER_PROPERTIES = "properties";
  const FILTER_CLEANING_STATUS = "cleaningStatus";
  const FILTER_HOUSEKEEPER = "housekeeper";
  const FILTER_MORE = "more";
  const FILTER_PROS_CATEGORY = "prosCategories";

  const filtersTotal =
    notesIdsArray.length +
    channelsArray.length +
    cleaningStatusArray.length +
    paymentStatusArray.length;

  let isActive = false;

  let filters = {
    FILTER_PROPERTIES: {
      label: "Property",
      active: false,
    },
    FILTER_CLEANING_STATUS: {
      label: "Job status",
      active: false,
    },
    FILTER_HOUSEKEEPER: {
      label: "Pros",
      active: false,
    },
    FILTER_MORE: {
      label: "More filters",
      active: false,
    },
    FILTER_PROS_CATEGORY: {
      label: "Category",
      active: false,
    },
  };

  if (
    property_ids ||
    maid_ids ||
    note ||
    channels ||
    payment_statuses ||
    cleaning_status
  ) {
    isActive = true;
  }

  useEffect(() => {
    if (propertyIdsArray.length === 1) {
      if (parseInt(propertyIdsArray[0]) && isEmpty(property)) {
        fetchProperty(parseInt(propertyIdsArray[0]));
      }
    }

    if (housekeeperIdsArray.length === 1) {
      if (parseInt(housekeeperIdsArray[0]) && isEmpty(housekeeper)) {
        fetchMyPros(parseInt(housekeeperIdsArray[0]));
      }
    }
  }, []);

  // Activate properties filter
  if (!isEmpty(propertyIdsArray)) {
    filters.FILTER_PROPERTIES.active = true;
  } else {
    filters.FILTER_PROPERTIES.active = false;
  }

  // Activate housekeeper filter
  if (!isEmpty(housekeeperIdsArray)) {
    filters.FILTER_HOUSEKEEPER.active = true;
  } else {
    filters.FILTER_HOUSEKEEPER.active = false;
  }

  // Activate More filter
  if (!filters.FILTER_MORE.active) {
    if (
      !isEmpty(notesIdsArray) ||
      !isEmpty(channelsArray) ||
      !isEmpty(cleaningStatusArray) ||
      (type === "past" && !isEmpty(paymentStatusArray))
    ) {
      filters.FILTER_MORE.active = true;
    } else {
      filters.FILTER_MORE.active = false;
    }
  }

  // Activate cleaning status filter
  if (!isEmpty(cleaningStatusArray)) {
    filters.FILTER_CLEANING_STATUS.active = true;
  } else {
    filters.FILTER_CLEANING_STATUS.active = false;
  }

  // Add custom label for Housekeeper filter
  if (housekeeperIdsArray.length === 1) {
    if (housekeeper && housekeeper[0]) {
      filters.FILTER_HOUSEKEEPER.label = turncateString(
        `${housekeeper[0].first} ${housekeeper[0].last}`,
        30
      );
    }
  } else if (housekeeperIdsArray.length > 1) {
    filters.FILTER_HOUSEKEEPER.label = (
      <div className="inline-flex align-center">
        <div className="filter__count">{housekeeperIdsArray.length}</div> Pros
      </div>
    );
  }

  // Add custom label for Properties filter
  if (propertyIdsArray.length === 1) {
    if (property && property.title) {
      filters.FILTER_PROPERTIES.label = turncateString(property.title, 30);
    }
  } else if (propertyIdsArray.length > 1) {
    filters.FILTER_PROPERTIES.label = (
      <div className="inline-flex align-center">
        <div className="filter__count">{propertyIdsArray.length}</div>{" "}
        Properties
      </div>
    );
  }

  // Add custom label for Cleaning status filter
  if (cleaningStatusArray.length === 1) {
    filters.FILTER_CLEANING_STATUS.label = cleaningStatusLabel(
      cleaningStatusArray[0]
    );
  } else if (cleaningStatusArray.length > 1) {
    filters.FILTER_CLEANING_STATUS.label = (
      <div className="inline-flex align-center">
        <div className="filter__count">{cleaningStatusArray.length}</div> Job
        statuses
      </div>
    );
  }

  if (filtersTotal === 0) {
    filters.FILTER_MORE.label = "More filters";
  } else if (filtersTotal === 1) {
    filters.FILTER_MORE.label = (
      <div className="inline-flex align-center">
        <div className="filter__count">{filtersTotal}</div> Filter
      </div>
    );
  } else if (filtersTotal > 1) {
    filters.FILTER_MORE.label = (
      <div className="inline-flex align-center">
        <div className="filter__count">{filtersTotal}</div> Filters
      </div>
    );
  }

  const openModal = (filter) => {
    if (filter === FILTER_PROPERTIES) {
      openThisDialog({
        dialogType: DIALOG_TYPE_FILTER_BY_PROPERTY,
        propertiesSelected: propertyIdsArray,
        user: userToken,
      });
    } else if (filter === FILTER_CLEANING_STATUS) {
      setDialogOpen(DT_FILTER_CLEANING_STATUS);
    } else if (filter === FILTER_HOUSEKEEPER) {
      setDialogOpen(
        DIALOG_TYPE_FILTER_BY_HOUSEKEEPERS,
        null,
        housekeeperIdsArray
      );
    } else if (filter === FILTER_MORE) {
      setDialogOpen(DIALOG_TYPE_ALL_FILTERS);
    } else if (filter === FILTER_PROS_CATEGORY) {
      setDialogOpen(DT_PROS_CATEGORIES);
    } else {
      alert("Filter missing");
    }
  };

  const clearAllFilters = () => {
    let _location = type ? `&type=${type}` : "";

    history.push({
      search: _location,
    });

    if (userToken && userToken.is_host) {
      fetchHostJobs();
    } else if (userToken && userToken.is_maid) {
      fetchProsJobs();
    } else if (userToken && userToken.is_ops) {
      fetchOpsJobs();
    }
  };

  if (filterType === "prosJobs") {
    return (
      <>
        {type !== "past" && (
          <ButtonBase
            className={classnames("filter", {
              active: filters.FILTER_CLEANING_STATUS.active,
            })}
            onClick={() => openModal(FILTER_CLEANING_STATUS)}
          >
            {filters.FILTER_CLEANING_STATUS.label}
          </ButtonBase>
        )}
        {isActive && (
          <Button
            style={{ fontSize: "13px" }}
            onClick={() => clearAllFilters()}
            className="clear-all"
          >
            Clear all
          </Button>
        )}
      </>
    );
  } else if (filterType === "ops") {
    return (
      <>
        <ButtonBase
          className={classnames("filter", {
            active: filters.FILTER_PROPERTIES.active,
          })}
          onClick={() => openModal(FILTER_PROPERTIES)}
        >
          {filters.FILTER_PROPERTIES.label}
        </ButtonBase>
        {isActive && (
          <Button
            style={{ fontSize: "13px" }}
            onClick={() => clearAllFilters()}
            className="clear-all"
          >
            Clear all
          </Button>
        )}
      </>
    );
  } else if (filterType === "hostJobs") {
    return (
      <>
        <ButtonBase
          className={classnames("filter", {
            active: filters.FILTER_PROPERTIES.active,
          })}
          onClick={() => openModal(FILTER_PROPERTIES)}
        >
          {filters.FILTER_PROPERTIES.label}
        </ButtonBase>
        <ButtonBase
          className={classnames("filter", {
            active: filters.FILTER_HOUSEKEEPER.active,
          })}
          onClick={() => openModal(FILTER_HOUSEKEEPER)}
        >
          {filters.FILTER_HOUSEKEEPER.label}
        </ButtonBase>
        <ButtonBase
          className={classnames("filter", {
            active: filters.FILTER_MORE.active,
          })}
          onClick={() => openModal(FILTER_MORE)}
        >
          {filters.FILTER_MORE.label}
        </ButtonBase>
        {isActive && (
          <Button
            style={{ fontSize: "13px" }}
            onClick={() => clearAllFilters()}
            className="clear-all"
          >
            Clear all
          </Button>
        )}
      </>
    );
  } else if (filterType === "pros") {
    if (!isEmpty(categories)) {
      return (
        <div className="mb1">
          <ButtonBase
            className={classnames("filter", {
              active: filters.FILTER_PROS_CATEGORY.active,
            })}
            onClick={() => openModal(FILTER_PROS_CATEGORY)}
          >
            {filters.FILTER_PROS_CATEGORY.label}
          </ButtonBase>
        </div>
      );
    } else {
      return "";
    }
  } else {
    return "Check Filters...";
  }
};

export default connect(
  (state) => ({
    property: getPMSProperty(state),
    categories: getProsCategories(state),
    housekeeper: getPMSMyHousekeeper(state),
    userToken: getUserToken(state),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        fetchProperty,
        setDialogOpen,
        fetchHostJobs,
        fetchProsJobs,
        fetchMyPros,
        openThisDialog,
        fetchOpsJobs,
      },
      dispatch
    )
)(Filter);
