import axios from "../helpers/axios";

// NOT sure what it does
import { serverError } from "../helpers/helper_serverError";
import { parseQuery } from "../helpers/url";
import { closeThisDialog } from "./dialog";
import { assignProToProperty } from "./properties";

import { setContentLoading } from "./loading";
import { JOBS } from "../routes";
import { DT_GENERIC_MODAL } from "../constants/dialogTypes";
import { fetchProperty } from "./pmsProperties";

export const SET_MY_PROS = "set_my_pros";
export const RESET_MY_PROS = "reset_my_pros";
export const SET_PROS_CATEGORIES = "set_pros_categories";

const { opu } = parseQuery();

const isOnboarding = opu === "onboarding";

export const fetchMyPros = (next) => {
  const { category, location } = parseQuery();

  return function action(dispatch) {
    if (!next) {
      dispatch(setContentLoading(true));
      dispatch(resetMyPros());
    }

    let myMaidsEndpoint = "/v1/mymaids";
    if (next && category) {
      myMaidsEndpoint =
        myMaidsEndpoint +
        `?page=${encodeURIComponent(next)}&filter[category]=${category}`;
    } else if (next) {
      myMaidsEndpoint = myMaidsEndpoint + `?page=${encodeURIComponent(next)}`;
    } else if (category) {
      myMaidsEndpoint = myMaidsEndpoint + `?filter[category]=${category}`;
    }

    return axios
      .get(myMaidsEndpoint)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setContentLoading(false));
          dispatch(setMyPros(response.data));
        }
      })
      .catch((error) => {
        dispatch(setContentLoading(false));
        dispatch(serverError(error));
      });
  };
};

// Delete Pro from MyPros section
export const deletePro = ({ id, reasons }) => {
  return (dispatch) => {
    return axios
      .put(`/v1/mymaids/${id}/deactivate?reason=${reasons}`)
      .then((response) => {
        if (response.status === 200) {
          // Refresh the UI
          dispatch(fetchMyPros());
        }
      })
      .catch((error) => {
        dispatch(serverError(error, "Something went wrong, please try again."));
      });
  };
};

// Invite Pro from MyPros section
export const invitePro = ({ first, last, phone, category }) => {
  return (dispatch) => {
    return axios
      .post("/v1/maids/invite", { first, last, phone, category_id: category })
      .then((response) => {
        if (response.status === 200) {
          // Refresh the UI
          dispatch(fetchMyPros());
          dispatch(closeThisDialog());
        }
      })
      .catch((error) => {
        dispatch(serverError(error, "Something went wrong, please try again."));
      });
  };
};

export const inviteAndAssignPro = ({
  first,
  last,
  phone,
  propertyId = null,
  assignExistingJobs = true,
  category,
}) => {
  return (dispatch) => {
    return axios
      .post("/v1/maids/invite", { first, last, phone, category_id: category })
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          //
          const proId = data.id || null;
          if (proId && propertyId) {
            dispatch(
              assignProToProperty({ propertyId, proId, assignExistingJobs })
            );
          }
        }
      })
      .catch((error) => {
        dispatch(
          serverError(
            error,
            "Try again! If this persists please try assigning a pro under properties."
          )
        );
      });
  };
};

export function unassignPro(id) {
  return (dispatch) => {
    return axios
      .put(
        `/v1/properties/${id}/update_assign_rule?assign_maid_rule=ASSIGN_MAID_AUTO&maid_id=`
      )
      .then((res) => {
        if (res && res.status === 200) {
          dispatch(fetchProperty(id));
          dispatch(closeThisDialog({ dialogType: DT_GENERIC_MODAL }));
        }
      })
      .catch((err) => {
        dispatch(serverError(err));
      });
  };
}

export const fetchProsCategories = () => {
  return (dispatch) => {
    axios
      .get("/v1/categories")
      .then((response) => {
        if (response.status === 200) {
          dispatch(setProsCategories(response.data));
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };
};

export const setMyPros = (data) => ({
  type: SET_MY_PROS,
  data,
});

export const setProsCategories = (data) => ({
  type: SET_PROS_CATEGORIES,
  data,
});

export const resetMyPros = () => ({ type: RESET_MY_PROS });
