import React from "react";
import { useDispatch } from "react-redux";

// Material UI - Dialog
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Link, Typography } from "@material-ui/core";

function Discrepancies(props) {
  const { closeThisDialog } = props;
  const dispatch = useDispatch();

  function handleClose() {
    closeThisDialog();
  }

  return (
    <>
      <DialogTitle id="scroll-dialog-title" className="discrepancies-modal">
        Jobs not sycned
      </DialogTitle>
      <DialogContent style={{ position: "relative" }} className="ab-dialog">
        <div>
          <div>
            <Typography variant="h6" style={{ fontSize: "16px" }}>
              Sync schedule
            </Typography>
            <p>
              Automatebnb syncs ICS calendars from your booking platforms:
              Airbnb, VRBO, TripAdvisor, and Hostaway{" "}
              <span className="semibold">every hour</span>.
            </p>
          </div>
          <div className="mt3">
            <Typography variant="h6" style={{ fontSize: "16px" }}>
              Troubleshooting
            </Typography>
            <Typography variant="body1">
              If you see any discrepancies between your reservations from
              booking platforms and Jobs on Automatebnb, please attempt to
              perform a force sync by navigating to:
            </Typography>
            <div className="mt1">
              <ol className="ordered-list" type="1">
                <li>
                  <Link style={{ color: "#3a66ff" }} href="/properties">
                    Properties view
                  </Link>
                </li>
                <li>Click on the property that is out of sync</li>
                <li>Click on Import calendar tab</li>
                <li>
                  Click on “force sync” next to the calendar that is out of
                  sync.
                </li>
              </ol>
            </div>
            <div className="mt2">
              <Typography variant="body1">
                If you have attempted the above steps and are still experiencing
                issues, please reach out to us at{" "}
                <a href="mailto:help@automatebnb.com" className="semibold link">
                  help@automatebnb.com
                </a>{" "}
                for further assistance.
              </Typography>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="inline-flex justify-end full-width">
          <div className="pms-dialog__actions">
            <Button
              onClick={handleClose}
              variant="contained"
              color="primary"
              disableElevation
            >
              Done
            </Button>
          </div>
        </div>
      </DialogActions>
    </>
  );
}

export default Discrepancies;
