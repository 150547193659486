import React from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import { Button } from "@material-ui/core";

import {
  PAYMENT_STATUS_FAILED,
  PAYMENT_STATUS_SUCCESS,
  PAYMENT_STATUS_PENDING,
  PAYMENT_STATUS_PROCESSING,
} from "../constants/apiEnum";

export default ({
  id = null,
  receipt_url = null,
  status = null,
  job_fee = null,
  total_fee = null,
  additional_fee = null,
  cleaning_fee_note: paymentDescription = null,
  title = "Payment details",
}) => {
  const dispatch = useDispatch();

  const isPending = status === PAYMENT_STATUS_PENDING;
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleReceiptURL = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div
      className={isMobile ? "job-details job-details--mobile" : "job-details"}
    >
      <h3 className="job-details__title">{title}</h3>
      <div className="job-details__wrapper">
        <div className="job-details__group">
          <p className="job-details__label">Total</p>
          <p className="job-details__body">{`$${total_fee}`}</p>
        </div>
        <div className="job-details__group">
          <p className="job-details__label">Job fee</p>
          <p className="job-details__body">{`$${job_fee}`}</p>
        </div>
        <div className="job-details__wrapper job-details__wrapper--mt2">
          {additional_fee && (
            <div className="job-details__group">
              <p className="job-details__label">Additional</p>
              <div className="job-details__body">{`$${additional_fee}`}</div>
            </div>
          )}
          {paymentDescription && (
            <div className="job-details__group">
              <p className="job-details__label">Payment description</p>
              <div className="job-details__body">{paymentDescription}</div>
            </div>
          )}
        </div>
      </div>
      {!isPending && (
        <div className="mt1">
          <Button
            variant="contained"
            disableElevation
            color="primary"
            endIcon={<LaunchIcon size="small" />}
            onClick={() => handleReceiptURL(receipt_url)}
          >
            View Receipt
          </Button>
        </div>
      )}
    </div>
  );
};
