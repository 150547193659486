import { SHOW_SNACKBAR, HIDE_SNACKBAR } from '../constants/action-types';

export function showSnackbar(data) {

  return {
    type: SHOW_SNACKBAR,
    ...data
  }
}

export function hideSnackbar() {

  return {
    type: HIDE_SNACKBAR
  }
}
