import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import amplitude from "amplitude-js";

import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import {
  Chip,
  CircularProgress,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import CloseIcon from "@material-ui/icons/Close";

// HELPERS
import {
  getCommaSeparatedNumber,
  getFormattedCheckInOutTime,
} from "../helpers/format";

// CONSTANTS
import { DIALOG_TYPE_PROPERTY_DETAILS_EDIT } from "../constants/dialogTypes";
import { SpecialRequirements } from "../constants/properties";

// ACTIONS
import { fetchProperty } from "../actions/pmsProperties";
import { setDialogOpen } from "../actions/dialog";

// REDUCERS
import { getPMSProperty } from "../reducers/pmsProperties";
import { getSpaces } from "../reducers/staging";
import { Analytics } from "../services/analyticsService.tsx";

const QuickActions = {
  SETUP_STAGING: "setup_staging",
  SETUP_AUTOMATION: "setup_automation",
};

const PropertyDetails = (props) => {
  const {
    fetchProperty,
    property,
    propertyId,
    setDialogOpen,
    spacesObject,
  } = props;

  const spaces = spacesObject?.data;

  const history = useHistory();

  const isMobile = useMediaQuery("(max-width:600px)");

  const { amenities = {} } = property;

  const isPropertyInformationAvailable =
    property.gate_code ||
    property.lock_code ||
    property.maid_closet_code ||
    property.gate_instructions ||
    property.access_instructions ||
    property.maid_closet_info;

  const isLaundryAvailable = property.washer || property.dryer;

  const [closedActions, setClosedActions] = useState({});

  useEffect(() => {
    if (propertyId) {
      fetchProperty(propertyId);
    }
  }, []);

  // Load closed properties from local storage when the component mounts
  useEffect(() => {
    const storedClosedActions =
      JSON.parse(localStorage.getItem("closedQuickActions")) || {};
    setClosedActions(storedClosedActions);
  }, []);

  // Save closed actions to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem("closedQuickActions", JSON.stringify(closedActions));
  }, [closedActions]);

  const handleClick = (props) => {
    setDialogOpen(DIALOG_TYPE_PROPERTY_DETAILS_EDIT, propertyId);
  };

  const RenderPropertyAddress = () => {
    // TODO: full_address is null. Verify with Akash before updating this.
    const { street = "", city = "", state = "", zip = "" } = property || {};

    const address = `${street}, ${city}, ${state} ${zip}`;

    return (
      <Link
        target="_new"
        href={`https://maps.google.com/?q=${address}`}
        className="link"
        style={{
          color: "#3a66ff",
          wordBreak: "break-all",
          overflowWrap: "break-word",
          whiteSpace: "normal",
        }}
      >
        {address}
      </Link>
    );
  };

  // Handle closing a specific action for a property
  const handleCloseQuickActions = (propertyId, action) => {
    const updatedClosedActions = {
      ...closedActions,
      [propertyId]: {
        ...closedActions[propertyId],
        [action]: true,
      },
    };
    setClosedActions(updatedClosedActions);
  };

  const isNextActionSetupStaging = isEmpty(spaces)
    ? !closedActions[propertyId]?.["setup_staging"]
    : false;

  const RenderNextBestActions = () => {
    return (
      !spacesObject.isLoading &&
      isNextActionSetupStaging && (
        <div className="pl2 pr2 pt3" style={{ marginBottom: 16 }}>
          <div style={{ marginBottom: "8px" }}>
            <Typography variant="subtitle1" style={{ fontWeight: "500" }}>
              Next steps
            </Typography>
          </div>
          <div
            style={{
              width: "350px",
              borderRadius: "8px",
              border: "1px solid #DDDDDD",
              padding: "8px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <CameraAltIcon />
              <IconButton
                aria-label="close setup staging quick action"
                onClick={() =>
                  handleCloseQuickActions(
                    propertyId,
                    QuickActions.SETUP_STAGING
                  )
                }
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div style={{ marginBottom: "8px" }}>
              <Typography
                variant="subtitle2"
                style={{ fontWeight: "500", lineHeight: "120%" }}
              >
                Improve your cleaning quality
              </Typography>
            </div>
            <Typography>
              Set clear guidelines for your pros on how you would like your
              property to be staged on every cleaning.
            </Typography>
            <div style={{ marginTop: "8px", marginBottom: "4px" }}>
              <Link
                style={{
                  textDecoration: "none",
                  color: "white",
                  backgroundColor: "#4200ff",
                  padding: "8px 12px",
                  borderRadius: "16px",
                  fontSize: "16px",
                }}
                onClick={() =>
                  Analytics.trackEvent(
                    "next_steps_staging_setup_action_from_property_details_overview",
                    { property_id: propertyId }
                  )
                }
                href={`/listings/${propertyId}/staging`}
              >
                Setup
              </Link>
            </div>
          </div>
        </div>
      )
    );
  };

  if (isEmpty(property)) {
    return (
      <div>
        <h3 className="header-3">
          Uh oh! <br /> Looks like something went wrong. Please try reloading
          this page. If problem presists, contact us at{" "}
          <a href="mailto:help@automatebnb.com">help@automatebnb.com</a>
        </h3>
      </div>
    );
  } else {
    return (
      <>
        {<RenderNextBestActions />}

        <div className="pl2 pr2 pt3">
          <div className="grid-container">
            <div className="sm-col sm-col-6 inline-label-body">
              <Typography
                variant="subtitle2"
                className="inline-label-body__label"
              >
                Property title
              </Typography>
              <Typography variant="body1" className="inline-label-body__body">
                {property.title}
              </Typography>
            </div>
            <div className="sm-col sm-col-6 inline-label-body">
              <Typography
                variant="subtitle2"
                className="inline-label-body__label"
                style={{ paddingRight: 32 }}
              >
                Full address
              </Typography>
              <Typography variant="body1" className="inline-label-body__body">
                <RenderPropertyAddress />
              </Typography>
            </div>
          </div>

          <div className="grid-container">
            <div className="sm-col sm-col-6 inline-label-body">
              <Typography
                variant="subtitle2"
                className="show-view__label"
                style={{ whiteSpace: "nowrap" }}
              >
                Property size (sq ft)
              </Typography>
              {property.square_feet ? (
                <Typography variant="body1" className="show-view__body">
                  {getCommaSeparatedNumber(property.square_feet)}
                  {" sq ft"}
                </Typography>
              ) : (
                <Typography>--</Typography>
              )}
            </div>
            <div className="sm-col sm-col-6 inline-label-body">
              <Typography
                variant="subtitle2"
                className="show-view__label"
                style={{ whiteSpace: "nowrap" }}
              >
                Number of bedrooms
              </Typography>
              <Typography variant="body1" className="show-view__body">
                {property.bedrooms ? property.bedrooms : "--"}
              </Typography>
            </div>
            <div className="sm-col sm-col-6 inline-label-body">
              <Typography
                variant="subtitle2"
                className="show-view__label"
                style={{ whiteSpace: "nowrap" }}
              >
                Number of bathrooms
              </Typography>
              <Typography variant="body1" className="show-view__body">
                {property.bathrooms ? property.bathrooms : "--"}
              </Typography>
            </div>
            <div className="sm-col sm-col-6 inline-label-body">
              <Typography
                variant="subtitle2"
                className="show-view__label"
                style={{ whiteSpace: "nowrap" }}
              >
                Number of beds
              </Typography>
              <Typography variant="body1" className="show-view__body">
                {property.beds ? property.beds : "--"}
              </Typography>
            </div>
            <div className="sm-col sm-col-6 inline-label-body">
              <Typography
                variant="subtitle2"
                className="show-view__label"
                style={{ whiteSpace: "nowrap" }}
              >
                Number of guests
              </Typography>
              <Typography variant="body1" className="show-view__body">
                {property.total_guests ? property.total_guests : "--"}
              </Typography>
            </div>
          </div>
        </div>

        <div style={{ clear: "both" }} />
        <div className="pl2 pr2 mt4">
          <div className="mb1">
            <Typography variant="subtitle2">Onsite laundry</Typography>
            <Typography variant="caption" style={{ fontSize: "14px" }}>
              Indicates if there is an onsite washer and/or dryer available for
              your pro to use during the job.
            </Typography>
          </div>
          {!isLaundryAvailable && (
            <Typography style={{ fontSize: 14, opacity: 0.7 }}>--</Typography>
          )}
          <div className="grid-container">
            {property.washer && (
              <div className="sm-col sm-col-6 inline-label-body">
                <Typography variant="body1" className="show-view__body">
                  Washer
                </Typography>
              </div>
            )}
            {property.dryer && (
              <div className="sm-col sm-col-6 inline-label-body">
                <Typography variant="body1" className="show-view__body">
                  Dryer
                </Typography>
              </div>
            )}
          </div>
        </div>
        <div className="pl2 pr2 mt4">
          <div>
            <div className="">
              <Typography variant="subtitle2">
                Special cleaning tasks
              </Typography>
              <Typography variant="caption">
                Below are the special cleaning tasks that are required in
                addition to the cleaning checklist.
              </Typography>
              <div>
                {!isEmpty(amenities) ? (
                  <div
                    style={{
                      display: "inline-flex",
                      gap: "8px",
                      flexWrap: "wrap",
                      marginTop: "8px",
                    }}
                  >
                    {Object.entries(amenities).map(([key]) => (
                      <Chip
                        size="small"
                        key={key}
                        label={SpecialRequirements[key]}
                      />
                    ))}
                  </div>
                ) : (
                  <Typography
                    variant="caption"
                    component="p"
                    style={{ fontStyle: "italic", opacity: 0.6, paddingTop: 8 }}
                  >
                    Nothing selected
                  </Typography>
                )}
              </div>
            </div>
          </div>
        </div>

        <div style={{ clear: "both" }} />
        <div className="pl2 pr2 mt4">
          <Typography variant="subtitle1">
            WiFi connection instructions for pros
          </Typography>
          <Typography variant="caption">
            Pros might not have cell service at the property. This assists in
            communication while working.
          </Typography>
          <div className="grid-container mt2">
            <div className="sm-col sm-col-6 inline-label-body">
              {property.wifi_instructions ? (
                <>
                  {property.wifi_instructions.network && (
                    <div className="lg-col lg-col-6 inline-label-body">
                      <Typography
                        variant="subtitle2"
                        className="show-view__label"
                      >
                        Network name
                      </Typography>
                      <Typography variant="body1" className="show-view__body">
                        {property.wifi_instructions.network}
                      </Typography>
                    </div>
                  )}
                  {property.wifi_instructions.password && (
                    <div className="lg-col lg-col-6 inline-label-body">
                      <Typography
                        variant="subtitle2"
                        className="show-view__label"
                      >
                        WiFi password
                      </Typography>
                      <Typography variant="body1" className="show-view__body">
                        {property.wifi_instructions.password}
                      </Typography>
                    </div>
                  )}
                </>
              ) : (
                <Typography style={{ fontSize: 14, opacity: 0.7 }}>
                  --
                </Typography>
              )}
            </div>
          </div>
        </div>

        <div style={{ clear: "both" }} />
        <div className="pl2 pr2 mt4">
          <Typography variant="subtitle1" className="show-view__label">
            Access and instructions
          </Typography>
          {!isPropertyInformationAvailable && (
            <Typography style={{ fontSize: 14, opacity: 0.7 }}>--</Typography>
          )}
          <div className="mt1">
            {property.gate_code && (
              <div className="lg-col lg-col-6 inline-label-body">
                <Typography variant="subtitle2" className="show-view__label">
                  Gate code
                </Typography>
                <Typography variant="body1" className="show-view__body">
                  {property.gate_code}
                </Typography>
              </div>
            )}
            {property.gate_instructions && (
              <div className="lg-col lg-col-12">
                <Typography variant="subtitle2" className="show-view__label">
                  Gate instructions
                </Typography>
                <Typography variant="body1" className="show-view__body">
                  {property.gate_instructions}
                </Typography>
              </div>
            )}
          </div>
          <div style={{ clear: "both" }} />
          <div className="mt3">
            {property.lock_code && (
              <div className="lg-col lg-col-12 inline-label-body">
                <Typography variant="subtitle2" className="show-view__label">
                  Main entrance code
                </Typography>
                <Typography variant="body1" className="show-view__body">
                  {property.lock_code}
                </Typography>
              </div>
            )}
            {property.access_instructions && (
              <div className="lg-col lg-col-12 mt1">
                <Typography variant="subtitle2" className="show-view__label">
                  Main entrance instructions
                </Typography>
                <Typography variant="body1" className="show-view__body">
                  {property.access_instructions}
                </Typography>
              </div>
            )}
          </div>
          <div style={{ clear: "both" }} />
          <div className="mt3">
            {property.maid_closet_code && (
              <div className="lg-col lg-col-12 inline-label-body">
                <Typography variant="subtitle2" className="show-view__label">
                  Cleaning closet code
                </Typography>
                <Typography variant="body1" className="show-view__body">
                  {property.maid_closet_code}
                </Typography>
              </div>
            )}
            {property.maid_closet_info && (
              <div className="lg-col lg-col-12 mt1">
                <Typography variant="subtitle2" className="show-view__label">
                  Cleaning closet instructions
                </Typography>
                <Typography variant="body1" className="show-view__body">
                  {property.maid_closet_info}
                </Typography>
              </div>
            )}
          </div>
          <div className="sm-col sm-col-12 mt3">
            <Typography
              variant="subtitle2"
              className="inline-label-body__label"
              style={{ paddingRight: 32 }}
            >
              Pro parking info
            </Typography>
            {property?.parking_info ? (
              <Typography variant="body1" className="inline-label-body__body">
                {property.parking_info}
              </Typography>
            ) : (
              <div>
                <Typography variant="caption">
                  Provide street parking availability, pass requirements, or any
                  other relevant instructions.
                </Typography>
                <Typography variant="body2">--</Typography>
              </div>
            )}
          </div>
        </div>

        <div style={{ clear: "both" }} />
        <div className="pl2 pr2 mt4">
          <Typography variant="subtitle1" className="show-view__label">
            Trash instructions
          </Typography>
          <div className="lg-col lg-col-12">
            {property.trash_instructions ? (
              <Typography variant="body1" className="show-view__body">
                {property.trash_instructions}
              </Typography>
            ) : (
              <>
                <div className="mb1">
                  <Typography variant="caption">
                    Provide details on how pros can access trash cans and the
                    trash pickup schedule.
                  </Typography>
                </div>
                <Typography style={{ fontSize: 14, opacity: 0.7 }}>
                  --
                </Typography>
              </>
            )}
          </div>
        </div>

        <div style={{ clear: "both" }} />
        <div className="pl2 pr2 mt4">
          <Typography variant="subtitle1" className="show-view__label">
            Cleaning checklist
          </Typography>

          <div className="lg-col lg-col-12">
            {property.cleaning_guide_url ? (
              <Link
                target="_blank"
                href={`${property.cleaning_guide_url}`}
                className="show-view__link"
                style={{ wordBreak: "break-all" }}
              >
                {property.cleaning_guide_url}
              </Link>
            ) : (
              <Typography style={{ fontSize: 14, opacity: 0.7 }}>--</Typography>
            )}
          </div>
        </div>

        <div className="pl2 pr2 pt3">
          <div className="grid-container">
            <div className="sm-col sm-col-6 inline-label-body">
              <Typography variant="body2" className="inline-label-body__label">
                Check in time
              </Typography>
              <Typography variant="body1" className="inline-label-body__body">
                {getFormattedCheckInOutTime(property.check_in_time)}
              </Typography>
            </div>
            <div className="sm-col sm-col-6 inline-label-body">
              <Typography variant="body2" className="inline-label-body__label">
                Check out time
              </Typography>
              <Typography variant="body1" className="inline-label-body__body">
                {getFormattedCheckInOutTime(property.check_out_time)}
              </Typography>
            </div>
          </div>

          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleClick()}
            style={{ marginTop: "24px", marginBottom: "48px" }}
          >
            Edit
          </Button>
        </div>
      </>
    );
  }
};

export default connect(
  (state) => ({
    property: getPMSProperty(state),
    spacesObject: getSpaces(state),
  }),
  (dispatch) => bindActionCreators({ fetchProperty, setDialogOpen }, dispatch)
)(PropertyDetails);
