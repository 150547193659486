import React from "react";
import OutdoorGrillIcon from "@material-ui/icons/OutdoorGrill";
import { isEmpty } from "lodash";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import FireplaceIcon from "@material-ui/icons/Fireplace";
import PetsIcon from "@material-ui/icons/Pets";
import { ReactComponent as PatioCustomIcon } from "../images/mui-icons/table.svg";
import { ReactComponent as WashDishesCustomIcon } from "../images/mui-icons/wine.svg";
import { ReactComponent as DishwasherCustomIcon } from "../images/mui-icons/dishwasher.svg";
import { ReactComponent as OffSiteLaundryCustomIcon } from "../images/mui-icons/laundary.svg";
import LocalLaundryServiceIcon from "@material-ui/icons/LocalLaundryService";

import { PropertyModel } from "../models";

interface JobSpecialRequirementsProps {
  property: PropertyModel;
}

const supportedIcons = {
  bbq_grill: <OutdoorGrillIcon />,
  firepit: <WhatshotIcon />,
  fireplace: <FireplaceIcon />,
  pet_cleaning: <PetsIcon />,
  patio: <PatioCustomIcon />,
  wash_dishes: <WashDishesCustomIcon />,
  washer: <LocalLaundryServiceIcon />,
  dryer: <LocalLaundryServiceIcon />,
  dishwasher: <DishwasherCustomIcon />,
  offsite_laundry: <OffSiteLaundryCustomIcon />,
};

interface AmenityProps {
  label: string;
  icon: string;
}

const JobSpecialRequirements: React.FC<JobSpecialRequirementsProps> = ({
  property,
}) => {
  if (!property) return null;

  const { amenities } = property;
  const amenitiesToShow: AmenityProps[] = [];

  if (amenities) {
    Object.keys(amenities).forEach((key) => {
      if (supportedIcons[key]) {
        amenitiesToShow.push({
          label: amenities[key].label || key,
          icon: supportedIcons[key],
        });
      }
    });
  }

  return (
    !isEmpty(amenitiesToShow) && (
      <>
        <div className="mb-4">
          <h3 className="text-lg">
            <CheckCircleIcon style={{ fontSize: 20, opacity: 0.8 }} /> Special
            requirements
          </h3>
          <div className="mt-2">
            <div>
              {amenitiesToShow.map((amenity, index) => (
                <div key={index}>
                  <div>
                    {amenity.icon}
                    {amenity.label}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <hr className="opacity-40" />
      </>
    )
  );
};

export default JobSpecialRequirements;
