import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { parseQuery } from "../../helpers/url";
import { OptimizelyFeature } from "@optimizely/react-sdk";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Box from "@material-ui/core/Box";
import DialogTitle from "@material-ui/core/DialogTitle";

import { fetchProperty } from "../../actions/pmsProperties";
import { getPMSProperty } from "../../reducers/pmsProperties";
import ChangeProSection from "../ChangeProSection";
import AssignProSection from "../AssignProSection";

import history from "../../helpers/history";

import { PMS_PROPERTIES_NEW, JOBS } from "../../routes";
import { Analytics } from "../../services/analyticsService.tsx";

const NewJobAutomation = (props) => {
  const { fetchProperty, property = {}, propertyId, styles } = props;

  const { opu } = parseQuery();

  const isOnboarding = opu === "onboarding";

  const assignedPro = property.auto_assigned_maid || null;

  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [open, setOpen] = useState(false);

  const containerWidth = styles.stepperBody.maxWidth || "400px";

  const localStyles = {
    flexColumn: {
      display: "flex",
      flexDirection: "column",
      width: containerWidth,
    },
    boder2: {
      borderWidth: 2,
    },
    mt1: {
      marginTop: "8px",
    },
    mt2: {
      marginTop: "16px",
    },
    mt4: {
      marginTop: "32px",
    },
  };

  const addAnotherProperty = (param) => {
    if (param === "prompt") {
      setOpen(true);
    } else {
      history.push({
        state: {},
      });
    }
  };

  const goToJobs = () => {
    history.push({
      pathname: JOBS,
      state: {},
    });
  };

  const goToStaging = () => {
    if (assignedPro) {
      if (isOnboarding) {
        Analytics.trackSimple("onboarding_job_automation");
      } else {
        Analytics.trackSimple("new_property_job_automation");
      }
    } else {
      if (isOnboarding) {
        Analytics.trackSimple("onboarding_job_automation_skipped");
      } else {
        Analytics.trackSimple("new_property_job_automation_skipped");
      }
    }

    history.push({
      search: isOnboarding ? "opu=onboarding" : "",
      state: { id: propertyId, nextStep: "staging" },
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleNewProperty = () => {
    handleClose();
    history.push({
      state: {},
    });
  };

  useEffect(() => {
    if (propertyId) {
      fetchProperty(propertyId);
    }
  }, []);

  if (property.isLoading) {
    return <CircularProgress color="primary" />;
  } else {
    return (
      <>
        <div style={localStyles.flexColumn}>
          {assignedPro ? (
            <ChangeProSection
              property={property}
              isNewPropertyFlow={true}
              isOnboarding={isOnboarding}
            />
          ) : (
            <AssignProSection
              property={property}
              isNewPropertyFlow={true}
              isOnboarding={isOnboarding}
            />
          )}
          <div style={localStyles.mt2}>
            <OptimizelyFeature feature="staging_guide">
              {(isStagingGuideVisible) =>
                isStagingGuideVisible ? (
                  <Button
                    variant={assignedPro ? "contained" : "outlined"}
                    color="primary"
                    disableElevation
                    style={localStyles.flexColumn}
                    onClick={() => goToStaging()}
                  >
                    {assignedPro ? "Next: Staging guide" : "Skip"}
                  </Button>
                ) : (
                  <Button
                    variant={assignedPro ? "contained" : "outlined"}
                    color="primary"
                    disableElevation
                    style={localStyles.flexColumn}
                    onClick={() => goToStaging()}
                  >
                    {assignedPro
                      ? "Finsih onboarding"
                      : "Skip, I'll do it later"}
                  </Button>
                )
              }
            </OptimizelyFeature>
          </div>
        </div>
        {/* <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ paddingBottom: 0 }}>
            Skip pro assignment?
          </DialogTitle>
          <DialogContent>
            You're trying to skip assigning pro for the{" "}
            <Box
              component="div"
              display="inline"
              p={0.5}
              m={0.2}
              bgcolor="#eaeaea"
            >{`${property.title}`}</Box>{" "}
            property. This means you'll have to manually assign jobs to a pro.
            You can still automatically assign a pro under Properties view
            later.
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleNewProperty}
              color="primary"
              variant="contained"
              autoFocus
              disableElevation
            >
              Skip, add a property
            </Button>
          </DialogActions>
        </Dialog> */}
      </>
    );
  }
};

export default connect(
  (state) => ({ property: getPMSProperty(state) }),
  (dispatch) => bindActionCreators({ fetchProperty }, dispatch)
)(NewJobAutomation);
