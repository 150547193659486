import { SET_CURRENT_USER, SET_CURRENT_USER_TOKEN } from "../actions/user";

export const getUser = ({ user }) => user;
export const getUserToken = ({ userToken }) => userToken;

export const user = (state = {}, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return { ...state, ...action.user };

    default:
      return state;
  }
};

export const userToken = (state = {}, action) => {
  switch (action.type) {
    case SET_CURRENT_USER_TOKEN:
      return { ...state, ...action.data };

    default:
      return state;
  }
};
