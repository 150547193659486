import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import snackbar from "./reducer_snackbar";
import { invoices } from "./previousInvoices";
import { subscribe } from "./reducer_subscribe";

import {
  housekeeperCleanings,
  housekeeperCleaning,
  housekeeperCleaingsStatus,
} from "./housekeeperCleanings";
import { pmsCleaning } from "./pmsCleaning";
import { promotion } from "./promotion";
import { notifications } from "./notifications";
import { dialog, dialogObject } from "./dialog";
import { properties, property } from "./pmsProperties";
import { pmsHousekeepers, pmsMyHousekeeper } from "./pmsMyHousekeepers";
import { reservations, reservation } from "./pmsReservations";
import eventDetails from "./new_eventDetails";
import { loading, modalLoading } from "./loading";
import { user, userToken } from "./user";
import { overlay } from "./overlayLoading";
import { myPros, categories } from "./pros";
import { jobs, job } from "./jobs";
import { clients, clientProperties, invitedClients } from "./clients";
import { onboardingAnalytics } from "./misc";
import { walkthroughTasks } from "../reducers/walkthroughTasks";
import { spaces, space, tasks } from "./staging";

import {
  paymentReminders,
  payment,
  payoutBalance,
  paymentMethod,
  payoutAccount,
  cleaningPaymentInvoices,
} from "./payments";

const rootReducer = combineReducers({
  form: formReducer,
  pmsHousekeepers,
  promotion,
  pmsMyHousekeeper,
  invitedClients,
  housekeeperCleaning,
  clientProperties,
  onboardingAnalytics,
  pmsCleaning,
  properties,
  subscribe,
  property,
  invoices,
  overlay,
  snackbar,
  clients,
  user,
  spaces,
  space,
  tasks,
  userToken,
  jobs,
  job,
  myPros,
  categories,
  eventDetails,
  paymentMethod,
  payoutAccount,
  dialogObject,
  paymentReminders,
  cleaningPaymentInvoices,
  payment,
  payoutBalance,
  housekeeperCleanings,
  housekeeperCleaingsStatus,
  walkthroughTasks,
  dialog,
  notifications,
  reservations,
  reservation,
  loading,
  modalLoading,
});

export default rootReducer;
