import React, { useState } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { bindActionCreators } from "redux";

import Button from "@material-ui/core/Button";

import { fetchMyPros } from "../actions/pros";
import { fetchProperties } from "../actions/pmsProperties";
import { fetchProsJobs } from "../actions/jobs";
import { fetchCleaningPaymentInvoices } from "../actions/payments";
import { fetchHostJobs } from "../actions/jobs";
import { fetchPreviousInvoices } from "../actions/previousInvoices";
import { fetchOpsClientProperties, fetchOpsClients } from "../actions/ops";

const Pagination = (props) => {
  const {
    type,
    nextPage,
    prevPage,
    fetchMyPros,
    fetchHostJobs,
    fetchProperties,
    fetchOpsClients,
    fetchProsJobs,
    fetchPreviousInvoices,
    fetchOpsClientProperties,
    fetchCleaningPaymentInvoices,
  } = props;
  const [isFetching, setFetching] = useState(false);

  const fetchMoreResults = () => {
    if (type === "hostJobs") {
      fetchHostJobs(nextPage);
    } else if (type === "properties") {
      fetchProperties({ nextProperties: nextPage, allProperties: false });
    } else if (type === "prosJobs") {
      fetchProsJobs({ next: nextPage, renderLoadingScreen: false });
    } else if (type === "previousInvoices") {
      fetchPreviousInvoices(nextPage);
    } else if (type === "cleaningPaymentInvoices") {
      fetchCleaningPaymentInvoices(nextPage);
    } else if (type === "pros") {
      fetchMyPros(nextPage);
    } else if (type === "clients") {
      fetchOpsClients(nextPage);
    } else if (type === "clientsProperties") {
      fetchOpsClientProperties(nextPage)
    } else {
      console.log(`attempting to fetch ${type}`);
    }
  };

  const disableShowMore = nextPage === null;
  const hideShowMore =
    (prevPage === null || prevPage === undefined) && nextPage === null;

  return !hideShowMore ? (
    <div className="flex full-width justify-center mt2 mb3">
      <Button
        variant="outlined"
        color="primary"
        disabled={disableShowMore}
        onClick={() => fetchMoreResults()}
      >
        Show more
      </Button>
    </div>
  ) : (
    ""
  );
};

export default connect(
  (state) => ({}),
  (dispatch) =>
    bindActionCreators(
      {
        fetchMyPros,
        fetchProsJobs,
        fetchProperties,
        fetchHostJobs,
        fetchPreviousInvoices,
        fetchOpsClients,
        fetchOpsClientProperties,
      },
      dispatch
    )
)(Pagination);
