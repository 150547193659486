import React, { Component } from "react";
import { connect } from "react-redux";
import { CardElement, injectStripe } from "react-stripe-elements";
import { Link } from "react-router-dom";
import { Button, CircularProgress } from "@material-ui/core";
import Moment from "react-moment";
import amplitude from "amplitude-js";

import Navigation from "../containers/navigation";
import { chargeCC } from "../actions/subscription";
import { fetchSubscription } from "../actions/subscription";
import Card from "../components/card";
import { serverError } from "../helpers/helper_serverError";
import { JOBS, MANAGE_SUBSCRIPTION } from "../routes";
import { DATE_FORMAT_FULL } from "../constants/apiEnum";

import "./styles.scss";

class CheckoutForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      isLoading: false,
    };
  }

  componentDidMount() {
    this.props.fetchSubscription();
  }
  handleSubmit = (ev) => {
    ev.preventDefault();
    this.setState({ isLoading: true });

    if (this.props.stripe) {
      this.props.stripe
        .createToken({ name: this.state.name })
        .then(({ error, token }) => {
          if (error) {
            this.setState({ isLoading: false });
          } else {
            this.props
              .chargeCC(token)
              .then((res) => {
                if (res) {
                  this.setState({ isLoading: false });
                } else {
                  this.setState({ isLoading: false });
                }
              })
              .catch((error) => {
                this.setState({ isLoading: false });
              });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });

          this.props.serverError(error);
        });
    } else {
      this.setState({ isLoading: false });
    }
  };

  handleChange = (event) => {
    this.setState({ name: event.target.value });
  };

  renderSubscribeForm = (subscribe) => {
    return [
      <h3 key="subscribe-header" className="header-3 trial subscribe-header">
        Subscribe
      </h3>,
      <p key="subscribe-subheader" className="subscribe-subheader"></p>,
      <div key="subscribe-payment-information" className="subscribe-info">
        <div className="info info-count">
          <h2 className="info-title">
            {subscribe.data ? subscribe.data.properties_count : "n/a"}
          </h2>
          <small className="info-label">Listings</small>
        </div>
        <div className="info">
          <h2 className="info-title info-small">X</h2>
        </div>
        <div className="info info-amount">
          <h2 className="info-title">$5</h2>
          <small className="info-label">/listing/month</small>
        </div>
      </div>,
      <div key="subscribe-payment-today" className="subscribe-payment">
        <h3 className="header-4 payment-header">
          Your total for today: $
          {subscribe.data ? subscribe.data.total_monthly_fee : "0.00"}
        </h3>
        <div className="payment-message">
          Your credit card will automatically be charged on the{" "}
          <Moment format={DATE_FORMAT_FULL}>{new Date()}</Moment> of every
          month.
        </div>
        <small className="payment-important">
          No contract. Cancel anytime.
        </small>
      </div>,
      <div key="subscribe-form" className="subscribe-form">
        <form onSubmit={this.handleSubmit}>
          {window.Stripe ? (
            [
              <label className="input-label" key="subscribe-name">
                Name
                <input
                  name="name"
                  value={this.state.name}
                  className="input-text"
                  type="text"
                  placeholder="Jane Doe"
                  required
                  onChange={this.handleChange}
                />
              </label>,
              <CardElement key="subscribe-card-elements" />,
            ]
          ) : (
            <CircularProgress size={24} className="button-progress" />
          )}
          <div className="flex align-center space-between">
            <small className="small">
              By subscribing you are agreeing to{" "}
              <a href="https://automatebnb.com/terms-of-service" target="_new">
                Terms of use
              </a>{" "}
              and{" "}
              <a href="https://automatebnb.com/privacy" target="_new">
                Privacy
              </a>
              .
            </small>
            <Button
              className="host-primary"
              variant="contained"
              color="primary"
              type="submit"
              disabled={this.state.isLoading}
              style={{ width: "98px", height: "36px" }}
            >
              {this.state.isLoading ? (
                <CircularProgress size={24} className="button-progress" />
              ) : (
                "Subscribe"
              )}
            </Button>
          </div>
        </form>
      </div>,
    ];
  };

  renderSuccessMessage = () => [
    <h3 key="success-header" className="header-3 trial success-header">
      Subscribed
    </h3>,
    <p key="success-body" className="success-body">
      You have been successfully subscribed.
    </p>,
    <div
      key="success-actions"
      className="flex align-center space-between subscribed-action"
    >
      <Link
        to={MANAGE_SUBSCRIPTION}
        className="button button-host button-ghost"
      >
        Manage subscription
      </Link>
      <Link to={JOBS} className="button button-host">
        Go home
      </Link>
    </div>,
  ];

  render() {
    const { subscribe } = this.props;

    return [
      <Navigation key="appbar-navigation-subscribe-page" />,
      <div key="subscribe-page" className="subscribe mt4">
        <Card key="subscribe-card" className="card-elevated">
          {subscribe.status === 200 ? (
            subscribe.data.status === "ACTIVE" ? (
              this.renderSuccessMessage()
            ) : (
              this.renderSubscribeForm(subscribe)
            )
          ) : (
            <CircularProgress size={24} className="button-progress" />
          )}
        </Card>
      </div>,
    ];
  }
}

function mapStateToProps(state) {
  return {
    subscribe: state.subscribe,
  };
}

export default connect(mapStateToProps, {
  chargeCC,
  serverError,
  fetchSubscription,
})(injectStripe(CheckoutForm));
