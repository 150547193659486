import axios from "../helpers/axios";
import { serverError } from "../helpers/helper_serverError";

import { parseQuery } from "../helpers/url";

import { setContentLoading } from "./loading";
import { fetchPaymentReminders } from "./payments";

import { CLEANER_STATUS_COMPLETED } from "../constants/apiEnum";

export const SET_HOUSEKEEPER_CLEANINGS = "set_housekeeper_cleanings";
export const RESET_HOUSEKEEPER_CLEANINGS = "reset_housekeeper_cleanings";
export const SET_HOUSEKEEPER_CLEANING = "set_housekeeper_cleaning";
export const SET_HOUSEKEEPER_CLEANINGS_STAUTS =
  "set_housekeeper_cleanings_status";
export const SET_HOUSEKEEPER_CLEANING_STATUS =
  "set_housekeeper_cleaning_status";
export const SET_HOUSEKEEPER_CLEANING_COMPLETED =
  "set_housekeeper_cleaning_completed";

export function fetchCleanings(next) {
  const { type, request_payment, cleaning_status } = parseQuery();

  return function action(dispatch) {
    if (!next) {
      dispatch(setContentLoading(true));
      dispatch(resetHousekeeperCleanings());
    }

    let cleaningStatus = "";

    if (type === "completed") {
      cleaningStatus = "COMPLETED";
    } else {
      if (cleaning_status) {
        cleaningStatus += cleaning_status;
      } else {
        cleaningStatus = `ACCEPTED, DECLINED, CLEANING DATE CHANGED, WAITING TO ACCEPT`;
      }
    }

    return axios
      .get(
        `/v1/cleanings?${next ? `page=${encodeURIComponent(next)}` : ""}${
          cleaningStatus ? `&filter[statuses]=${cleaningStatus}` : ""
        }${request_payment ? `&request_payment=${request_payment}` : ""}${
          type === "completed" ? `&sort_by=DESC` : ""
        }`
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(setContentLoading(false));
          dispatch(setHousekeeperCleanings(res.data));
        }
      })
      .catch((error) => {
        dispatch(setContentLoading(false));
        dispatch(serverError(error));
      });
  };
}

export function fetchHousekeeperCleaning(id) {
  return function action(dispatch) {
    dispatch(setContentLoading(true));
    return axios
      .get(`/v1/cleanings/${id}`)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setHousekeeperCleaning(res.data));
          dispatch(setContentLoading(false));
        }
      })
      .catch((error) => {
        dispatch(setContentLoading(false));
        dispatch(serverError(error));
      });
  };
}

export function updateCleaningStatus(id, status) {
  return function action(dispatch) {
    return axios
      .put(`/v1/cleanings/${id}/change_status`, { status: status })
      .then((res) => {
        if (res.status === 200) {
          dispatch(setContentLoading(false));
          dispatch(setHousekeeperCleaningStatus(res.data));
          dispatch(setHousekeeperCleaning(res.data));
          if (res.data.status === CLEANER_STATUS_COMPLETED) {
            dispatch(setCleaningCompleted(res.data));
            dispatch(fetchPaymentReminders());
          }
        }
      })
      .catch((error) => {
        dispatch(setContentLoading(false));
        dispatch(serverError(error));
      });
  };
}

export const setHousekeeperCleanings = (data) => ({
  type: SET_HOUSEKEEPER_CLEANINGS,
  data,
});

export const setHousekeeperCleaning = (cleaning) => ({
  type: SET_HOUSEKEEPER_CLEANING,
  cleaning,
});

export const setHousekeeperCleaningsStatus = (status) => ({
  type: SET_HOUSEKEEPER_CLEANINGS_STAUTS,
  status,
});

export const resetHousekeeperCleanings = () => ({
  type: RESET_HOUSEKEEPER_CLEANINGS,
});

export const setHousekeeperCleaningStatus = (data) => ({
  type: SET_HOUSEKEEPER_CLEANING_STATUS,
  data,
});

export const setCleaningCompleted = (data) => ({
  type: SET_HOUSEKEEPER_CLEANING_COMPLETED,
  data,
});
