import {
  CLEANER_STATUS_ACCEPTED,
  CLEANER_STATUS_COMPLETED,
  CLEANER_STATUS_DECLINED,
  CLEANING_CANCELLED,
  CLEANING_DATE_CHANGED,
  CLEANING_STARTED,
  PAYMENT_STATUS_FAILED,
  PAYMENT_STATUS_PENDING,
  PAYMENT_STATUS_PROCESSING,
  PAYMENT_STATUS_SUCCESS,
  WAITING_TO_ACCEPT,
} from "../constants/apiEnum";

const statusMap = {
  [WAITING_TO_ACCEPT]: "Waiting to accept",
  [CLEANER_STATUS_DECLINED]: "Declined",
  [CLEANER_STATUS_ACCEPTED]: "Accepted",
  [CLEANING_STARTED]: "Started",
  [CLEANING_DATE_CHANGED]: "Date changed",
  [PAYMENT_STATUS_PENDING]: "Payment pending",
  [PAYMENT_STATUS_SUCCESS]: "Payment paid",
  [PAYMENT_STATUS_PROCESSING]: "Payment processing",
  [PAYMENT_STATUS_FAILED]: "Payment failed",
  [CLEANER_STATUS_COMPLETED]: "Completed",
};

export const getNormalizedStatus = (status = WAITING_TO_ACCEPT) => {
  console.log();
  return statusMap[status] || status;
};

export const getStatusColor = (status) => {
  switch (status) {
    case CLEANING_STARTED:
      return "text-indigo-300";
    case WAITING_TO_ACCEPT:
      return "text-orange-500";
    case CLEANER_STATUS_ACCEPTED:
      return "text-green-700";
    case CLEANER_STATUS_DECLINED:
      return "text-red-500";
    case CLEANING_DATE_CHANGED:
      return "text-gray-600";
    case CLEANER_STATUS_COMPLETED:
      return "text-gray-600";
    case PAYMENT_STATUS_PENDING:
      return "text-red-500";
    case PAYMENT_STATUS_SUCCESS:
      return "text-green-600";
    case PAYMENT_STATUS_PROCESSING:
      return "text-orange-500";
    case PAYMENT_STATUS_FAILED:
      return "text-red-500";

    default:
      return "text-black";
  }
};
