import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Button from "@material-ui/core/Button";

import { getMyPros } from "../reducers/pros";

import { openThisDialog } from "../actions/dialog";
import { fetchMyPros } from "../actions/pros";

import {
  DT_ASSIGN_PRO_TO_PROPERTY,
  DT_INVITE_PRO,
} from "../constants/dialogTypes";
import { isEmpty } from "lodash";
import { Analytics } from "../services/analyticsService.tsx";

const AssignProSection = (props) => {
  const {
    property = {},
    openThisDialog,
    isOnboarding = true,
    isNewPropertyFlow = false,
    pros = {},
    fetchMyPros,
  } = props;
  const { id: propertyId = null } = property;

  const handleChangePro = () => {
    if (isOnboarding) {
      Analytics.trackSimple("onboarding_assign_pro_action_from_job_automation");
    } else {
      Analytics.trackSimple(
        "new_property_assign_pro_action_from_job_automation"
      );
    }
    openThisDialog({
      dialogType: DT_ASSIGN_PRO_TO_PROPERTY,
      propertyId,
      property,
    });
  };

  useEffect(() => {
    if (isEmpty(pros)) {
      fetchMyPros();
    }
  }, []);

  const handleInvitePro = () => {
    if (isOnboarding) {
      Analytics.trackSimple(
        "onboarding_invite_and_assign_pro_action_from_job_automation"
      );
    } else {
      Analytics.trackSimple(
        "new_property_invite_and_assign_pro_action_from_job_automation"
      );
    }
    openThisDialog({
      dialogType: DT_INVITE_PRO,
      propertyId,
      isNewPropertyFlow,
    });
  };

  const localStyles = {
    wrapper: {
      width: isNewPropertyFlow ? "100%" : "375px",
      marginTop: "32px",
    },
  };

  const RenderProAssignment = () => {
    if (isEmpty(pros)) {
      return (
        <Button
          variant="contained"
          color="primary"
          fullWidth={isNewPropertyFlow}
          disableElevation
          onClick={handleInvitePro}
        >
          Invite & Assign pro
        </Button>
      );
    } else {
      return (
        <Button
          variant="contained"
          color="primary"
          fullWidth={isNewPropertyFlow}
          disableElevation
          onClick={handleChangePro}
        >
          Assign pro
        </Button>
      );
    }
  };

  return (
    <div className="empty-state">
      <div style={localStyles.wrapper}>
        <h3 className="header-3">No pro assigned</h3>
        <p className="grey">
          The assigned pro will be automatically notified when new jobs are
          created, canceled, or there is a change in check-in or check-out
          dates.
        </p>
        <div className="mt2">
          <RenderProAssignment />
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    pros: getMyPros(state).items,
  }),
  (dispatch) => bindActionCreators({ openThisDialog, fetchMyPros }, dispatch)
)(AssignProSection);
