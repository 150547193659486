import { MixpanelInstance } from "./MixpanelManager.tsx";
import axios from "../helpers/axios";

class AnalyticsService {
  static analyticsInstance: any = null;

  constructor() {
    if (!AnalyticsService.analyticsInstance) {
      AnalyticsService.analyticsInstance = this;
    }
    return AnalyticsService.analyticsInstance;
  }

  private analyticsClient = MixpanelInstance; // setting the default one

  setAnalyticsClient = (clientName: string) => {
    switch (clientName.toUpperCase()) {
      case "MIXPANEL":
      default:
        this.analyticsClient = MixpanelInstance;
        break;
    }
  };

  trackEvent = (event: string, properties: {}) => {
    this.analyticsClient.track(event, {
      ...properties,
      ...this.getDefaultProperties(),
    });
  };

  trackSimple = (event: string) => {
    this.analyticsClient.track(event, this.getDefaultProperties());
  };

  identify = (userId: string) => {
    this.analyticsClient.identify(userId);
  };

  setUserProperty = (properties: any) => {
    this.analyticsClient.getPeople().set(properties);
  };

  timeEvent(name) {
    this.analyticsClient.timeEvent(name);
  }

  register = (event: object) => {
    this.analyticsClient.register(event);
  };

  getDefaultProperties() {
    const isDevAPI = axios.defaults.baseURL.includes(
      "https://automatebnb-api-dev."
    );
    return {
      env: isDevAPI ? "dev" : "production",
      source: "webapp",
    };
  }
  reset() {
    this.analyticsClient.reset();
  }
}

const Analytics = new AnalyticsService();

export { AnalyticsService, Analytics };
