import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Moment from "react-moment";

// Material Components
import { Button, Chip } from "@material-ui/core";
import LoopIcon from "@material-ui/icons/Loop";
import LaunchIcon from "@material-ui/icons/Launch";
import Link from "@material-ui/core/Link";
import { Typography, useMediaQuery } from "@material-ui/core";

// Local Components
import NormalizedCleaningStatus from "../components/normalizedCleaningStatus";
import LoadingScreen from "../components/loadingScreen";
import SameDayFlip from "../components/sameDayFlip";
import { JOBS } from "../routes";
import JobLocation from "../components/jobLocation";
import NomalizedCleaningStatus from "../components/normalizedCleaningStatus";
import MarketplaceDetails from "../components/MarketplaceDetails";

import JobSchedule from "../components/jobSchedule";
import CleaningImageViewer from "../components/cleaningImageViewer";
import AssignedPros from "../containers/jobAssignedPros";
import HostDetails from "../components/jobHostDetails";
import GuestReservationDetails from "../components/jobGuestReservationDetails";
import PaymentDetails from "../components/paymentDetails";
import GuestRating from "../components/guestRating";

// Containers
import JobActions from "../containers/jobActions";

// Helpers
import { getFormattedCheckInOutTime } from "../helpers/format";
import { parseQuery } from "../helpers/url";

// Constants
import {
  DT_JOB_DESCRIPTION,
  DT_CHANGE_A_PRO,
  DIALOG_TYPE_MAKE_CLEANING_PAYMENT,
  DT_CANCEL_JOB,
  DT_UPDATE_JOB_DATE_TIME,
} from "../constants/dialogTypes";
import {
  PAYMENT_STATUS_PENDING,
  PAYMENT_STATUS_SUCCESS,
  CLEANING_CANCELLED,
  CLEANER_STATUS_COMPLETED,
  DATE_FORMAT_FULL,
  DATE_FORMAT,
  CLEANER_STATUS_DECLINED,
  PAYMENT_STATUS_PROCESSING,
  MARKETPLACE,
} from "../constants/apiEnum";

// Actions
import { fetchOpsJobDetails } from "../actions/ops";
import { fetchWalkthroughTasks } from "../actions/jobs";
import { openThisDialog, setDialogOpen } from "../actions/dialog";

// Reducer
import { getJobDetails } from "../reducers/jobs";
import { getUser, getUserToken } from "../reducers/user";
import { getWalkthroughTasks } from "../reducers/walkthroughTasks";
import { Analytics } from "../services/analyticsService.tsx";

function RenderJob(props) {
  const {
    setDialogOpen,
    openThisDialog,
    id: jobId,
    job_date: jobDate,
    property = {},
    pro = {},
    is_past: isPast,
    reservation = {},
    job_fee,
    is_flip: isFlip,
    host_name: hostName,
    host_phone: hostPhone,
    is_completed: isCompleted,
    job_time: jobTime = "11:00",
    status,
    additional_fee,
    marketplace_fee,
    total_payout_minus_fees,
    total_fee,
    cleaning_fee_note: paymentDescription,
    address,
    payment,
    description,
    assignments = {},
    user,
    userToken,
    addon_fees,
    special_request,
    guest_rating,
    guest_review,
    tasks,
  } = props;

  const isMobile = useMediaQuery("(max-width:600px)");

  // Extract properties key,value
  const {
    title = "",
    check_in_time: jobCheckinTime = "2:00",
    check_out_time: jobCheckoutTime = "11:00",
    id: propertyId,
    zip = "00000",
    city = "",
    state = "",
  } = property;
  // Extract Pros key,value
  const {
    id: proId = "",
    name: proName = "",
    phone: proPhoneNumber = "",
    category = {},
  } = pro || {};
  // Extract reservations key,value
  const {
    channel = "",
    check_in: reservationCheckIn = "",
    check_out: reservationCheckOut = "",
    airbnb_reservation_id: airbnbReservationId = "",
    airbnb_reservation_url: airbnbReservationUrl = "",
    homeaway_reservation_id: homeawayReservationId = "",
    ta_reservation_id: tripAdvisorReservationId = "",
    vrbo_reservation_id: vrboReservationId,
    duration = "",
  } = reservation;

  // Check if description is empty
  const isDescriptionEmpty = isEmpty(description);

  // Get a formatted time based on military time
  const formattedCheckoutTime = getFormattedCheckInOutTime(jobTime);
  const formattedCheckinTime = getFormattedCheckInOutTime(jobCheckinTime);
  const isJobCanceled = status === CLEANING_CANCELLED;

  const isMarketplace = channel === MARKETPLACE;
  const isJobCompleted = status === CLEANER_STATUS_COMPLETED;

  // Set job as inactive if a job is Completed or Cancelled
  const isJobNotActive =
    isMarketplace || isCompleted || isJobCanceled || isPast;

  const RenderDateTime = () => {
    return isJobCanceled ? (
      <>
        <Moment format={DATE_FORMAT}>{jobDate}</Moment> {"- Job is canceled"}
      </>
    ) : (
      <>
        <Moment format={DATE_FORMAT}>{jobDate}</Moment>
        {" at "}
        {formattedCheckoutTime}
      </>
    );
  };

  const RenderJobStatus = (props) => {
    const { proJobStatus } = props;
    if (isCompleted) {
      if (!proJobStatus && !isEmpty(payment)) {
        return (
          <NormalizedCleaningStatus
            status={payment.status}
            human={payment.status_human}
          />
        );
      } else {
        return <NormalizedCleaningStatus status={status} />;
      }
    } else {
      return <NormalizedCleaningStatus status={status} />;
    }
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content pt3">
          <div className="job__header mb2" key="host-job-primary-action">
            {isMobile ? (
              <div key="desktop-page-header-first" className="">
                <h1 className="page-header">
                  <RenderDateTime />
                </h1>
                <RenderJobStatus status={status} />
              </div>
            ) : (
              <div key="desktop-page-header-first" className="">
                <h1 className="page-header">
                  <RenderDateTime />
                </h1>
                <RenderJobStatus status={status} />
              </div>
            )}
          </div>
          <div className="flex items-center ml2">
            {isFlip && (
              <Chip
                icon={<LoopIcon style={{ color: "white" }} />}
                label="Same day flip"
                size="small"
                style={{
                  backgroundColor: "#ff2929",
                  color: "white",
                  marginRight: 4,
                }}
              />
            )}
            {isMarketplace && (
              <Chip color="primary" label="Marketplace job" size="small" />
            )}
          </div>
          {!isEmpty(payment) && (
            <div className="job__note mt3">
              <PaymentDetails
                additional_fee={additional_fee}
                job_fee={job_fee}
                total_fee={total_fee}
                {...payment}
              />
            </div>
          )}
          {!isDescriptionEmpty && !isMarketplace && (
            <div className={classnames("job__note mt3")}>
              <h3 className="header-3">Job description</h3>
              <p>{description}</p>
            </div>
          )}
          {isMarketplace && (
            <>
              <div className="mt3 job__details">
                <Typography variant="h6">{`💰 Job fee: $${total_payout_minus_fees}`}</Typography>
              </div>
              <div className="job__details mt3">
                <MarketplaceDetails
                  addon_fees={addon_fees}
                  price={{
                    total_payout_minus_fees,
                    marketplace_fee,
                    total_fee,
                  }}
                  special_request={special_request}
                  viewType="ops"
                />
              </div>
            </>
          )}
          <div className="job__details mt4">
            <JobSchedule
              jobDate={jobDate}
              jobTime={jobTime}
              title="Job schedule"
            />
            <div className="mt2">
              {!isJobNotActive && (
                <Button
                  onClick={() => {
                    Analytics.trackEvent(
                      "job_update_schedule_action_from_job_details",
                      { job_id: jobId }
                    );
                    openThisDialog({
                      dialogType: DT_UPDATE_JOB_DATE_TIME,
                      jobId,
                      jobDate,
                      jobTime,
                      view: "job",
                    });
                  }}
                  variant="outlined"
                  disabled={status === CLEANER_STATUS_DECLINED}
                  className="btn-primary--outlined"
                >
                  Update schedule
                </Button>
              )}
              {isMarketplace && (
                <Typography
                  variant="body2"
                  style={{ fontStyle: "italic", marginTop: -8 }}
                >
                  Reach out to your host (email below) to update the marketplace
                  job schedule.
                </Typography>
              )}
            </div>
          </div>
          <div className="job__pro mt4">
            <JobLocation
              property={property}
              rooms={user.rooms}
              isHost={userToken.is_host}
              isMarketplace={isMarketplace}
              className="mt4"
            />
          </div>
          <div className="job__pro mt4">
            <h3 className="header-3">Assigned pro</h3>
            <AssignedPros
              assignments={assignments}
              multiple={true}
              jobId={jobId}
              jobStatus={status}
              isPast={isPast}
              isMarketplace={isMarketplace}
            />
          </div>
          <div className="job__details mt4">
            <HostDetails
              name={hostName}
              phone={hostPhone}
              jobId={jobId}
              isMarketplace={isMarketplace}
              isOps={true}
            />
          </div>
          {!isMarketplace && (
            <div className="job__details mt4">
              <GuestReservationDetails
                duration={duration}
                format={DATE_FORMAT}
                reservationDate={reservationCheckIn}
                reservationTime={jobCheckinTime}
                jobDate={jobDate}
                jobTime={jobTime}
                title="Guest reservation details"
              />
            </div>
          )}
          {guest_rating > 0 && (
            <div className="job__details mt3">
              {/* Requires images array of objects with original, thumbnail and description */}
              <Typography
                variant="h6"
                style={{
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "140%",
                }}
              >
                Inspection report
              </Typography>
              {/* <Typography variant="subtitle2" style={{ marginBottom: 16 }}>
            Photos showing property condition and damages after guest departure.
          </Typography> */}
              <div style={{ marginTop: 8 }}>
                <GuestRating rate={guest_rating} />
                {guest_review && (
                  <div style={{ marginTop: 8 }}>
                    <Typography style={{ fontWeight: 500, fontSize: 14 }}>
                      Note:{" "}
                      <Typography
                        variant="body1"
                        component="span"
                        style={{
                          marginTop: 4,
                          fontWeight: "normal",
                          fontSize: 14,
                        }}
                      >
                        {guest_review}
                      </Typography>
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          )}
          {isJobCompleted && !isEmpty(tasks.data) && (
            <div className="job__details mt3">
              <Typography
                variant="h6"
                style={{
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "140%",
                }}
              >
                Final walkthrough
              </Typography>
              <Typography variant="body2" style={{ marginBottom: 16 }}>
                Photos taken by cleaner after preparing the property for new
                guests.
              </Typography>
              <CleaningImageViewer images={tasks.data} />
            </div>
          )}
          <div className="job-actions--spacer job-actions--smaller" />
          {isEmpty(payment) && (
            <div className="job-details__actions job-details__actions--fixed">
              <JobActions view="jobDetails" {...props} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const OpsJobView = (props) => {
  const {
    fetchOpsJobDetails,
    fetchWalkthroughTasks,
    openThisDialog,
    job = {},
    setDialogOpen,
    user,
    userToken,
    tasks,
  } = props;
  // Extract payment object to test the condition to open modal to make payment
  const { payment = {} } = job;
  const url = window.location.href;

  const { isLoading = true } = job;

  const localId = url.substring(url.lastIndexOf("/") + 1);

  useEffect(() => {
    if (localId) {
      fetchOpsJobDetails(localId);
      fetchWalkthroughTasks(localId);
    }
  }, []);

  return isLoading ? (
    <LoadingScreen />
  ) : (
    !isEmpty(job) && (
      <RenderJob
        {...job}
        user={user}
        userToken={userToken}
        openThisDialog={openThisDialog}
        setDialogOpen={setDialogOpen}
        tasks={tasks}
      />
    )
  );
};

export default connect(
  (state) => ({
    job: getJobDetails(state),
    user: getUser(state),
    userToken: getUserToken(state),
    tasks: getWalkthroughTasks(state),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        fetchOpsJobDetails,
        openThisDialog,
        setDialogOpen,
        fetchWalkthroughTasks,
      },
      dispatch
    )
)(OpsJobView);
