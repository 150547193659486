import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import { PropertyModel } from "../models";

interface TrashProps {
  property: PropertyModel;
}

const JobTrashInstructions: React.FC<TrashProps> = ({ property }) => {
  return (
    <div className="mb-4">
      <h3 className="text-md flex align-center">
        <DeleteIcon style={{ fontSize: 20, opacity: 0.8, marginRight: 4 }} />{" "}
        Trash instructions
      </h3>
      <div className="mt-2">
        <p>
          {property?.trash_instructions ? (
            property.trash_instructions
          ) : (
            <p className="opacity-50 text-sm italic">Not available</p>
          )}
        </p>
      </div>
    </div>
  );
};

export default JobTrashInstructions;
