import { showSnackbar } from "../actions/action_snackbar";
import store from "../store";
import history from "./history";
import { UNAUTHORIZED } from "../routes";
import { CONTENT_LOADED } from "../constants/action-types";

export function serverError(error, message = null) {
  if (error.response) {
    if (error.response.status === 422) {
      store.dispatch(
        showSnackbar({
          message: error.response.data.message,
          error: true,
          hideDuration: 8000,
        })
      );
    } else if (error.response.status === 500) {
      store.dispatch(
        showSnackbar({
          message: error.response.data.message
            ? error.response.data.message
            : "Oops! Something went wrong. Try again or click the chat bubble to contact us.",
          error: true,
          hideDuration: 8000,
        })
      );
    } else if (error.response.status === 401) {
      history.replace(UNAUTHORIZED);
    } else if (error.response.status === 404) {
      store.dispatch(
        showSnackbar({
          message: error.response.data.message || message,
          error: true,
          hideDuration: 8000,
        })
      );
    }
  } else if (error.message) {
    store.dispatch(
      showSnackbar({
        message: error.message,
        error: true,
        hideDuration: 8000,
      })
    );
  } else if (message) {
    store.dispatch(
      showSnackbar({
        message: message,
        error: true,
        hideDuration: 8000,
      })
    );
  }

  return { type: CONTENT_LOADED };
}
