import React, { useState, useEffect, useMemo } from "react";
import { Provider, useSelector } from "react-redux";
import classnames from "classnames";
import { isEmpty } from "lodash";
import { Router, Switch, Route, Redirect, useLocation } from "react-router-dom";
import { Elements, StripeProvider } from "react-stripe-elements";
import amplitude from "amplitude-js";
import { useMediaQuery } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { PopupButton } from "@typeform/embed-react";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Intercom from "@intercom/messenger-js-sdk";
// import Intercom from "react-intercom";

import {
  setLogger,
  createInstance,
  OptimizelyProvider,
} from "@optimizely/react-sdk";

import store from "./store";

// Helpers
import history from "./helpers/history";
import setAuthToken from "./helpers/authToken";

import {
  APP,
  PMS_PROPERTIES_SHOW,
  PMS_PROPERTIES_NEW,

  // New routes
  LOG_IN,
  HOST_SIGNUP,
  MANAGE_ACCOUNT_ROOT,
  PROS_SIGN_UP,
  HOST_SUBSCRIBE,
  HOST_PAYMENT_METHOD,
  JOBS,
  JOB,
  PROPERTIES,
  MY_PROS,
  UNAUTHORIZED,
  FORGOT_PASSWORD,
  RESET_PASSWORD,

  // OPS
  OPERATIONS_CLIENTS,
  OPERATIONS_CLIENTS_PROPERTIES,
  INVITED_CLIENTS,
  MESSAGES,
  NEW_USER_MARKETPLACE_USERNAME,
  NEW_USER_SIGNUP,
  NEW_USER_ACCOUNT_CREATION,
  NEW_USER_MARKETPLACE_PROFILE_BASIC,
  NEW_USER_MARKETPLACE_PROFILE_CHECKLIST,
  NEW_USER_MARKETPLACE_PROFILE_PRICING,
  NEW_USER_MARKETPLACE_PROFILE_SERVICES,
} from "./routes";

// ACTIONS
import { fetchUser } from "./actions/user";

// Components
import DialogWrapper from "./containers/dialogWrapper";
import Snackbar from "./containers/snackbar";
import OverlayLoadingScreen from "./components/overlayLoadingScreen";

import NotFound from "./components/notFound";

import Jobs from "./jobs";
import Job from "./job";
import JobDetails from "./job/index.tsx";

// HOST
import Properties from "./properties";
import PropertyShow from "./properties/property";
import NewProperty from "./properties/newProperty";

// OPS
import OpsCliens from "./clients";
import OpsClientsProperties from "./clients/clientsProperties";
import InvitedClients from "./clients/invitedClients";

// USER
import SignIn from "./public/signIn";
import MangeAccount from "./manageAccount";
import ForgotPassword from "./public/forgotPassword";
import ResetPassword from "./public/resetPassword";
import HostSignup from "./public/hostSignup";
import ProsSignup from "./public/prosSignup";
import UsernameScreen from "./public/usernameScreen";
import NewSignup from "./public/newSignupScreen";

// Payment
import PmsUpdatePaymentMethod from "./subscribe/payment-method.js";
import PmsSubscribe from "./subscribe/index";
import Banner from "./containers/banner.jsx";
import NewFeaturesRelease from "./components/newFeatureRelease";

// My Pros
import MyPros from "./myPros/index";

// Messages
import Messages from "./messages";

import UnauthorizedPage from "./containers/unauthorizedPage";

// Promotion
import Promotion from "./containers/promotionDialog";

// Onboarding
import AccountSetupScreen from "./onboarding/accountSetupScreen.jsx";
import ChecklistStandardScreen from "./onboarding/checklistStandardScreen.jsx";
import CleaningServiceScreen from "./onboarding/cleaningServicesScreens.jsx";
import ProfileInformationScreen from "./onboarding/profileInformationScreen.jsx";
import PricingStructureScreen from "./onboarding/pricingStructureScreen.jsx";

import URLForwardList from "./helpers/urlForwardList";
import { parseQuery } from "./helpers/url";

import { ModalProvider } from "./ModalContext.js";

// Main styles
import "./styles/index.scss";
import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import SendbirdChat from "@sendbird/chat";
import { GroupChannelModule } from "@sendbird/chat/groupChannel";

if (localStorage.jwtToken) {
  setAuthToken(localStorage.jwtToken);
  store.dispatch(fetchUser());
}

// API KEYS
const optimizelyKey = process.env.REACT_APP_OPTIMIZELY_API_KEY;

// forward urls
URLForwardList.map((url) => {
  const currentPath = window.location.pathname;
  const searchPath = window.location.search;

  if (currentPath == url.old) {
    window.location.replace(`${url.new}${searchPath ? searchPath : ""}`);
  } else if (currentPath.includes("/housekeeper/cleanings/")) {
    const newURLCleaning = currentPath.split("/");
    window.location.replace(`/job/${newURLCleaning[3]}`);
  }
});

//App shortcut
if (window.location.pathname === "/index.html") {
  window.location.replace(APP);
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return localStorage.jwtToken ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: LOG_IN,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

function lookupUserId() {
  return new Promise(function(resolve, reject) {
    const _userInterval = setInterval(() => {
      const user = store.getState().user;
      if (user && user.id) {
        resolve(user);
        clearInterval(_userInterval);
      }
    }, 50);
  });
}

const optimizely = createInstance({
  sdkKey: optimizelyKey,
});

// Turn off logger
setLogger(null);

const userPromise = lookupUserId().then((response = {}) => {
  const id = response.id.toString();
  return {
    id,
    attributes: {
      email: response.email,
    },
  };
});

// Material UI Theme builder
// createTheme
// OPS
const opsTheme = {
  palette: {
    common: {
      black: "rgba(33, 33, 33, 1)",
      white: "#fff",
    },
    background: {
      paper: "rgba(255, 255, 255, 1)",
      default: "rgba(249, 249, 253, 1)",
    },
    primary: {
      light: "#ff4081",
      main: "rgba(255, 204, 128, 1)",
      dark: "rgba(254, 220, 170, 1)",
      contrastText: "rgba(29, 41, 57, 1)",
    },
    secondary: {
      light: "rgba(212, 217, 225, 1)",
      main: "rgba(29, 41, 57, 1)",
      dark: "rgba(52,72,100,1)",
      contrastText: "#fff",
    },
    error: {
      light: "#e57373",
      main: "rgba(255, 41, 41, 1)",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    text: {
      primary: "rgba(33, 33, 33, 1)",
      secondary: "rgba(88, 88, 98, 1)",
      disabled: "rgba(159, 159, 171, 1)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
  },
};

// HOST & PRO
const otherRolesTheme = {
  palette: {
    common: {
      black: "rgba(33, 33, 33, 1)",
      white: "#fff",
    },
    background: {
      paper: "rgba(255, 255, 255, 1)",
      default: "rgba(249, 249, 253, 1)",
    },
    primary: {
      light: "#ff4081",
      main: "rgba(29, 41, 57, 1)",
      dark: "rgba(0, 0, 0, 1)",
      contrastText: "#fff",
    },
    secondary: {
      light: "rgba(212, 217, 225, 1)",
      main: "rgba(66, 0, 255, 1)", // Brand purple
      dark: "#3200C1", // Brand light
      contrastText: "rgba(255, 255, 255, 1)",
    },
    info: {
      light: "rgba(212, 217, 225, 1)",
      main: "rgba(29, 41, 57, 1)",
      dark: "rgba(52,72,100,1)",
      contrastText: "#fff",
    },
    error: {
      light: "#e57373",
      main: "rgba(255, 41, 41, 1)",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    text: {
      primary: "rgba(33, 33, 33, 1)",
      secondary: "rgba(88, 88, 98, 1)",
      disabled: "rgba(159, 159, 171, 1)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        whiteSpace: "nowrap",
        borderRadius: "50px",
      },
      outlinedPrimary: {
        borderColor: "rgba(29, 41, 57, 1)", // Adjust to match your primary color
        borderWidth: "1.5px",
        "&:hover": {
          borderColor: "rgba(29, 41, 57, 1)", // Keep the same on hover
          borderWidth: "1.5px",
        },
      },
      containedSecondary: {
        "&:hover": {
          backgroundColor: "#3200C1",
        },
      },
    },
    MuiLink: {
      root: {
        color: "#3a66ff", // Change this to your desired color
        "&:hover": {
          color: "#1a237e", // Change this to your desired hover color
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: "#1D2939", // Change this to your desired color
      },
    },
    MuiFormLabel: {
      root: {
        color: "rgba(29, 41, 57, 1)",
        "&$focused": {
          color: "black",
        },
      },
    },
  },
};

let sb;

const App = () => {
  const [stripe, setStripe] = useState(null);
  const [currentAutomatebnbTheme, setCurrentAutomatebnbTheme] = useState(
    otherRolesTheme
  );

  const userToken = store.getState().userToken;

  useEffect(() => {
    (function() {
      var d = document;
      var s = d.createElement("script");

      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
    if (window.Stripe) {
      setStripe(window.Stripe(process.env.REACT_APP_STRIPE_API_KEY));
    } else {
      document.querySelector("#stripe-js").addEventListener("load", () => {
        setStripe(window.Stripe(process.env.REACT_APP_STRIPE_API_KEY));
      });
    }
  }, []);

  const TypeFormCampaign = () => {
    const userData = useSelector((state) => state.user);
    const _localCampaignSetting =
      localStorage.getItem("typeform_campaign-invite-om_closed") || false;
    const [campaignClosed, setCampaignClosed] = useState(_localCampaignSetting);

    const handleCampaignClick = () => {
      window.open("https://automatebnb.com/cleaning-business", "_blank");
      closeTypeformCampaign("completed");
    };
    const closeTypeformCampaign = (val) => {
      localStorage.setItem("typeform_campaign-invite-om_closed", true);
      setCampaignClosed(true);
      if (val !== "completed") {
      }
    };

    if (!isEmpty(userData) && userToken.is_maid) {
      // Maid account only
      if (!campaignClosed) {
        return (
          <div className="campaign campaign--typeform">
            <Button
              color="primary"
              varient="contained"
              className="button-typeform"
              onClick={() => handleCampaignClick()}
            >
              <div className="campaign__emoji">😎</div>
              <div className="camapaign__text">
                <p>The all new Cleaning Agency Platform.</p>
                <p className="campaign__text--ops">Get Early Access!</p>
              </div>
            </Button>
            <button
              className="button button-close"
              onClick={() => closeTypeformCampaign()}
            >
              <CloseIcon style={{ fontSize: "16px" }} />
            </button>
          </div>
        );
      } else return null;
    } else return null;
  };

  return (
    <StripeProvider stripe={stripe}>
      <Provider store={store}>
        <OptimizelyProvider
          optimizely={optimizely}
          timeout={500}
          user={userPromise}
        >
          <InnerApp />
        </OptimizelyProvider>
        {/* <TypeFormCampaign /> prompt for maids to convert to OPS */}
      </Provider>
    </StripeProvider>
  );
};

const InnerApp = () => {
  // const appId = process.env.REACT_APP_SENDBIRD_APP_ID;
  const userData = useSelector((state) => state.user);
  const userToken = useSelector((state) => state.userToken);
  const subscription = useSelector((state) => state.subscribe).data;
  const isMobile = useMediaQuery("(max-width:600px)");

  const [chatState, updateChatState] = useState({
    applicationUsers: [],
    groupChannelMembers: [],
    currentlyJoinedChannel: null,
    messages: [],
    channels: [],
    messageInputValue: "",
    userNameInputValue: "",
    userIdInputValue: "",
    channelNameUpdateValue: "",
    settingUpUser: true,
    file: null,
    messageToUpdate: null,
    messageCollection: null,
    loading: false,
    error: false,
  });

  // SENDBIRD is not going live with May release.
  // useEffect(() => {
  //   setupChatUser();

  //   return () => {
  //     if (sb) {
  //       sb.disconnect();
  //     }
  //   };
  // }, [userData]);

  // const setupChatUser = async () => {
  //   if (userData.user_id === undefined || userData.user_id === "") {
  //     return;
  //   }
  //
  //   const sendbirdChat = await SendbirdChat.init({
  //     appId: appId,
  //     localCacheEnabled: false,
  //     modules: [new GroupChannelModule()],
  //   });
  //   if (sendbirdChat.connectionState !== "OPEN") {
  //     await sendbirdChat.connect(userData.user_id);
  //   }
  //   sb = sendbirdChat;
  //
  //   updateChatState({ ...chatState, loading: false, settingUpUser: false });
  // };

  const { is_host, is_maid, is_ops } = userToken;

  const accountRole = is_host
    ? "Host"
    : is_maid
    ? "Maid"
    : is_ops
    ? "Ops"
    : null;
  const hostObject = is_host
    ? {
        status: subscription?.status,
        start_date: subscription?.start_date,
        properties_count: subscription?.properties_count,
      }
    : {};

  Intercom({
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    user_id: userToken.id,
    name: userData.full_name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    email: userData.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    role: accountRole,
    ...hostObject,
  });

  return (
    <Elements>
      <Router history={history}>
        <div
          className={classnames("main", {
            "mobile-ui": isMobile,
            public: !localStorage.jwtToken,
          })}
          id="abMainPage"
        >
          <ThemeProvider theme={createTheme(otherRolesTheme)}>
            <DialogWrapper />
            <Snackbar />
            <Promotion />
            <OverlayLoadingScreen />
            <NewFeaturesRelease hideBanner={true} />
            <Banner />
            <ModalProvider>
              <Switch>
                <PrivateRoute
                  exact
                  path={HOST_SUBSCRIBE}
                  component={PmsSubscribe}
                />
                <PrivateRoute
                  exact
                  path={HOST_PAYMENT_METHOD}
                  component={PmsUpdatePaymentMethod}
                />
                <PrivateRoute
                  path={MANAGE_ACCOUNT_ROOT}
                  component={MangeAccount}
                />
                <PrivateRoute exact path={JOBS} component={Jobs} />
                <PrivateRoute exact path={JOB} component={JobDetails} />
                <PrivateRoute exact path={PROPERTIES} component={Properties} />
                <PrivateRoute
                  exact
                  path={OPERATIONS_CLIENTS}
                  component={OpsCliens}
                />
                <PrivateRoute
                  exact
                  path={OPERATIONS_CLIENTS_PROPERTIES}
                  component={OpsClientsProperties}
                />
                <PrivateRoute
                  exact
                  path={INVITED_CLIENTS}
                  component={InvitedClients}
                />

                <PrivateRoute
                  exact
                  path={PMS_PROPERTIES_NEW}
                  component={NewProperty}
                />
                <PrivateRoute
                  path={PMS_PROPERTIES_SHOW}
                  component={PropertyShow}
                />
                <PrivateRoute
                  path={NEW_USER_ACCOUNT_CREATION}
                  component={AccountSetupScreen}
                />
                <PrivateRoute
                  path={NEW_USER_MARKETPLACE_PROFILE_BASIC}
                  component={ProfileInformationScreen}
                />
                <PrivateRoute
                  path={NEW_USER_MARKETPLACE_PROFILE_CHECKLIST}
                  component={ChecklistStandardScreen}
                />
                <PrivateRoute
                  path={NEW_USER_MARKETPLACE_PROFILE_PRICING}
                  component={PricingStructureScreen}
                />
                <PrivateRoute
                  path={NEW_USER_MARKETPLACE_PROFILE_SERVICES}
                  component={CleaningServiceScreen}
                />
                <PrivateRoute exact path={MY_PROS} component={MyPros} />
                {/*<PrivateRoute exact path={MESSAGES} component={Messages} />*/}
                <Route
                  exact
                  path={NEW_USER_MARKETPLACE_USERNAME}
                  component={UsernameScreen}
                />
                <Route exact path={NEW_USER_SIGNUP} component={NewSignup} />
                <Route exact path={HOST_SIGNUP} component={HostSignup} />
                <Route exact path={PROS_SIGN_UP} component={ProsSignup} />
                <Route exact path={RESET_PASSWORD} component={ResetPassword} />
                <Route
                  exact
                  path={FORGOT_PASSWORD}
                  component={ForgotPassword}
                />
                <Route exact path={LOG_IN} component={SignIn} />
                <Route exact path={APP} component={SignIn} />

                <Route exact path={UNAUTHORIZED} component={UnauthorizedPage} />
                <Route component={NotFound} />
              </Switch>
            </ModalProvider>
          </ThemeProvider>
        </div>
      </Router>
    </Elements>
  );
};

export default App;
