import React from "react";
import PersonIcon from "@material-ui/icons/Person";

import { getInitialsFromFullName } from "../helpers/format.js";
import { Avatar } from "@material-ui/core";

interface JobProviderProps {
  assignor_name: string;
  assignore_phone: string;
}

const JobProvider: React.FC<JobProviderProps> = ({
  assignor_name,
  assignore_phone,
}) => {
  const nameInitials = getInitialsFromFullName(assignor_name);
  return (
    <div className="mb-4">
      <h3 className="text-lg">
        <PersonIcon style={{ fontSize: 20, opacity: 0.8 }} /> Job provider
      </h3>
      <div className="flex mt-2">
        <Avatar className="mr-2">{nameInitials}</Avatar>
        <div className="flex align-center">
          <p style={{ margin: 0 }}>{assignor_name}</p>
          {assignore_phone && (
            <a href={`tel:${assignore_phone}`} className="">
              {assignore_phone}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobProvider;
