import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import SvgIcon from "@material-ui/core/SvgIcon";

import Button from "@material-ui/core/Button";
import { ReactComponent as TipsUpdatesSVG } from "../images/tips-updates.svg";

import { fetchNotificationSettings } from "../actions/notifications";

import "../styles/flexbox.scss";
import "./notifications.scss";
import { isEmpty } from "lodash";
import { DT_NOTIFICATION_CONFIG } from "../constants/dialogTypes";
import { openThisDialog } from "../actions/dialog";
import { CircularProgress } from "@material-ui/core";
import { Analytics } from "../services/analyticsService.tsx";

const Notifications = (props) => {
  const { fetchNotificationSettings } = props;

  const dispatch = useDispatch();

  const settingsObject = useSelector((state) => state.notifications);
  const settingItems = settingsObject.items;
  const jobSettings = settingItems.filter((rule) => rule.category === "job");
  const prosSettings = settingItems.filter((rule) => rule.category === "pro");
  const paymentSettings = settingItems.filter(
    (rule) => rule.category === "payment"
  );
  const isLoading = settingsObject.isLoading;

  useEffect(() => {
    fetchNotificationSettings();
  }, []);

  function configNotification(id) {
    dispatch(
      openThisDialog({
        dialogType: DT_NOTIFICATION_CONFIG,
        id: id,
      })
    );
  }

  function NotificationStatus({ rules }) {
    if (rules.email.notify && rules.sms.notify) {
      return <small className="small grey">On: Email, SMS</small>;
    } else if (rules.email.notify) {
      return <small className="small grey">On: Email</small>;
    } else if (rules.sms.notify) {
      return <small className="small grey">On: SMS</small>;
    } else {
      return <small className="small grey">Off</small>;
    }
  }

  function NotificationSetting(props = []) {
    const {
      id = null,
      title = "Sample",
      heading = "Title",
      rules = [{ email: true }],
      tip = "You are awesome",
    } = props;

    const email = rules.email.notify;
    const sms = rules.sms.notify;

    return (
      <div key={id} className="inline-flex full-width space-between pb2">
        <div>
          <p className="body-text">{heading}</p>
          <NotificationStatus rules={rules} />
        </div>
        <div>
          <Button
            variant="text"
            className="host-primary"
            color="primary"
            onClick={() => configNotification(id)}
          >
            Edit
          </Button>
        </div>
      </div>
    );
  }

  function NotificationSettings({ settings }) {
    return settings.map((setting) => (
      <NotificationSetting key={setting.id} {...setting} />
    ));
  }

  //
  if (isLoading) {
    return (
      <div
        className="full-width flex align-center"
        style={{ justifyContent: "center" }}
      >
        <CircularProgress />
      </div>
    );
  } else {
    if (isEmpty(settingItems)) {
      return <div>No rules..</div>;
    } else {
      return (
        <div className="pt1 pl3" style={{ maxWidth: "600px", margin: "auto" }}>
          <div className="notification-settings__up-sell">
            <div style={{ display: "inline-flex" }}>
              <SvgIcon
                component={TipsUpdatesSVG}
                viewBox="0 0 900 1070.6"
                style={{ marginRight: "8px" }}
              />
              <div>
                <p>Get Push notifications on your Phone!</p>
                <p>
                  <a
                    href="https://automatebnb.com/downloads?utm_source=download_action_from_app_notification"
                    target="_blank"
                    className="link"
                    onClick={() =>
                      Analytics.trackSimple(
                        "user_clicked_download_app_action_from_notifications"
                      )
                    }
                  >
                    Click here to Download our app!
                  </a>
                </p>
              </div>
            </div>
          </div>
          {!isEmpty(jobSettings) && (
            <div className="pt2">
              <h2 className="header-4">Job activity</h2>
              <NotificationSettings settings={jobSettings} />
            </div>
          )}

          {!isEmpty(prosSettings) && (
            <div className="pt3">
              <h2 className="header-4">Pro's activity</h2>
              <NotificationSettings settings={prosSettings} />
            </div>
          )}
          {!isEmpty(paymentSettings) && (
            <div className="pt3">
              <h2 className="header-4">Payment activity</h2>
              <NotificationSettings settings={paymentSettings} />
            </div>
          )}
        </div>
      );
    }
  }
};

export default connect(
  (state) => ({}),
  (dispatch) => bindActionCreators({ fetchNotificationSettings }, dispatch)
)(Notifications);
