import React, { useState, useEffect, useRef } from "react";
import { NumericFormat } from "react-number-format";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import MaskedInput from "react-text-mask";

import InputAdornment from "@material-ui/core/InputAdornment";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import {
  FormControl,
  FormGroup,
  OutlinedInput,
  InputLabel,
  Checkbox,
  Select,
  MenuItem,
  FormHelperText,
  IconButton,
  Typography,
  Box,
  ListItemText,
} from "@material-ui/core";

import CancelIcon from "@material-ui/icons/Cancel";
import green from "@material-ui/core/colors/green";
import { withStyles } from "@material-ui/core/styles";

import {
  getCommaSeparatedNumber,
  stripPhoneNumberFormatting,
} from "../helpers/format";

// API
import { validateUsernameAvailability } from "../actions/user";
import { TextField } from "@material-ui/core";

import { loadGoogleMapsScript } from "../helpers/loadGoogleMapsScript";
import { emailRegex } from "../constants/validate";

const GOOGLE_PLACES_API = process.env.REACT_APP_GOOGLE_API_KEY;
const mapsAPIjs = "https://maps.googleapis.com/maps/api/js";

const CustomOutlinedInput = withStyles({
  root: {
    "& $input": {
      marginLeft: "-7px", // Remove left padding from the input
    },
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "inherit",
    // Adjust the following to ensure there's no gap if necessary
    "& legend": {
      display: "none",
    },
  },
  adornedStart: {
    "& $inputAdornedStart": {
      fontWeight: "500", // Bold for startAdornment text
    },
  },
  inputAdornedStart: {},
})(OutlinedInput);

const UsernameField = ({ handleUsernameChange }) => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [helperText, setHelperText] = useState("");
  const [helperTextColor, setHelperTextColor] = useState("red");
  const [error, setError] = useState(false);
  const [icon, setIcon] = useState(null);

  const prefix = "autobnb.pro/";
  // Handle input change
  const handleChange = (event) => {
    const { value } = event.target;
    setUsername(value);
    setHelperText("");
    setError(false);
    setIcon(null);
  };

  // Use useEffect to trigger validation when username changes
  useEffect(() => {
    if (username) validateUsername();
    // Cancel the debounce on useEffect cleanup
    return () => validateUsername.cancel();
  }, [username]);

  // Debounce validateUsername function
  const validateUsername = debounce(() => {
    // Wait 1 second before doing anything
    if (username.length < 4) {
      setHelperText("At least 4 characters are required for a username");
      setHelperTextColor("red");
      setError(true);
      handleUsernameChange(null);
    } else if (!/^[a-zA-Z0-9]+$/.test(username)) {
      setHelperText("Username can only have letters and/or numbers");
      setHelperTextColor("red");
      setError(true);
      handleUsernameChange(null);
    } else {
      // If all validations pass, check username availability
      dispatch(validateUsernameAvailability(username))
        .then((response) => {
          if (response.status === 200) {
            setHelperText(response.data.message);
            setError(false);
            setIcon(
              <CheckCircleIcon
                style={{ color: green[500], marginRight: 4, fontSize: 16 }}
              />
            );
            setHelperTextColor(green[500]);
            // Callback function to pass username back to parent
            handleUsernameChange(username);
          } else {
            setHelperText("Username is not available");
            setError(true);
            setIcon(
              <CancelIcon
                style={{ color: "red", marginRight: 4, fontSize: 16 }}
              />
            );
            setHelperTextColor("red");
            handleUsernameChange(null);
          }
        })
        .catch((error) => {
          setHelperText(error.response.data.message);
          setError(true);
          setIcon(
            <CancelIcon
              style={{ color: "red", marginRight: 4, fontSize: 16 }}
            />
          );
          setHelperTextColor("red");
          handleUsernameChange(null);
        });
    }
  }, 1000);

  return (
    <div style={{ height: "82px" }}>
      <CustomOutlinedInput
        id="standard-username-field"
        variant="outlined"
        onChange={handleChange}
        fullWidth
        value={username}
        placeholder="yourname"
        style={{ fontWeight: 700 }}
        labelWidth={0}
        startAdornment={
          <InputAdornment position="start">{prefix}</InputAdornment>
        }
      />
      <FormHelperText
        error={error}
        className="flex align-center"
        style={{
          color: helperTextColor,
          fontSize: "14px",
          fontWeight: 400,
        }}
      >
        {icon} {helperText}
      </FormHelperText>
    </div>
  );
};

const PasswordField = ({
  value,
  label,
  handleChange,
  inputRef,
  className,
  required,
  onInput,
  error,
  helperText,
  name,
}) => {
  const [togglePassword, setPasswordVisibility] = React.useState(true);

  const handleClickShowPassword = () => {
    setPasswordVisibility(!togglePassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={className}>
      <TextField
        variant="outlined"
        type={togglePassword ? "password" : "text"}
        name={name}
        required
        inputRef={inputRef}
        label={label}
        fullWidth
        onInput={onInput}
        value={value}
        error={error}
        onChange={handleChange}
        helperText={helperText}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {togglePassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

const EmailTextField = ({
  value,
  handleChange,
  className,
  helperText,
  disabled,
  name,
  required,
  label,
  inputRef = null,
}) => {
  const [error, setError] = React.useState(false);

  const validateEmail = (email) => {
    return emailRegex.test(String(email).toLowerCase());
  };

  const handleEmailChange = (event) => {
    const email = event.target.value;
    handleChange(event);
    setError(!validateEmail(email));
  };

  return (
    <div className={className}>
      <TextField
        variant="outlined"
        name={name}
        required={required}
        label={label}
        disabled={disabled}
        fullWidth
        inputRef={inputRef}
        value={value}
        onChange={handleEmailChange}
        helperText={error ? "Invalid email address" : helperText}
        error={error}
      />
    </div>
  );
};

const GeneralNumberTextField = ({
  value,
  handleChange,
  className,
  autoFocus,
  helperText,
  name,
  label,
  multiline = false,
  placeholder = null,
  rows = null,
  inputRef = null,
  inputProps = {},
}) => {
  const handleInputChange = (event, handleChange) => {
    const rawValue = event.target.value.replace(/,/g, "");
    if (!isNaN(rawValue)) {
      handleChange({
        target: {
          name: event.target.name,
          value: rawValue,
        },
      });
    }
  };

  const { adornmentPosition = "end", adornmentText = "sq ft" } = inputProps;

  const textFieldProps = {
    variant: "outlined",
    name,
    autoFocus,
    label,
    placeholder,
    fullWidth: true,
    inputRef,
    value: getCommaSeparatedNumber(value),
    onChange: (event) => handleInputChange(event, handleChange),
    helperText,
    multiline,
    minRows: rows,
    InputProps: {
      [adornmentPosition + "Adornment"]: (
        <InputAdornment position={adornmentPosition}>
          {adornmentText}
        </InputAdornment>
      ),
    },
  };

  return (
    <div className={className}>
      <TextField {...textFieldProps} />
    </div>
  );
};

const GeneralTextField = ({
  value,
  handleChange,
  className,
  autoFocus,
  helperText,
  name,
  label,
  labelInFieldset = true,
  multiline = false,
  placeholder = null,
  rows = null,
  inputRef = null,
}) => {
  if (labelInFieldset) {
    return (
      <div className={className}>
        <TextField
          variant="outlined"
          name={name}
          autoFocus={autoFocus}
          label={label}
          fullWidth
          inputRef={inputRef}
          value={value}
          onChange={handleChange}
          helperText={helperText}
          multiline={multiline}
          minRows={rows}
        />
      </div>
    );
  } else {
    return (
      <div className={className}>
        <FormControl fullWidth>
          <>
            <FormLabel
              htmlFor={`${name}-outlined-input`}
              style={{ fontWeight: 500 }}
            >
              {label}
            </FormLabel>
            {helperText && (
              <Typography
                variant="body2"
                style={{ opacity: 0.7, marginTop: 4 }}
              >
                {helperText}
              </Typography>
            )}
            <OutlinedInput
              id={`${name}-outlined-input`}
              placeholder={placeholder}
              name={name}
              style={{ marginTop: 4 }}
              autoFocus={autoFocus}
              fullWidth
              inputRef={inputRef}
              value={value}
              onChange={handleChange}
              multiline={multiline}
              minRows={rows}
            />
          </>
        </FormControl>
      </div>
    );
  }
};

function ReadOnlyField(props) {
  const { label = "Label", value = "body", className } = props;

  return (
    <div className={className}>
      <Typography variant="caption">{label}</Typography>
      <Typography variant="body1">{value}</Typography>
    </div>
  );
}

function CurrencyFormatCustom(props) {
  const { onChange, inputRef, ...rest } = props;

  // Function to format the currency using Intl.NumericFormat
  const formatCurrency = (numStr) => {
    if (numStr === "") return "";
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    }).format(numStr);
  };

  // Only pass valid props to NumberFormat
  const numberFormatProps = {
    ...rest,
    getInputRef: inputRef,
    onValueChange: (values) => {
      onChange({
        target: {
          name: props.name,
          value: values.value,
        },
      });
    },
    // prefix: currencyPosition === "front" ? currencySymbol : "",
    // suffix: currencyPosition === "back" ? currencySymbol : "",
    // Explicitly declare props meant for NumberFormat to avoid React warnings
    // decimalScale: 2,
    // fixedDecimalScale: true,
    format: formatCurrency,
    valueIsNumericString: true,
    thousandSeparator: ",",
  };

  return <NumericFormat {...numberFormatProps} />;
}

// CurrencyTextField component that incorporates the additional props
function CurrencyTextField({
  currency = "USD",
  value,
  label,
  onChange,
  helperText,
  fullWidth,
  className,
  name,
}) {
  // Define currency symbol and position based on the currency prop
  let currencySymbol = "$";
  let currencyPosition = "front"; // or 'back'

  // Extend this switch case to handle different currencies and their positions
  switch (currency) {
    case "USD":
      currencySymbol = "$";
      currencyPosition = "front";
      break;
    // Add more cases for different currencies as needed
    default:
      currencySymbol = "$";
      currencyPosition = "front";
  }

  return (
    <div className={className}>
      <TextField
        label={label}
        variant="outlined"
        value={value}
        onChange={onChange}
        fullWidth={fullWidth}
        helperText={helperText}
        name={name}
        InputProps={{
          inputComponent: CurrencyFormatCustom,
          inputProps: {
            name,
            onChange: (event) => onChange(event),
          },
          startAdornment:
            currencyPosition === "front" ? (
              <InputAdornment position="start">{currencySymbol}</InputAdornment>
            ) : null,
          endAdornment:
            currencyPosition === "back" ? (
              <InputAdornment position="end">{currencySymbol}</InputAdornment>
            ) : null,
        }}
      />
    </div>
  );
}

const TextMaskCustom = React.memo(function TextMaskCustom(props) {
  const { inputRef, onChange, ...other } = props;

  const handleKeyDown = (event) => {
    const { key, target } = event;
    if (key === "Backspace") {
      const { selectionStart, selectionEnd, value } = target;
      if (selectionStart === selectionEnd) {
        let newPosition = selectionStart - 1;
        while (newPosition >= 0 && /[\(\)\- ]/.test(value[newPosition])) {
          newPosition -= 1;
        }
        if (newPosition >= 0) {
          const newValue =
            value.slice(0, newPosition) + value.slice(selectionStart);
          event.preventDefault();
          onChange({
            target: {
              name: props.name,
              value: newValue,
            },
          });
        }
      }
    }
  };

  const handleChange = (event) => {
    onChange(event);
  };

  return (
    <MaskedInput
      {...other}
      ref={(ref) => inputRef(ref ? ref.inputElement : null)}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      guide={false}
      keepCharPositions={true}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
    />
  );
});

const PhoneNumberField = ({
  value,
  handleChange,
  className,
  helperText,
  inputRef = null,
  required = false,
}) => {
  const [isInvalid, setIsInvalid] = useState(false);

  const isValidPhoneNumber = (phone) => {
    const strippedPhone = stripPhoneNumberFormatting(phone);
    return strippedPhone.length === 10; // Assuming valid phone numbers are 10 digits long
  };

  const handleBlur = () => {
    const strippedValue = stripPhoneNumberFormatting(value);
    setIsInvalid(!isValidPhoneNumber(strippedValue));
  };
  const handleInputChange = (event) => {
    handleChange(event);
  };

  return (
    <div className={className}>
      <TextField
        label="Phone Number"
        variant="outlined"
        name="phone"
        required={required}
        error={isInvalid}
        value={value}
        inputRef={inputRef}
        fullWidth
        onChange={handleInputChange}
        onBlur={handleBlur}
        helperText={helperText ? helperText : "e.g. (555) 555 - 5555"}
        InputProps={{
          inputComponent: TextMaskCustom,
        }}
      />
    </div>
  );
};

// Extract address function
const extractAddress = (place) => {
  const address = {
    street: "",
    city: "",
    state: "",
    zip: "",
    fulladdress: place.formatted_address || "",
  };

  if (place.address_components) {
    place.address_components.forEach((component) => {
      const types = component.types;
      if (types.includes("street_number")) {
        address.street = `${component.long_name} ${address.street}`;
      }
      if (types.includes("route")) {
        address.street += component.long_name;
      }
      if (types.includes("locality")) {
        address.city = component.long_name;
      }
      if (types.includes("administrative_area_level_1")) {
        address.state = component.short_name;
      }
      if (types.includes("postal_code")) {
        address.zip = component.long_name;
      }
    });
  }
  return address;
};
const AddressField = ({
  value = {},
  handleChange,
  helpText = null,
  className,
  label = null,
  placeholder = "Start typing property address",
}) => {
  const googleSearchRef = useRef(null);
  const isMounted = useRef(true);

  const { street, city, state, zip } = value;
  const parts = [];

  if (street) parts.push(street);
  if (city) parts.push(city);
  if (state) parts.push(state);
  if (zip) parts.push(zip);

  let fullAddressConstructed = parts.join(", ");

  const [localValues, setLocalValues] = React.useState({
    street: value.street || "",
    city: value.city || "",
    state: value.state || "",
    zip: value.zip || "",
    fulladdress: fullAddressConstructed,
  });

  // Address change handler
  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    const address = extractAddress(place);
    if (isMounted.current) {
      setLocalValues((prevState) => ({
        ...prevState,
        ...address,
      }));
    }
  };

  // Initialize autocomplete function
  const initAutocomplete = () => {
    if (!googleSearchRef.current) return;

    const autocomplete = new window.google.maps.places.Autocomplete(
      googleSearchRef.current,
      { types: ["address"] } // Restrict the search to addresses only
    );

    autocomplete.setFields([
      "address_component",
      "geometry",
      "formatted_address",
    ]);

    autocomplete.addListener("place_changed", () =>
      onChangeAddress(autocomplete)
    );
  };

  // Handle local change function
  const handleLocalChange = (event) => {
    const { name, value } = event.target;
    setLocalValues((prevState) => ({
      ...prevState,
      [name]: value,
      ...(name === "fulladdress" && {
        street: "",
        city: "",
        state: "",
        zip: "",
      }),
    }));
  };

  // Debounced handleChange function
  const debouncedHandleChange = useRef(debounce(handleChange, 300)).current;

  useEffect(() => {
    isMounted.current = true; // Component is mounted

    loadGoogleMapsScript(GOOGLE_PLACES_API)
      .then(() => initAutocomplete())
      .catch((error) =>
        console.error("Error loading Google Maps script", error)
      );

    return () => {
      isMounted.current = false; // Component is unmounted
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      debouncedHandleChange(localValues);
    }
  }, [localValues, debouncedHandleChange]);

  return (
    <div className={className}>
      <TextField
        name="fulladdress"
        color="primary"
        label={label}
        variant="outlined"
        placeholder={placeholder}
        inputRef={googleSearchRef}
        InputLabelProps={{
          shrink: true,
        }}
        defaultValue={localValues.fulladdress}
        fullWidth={true}
        required
        helperText={helpText}
        onChange={handleLocalChange}
      />
    </div>
  );
};

const LanguageAutocomplete = ({
  value = ["English"],
  label = "Languages",
  name = "language",
  placeholder = "Add a language",
  handleChange,
  className,
}) => {
  const languageOptions = [
    "English",
    "Spanish",
    "Mandarin",
    "Cantonese",
    "Tagalog (including Filipino)",
    "Vietnamese",
    "Arabic",
    "French",
    "Korean",
    "Russian",
    "German",
  ];
  return (
    <Autocomplete
      multiple
      options={languageOptions}
      value={value}
      className={className}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          name={name}
          placeholder={placeholder}
          required
        />
      )}
      freeSolo
    />
  );
};

function YesNoGeneralField({ label, name, handleChange, value, className }) {
  // Convert the boolean value to a string "true" or "false"
  const stringValue = String(value);
  return (
    <div className={className}>
      <FormControl component="fieldset">
        <FormLabel component="legend" style={{ lineHeight: "120%" }}>
          {label}
        </FormLabel>
        <RadioGroup
          aria-label={label}
          name={name}
          value={stringValue}
          onChange={handleChange}
        >
          <FormControlLabel
            value="true"
            control={<Radio color="primary" />}
            label="Yes"
          />
          <FormControlLabel
            value="false"
            control={<Radio color="primary" />}
            label="No"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
}

function GeneralSelectField({
  name,
  label,
  value,
  handleChange,
  helperText,
  options,
  className,
}) {
  return (
    <div className={className}>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id={`${name}-label`}>{label}</InputLabel>
        <Select
          labelId={`${name}-label`}
          id={name}
          name={name}
          value={value}
          onChange={handleChange}
          label={label}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </div>
  );
}

function NumberInteractGeneralField({
  label,
  helperText,
  name,
  value,
  className,
  handleChange,
  minimumValue = 0,
  placeholder = null,
}) {
  const handleIncrement = () => {
    handleChange(name, value + 1);
  };

  const handleDecrement = () => {
    handleChange(name, Math.max(value - 1, minimumValue));
  };

  return (
    <div className={className}>
      <FormLabel component="legend" style={{ lineHeight: "120%" }}>
        <Typography variant="subtitle2">{label}</Typography>
        {placeholder && (
          <Typography variant="caption">{placeholder}</Typography>
        )}
      </FormLabel>
      <Box display="flex" alignItems="center">
        <IconButton onClick={handleDecrement} disabled={value <= minimumValue}>
          <RemoveCircleIcon />
        </IconButton>
        <Typography component="span">{value}</Typography>
        <IconButton onClick={handleIncrement}>
          <AddCircleIcon />
        </IconButton>
      </Box>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </div>
  );
}

function MultiSelectChecklist({
  options,
  selectedValues,
  className,
  handleChange,
  label,
  name,
}) {
  return (
    <div className={className}>
      <FormControl variant="outlined" fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
          multiple
          name={name}
          value={selectedValues}
          onChange={handleChange}
          renderValue={(selected) => selected.join(", ")}
          label={label}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              <Checkbox checked={selectedValues.indexOf(option) > -1} />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export {
  UsernameField,
  GeneralNumberTextField,
  NumberInteractGeneralField,
  CurrencyTextField,
  PhoneNumberField,
  AddressField,
  GeneralTextField,
  LanguageAutocomplete,
  YesNoGeneralField,
  GeneralSelectField,
  MultiSelectChecklist,
  ReadOnlyField,
  PasswordField,
  EmailTextField,
};
