import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import amplitude from "amplitude-js";

import PayoutPerferences from "./payoutPreferences";
import PayoutBalance from "./payoutBalance";
import CleaningInvoices from "./cleaningInvoices";
import LoadingScreen from "../components/loadingScreen";

// Actions
import { fetchPayoutAccount } from "../actions/payments";

// Reducers
import { getUserToken } from "../reducers/user";
import { getLoading } from "../reducers/loading";
import { getPayoutAccount } from "../reducers/payments";

import "./manageAccount.scss";

const ManagePayout = (props) => {
  const { userToken, isLoading, account, fetchPayoutAccount } = props;

  useEffect(() => {
    fetchPayoutAccount();
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  } else {
    if (userToken && userToken.is_host) {
      return <PayoutPerferences host={true} />;
    } else if (userToken && (userToken.is_maid || userToken.is_ops)) {
      return (
        <div className="manage-account">
          <PayoutPerferences
            housekeeper={true}
            account={account}
            userToken={userToken}
          />
          <PayoutBalance account={account} />
          <div>
            <CleaningInvoices account={account} />
          </div>
        </div>
      );
    } else {
      return "Empty...";
    }
  }
};

export default connect(
  (state) => ({
    userToken: getUserToken(state),
    isLoading: getLoading(state),
    account: getPayoutAccount(state),
  }),
  (dispatch) => bindActionCreators({ fetchPayoutAccount }, dispatch)
)(ManagePayout);
