import { SET_JOBS, RESET_JOBS, SET_A_JOB, RESET_A_JOB } from "../actions/jobs";

export const getJobs = ({ jobs }) => jobs;
export const getJobDetails = ({ job }) => job;

export const jobs = (
  state = { page: { total: 0 }, items: [], isLoading: true },
  action
) => {
  const { data } = action;
  switch (action.type) {
    case RESET_JOBS:
      return { page: { total: 0 }, items: [], isLoading: true };
    case SET_JOBS:
      return {
        ...state,
        items: [...state.items, ...data.items],
        page: {
          total: data.total,
          currentPage: data.current_page,
          nextPage: data.next_page,
          prevPage: data.previous_page,
          previousPage: data.previous_page,
        },
        isLoading: false,
      };

    default:
      return state;
  }
};

export const job = (state = { isLoading: true }, action) => {
  const { data } = action;
  switch (action.type) {
    case RESET_A_JOB:
      return { isLoading: true };
    case SET_A_JOB:
      return {
        ...state,
        ...data,
        isLoading: false,
      };

    default:
      return state;
  }
};
