import React, { useEffect } from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useDispatch, useSelector } from "react-redux";
import { Button, Typography } from "@material-ui/core";
import { isEmpty } from "lodash";
import Navigation from "../containers/navigation";
import {
  AddressField,
  FirstNameField,
  GeneralTextField,
  LastNameField,
  PhoneNumberField,
} from "../containers/accountFields";

import { useQuery } from "../helpers/url";

import { abColors } from "../constants/misc";
import { createMaidAccount } from "../actions/user";

const AccountSetupScreen = () => {
  const userToken = useSelector((state) => state.userToken);
  const [account, setAccount] = React.useState({
    first: "",
    last: "",
    phone: "",
    address: {
      street: "",
      city: "",
      state: "",
      zip: "",
    },
  });

  const query = useQuery();
  const username = query.get("username");
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setAccount({ ...account, [name]: value });
  };
  const handleAddressChange = (value) => {
    setAccount({ ...account, ["address"]: value });
  };

  useEffect(() => {
    if (userToken && userToken.first) {
      console.log("Push the user out");
    }
  }, [userToken]);

  const isCTADisabled = React.useMemo(() => {
    // Destructure the account state for easier access
    const { first, last, phone, address } = account;
    const { street, city, state, zip } = address;

    // Define a regex for validating US phone number format
    // Adjust the regex according to the specific format you're validating
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;

    // Check if any of the fields are empty
    const areFieldsEmpty =
      !first || !last || !phone || !street || !city || !state || !zip;

    // Check if the phone format is correct
    const isPhoneValid = phoneRegex.test(phone);

    return areFieldsEmpty || !isPhoneValid;
  }, [account]); // Depend on account state to re-compute when it changes

  const handleSubmit = () => {
    if (!isEmpty(account)) {
      dispatch(createMaidAccount({ ...account }));
    }
  };

  return (
    <>
      <Navigation type="onboarding" />
      <div
        className="flex align-center justify-center flex-column"
        style={{ height: "calc(100vh - 40px)" }}
      >
        <div
          className="flex flex-column"
          style={{ maxWidth: "350px", width: "100%", minWidth: "260px" }}
        >
          {!isEmpty(username) && (
            <div
              className="mb3 px2 py2 flex align-center flex-column text-center"
              style={{
                backgroundColor: abColors.proBackground,
                borderRadius: 4,
              }}
            >
              <CheckCircleIcon style={{ fontSize: 32, marginBottom: 16 }} />
              <Typography variant="body2">
                Congratulations for reserving{" "}
                <span style={{ fontWeight: 500 }}>'{username}'</span> as your
                unique username! Secure it by providing your account information
                – let&apos;s make it official!
              </Typography>
            </div>
          )}
          <GeneralTextField
            name="first"
            label="Fist name"
            value={account.first}
            handleChange={handleChange}
          />
          <GeneralTextField
            name="last"
            label="Last name"
            className="mt2"
            value={account.last}
            handleChange={handleChange}
          />
          <PhoneNumberField
            className="mt2"
            helperText="For TXT notifications. Message/data rates may apply."
            value={account.phone}
            handleChange={handleChange}
          />
          <Typography variant="body1">Enter your address</Typography>
          <Typography variant="caption">
            This will not be shared with anyone.
          </Typography>
          <AddressField
            value={account.address}
            handleChange={handleAddressChange}
            className="mt2"
          />
          <div className="mt4">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={isCTADisabled}
              onClick={() => handleSubmit()}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountSetupScreen;
