import axios from "axios";
import base64 from "base-64";

const appEnv = process.env.REACT_APP_ENV;
const prodAPI = process.env.REACT_APP_AUTOMATEBNB_API_PROD;
const stageAPI = process.env.REACT_APP_AUTOMATEBNB_API_STAGE;
const devAPI = process.env.REACT_APP_AUTOMATEBNB_API_DEV;
const localAPI = process.env.REACT_APP_AUTOMATEBNB_API_LOCAL;
const apiKey = process.env.REACT_APP_AUTOMATEBNB_API_TOKEN;

if (!appEnv) {
  console.error("Missing REACT_APP_ENV enviornment variabel");
  console.error("USING STAGE INSTANCE");
}

if (appEnv && appEnv === "production") {
  if (!prodAPI) {
    console.error(
      "Missing REACT_APP_AUTOMATEBNB_API_PROD enviornment variabel"
    );
  }
}

if (appEnv && appEnv === "stage") {
  if (!stageAPI) {
    console.error(
      "Missing REACT_APP_AUTOMATEBNB_API_STAGE enviornment variabel"
    );
  }
}

if (appEnv && appEnv === "dev") {
  if (!devAPI) {
    console.error("Missing REACT_APP_AUTOMATEBNB_API_DEV enviornment variabel");
  }
}

if (appEnv && appEnv === "local") {
  if (!localAPI) {
    console.error("Missing REACT_APP_AUTOMATEBNB_API_LOCAL envioronment variable");
  }
}

const baseURL =
  appEnv === "production" ? prodAPI : appEnv === "stage" ? stageAPI : appEnv === "dev" ? devAPI : localAPI;

const axiosInstance = axios.create({
  baseURL,
  headers: { "Automatebnb-Api-Token": apiKey },
});

export default axiosInstance;
