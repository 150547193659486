import React, { useEffect, useState } from "react";
import classnames from "classnames";
import _moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import { OptimizelyFeature } from "@optimizely/react-sdk";
import amplitude from "amplitude-js";
import Button from "@material-ui/core/Button";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import RequestPaymentButton from "./requestPaymetnButton";

// Actions
import {
  updateJobStatus,
  fetchProsJobs,
  fetchProJobDetails,
} from "../actions/jobs";
import { fetchOpsJobDetails, fetchOpsJobs } from "../actions/ops";
import { setDialogOpen } from "../actions/dialog";
import { Analytics } from "../services/analyticsService.tsx";
// Reducers
import { getUserToken } from "../reducers/user";

import {
  CLEANER_STATUS_ACCEPTED,
  CLEANER_STATUS_DECLINED,
  CLEANER_STATUS_COMPLETED,
  CLEANING_DATE_CHANGED,
  WAITING_TO_ACCEPT,
  CLEANING_STARTED,
} from "../constants/apiEnum";
import { DT_MARK_COMPLETED } from "../constants/dialogTypes";

import "./prosJobActions.scss";

const JobActions = (props) => {
  const {
    id: jobId,
    status: jobStatus,
    job_date: jobDate,
    is_completed: isCompleted,
    is_payment_request_available: isPaymentRequestAvailable,
    is_past: isPast,
    userToken,
    view,
    assignments,
    updateJobStatus,
    setDialogOpen,
    fetchProsJobs,
    fetchProJobDetails,
    fetchOpsJobDetails,
    fetchOpsJobs,
    is_assignment: isAssignment = false,
  } = props;

  const isOps = userToken.is_ops || false;
  const isPro = userToken.is_maid || false;

  const [isLoading, setButtonLoading] = useState({
    accept: false,
    decline: false,
    completed: false,
  });

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [newActionStatus, setNewActionStatus] = useState(null);

  const jobDateFormatted = _moment(jobDate);
  const today = _moment();

  const componentLocationForAnalytics =
    view === "jobDetails" ? "job_details" : "jobs";

  const handleConfirmDialogClose = () => {
    setButtonLoading({ decline: false });
    setConfirmDialogOpen(false);
  };
  const handleConfirmDialogOpen = () => setConfirmDialogOpen(true);

  const handleJobActionClick = ({ stateKey, newStatus, amplitudeEvent }) => {
    // newStatus is not defined don't call the API
    if (!newStatus) return;

    setButtonLoading({ [stateKey]: true });

    if (!isEmpty(assignments) && newStatus === CLEANER_STATUS_DECLINED) {
      setNewActionStatus(newStatus);
      handleConfirmDialogOpen();
    } else {
      handleNewStatusChange(newStatus);
    }
  };

  const handleNewStatusChange = (newStatus) => {
    updateJobStatus(jobId, newStatus)
      .then((res) => {
        if (res.status === 200) {
          const lowerCaseStatus = newStatus.toLowerCase();
          Analytics.trackEvent(
            `job_${lowerCaseStatus}_action_from_${componentLocationForAnalytics}`,
            { job_id: jobId }
          );
          if (isOps) {
            if (view === "jobDetails") {
              // Fetch ops Job details, Job id is required
              fetchOpsJobDetails(jobId);
            } else {
              // fetch ops jobs
              fetchOpsJobs();
            }
          } else {
            if (view === "jobDetails") {
              // Fetch job Details JobID is required
              fetchProJobDetails(jobId);
            } else {
              // fetch pro jobs
              fetchProsJobs({ renderLoadingScreen: false });
            }
          }
          setButtonLoading(false);
        }
      })
      .catch((err) => {
        setButtonLoading(false);
        return err;
      });
  };

  function isButtonLoading(type) {
    return isLoading[type];
  }

  const RenderMarkComplete = () => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="medium"
        disableElevation
        onClick={() =>
          handleJobActionClick({
            stateKey: "completed",
            newStatus: CLEANER_STATUS_COMPLETED,
            amplitudeEvent: "mark-completed",
          })
        }
        startIcon={<DoneAllIcon />}
      >
        Mark as complete
      </Button>
    );
  };

  const RenderButton = ({ type }) => {
    /*
      Customize buttons based on its type
    */

    let buttonLabel = "N/A"; // html label for the button
    let newStatus = null; // add a status to call the API
    let buttonClass = ""; // add a custom class based on type
    let stateKey = "other"; // handle button loading type
    let amplitudeEvent = ""; // set Amplitude event

    if (type === "request payment") {
      // isPro

      return (
        !isAssignment && (
          <RequestPaymentButton
            job={props}
            isOps={isOps}
            className={`self-flex-start ${
              isOps ? "btn-brand--ops" : "host-primary"
            }`}
          />
        )
      );
    } else if (type == "mark completed") {
      amplitudeEvent = "mark-completed";
      buttonLabel = "Mark as completed";
      newStatus = CLEANER_STATUS_COMPLETED;
      buttonClass = "button--mark-complete";
      stateKey = "completed";
    } else if (type === "accepted") {
      amplitudeEvent = "";
      buttonLabel = "Accepted";
      buttonClass = "button--accepted";
      // Because the status is "accepted" there is no action that needs to be performed
      newStatus = null;
    } else if (type === "declined") {
      amplitudeEvent = "";
      buttonLabel = "Declined";
      buttonClass = "button--declined";
      // Because the status is "declined" there is no action that needs to be performed
      newStatus = null;
    } else if (type === "accept") {
      amplitudeEvent = "accept";
      buttonLabel = "Accept";
      stateKey = "accept";
      newStatus = CLEANER_STATUS_ACCEPTED;
      buttonClass = "button--pro-resting";
    } else if (type === "decline") {
      amplitudeEvent = "declined";
      buttonLabel = "Decline";
      stateKey = "decline";
      newStatus = CLEANER_STATUS_DECLINED;
      buttonClass = "button--pro-resting";
    }

    // Refactor this file to have more flexibility.

    return (
      <>
        <Button
          variant="outlined"
          disableElevation
          aria-label={buttonLabel}
          onClick={() =>
            handleJobActionClick({ stateKey, newStatus, amplitudeEvent })
          }
          className={classnames("button", buttonClass)}
        >
          {isButtonLoading(stateKey) ? (
            <CircularProgress size={24} color="primary" />
          ) : (
            buttonLabel
          )}
        </Button>
        <Dialog
          open={confirmDialogOpen}
          onClose={handleConfirmDialogClose}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle id="draggable-dialog-title" style={{ fontSize: "16px" }}>
            Are you sure?
          </DialogTitle>
          <DialogContent>
            Heads up! All pros assigned to this job will be removed and notified
            of the job cancellation.
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={handleConfirmDialogClose}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleNewStatusChange(newActionStatus)}
              color="primary"
              variant="contained"
              disableElevation
              style={{ backgroundColor: "red" }}
            >
              I understand, Decline job
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  // LOGIC
  if (isCompleted) {
    return isPaymentRequestAvailable ? (
      <RenderButton type="request payment" />
    ) : (
      ""
    );
  } else if (
    isPast &&
    (jobStatus === WAITING_TO_ACCEPT || jobStatus === CLEANING_DATE_CHANGED)
  ) {
    return <RenderMarkComplete />;
  } else if (isPast && jobStatus === CLEANER_STATUS_DECLINED) {
    return null;
  } else {
    if (jobStatus === CLEANER_STATUS_ACCEPTED && today >= jobDateFormatted) {
      return (
        <div key="job-action-mark-completed-decline" className="job-actions">
          {!isPast && <RenderButton type="decline" />}
          <RenderMarkComplete />
        </div>
      );
    } else if (jobStatus === CLEANER_STATUS_ACCEPTED) {
      return (
        <div key="job-action-accepted-decline" className="job-actions">
          <RenderButton type="decline" />
          <RenderButton type="accepted" />
        </div>
      );
    } else if (jobStatus === CLEANER_STATUS_DECLINED) {
      return (
        <div key="job-action-accept-declined" className="job-actions">
          <RenderButton type="declined" />
          <RenderButton type="accept" />
        </div>
      );
    } else if (
      jobStatus === WAITING_TO_ACCEPT ||
      jobStatus === CLEANING_DATE_CHANGED
    ) {
      return (
        <div key="job-action-accept-decline" className="job-actions">
          <RenderButton type="decline" />
          <RenderButton type="accept" />
        </div>
      );
    } else if (jobStatus === CLEANING_STARTED) {
      return null;
    } else {
      return <div />;
    }
  }
};

export default connect(
  (state) => ({
    userToken: getUserToken(state),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        updateJobStatus,
        fetchOpsJobs,
        fetchOpsJobDetails,
        fetchProsJobs,
        setDialogOpen,
        fetchProJobDetails,
      },
      dispatch
    )
)(JobActions);
