import React from "react";
import { useDispatch } from "react-redux";
import amplitude from "amplitude-js";

import Button from "@material-ui/core/Button";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

import { setDialogOpen } from "../actions/dialog";
import { DIALOG_TYPE_REQUEST_CLEANING_PAYMENT } from "../constants/dialogTypes";
import { Analytics } from "../services/analyticsService.tsx";

let dispatch = null;

export default function RequestPaymentButton({
  className,
  job,
  isJobDetails = false,
}) {
  dispatch = useDispatch();

  const handleClick = () => {
    Analytics.trackEvent("request_payment_action_from_job_details", {
      job_id: job.id,
    });
    dispatch(setDialogOpen(DIALOG_TYPE_REQUEST_CLEANING_PAYMENT, job.id));
  };

  console.log("< isJobDetails: ", isJobDetails);

  if (job && job.is_payment_request_available) {
    return (
      <Button
        className={`nowrap ${className}`}
        disableElevation
        color="secondary"
        size={isJobDetails ? "large" : "small"}
        variant="contained"
        startIcon={<MonetizationOnIcon />}
        onClick={handleClick}
      >
        Request payment
      </Button>
    );
  } else {
    return null;
  }
}
