import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import { Typography, useMediaQuery } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import CloseIcon from "@material-ui/icons/Close";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// Local helpers
import NormalizedCleaningStatus from "../components/normalizedCleaningStatus";

// Actions
import { removeAPro } from "../actions/ops";
import { openThisDialog } from "../actions/dialog";

// Enum || Constants
import {
  CLEANER_STATUS_DECLINED,
  CLEANER_STATUS_ACCEPTED,
  CLEANER_STATUS_COMPLETED,
} from "../constants/apiEnum";
import { DT_CHANGE_A_PRO } from "../constants/dialogTypes";

import "./jobAssignedPros.scss";
import { Analytics } from "../services/analyticsService.tsx";

function getClassName(status) {
  if (status === CLEANER_STATUS_ACCEPTED) {
    return "pro-avatar__border--success";
  } else if (status === CLEANER_STATUS_DECLINED) {
    return "pro-avatar__border--danger";
  } else if (status === CLEANER_STATUS_COMPLETED) {
    return "pro-avatar__border--completed";
  } else {
    return "pro-avatar__border--grey";
  }
}

export default ({
  pro = null,
  assignments = null,
  multiple = false,
  jobId = null,
  jobStatus = null,
  isPast = false,
  isMarketplace = false,
}) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [removeProObject, setRemoveProObject] = useState({
    jobId: null,
    proId: null,
    fullName: null,
  });
  const isMarketplaceJobAccepted =
    isMarketplace && jobStatus !== CLEANER_STATUS_ACCEPTED;

  const handleProUnassignment = (jobId, proId, first, last) => {
    const fullName = `${first} ${last}`;
    setRemoveProObject({ jobId, proId, fullName });
    handleConfirmDialogOpen();
  };

  const handleRemoveAssignedPro = () => {
    dispatch(removeAPro(removeProObject.jobId, removeProObject.proId));
  };
  const handleConfirmDialogClose = () => setConfirmDialogOpen(false);
  const handleConfirmDialogOpen = () => setConfirmDialogOpen(true);

  const handleProAssignment = (jobId) => {
    dispatch(openThisDialog({ dialogType: DT_CHANGE_A_PRO, jobId, multiple }));
  };

  function ProAvatar({ first, last, status }) {
    return (
      <div className="inline-flex align-center">
        <Avatar
          className={`pro-avatar pro-avatar__border ${getClassName(status)}`}
        >
          {Array.from(`${first}`)[0]}
          {Array.from(`${last}`)[0]}
        </Avatar>{" "}
      </div>
    );
  }
  function ProTableRow({
    pro = {},
    status = null,
    id = null,
    removeProId = null,
  }) {
    const { first = null, last = null, phone = null, category = null } = pro;
    return (
      <TableRow key={id}>
        <TableCell component="th" scope="row" className="pro-assigned__th">
          <div className="inline-flex align-center">
            <ProAvatar first={first} last={last} status={status} />
            <div className="ml1">
              <p className="nowrap">{`${first} ${last}`}</p>
              {isMobile && <small className="small">{phone}</small>}
            </div>
          </div>
        </TableCell>
        {!isMobile && (
          <>
            <TableCell align="left">{phone}</TableCell>
            <TableCell align="left">{category}</TableCell>
            <TableCell align="left">
              <NormalizedCleaningStatus status={status} />
            </TableCell>
          </>
        )}
        {removeProId && (
          <TableCell align="center" padding="none">
            {!isPast && (
              <Button
                startIcon={<CloseIcon />}
                size="small"
                variant="text"
                onClick={() =>
                  handleProUnassignment(jobId, pro.id, first, last)
                }
              >
                Remove
              </Button>
            )}
          </TableCell>
        )}
      </TableRow>
    );
  }

  const ProsTable = () => {
    return (
      <>
        <Table
          aria-label="simple table"
          size="small"
          className="assigned-pros-table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="left">Full name</TableCell>
              {!isMobile && (
                <>
                  <TableCell align="left">Phone number</TableCell>
                  <TableCell align="left">Job type</TableCell>
                  <TableCell align="left">Status</TableCell>
                </>
              )}
              {multiple && (
                <TableCell align="left" className="action"></TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {multiple ? (
              assignments.map((assignment) => (
                <ProTableRow
                  pro={assignment.pro}
                  status={assignment.status}
                  id={assignment.id}
                  removeProId={assignment.pro.id}
                />
              ))
            ) : (
              <ProTableRow pro={pro} status={jobStatus} id={pro.id} />
            )}
          </TableBody>
        </Table>
        <Dialog
          open={confirmDialogOpen}
          onClose={handleConfirmDialogClose}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle id="draggable-dialog-title" style={{ fontSize: "16px" }}>
            Are you sure?
          </DialogTitle>
          <DialogContent>
            A notification of job cancellation will be sent to your pro
            {removeProObject ? `, ${removeProObject.fullName}.` : "."}
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={handleConfirmDialogClose}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={handleRemoveAssignedPro}
              color="primary"
              variant="contained"
              disableElevation
              style={{ backgroundColor: "red" }}
            >
              Remove
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const AssignProCTA = ({ isPast, status }) => {
    return (
      <div className="mt2">
        <Button
          startIcon={<PersonAddIcon />}
          size="medium"
          variant="contained"
          className="btn-primary"
          disableElevation
          disabled={
            isMarketplaceJobAccepted ||
            isPast ||
            status === CLEANER_STATUS_DECLINED
          }
          onClick={() => {
            Analytics.trackEvent("assign_pro_action_from_job_details", {
              job_id: jobId,
            });
            handleProAssignment(jobId);
          }}
        >
          Assign a pro
        </Button>
      </div>
    );
  };

  const ChangeProCTA = ({ isPast, buttonTitle }) => {
    return (
      !isPast && (
        <div className="mt2">
          <Button
            size="medium"
            color="primary"
            variant="contained"
            disableElevation
            onClick={() => {
              Analytics.trackEvent("change_pro_action_from_job_details", {
                job_id: jobId,
              });
              handleProAssignment(jobId);
            }}
          >
            {buttonTitle}
          </Button>
        </div>
      )
    );
  };

  if (multiple) {
    // Assignments for Ops
    if (!isEmpty(assignments)) {
      return (
        <>
          <ProsTable />
          <AssignProCTA isPast={isPast} status={jobStatus} />
        </>
      );
    } else {
      // CTA to assign pros
      return (
        <>
          {isMarketplaceJobAccepted && (
            <Typography variant="body2" style={{ fontStyle: "italic" }}>
              Marketplace job requests must be “Accepted” before you can assign
              it to your pros.
            </Typography>
          )}
          <AssignProCTA isPast={isPast} status={jobStatus} />
        </>
      );
    }
  } else {
    if (!isEmpty(pro)) {
      // Assign a pro for a host
      return (
        <>
          <ProsTable />
          <ChangeProCTA isPast={isPast} buttonTitle="Change pro" />
        </>
      );
    } else {
      // Assign a pro for a host
      return (
        <>
          <ChangeProCTA isPast={isPast} buttonTitle="Assign a pro" />
        </>
      );
    }
  }
};
