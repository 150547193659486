import React from "react";
import Moment from "react-moment";

// Contants
import { DATE_FORMAT } from "../constants/apiEnum";

import { getFormattedCheckInOutTime } from "../helpers/format";

export default (props) => {
  const { date, time, format = DATE_FORMAT } = props;
  return (
    <>
      {date && <Moment format={format}>{date}</Moment>}
      {date && time && " @ "}
      {time && getFormattedCheckInOutTime(time)}
    </>
  );
};
