import axios from "../helpers/axios";
import { DT_CHANGE_A_PRO } from "../constants/dialogTypes";
import { closeThisDialog } from "./dialog";
import { serverError } from "../helpers/helper_serverError";

import { fetchProperty } from "./pmsProperties";

export const SET_PMS_MY_HOUSEKEEPERS = "set_pms_my_housekeepers";
export const SET_PMS_MY_HOUSEKEEPER = "set_pms_my_housekeeper";

// reuse this in My Pros
export function fetchMyHousekeepers() {
  return function action(dispatch) {
    return axios
      .get(`/v1/mymaids`)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setMyHousekeepers(res.data));
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
}

export function fetchMyHousekeeper(id) {
  return function action(dispatch) {
    return axios
      .get(`/v1/mymaids/${id}`)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setMyHousekeeper(res.data));
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
}

// Will be deprecated once Triggers are built.
export function addHousekeeper(propertyId, housekeepers) {
  return (dispatch) => {
    return axios
      .put(`v1/properties/${propertyId}/add_maid?maid_ids=${housekeepers}`)
      .then((res) => {
        if (res.status === 200) {
          dispatch(closeThisDialog({ dialogType: DT_CHANGE_A_PRO }));
          if (res.data && res.data.id) {
            dispatch(fetchProperty(res.data.id));
          }
        }
      })
      .catch((error) => {
        dispatch(serverError(error, "Something went wront, please try again."));
      });
  };
}

// Use this to invite a pro
export function inviteHousekeeper(data) {
  return (dispatch) => {
    return axios.post("/v1/maids/invite", data).catch((error) => {
      dispatch(serverError(error, "Something went wront, please try again."));
    });
  };
}

export const setMyHousekeepers = (housekeepers) => {
  return {
    type: SET_PMS_MY_HOUSEKEEPERS,
    data: housekeepers,
  };
};

export const setMyHousekeeper = (housekeeper) => {
  return {
    type: SET_PMS_MY_HOUSEKEEPER,
    data: housekeeper,
  };
};
