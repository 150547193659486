import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Moment from "react-moment";
import LaunchIcon from "@material-ui/icons/Launch";
import { useMediaQuery } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Typography } from "@material-ui/core";

import Pagination from "../containers/pagination";
import NormalizedCleaningStatus from "../components/normalizedCleaningStatus";
import { DATE_FORMAT_FULL } from "../constants/apiEnum";

import { fetchCleaningPaymentInvoices } from "../actions/payments";
import { getCleaningPaymentInvoices } from "../reducers/payments";

const CleaningPaymentsTable = ({ items }) => {
  return (
    <Table size="small" aria-label="simple table" className="abnb-table">
      <TableHead>
        <TableRow>
          <TableCell align="left">Cleaning date</TableCell>
          <TableCell align="left">Total earnings</TableCell>
          <TableCell align="left">Total payout</TableCell>
          <TableCell align="left">Status</TableCell>
          <TableCell align="left"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody className="--bk-white">
        {items.map((item, index) => {
          const {
            amount,
            receipt_url: url,
            status,
            payout,
            status_human: human,
            cleaning: { id, cleaning_date: cleaningDate },
          } = item;
          return (
            <TableRow key={index}>
              <TableCell align="left">
                <a href={`/job/${id}`} alt="Link to cleaning">
                  <Moment format={DATE_FORMAT_FULL}>{cleaningDate}</Moment>
                </a>
              </TableCell>
              <TableCell align="left">{`$${amount}`}</TableCell>
              <TableCell align="left">{`$${payout}`}</TableCell>
              <TableCell align="left">
                <NormalizedCleaningStatus status={status} human={human} />
              </TableCell>
              <TableCell>
                {url && (
                  <a
                    target="_new"
                    className="inline-flex align-centet"
                    href={url}
                  >
                    Receipt <LaunchIcon fontSize="small" />
                  </a>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const CleaningInvoices = (props) => {
  const { account, invoices, fetchCleaningPaymentInvoices } = props;

  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    if (!isEmpty(account)) {
      fetchCleaningPaymentInvoices();
    }
  }, []);

  if (isEmpty(invoices)) {
    return "";
  } else {
    const { items, next_page: nextPage } = invoices;
    return (
      <div className="card mt3">
        <Typography variant="h6">Cleaning invoices</Typography>
        <div className="admin mxn1">
          <CleaningPaymentsTable items={items} />
          <Pagination nextPage={nextPage} type="cleaningPaymentInvoices" />
        </div>
      </div>
    );
  }
};

export default connect(
  (state) => ({ invoices: getCleaningPaymentInvoices(state) }),
  (dispatch) => bindActionCreators({ fetchCleaningPaymentInvoices }, dispatch)
)(CleaningInvoices);
