import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { isEmpty } from "lodash";
import classnames from "classnames";

import Button from "@material-ui/core/Button";
import { useMediaQuery } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { getDialogStatus, getDialogObject } from "../reducers/dialog";

import DeletePropertyConfirmDialogBody from "./dialogs/DeletePropertyConfirmDialogBody";
import AddACleaningNoteDialogBody from "./dialogs/AddACleaningNoteDialogBody";
import PropertyDetailsDialogBody from "./dialogs/PropertyDetailsDialogBody";
import NotificationSettingsDialogBody from "./dialogs/NotificationSettingsDialogBody";
import FilterByPropertiesDialogBody from "./dialogs/FilterByPropertiesDialogBody";
import FilterByHousekeepersDialogBody from "./dialogs/FilterByHousekeepersDialogBody";
import DelayCleaningAgreementDialogBody from "./dialogs/DelayCleaningAgreementDialogBody";
import DeactivateDelayCleaningPrompDialogBody from "./dialogs/DeactivateDelayCleaningPrompDialogBody";
import CleaningDateConflictDialogBody from "./dialogs/CleaningDateConflictsDialogBody";
import AllFiltersDialogBody from "./dialogs/allFiltersDialogBody";
import CleaningDateChangeDialogBody from "./dialogs/CleaningDateChangeDialogBody";
import MakeCleaningPaymentDialogBody from "./dialogs/MakeCleaningPaymentDialogBody";
import RequestCleaningPaymentDialogBody from "./dialogs/RequestCleaningPaymentDialogBody";
import SubscribeToMakePaymentPromptDialogBody from "./dialogs/SubscribeToMakePaymentPromptDialogBody";
import CancelPaymentPromptDialogBody from "./dialogs/CancelPaymentPromptDialogBody";
import InviteHousekeeperToPayDialogBody from "./dialogs/InviteHousekeeperToPayDialogBody";
import MarkCleaningCompleted from "./dialogs/MarkCleaningCompleted";
import CleaningStatusFilterDialogBody from "./dialogs/cleaningStatusFiltersDialogBody";
import InviteProDialogBody from "./dialogs/InviteProDialogBody";
import CategoryFilterDialogBody from "./dialogs/CategoryFilterDialogBody";
import ImportCalendarDialogBody from "./dialogs/ImportCalendarDialogBody";
import GenericDialog from "./dialogs/genericDialog";
import GenericModal from "../dialogs/genericModal";
import DeleteCalendarConfirm from "./dialogs/DeleteCalendarConfirm";
import NotificationConfigurationDialogBody from "../manageAccount/notificationConfigDialogBody";
import InviteOpsClients from "../dialogs/inviteOpsClients";
import Discrepancies from "../dialogs/discrepancies";
import PromptToUnassignProFromProperty from "../dialogs/PromptToUnassignProFromProperty";
import AssignProToProperty from "../dialogs/AssignProToProperty";

import {
  RequestAJob,
  JobDescription,
  ChangePro,
  PromptToSubscribe,
  CancelJobPrompt,
  InviteProToPay,
  UpdateJobDateTime,
} from "../dialogs/index";

import { setDialogClose, closeThisDialog } from "../actions/dialog";
import {
  DIALOG_TYPE_ADD_A_CLEANING_NOTE,
  DIALOG_TYPE_PROMPT_TO_SUBSCIBE,
  DIALOG_TYPE_PROPERTY_DELETE_CONFIRM,
  DIALOG_TYPE_DELAY_CLEANING_AGREEMENT,
  DIALOG_TYPE_FILTER_BY_HOUSEKEEPERS,
  DIALOG_TYPE_PROPERTY_LINK_ADD,
  DIALOG_TYPE_PROPERTY_DETAILS_EDIT,
  DIALOG_TYPE_NOTIFICATION_SETTINGS,
  DIALOG_TYPE_DEACTIVATE_DELAY_CLEANING_PROMPT,
  DIALOG_TYPE_SUBSCRIBE_TO_MAKE_PAYMENT_PROMPT,
  DIALOG_TYPE_CANCEL_PAYMENT_PROMPT,
  DIALOG_TYPE_REQUEST_CLEANING_PAYMENT,
  DIALOG_TYPE_CLEANING_DATE_CONFLICT,
  DIALOG_TYPE_CHANGE_CLEANING_DATE,
  DIALOG_TYPE_INVITE_HOUSEKEEPER_TO_PAY,
  DIALOG_TYPE_FILTER_BY_PROPERTY,
  DIALOG_TYPE_ALL_FILTERS,
  DIALOG_TYPE_MAKE_CLEANING_PAYMENT,
  DT_MARK_COMPLETED,
  DT_FILTER_CLEANING_STATUS,
  DT_INVITE_PRO,
  DT_PROS_CATEGORIES,
  DT_REQUEST_A_JOB,
  DT_JOB_DESCRIPTION,
  DT_CHANGE_A_PRO,
  DT_CANCEL_JOB,
  DT_SUBSCRIBE_PROMPT,
  DT_INVITE_TO_PAY,
  DT_UPDATE_JOB_DATE_TIME,
  DT_GENERIC_DIALOG,
  DT_PROMPT_DELETE_CAL,
  DT_NOTIFICATION_CONFIG,
  DT_INVITE_OPS_CLIENTS,
  DT_DISCREPANCIES_JOBS_RESERVATIONS,
  DT_PROMPT_UNASSIGN_PRO_FROM_PROPERTY,
  DT_ASSIGN_PRO_TO_PROPERTY,
  DT_GENERIC_MODAL,
} from "../constants/dialogTypes";

import { HOST_SUBSCRIBE } from "../routes";

import "./dialogWrapper.scss";
import "../dialogs/dialog.scss";

const DialogWrapper = (props) => {
  const { dialog, setDialogClose, closeThisDialog, dialogObject } = props;

  // NEW dialog refact:
  // This makes it more flexible by sending any object or objectype directly to a component
  const { dialogType, dialogOpen, ...other } = dialogObject;
  const isMobile = useMediaQuery("(max-width:600px)");

  let dialogClass = "pms-dialog__initial";
  let ariaLabelBy = "scroll-dialog-title";
  let ariaDescribeBy = "scroll-dialog-description";

  if (dialog) {
    dialogClass = "pms-dialog__assign-housekeeper";
  }

  function handleDialogClose() {
    closeThisDialog();
    setDialogClose();
  }

  const RenderDialogContent = () => {
    if (dialogType) {
      if (dialogType == DT_CHANGE_A_PRO) {
        return (
          <div className="ab-dialog">
            <ChangePro closeThisDialog={handleDialogClose} {...other} />
          </div>
        );
      } else if (dialogType === DIALOG_TYPE_MAKE_CLEANING_PAYMENT) {
        return (
          <div className="pms-dialog__body">
            <MakeCleaningPaymentDialogBody
              closeThisDialog={handleDialogClose}
              {...other}
            />
          </div>
        );
      } else if (dialogType === DT_DISCREPANCIES_JOBS_RESERVATIONS) {
        return <Discrepancies closeThisDialog={handleDialogClose} {...other} />;
      } else if (dialogType === DT_INVITE_OPS_CLIENTS) {
        return (
          <InviteOpsClients closeThisDialog={handleDialogClose} {...other} />
        );
      } else if (dialogType === DT_PROMPT_DELETE_CAL) {
        return (
          <DeleteCalendarConfirm
            closeThisDialog={handleDialogClose}
            {...other}
          />
        );
      } else if (dialogType === DT_INVITE_TO_PAY) {
        return (
          <div className="ab-dialog">
            <InviteProToPay closeThisDialog={handleDialogClose} {...other} />
          </div>
        );
      } else if (dialogType === DT_GENERIC_MODAL) {
        return (
          <div className="ab-dialog">
            <GenericModal {...other} />
          </div>
        );
      } else if (dialogType === DT_NOTIFICATION_CONFIG) {
        return (
          <div className="ab-dialog">
            <NotificationConfigurationDialogBody
              closeThisDialog={handleDialogClose}
              {...other}
            />
          </div>
        );
      } else if (dialogType === DT_UPDATE_JOB_DATE_TIME) {
        return (
          <div className="ab-dialog">
            <UpdateJobDateTime closeThisDialog={handleDialogClose} {...other} />
          </div>
        );
      } else if (dialogType === DT_JOB_DESCRIPTION) {
        return (
          <div className="ab-dialog">
            <JobDescription closeThisDialog={handleDialogClose} {...other} />
          </div>
        );
      } else if (dialogType === DT_REQUEST_A_JOB) {
        return (
          <RequestAJob
            closeThisDialog={handleDialogClose}
            {...other}
            className="ab-dialog"
          />
        );
      } else if (dialogType === DT_CANCEL_JOB) {
        return (
          <div className="ab-dialog">
            <CancelJobPrompt closeThisDialog={handleDialogClose} {...other} />
          </div>
        );
      } else if (dialogType === DT_INVITE_PRO) {
        return (
          <div className="ab-dialog">
            <InviteProDialogBody
              closeThisDialog={handleDialogClose}
              {...other}
            />
          </div>
        );
      } else if (dialogType === DT_SUBSCRIBE_PROMPT) {
        return (
          <div className="ab-dialog">
            <PromptToSubscribe closeThisDialog={handleDialogClose} {...other} />
          </div>
        );
      } else if (dialogType === DIALOG_TYPE_FILTER_BY_PROPERTY) {
        return (
          <div className="pms-dialog__body">
            <FilterByPropertiesDialogBody
              closeThisDialog={handleDialogClose}
              {...other}
            />
          </div>
        );
      } else if (dialogType === DIALOG_TYPE_PROPERTY_DELETE_CONFIRM) {
        return (
          <div className="pms-dialog__body pms-dialog--medium">
            <DeletePropertyConfirmDialogBody
              closeThisDialog={handleDialogClose}
              {...other}
            />
          </div>
        );
      } else if (dialogType === DIALOG_TYPE_MAKE_CLEANING_PAYMENT) {
        return (
          <div className="pms-dialog__body">
            <MakeCleaningPaymentDialogBody
              closeThisDialog={handleDialogClose}
              {...other}
            />
          </div>
        );
      } else if (dialogType === DT_PROMPT_UNASSIGN_PRO_FROM_PROPERTY) {
        return (
          <div className="pms-dialog__body pms-dialog--medium">
            <PromptToUnassignProFromProperty
              closeThisDialog={handleDialogClose}
              {...other}
            />
          </div>
        );
      } else if (dialogType === DT_ASSIGN_PRO_TO_PROPERTY) {
        return (
          <div className="pms-dialog__body pms-dialog--medium">
            <AssignProToProperty
              closeThisDialog={handleDialogClose}
              {...other}
            />
          </div>
        );
      }
    } else if (dialog) {
      if (dialog.dialogType === DIALOG_TYPE_ADD_A_CLEANING_NOTE) {
        // Add a note to a job
        return (
          <div className="pms-dialog__body pms-dialog--small">
            <AddACleaningNoteDialogBody
              closeDialog={handleDialogClose}
              id={dialog.id}
            />
          </div>
        );
      } else if (dialog.dialogType === DIALOG_TYPE_PROPERTY_LINK_ADD) {
        return (
          <div className="pms-dialog__body pms-dialog--medium">
            <ImportCalendarDialogBody
              id={dialog.id}
              closeDialog={handleDialogClose}
            />
          </div>
        );
      } else if (dialog.dialogType === DIALOG_TYPE_PROPERTY_DETAILS_EDIT) {
        return (
          <div className="pms-dialog__body pms-dialog--medium">
            <PropertyDetailsDialogBody
              id={dialog.id}
              closeDialog={handleDialogClose}
            />
          </div>
        );
      } else if (dialog.dialogType === DIALOG_TYPE_NOTIFICATION_SETTINGS) {
        return (
          <div className="pms-dialog__body">
            <NotificationSettingsDialogBody
              id={dialog.id}
              closeDialog={handleDialogClose}
            />
          </div>
        );
      } else if (dialog.dialogType === DIALOG_TYPE_FILTER_BY_HOUSEKEEPERS) {
        return (
          <div className="pms-dialog__body">
            <FilterByHousekeepersDialogBody
              id={dialog.id}
              closeDialog={handleDialogClose}
              housekeepersSelected={dialog.object}
            />
          </div>
        );
      } else if (dialog.dialogType === DIALOG_TYPE_ALL_FILTERS) {
        return (
          <div className="pms-dialog__body">
            <AllFiltersDialogBody
              id={dialog.id}
              closeDialog={handleDialogClose}
            />
          </div>
        );
      } else if (dialog.dialogType === DIALOG_TYPE_DELAY_CLEANING_AGREEMENT) {
        return (
          <div className="pms-dialog__body">
            <DelayCleaningAgreementDialogBody
              id={dialog.id}
              closeDialog={handleDialogClose}
            />
          </div>
        );
      } else if (
        dialog.dialogType === DIALOG_TYPE_DEACTIVATE_DELAY_CLEANING_PROMPT
      ) {
        return (
          <div className="pms-dialog__body">
            <DeactivateDelayCleaningPrompDialogBody
              id={dialog.id}
              closeDialog={handleDialogClose}
            />
          </div>
        );
      } else if (dialog.dialogType === DIALOG_TYPE_CLEANING_DATE_CONFLICT) {
        return (
          <div className="pms-dialog__body">
            <CleaningDateConflictDialogBody
              id={dialog.id}
              closeDialog={handleDialogClose}
              reservations={dialog.object}
            />
          </div>
        );
      } else if (dialog.dialogType === DIALOG_TYPE_CHANGE_CLEANING_DATE) {
        return (
          <div className="pms-dialog__body">
            <CleaningDateChangeDialogBody
              id={dialog.id}
              cleaning={dialog.object}
              closeDialog={handleDialogClose}
            />
          </div>
        );
      } else if (dialog.dialogType === DIALOG_TYPE_REQUEST_CLEANING_PAYMENT) {
        return (
          <div className="pms-dialog__body">
            <RequestCleaningPaymentDialogBody
              id={dialog.id}
              closeDialog={handleDialogClose}
            />
          </div>
        );
      } else if (
        dialog.dialogType === DIALOG_TYPE_SUBSCRIBE_TO_MAKE_PAYMENT_PROMPT
      ) {
        return (
          <div className="pms-dialog__body">
            <SubscribeToMakePaymentPromptDialogBody
              closeDialog={handleDialogClose}
            />
          </div>
        );
      } else if (dialog.dialogType === DIALOG_TYPE_CANCEL_PAYMENT_PROMPT) {
        return (
          <div className="pms-dialog__body">
            <CancelPaymentPromptDialogBody
              id={dialog.id}
              closeDialog={handleDialogClose}
            />
          </div>
        );
      } else if (dialog.dialogType === DIALOG_TYPE_INVITE_HOUSEKEEPER_TO_PAY) {
        return (
          <div className="pms-dialog__body">
            <InviteHousekeeperToPayDialogBody
              id={dialog.id}
              closeDialog={handleDialogClose}
              cleaning={dialog.object}
            />
          </div>
        );
      } else if (dialog.dialogType === DT_FILTER_CLEANING_STATUS) {
        return (
          <div className="pms-dialog__body">
            <CleaningStatusFilterDialogBody closeDialog={handleDialogClose} />
          </div>
        );
      } else if (dialog.dialogType === DT_PROS_CATEGORIES) {
        return <CategoryFilterDialogBody closeDialog={handleDialogClose} />;
      } else if (dialog.dialogType === DT_MARK_COMPLETED) {
        return (
          <MarkCleaningCompleted
            id={dialog.id}
            closeDialog={handleDialogClose}
            cleaning={dialog.object}
          />
        );
      } else if (dialog.dialogType === DT_GENERIC_DIALOG) {
        return (
          <GenericDialog
            title={dialog.object.title}
            body={dialog.object.body}
            closeDialog={handleDialogClose}
          />
        );
      } else {
        return "👋🏼";
      }
    } else {
      return "🤷🏽‍♂️🤷🏽‍♂️";
    }
  };

  return !isEmpty(dialog) ? (
    <Dialog
      open={dialog.isOpen || dialogOpen}
      onClose={handleDialogClose}
      aria-labelledby={ariaLabelBy}
      scroll="paper"
      aria-describedby={ariaDescribeBy}
      className={classnames(dialogClass, "pms-dialog", {
        "mobile-ui": isMobile,
      })}
    >
      <RenderDialogContent />
    </Dialog>
  ) : (
    "Loading..."
  );
};

export default connect(
  (state) => ({
    dialog: getDialogStatus(state),
    dialogObject: getDialogObject(state),
  }),
  (dispatch) =>
    bindActionCreators({ setDialogClose, closeThisDialog }, dispatch)
)(DialogWrapper);
