import {
  SET_HOUSEKEEPER_CLEANINGS,
  SET_HOUSEKEEPER_CLEANING,
  RESET_HOUSEKEEPER_CLEANINGS,
  SET_HOUSEKEEPER_CLEANINGS_STAUTS,
  SET_HOUSEKEEPER_CLEANING_STATUS,
  SET_HOUSEKEEPER_CLEANING_COMPLETED,
} from "../actions/housekeeperCleaings";

export const getHousekeeperCleanings = ({ housekeeperCleanings }) =>
  housekeeperCleanings;

export const getHousekeeperCleaning = ({ housekeeperCleaning }) =>
  housekeeperCleaning;

export const getHousekeeperCleaningsState = ({ housekeeperCleaingsStatus }) =>
  housekeeperCleaingsStatus;

export const housekeeperCleaingsStatus = (state = {}, action) => {
  switch (action.type) {
    case SET_HOUSEKEEPER_CLEANINGS_STAUTS:
      const { status } = action;
      return { ...state, status };

    default:
      return state;
  }
};

export const housekeeperCleanings = (
  state = { page: { total: 0 }, items: [] },
  action
) => {
  const { data } = action;
  const _items = state.items;
  switch (action.type) {
    case RESET_HOUSEKEEPER_CLEANINGS:
      return { page: { total: 0 }, items: [] };
    case SET_HOUSEKEEPER_CLEANING_STATUS:
      const _updatedCleaning = _items.map((item) => {
        if (item.id === data.id) {
          return { ...data };
        }
        return item;
      });

      return {
        items: _updatedCleaning,
        page: {
          total: state.page.total,
          currentPage: state.page.currentPage,
          nextPage: state.page.nextPage,
          previousPage: state.page.previousPage,
        },
      };
    case SET_HOUSEKEEPER_CLEANING_COMPLETED:
      const _filteredCleanings = _items.filter((item) => {
        return item.id !== data.id;
      });

      return {
        items: _filteredCleanings,
        page: {
          total: state.page.total,
          currentPage: state.page.currentPage,
          nextPage: state.page.nextPage,
          previousPage: state.page.previousPage,
        },
      };
    case SET_HOUSEKEEPER_CLEANINGS:
      return {
        ...state,
        items: [...state.items, ...data.items],
        page: {
          total: data.total,
          currentPage: data.current_page,
          nextPage: data.next_page,
          previousPage: data.previous_page,
        },
      };

    default:
      return state;
  }
};

export const housekeeperCleaning = (state = {}, action) => {
  switch (action.type) {
    case SET_HOUSEKEEPER_CLEANING:
      const { cleaning } = action;
      return { ...state, cleaning };

    default:
      return state;
  }
};
