import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import { Button, Typography } from "@material-ui/core";

import { CurrencyTextField } from "../containers/accountFields";

import { updateRateSheet } from "../actions/ops";

import Navigation from "../containers/navigation";
import { useQuery } from "../helpers/url";

const PricingStructureScreen = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const query = useQuery();
  const baseCleaningQuery = query.get("base_cleaning");

  const [amount, setAmount] = useState({
    base_cleaning: baseCleaningQuery, // Might be passed in from query params
    bedrooms: null,
    bathrooms: null,
    beds: null,
    bbq_grill: null,
    firepit: null,
    fireplace: null,
    wash_dishes: null,
    patio: null,
    offsite_laundry: null,
  });

  const handleAmountChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setAmount((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (!isEmpty(user)) {
      const { rate_sheet } = user;
      if (!isEmpty(rate_sheet)) {
        setAmount({
          base_cleaning: baseCleaningQuery
            ? baseCleaningQuery
            : rate_sheet.base_cleaning, // Only full in from user if query param is not available
          bedrooms: rate_sheet.bedrooms,
          bathrooms: rate_sheet.bathrooms,
          beds: rate_sheet.beds,
          bbq_grill: rate_sheet.bbq_grill,
          firepit: rate_sheet.firepit,
          fireplace: rate_sheet.fireplace,
          wash_dishes: rate_sheet.wash_dishes,
          patio: rate_sheet.patio,
          offsite_laundry: rate_sheet.offsite_laundry,
          pet_cleaning: rate_sheet.pet_cleaning,
        });
      }
    }
  }, [user]);

  const isCTADisabled = React.useMemo(() => {
    // Destructure the account state for easier access
    const {
      base_cleaning,
      bedrooms,
      bathrooms,
      beds,
      bbq_grill,
      firepit,
      fireplace,
      wash_dishes,
      patio,
      offsite_laundry,
      pet_cleaning,
    } = amount;

    // Check if any of the fields are empty
    const areFieldsEmpty =
      !base_cleaning ||
      !bedrooms ||
      !pet_cleaning ||
      !bathrooms ||
      !beds ||
      !bbq_grill ||
      !firepit ||
      !fireplace ||
      !wash_dishes ||
      !patio ||
      !offsite_laundry;

    return areFieldsEmpty;
  }, [amount]); // Depend on account state to re-compute when it changes

  const handleSubmit = () => {
    if (!isEmpty(amount)) {
      dispatch(updateRateSheet({ ...amount }));
    }
  };

  return (
    <>
      <Navigation type="onboarding" />
      <div
        className="flex align-center justify-center flex-column"
        style={{ marginTop: 24 }}
      >
        <div
          className="flex flex-column"
          style={{ maxWidth: "350px", width: "100%", minWidth: "260px" }}
        >
          {baseCleaningQuery && (
            <div className="mb2">
              <Typography variant="caption">
                Carried over from the previous screen. Feel free to modify this
                to align with your cleaning fee.
              </Typography>
            </div>
          )}
          <CurrencyTextField
            label="Base cleaning fee"
            currency="USD"
            value={amount.base_cleaning}
            fullWidth
            onChange={handleAmountChange}
            name="base_cleaning"
          />
          <div className="mt2 mb1">
            <Typography variant="h6">Space fee</Typography>
            <Typography variant="caption">
              This form includes charges for extra bedrooms, beds, and
              bathrooms.
            </Typography>
          </div>
          <CurrencyTextField
            label="Additional bedroom fee"
            currency="USD"
            value={amount.bedrooms}
            fullWidth
            onChange={handleAmountChange}
            name="bedrooms"
          />
          <CurrencyTextField
            label="Additional bed fee"
            currency="USD"
            className="mt2"
            value={amount.beds}
            fullWidth
            onChange={handleAmountChange}
            name="beds"
          />
          <CurrencyTextField
            label="Additional bathroom fee"
            currency="USD"
            fullWidth
            className="mt2"
            value={amount.bathrooms}
            onChange={handleAmountChange}
            name="bathrooms"
          />
          <div className="mt2 mb1">
            <Typography variant="h6">Add-ons</Typography>
            <Typography variant="caption">
              Cleaning add-ons are provided with each turnover.
            </Typography>
          </div>
          <CurrencyTextField
            label="Fireplace wood stove"
            currency="USD"
            fullWidth
            className="mt2"
            value={amount.fireplace}
            onChange={handleAmountChange}
            name="fireplace"
          />
          <CurrencyTextField
            label="Outdoor BBQ Grill"
            currency="USD"
            fullWidth
            className="mt2"
            value={amount.bbq_grill}
            onChange={handleAmountChange}
            name="bbq_grill"
          />
          <CurrencyTextField
            label="Outdoor firepit"
            currency="USD"
            fullWidth
            className="mt2"
            value={amount.firepit}
            onChange={handleAmountChange}
            name="firepit"
          />
          <CurrencyTextField
            label="Patio of Deck"
            currency="USD"
            fullWidth
            className="mt2"
            value={amount.patio}
            onChange={handleAmountChange}
            name="patio"
          />
          <CurrencyTextField
            label="Offsite laundry service"
            currency="USD"
            fullWidth
            className="mt2"
            value={amount.offsite_laundry}
            onChange={handleAmountChange}
            name="offsite_laundry"
          />
          <CurrencyTextField
            label="Wash dirty dishes"
            currency="USD"
            fullWidth
            className="mt2"
            value={amount.wash_dishes}
            onChange={handleAmountChange}
            name="wash_dishes"
          />
          <CurrencyTextField
            label="Pet cleaning"
            currency="USD"
            fullWidth
            className="mt2"
            value={amount.pet_cleaning}
            onChange={handleAmountChange}
            name="pet_cleaning"
          />
          <div className="mt4">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={isCTADisabled}
              onClick={() => handleSubmit()}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingStructureScreen;
