import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import DialogContent from "@material-ui/core/DialogContent";
import { TextField } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { addCleaningNote } from "../../actions/pmsCleanings";
import { getDialogStatus } from "../../reducers/dialog";

const AddANoteToCleaning = (props) => {
  const {
    id,
    fetchReservation,
    closeDialog,
    cleaning,
    addCleaningNote,
  } = props;

  const [note, setNote] = useState("");
  const [checked, setChecked] = useState(true);

  const handleDialogClose = (type) => {
    if (type === "add") {
      addCleaningNote({
        cleaingId: cleaning.id,
        reservationId: id,
        notify: checked,
        note,
      });
    } else if (type === "cancel") {
      closeDialog();
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (cleaning) {
      setNote(cleaning.note);
    }
  }, [cleaning]);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setNote(value);
  };

  return (
    <>
      <DialogTitle
        id="scroll-dialog-title"
        className="assign-housekeeper__title"
      >
        Add a cleaning note
      </DialogTitle>
      <DialogContent dividers={true} className="host">
        <TextField
          type="time"
          variant="outlined"
          row="5"
          onChange={handleInputChange}
          autoFocus={true}
          multiline
          defaultValue={note}
          fullWidth={true}
        />
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            }
            label="Notify your housekeeper."
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleDialogClose("cancel")}
          variant="contained"
          disableElevation
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleDialogClose("add")}
          variant="contained"
          color="primary"
          className="host-primary"
          disableElevation
        >
          Add
        </Button>
      </DialogActions>
    </>
  );
};

export default connect(
  (state) => ({ cleaning: getDialogStatus(state).object }),
  (dispatch) => bindActionCreators({ addCleaningNote }, dispatch)
)(AddANoteToCleaning);
