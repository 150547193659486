import axios from "../helpers/axios";
import history from "../helpers/history";

// NOT sure what it does
import { serverError } from "../helpers/helper_serverError";
import { parseQuery } from "../helpers/url";

import { PMS_RESERVATIONS } from "../routes";

import { setContentLoading } from "./loading";

//Refactor
import { SHOW_SNACKBAR } from "../constants/action-types";
import { property } from "../reducers/pmsProperties";

export const SET_RESERVATIONS = "set_reservations";
export const RESET_RESERVATIONS = "reset_reservations";
export const SET_RESERVATION = "set_reservation";

export const fetchReservations = (next) => {
  const {
    property_ids,
    maid_ids,
    channels,
    note,
    type,
    cleaning_status,
    payment_statuses,
  } = parseQuery();

  let cleaningStatus = "";

  return function action(dispatch) {
    if (!next) {
      dispatch(setContentLoading(true));
      dispatch(resetReservations());
    }

    if (type === "completed") {
      cleaningStatus = "COMPLETED";
    } else {
      if (cleaning_status) {
        cleaningStatus += cleaning_status;
      } else {
        cleaningStatus = `ACCEPTED, DECLINED, CLEANING DATE CHANGED, WAITING TO ACCEPT`;
      }
    }

    return axios
      .get(
        `/v1/reservations?${next ? `page=${encodeURIComponent(next)}` : ""}${
          property_ids ? `filter[property_ids]=${property_ids}` : ""
        }${maid_ids ? `&filter[maid_ids]=${maid_ids}` : ""}${
          cleaningStatus ? `&filter[statuses]=${cleaningStatus}` : ""
        }${channels ? `&filter[channels]=${channels}` : ""}${
          note ? `&filter[note]=${note}` : ""
        }${
          payment_statuses
            ? `&filter[payment_statuses]=${payment_statuses}`
            : ""
        }${type === "completed" ? `&sort_by=DESC` : ""}`
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(setContentLoading(false));
          dispatch(setReservations(res.data));
        }
      })
      .catch((error) => {
        dispatch(setContentLoading(false));
        dispatch(serverError(error));
      });
  };
};

export const fetchReservation = (id) => {
  return (dispatch) => {
    dispatch(setContentLoading(true));
    return axios
      .get(`/v1/reservations/${id}`)
      .then((res) => {
        dispatch(setContentLoading(false));
        if (res.status === 200) {
          dispatch(setReservation(res.data));
          return true;
        }
      })
      .catch((error) => {
        dispatch(setContentLoading(false));
        dispatch(serverError(error));
        return false;
      });
  };
};

export const newReservation = (data) => {
  return (dispatch) => {
    return axios
      .post("/v1/reservations", data)
      .then((res) => {
        if (res.status === 200) {
          if (res.data && res.data.id) {
            dispatch(fetchReservation(res.data.id));
          }

          history.replace(PMS_RESERVATIONS);
          dispatch({
            type: SHOW_SNACKBAR,
            message: "Your reservations has been created successfully",
          });
        }
      })
      .catch((error) => {
        dispatch(serverError(error, "Something went wront, please try again."));
      });
  };
};

export const setReservations = (data) => ({
  type: SET_RESERVATIONS,
  data,
});

export const setReservation = (data) => ({
  type: SET_RESERVATION,
  data,
});

export const resetReservations = () => ({ type: RESET_RESERVATIONS });
