import React from "react";

import { isEmpty } from "lodash";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Moment from "react-moment";

import { DATE_FORMAT_FULL } from "../../constants/apiEnum";

const CleaningDateConflictPrompt = (props) => {
  const { closeDialog, reservations } = props;

  return (
    <>
      <DialogTitle
        id="scroll-dialog-title"
        className="assign-housekeeper__title"
      >
        Cleaning date conflict
      </DialogTitle>
      <DialogContent dividers={true}>
        <p className="pb">
          Your future cleanings have been successfully delayed by 1 day.
          However, the following reservations{" "}
          <span className="bold">could not</span> be delayed as they are
          overlapping with another reservation.
        </p>
        <a
          href="https://automatebnb.notion.site/How-to-activate-delay-cleaning-by-24-hours-9efdf01ddceb445f83f02c18cea04322"
          target="_new"
        >
          How delay cleaning works?
        </a>
        <div className="mt2" style={{ overflow: "auto", height: "200px" }}>
          <Table stickyHeader aria-label="sticky simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Channel</TableCell>
                <TableCell align="left">Check-in</TableCell>
                <TableCell align="left">Check-out</TableCell>
                <TableCell align="left">Cleaning date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reservations ? (
                reservations.map((reservation) => (
                  <TableRow key={reservation.id}>
                    <TableCell component="th" scope="row">
                      {reservation.channel}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Moment format={DATE_FORMAT_FULL}>
                        {reservation.check_in}
                      </Moment>
                    </TableCell>
                    <TableCell align="left">
                      <Moment format={DATE_FORMAT_FULL}>
                        {reservation.check_out}
                      </Moment>
                    </TableCell>
                    <TableCell align="left">
                      <Moment format={DATE_FORMAT_FULL}>
                        {reservation.cleaning_date}
                      </Moment>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow />
              )}
            </TableBody>
          </Table>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => closeDialog()}
          variant="contained"
          color="primary"
          disableElevation
        >
          Done
        </Button>
      </DialogActions>
    </>
  );
};

export default CleaningDateConflictPrompt;
