import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import YouTube from "react-youtube";

import { isEmpty } from "lodash";
import classnames from "classnames";

import ButtonBase from "@material-ui/core/ButtonBase";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Button from "@material-ui/core/Button";
import { useMediaQuery, CircularProgress } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";

import { getPromotionStatus } from "../../reducers/promotion";
import { fetchPromotion, viewedPromotion } from "../../actions/promotion";
import { turncateString } from "../../helpers/url";

import "./promotion.scss";

const Promotion = (props) => {
  const { fetchPromotion, viewedPromotion, promotions } = props;
  const [size, setSize] = useState({ height: "260", width: "464" });
  const [isOpen, setDialogOpen] = useState(false);
  const [isVideoLoaded, setVideoLoading] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  const userToken = useSelector((state) => state.userToken);

  useEffect(() => {
    if (userToken.jwt) {
      fetchPromotion();
    }
  }, [userToken]);

  useEffect(() => {
    if (promotions && promotions.length >= 1 && !isOpen) {
      setDialogOpen(true);
    }
  }, [promotions]);

  useEffect(() => {
    if (isMobile) {
      setSize({ height: "200", width: "336" });
    } else {
      setSize({ height: "260", width: "464" });
    }
  }, [isMobile]);

  if (!isEmpty(promotions) && promotions.length >= 1) {
    const onePromotion = promotions.length === 1;
    const promo = promotions[0].promotion;

    const handleButtonClicked = (id) => {
      viewedPromotion(promotions[0].id);
    };
    const setDialogClose = () => {
      setDialogOpen(false);
    };
    const NextButton = () => (
      <ButtonBase
        onClick={() => handleButtonClicked()}
        variant="contained"
        color="primary"
        className="promotion__round-button"
      >
        <ArrowForwardIcon />
      </ButtonBase>
    );
    const DoneButton = () => (
      <Button
        onClick={() => handleButtonClicked()}
        variant="contained"
        className="host-primary"
        color="primary"
        disableElevation
      >
        Done
      </Button>
    );

    const _onReady = (event) => {
      event.target.pauseVideo();
      setVideoLoading(true);
    };

    const _onPlay = (event) => {
      //   event.target.requestFullscreen();
    };

    const _onEnd = (event) => {};

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={isOpen}
        onClose={setDialogClose}
        aria-labelledby="scroll-dialog-title"
        scroll="paper"
        aria-describedby="scroll-dialog-description"
        className={classnames("promotion-dialog", {
          "mobile-dialog": isMobile,
        })}
      >
        <DialogContent>
          <div className="promotion">
            <Typography variant="h5" className="promotion__header">
              {promo.title ? promo.title : "New feature"}
            </Typography>
            <div className="promotion__video">
              {promo.youtube_video_id ? (
                <>
                  <YouTube
                    videoId={promo.youtube_video_id}
                    opts={size}
                    onReady={_onReady}
                    onPlay={_onPlay}
                    onEnd={_onEnd}
                  />
                  <div className="promotion__video-container">
                    {isVideoLoaded ? "" : <CircularProgress className="" />}
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            <p className="promotion__content pt2 pb">
              {promo.description ? turncateString(promo.description, 185) : ""}
            </p>
            {promo.learn_more_url ? (
              <a
                href={`${promo.learn_more_url}`}
                alt="Learn more"
                target="__new"
              >
                Learn more
              </a>
            ) : (
              ""
            )}
          </div>
        </DialogContent>
        <DialogActions className="mt3 justify-center">
          {onePromotion ? <DoneButton /> : <NextButton />}
        </DialogActions>
      </Dialog>
    );
  } else {
    return "";
  }
};

export default connect(
  (state) => ({
    promotions: getPromotionStatus(state),
  }),
  (dispatch) =>
    bindActionCreators({ fetchPromotion, viewedPromotion }, dispatch)
)(Promotion);
