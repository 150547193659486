import React from "react";
import HomeIcon from "@material-ui/icons/Home";
import { PropertyModel } from "../models";

interface JobPropertyDetailsProps {
  property: PropertyModel;
}

const JobPropertyDetails: React.FC<JobPropertyDetailsProps> = ({
  property,
}) => {
  const isRoomDataAvailable =
    property.beds !== null ||
    property.bedrooms !== null ||
    property.bathrooms !== null;

  const { square_feet = null, dryer = false, washer = false } = property;

  const isLaundryOnSite = dryer || washer;

  function renderPropertyRoomDetails(property: {
    bathrooms: number | null;
    bedrooms: number | null;
    beds: number | null;
  }) {
    const details: string[] = [];

    if (property.beds !== null) {
      details.push(`${property.beds} Bed${property.beds > 1 ? "s" : ""}`);
    }

    if (property.bedrooms !== null) {
      details.push(
        `${property.bedrooms} Bedroom${property.bedrooms > 1 ? "s" : ""}`
      );
    }

    if (property.bathrooms !== null) {
      details.push(
        `${property.bathrooms} Bathroom${property.bathrooms > 1 ? "s" : ""}`
      );
    }

    return details.join(" · ");
  }

  const renderAppliances = (washer, dryer) => {
    if (washer && dryer) {
      return "Washer & Dryer";
    }
    if (washer) {
      return "Washer";
    }
    if (dryer) {
      return "Dryer";
    }
    return "N/A";
  };

  return (
    <div className="mb-4">
      <h3 className="text-md flex align-center">
        <HomeIcon style={{ fontSize: 20, opacity: 0.8, marginRight: 4 }} />{" "}
        Property details
      </h3>
      <div className="mt-2">
        <p>{renderPropertyRoomDetails(property)}</p>
        <div className="flex space-between align-center py-1 mt-2">
          <p>Square footage</p>
          <p>
            {square_feet ? (
              `${square_feet} sq ft`
            ) : (
              <span className="opacity-50">N/A</span>
            )}
          </p>
        </div>
        <div className="flex space-between align-center py-1">
          <p>Onsite laundry</p>
          <p>
            {isLaundryOnSite ? (
              `${renderAppliances(washer, dryer)}`
            ) : (
              <span className="opacity-50">N/A</span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default JobPropertyDetails;
