let googleMapsScriptLoadingPromise;

const loadGoogleMapsScript = (apiKey) => {
  if (googleMapsScriptLoadingPromise) {
    return googleMapsScriptLoadingPromise;
  }

  googleMapsScriptLoadingPromise = new Promise((resolve, reject) => {
    if (window.google && window.google.maps) {
      resolve();
      return;
    }

    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&v=weekly`;
    script.async = true;
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });

  return googleMapsScriptLoadingPromise;
};

const fetchPredictions = (input, callback) => {
  if (!window.google || !window.google.maps) return;

  const service = new window.google.maps.places.AutocompleteService();
  service.getPlacePredictions({ input }, callback);
};

export { loadGoogleMapsScript, fetchPredictions };
