import React from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

import { CLEANER_STATUS_COMPLETED } from "../../constants/apiEnum";
import { updateCleaningStatus } from "../../actions/housekeeperCleaings";
import { Typography } from "@material-ui/core";

const MarkCleaningCompleted = (props) => {
  const [isLoading, setLoading] = React.useState(false);

  const { id, closeDialog } = props;
  const dispatch = useDispatch();

  const handleDialogClose = (type) => {
    if (type === "confirm") {
      if (props && props.id) {
        setLoading(true);
        if (id) {
          dispatch(updateCleaningStatus(id, CLEANER_STATUS_COMPLETED));
          closeDialog();
        }
      }
    } else if (type === "cancel") {
      closeDialog();
    }
  };

  return (
    <>
      <DialogTitle
        id="scroll-dialog-title"
        className="assign-housekeeper__title"
      >
        Heads up!
      </DialogTitle>
      <DialogContent dividers={true}>
        <Typography variant="body1">
          This Cleaning date and time is in the future. Are you sure you want to
          proceed? This action cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleDialogClose("cancel")}
          variant="contained"
          disableElevation
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleDialogClose("confirm")}
          variant="contained"
          className="host-primary"
          color="primary"
          disableElevation
          disabled={isLoading}
        >
          Mark completed
        </Button>
      </DialogActions>
    </>
  );
};

export default connect(
  (state) => ({}),
  (dispatch) => bindActionCreators({}, dispatch)
)(MarkCleaningCompleted);
