import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import Moment from "react-moment";

// Components
import Navigation from "../containers/navigation";
import LoadingScreen from "../components/loadingScreen";

// reducer
import { getUserToken } from "../reducers/user";
import { getJobDetails } from "../reducers/jobs";

// Render pages
import HostJobView from "./hostJobView";
import ProsJobView from "./prosJobView";
import OpsJobView from "./opsJobView";

// Route
import { JOBS } from "../routes";

// Custom Styles
import "./job.scss";

const Job = ({ job, userToken, match, location }) => {
  // Extract property and give it default if the object "Property" is empty
  const { property = {} } = job;
  // Extract city from property and give it default if the key has no value
  const { city = "" } = property;

  const title = !isEmpty(job) ? (
    <span key="job-title-sub-nav-cleaning-location"> {`Job in ${city}`}</span>
  ) : (
    "Job details"
  );

  const isOps = userToken.is_ops;
  const isHost = userToken.is_host;

  const getBackRoute = () => {
    if (job) {
      if (job.cleaning && job.cleaning.is_completed) {
        return `${JOBS}?&type=completed`;
      } else if (job.is_completed) {
        // Pros object doesn't have a cleaning object
        return `${JOBS}?&type=completed`;
      } else {
        return JOBS;
      }
    } else {
      return JOBS;
    }
  };

  return (
    <>
      <Navigation
        hasASubNav={true}
        backToRoute={getBackRoute()}
        pageTitle={title}
      />
      {isEmpty(userToken) ? (
        <LoadingScreen />
      ) : isHost ? (
        <HostJobView />
      ) : isOps ? (
        <OpsJobView />
      ) : (
        <ProsJobView />
      )}
    </>
  );
};

export default connect((state) => ({
  userToken: getUserToken(state),
  job: getJobDetails(state),
}))(Job);
