import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import {
  AppBar,
  Tabs,
  Tab,
  Drawer,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Button,
  Link,
  Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import Badge, { BadgeProps } from "@material-ui/core/Badge";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import logo from "../images/logo-white.png";
import logoBrand from "../images/logo.svg";
import history from "../helpers/history";
import { logout } from "../actions/user";
import { openThisDialog, setDialogOpen } from "../actions/dialog";
import { useScrollPosition } from "../helpers/useScrollPosition";

import { getSubscription } from "../reducers/reducer_subscribe";

import {
  LOG_IN,
  MY_PROS,
  JOBS,
  PROPERTIES,
  MANAGE_PROFILE,
  MANAGE_NOTIFICATIONS,
  HOST_SIGNUP,
  PMS_PROPERTIES_NEW,
  OPERATIONS_CLIENTS,
  MANAGE_ACCOUNT,
  MANAGE_INVOICES,
  MANAGE_PAYOUT,
  MANAGE_SECURITY,
  MANAGE_SUBSCRIPTION,
  MESSAGES,
} from "../routes";

import {
  DT_REQUEST_A_JOB,
  DT_ADD_A_PROPERTY,
  DT_INVITE_PRO,
  DIALOG_TYPE_PROMPT_TO_SUBSCIBE,
  DT_SUBSCRIBE_PROMPT,
  DT_GENERIC_DIALOG,
  DT_INVITE_OPS_CLIENTS,
} from "../constants/dialogTypes";

import "./navigation.scss";
import { normalizeUnits } from "moment";
import { bindActionCreators } from "redux";
import {
  sendbirdSelectors,
  useSendbirdStateContext,
} from "@sendbird/uikit-react";
import GroupChannelHandler from "@sendbird/uikit-react/handlers/GroupChannelHandler";
import { Analytics } from "../services/analyticsService.tsx";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const OpsNavigation = (props) => {
  let TAB0 = 0;
  let TAB1 = 1;
  let TAB2 = 2;
  let TAB3 = 3;
  let TAB4 = 4;
  let TAB5 = 5;

  const {
    backToRoute,
    pageTitle,
    subscribe,
    hasASubNav,
    setDialogOpen,
    openThisDialog,
    type = null,
  } = props;

  // const globalStore = useSendbirdStateContext();
  // const sdkInstance = sendbirdSelectors.getSdk(globalStore);
  const [totalNewMessageCount, setTotalNewMessageCount] = useState(0);

  const userToken = useSelector((state) => state.userToken);
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const isMobile = useMediaQuery("(max-width:600px)");
  const desktop = useMediaQuery("(min-width:850px)");

  const [tabValue, setValue] = useState(TAB0);

  const AUTOMATEBNB_HELP_LINK =
    "https://automatebnb.notion.site/automatebnb/Automatebnb-Help-Center-fb870801dca94dddbde79a02b74cfe4f";

  // Current path URL;
  const currentPath = window.location.pathname;

  const propertiesObject = useSelector((state) => state.properties);

  const showSubNav = hasASubNav && desktop;

  const isOps = userToken.is_ops;
  const isHost = userToken.is_host;
  const isMaid = userToken.is_maid;

  useEffect(() => {
    // Set Tab active when following condition passes
    switch (currentPath) {
      case JOBS:
        setValue(TAB0);
        break;
      case PROPERTIES:
      case OPERATIONS_CLIENTS:
        setValue(TAB1);
        break;
      case MY_PROS:
        setValue(TAB2);
        break;
      case MESSAGES:
        setValue(TAB3);
        break;
      default:
        // Unselect all the tabs if user is on a page in which the tabs are not directly related
        setValue(false);
    }
  }, []);

  // useEffect(() => {
  //   fetchNewMessageCount();
  // }, [globalStore]);
  //
  // useEffect(() => {
  //   const uuid = "" + Math.random() * 100000;
  //   if (sdkInstance?.groupChannel?.addGroupChannelHandler) {
  //     const channelHandlerConstructor = {};
  //     channelHandlerConstructor.onMessageReceived = (channel, message) => {
  //       fetchNewMessageCount();
  //     };
  //     channelHandlerConstructor.onChannelChanged = (channel) => {
  //       fetchNewMessageCount();
  //     };
  //     const channelHandler = new GroupChannelHandler(channelHandlerConstructor);
  //     sdkInstance.groupChannel.addGroupChannelHandler(uuid, channelHandler);
  //   }
  //   return () => {
  //     if (sdkInstance?.groupChannel?.removeChannelHandler) {
  //       sdkInstance.groupChannel.removeChannelHandler(uuid);
  //     }
  //   };
  // }, [sdkInstance]);
  //
  // const fetchNewMessageCount = () => {
  //   if (sdkInstance?.groupChannel) {
  //     let count = sdkInstance.groupChannel
  //       ?.getTotalUnreadMessageCount()
  //       .then((count) => setTotalNewMessageCount(count));
  //   }
  // };

  // Primary action for mobile
  const MobileNavPrimaryButton = () => {
    const handleOnClick = (page) => {
      if (subscribe) {
        if (subscribe.deactivated) {
          if (page === JOBS) {
            return openThisDialog({
              dialogType: DT_SUBSCRIBE_PROMPT,
              dialogBody:
                "Your subscription has ended. Please subscribe to request new jobs.",
            });
          }
          if (page === PROPERTIES) {
            return openThisDialog({
              dialogType: DT_SUBSCRIBE_PROMPT,
              dialogBody:
                "Your subscription has ended. Please subscribe to create new properties.",
            });
          }
        }

        if (page === PROPERTIES) {
          const propertiesLength = propertiesObject?.items.length;
          const iapPropertiesCount = subscribe?.properties_count;
          const isIAP = subscribe?.subscription_type === "iap";

          if (isIAP && iapPropertiesCount === propertiesLength) {
            return setDialogOpen(DT_GENERIC_DIALOG, null, {
              title: "Uh oh!",
              body: (
                <p>
                  You can only add maximum of{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {iapPropertiesCount} properties.
                  </span>{" "}
                  Please upgrade your subscription using your Automatebnb iPhone
                  App to add more properties.
                </p>
              ),
            });
          }
          return history.push(PMS_PROPERTIES_NEW);
        }

        if (page === JOBS) {
          return openThisDialog({ dialogType: DT_REQUEST_A_JOB });
        }
      }

      if (isOps) {
        if (page === JOBS) {
          Analytics.trackSimple("invite_host_action_from_navigation");
          return dispatch(
            openThisDialog({ dialogType: DT_INVITE_OPS_CLIENTS })
          );
        }
      }

      if (page === MY_PROS) {
        return openThisDialog({ dialogType: DT_INVITE_PRO });
      }
    };

    const renderButton = (page, label) => (
      <Button
        variant="outlined"
        disableElevation
        color="primary"
        style={{ color: "#ffffff", borderColor: "#ffffff" }}
        onClick={() => handleOnClick(page)}
      >
        {label}
      </Button>
    );

    if (isHost) {
      switch (currentPath) {
        case JOBS:
          return renderButton(JOBS, "Request a job");
        case PROPERTIES:
          return renderButton(PROPERTIES, "Add a property");
        case MY_PROS:
          return renderButton(MY_PROS, "Invite a pro");
        default:
          return null;
      }
    } else if (isOps) {
      switch (currentPath) {
        case JOBS:
        case OPERATIONS_CLIENTS:
          return renderButton(JOBS, "Invite host");
        case MY_PROS:
          return renderButton(MY_PROS, "Invite a pro");
        default:
          return null;
      }
    }

    return null;
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const logAnalyticsEvent = (event) => {
    if (event.target) {
      const navigationTabText = event.target.textContent;
      // User (OPS) clicked on Hosts tab to view their clients
      if (navigationTabText === "Hosts") {
        Analytics.trackSimple("hosts_tab_action_from_navigation");
      }
    }
  };

  // Navigation Links
  function LinkTab(props) {
    return (
      <Tab
        component="a"
        onClick={(event) => {
          history.push(props.href);
          event.preventDefault();
          logAnalyticsEvent(event);
        }}
        {...props}
      />
    );
  }

  // Sub-navigation
  const SubNav = () => (
    <div className="sub-navigation">
      <div className="sub-navigation__content">
        <button
          className="button"
          aria-labelledby="Menu"
          key="navigation-title-back-button"
          onClick={() => history.replace(backToRoute)}
        >
          <ArrowBackIcon />
        </button>{" "}
        <h1 key="navigation-title-page-title-back-arrow" className="title">
          {pageTitle}
        </h1>
      </div>
    </div>
  );

  // Navigation logo
  const NavLogo = () => {
    if (isEmpty(user)) {
      return (
        <img
          alt="automatebnb-logo"
          src={logo}
          className="logo"
          onClick={(event) => {
            history.push(LOG_IN);
            event.preventDefault();
          }}
        />
      );
    } else {
      return (
        <img
          alt="automatebnb-logo"
          src={logo}
          className="logo"
          onClick={(event) => {
            history.push(JOBS);
            setValue(TAB0);
            event.preventDefault();
          }}
        />
      );
    }
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -8,
      top: -10,
      padding: "0 42x",
      color: "#fff",
      backgroundColor: "rgba(255, 41, 41, 1)",
    },
  }));

  // Desktop Navigation Tabs
  const DesktopNavigationTabs = (props) => {
    if (!isEmpty(user)) {
      return (
        <Tabs
          value={tabValue}
          className={isOps ? "tab-brand--ops" : ""}
          onChange={handleTabChange}
        >
          <LinkTab label="Jobs" href={JOBS} {...a11yProps(TAB0)} />
          {isOps && (
            <LinkTab
              label="Hosts"
              href={OPERATIONS_CLIENTS}
              {...a11yProps(TAB1)}
            />
          )}
          {isHost && (
            <LinkTab
              label="Properties"
              key="navigation-lin-tab-properties-host-only"
              href={PROPERTIES}
              {...a11yProps(TAB1)}
            />
          )}
          {/* {(isOps || isHost || isMaid) && (
            <LinkTab label={
              <Typography>
                Messages
                {props?.newMessagesCount > 0 &&
                  <StyledBadge max={999} badgeContent={props.newMessagesCount}  />}
              </Typography>
            } href={MESSAGES} {...a11yProps(TAB3)} />
          )} */}
          {(isOps || isHost) && (
            <LinkTab label="My Pros" href={MY_PROS} {...a11yProps(TAB2)} />
          )}
        </Tabs>
      );
    } else {
      return null;
    }
  };

  // PageTitle
  const PageTitle = () => {
    const current = window.location.pathname;

    if (current === JOBS) {
      return <h1 key={`nav-page-title-${current}`}>Jobs</h1>;
    } else if (current === PROPERTIES) {
      return <h1 key={`nav-page-title-${current}`}>Properties</h1>;
    } else if (current === MY_PROS) {
      return <h1 key={`nav-page-title-${current}`}>My Pros</h1>;
    } else if (current === OPERATIONS_CLIENTS) {
      return <h1 key={`nav-page-title-${current}`}>Hosts</h1>;
    } else if (current === PMS_PROPERTIES_NEW) {
      return <h1 key={`nav-page-title-${current}`}>New property</h1>;
    } else if (
      current === MANAGE_PROFILE ||
      current === MANAGE_INVOICES ||
      current === MANAGE_NOTIFICATIONS ||
      current === MANAGE_PAYOUT ||
      current === MANAGE_SECURITY ||
      current === MANAGE_SUBSCRIPTION
    ) {
      return <h1 key={`nav-page-title-${current}`}>Manage account</h1>;
    } else {
      return <h1 key={`nav-page-title-random`}></h1>;
    }
  };

  const MobileDrawerContent = (props) => {
    if (isEmpty(user)) {
      return (
        <div className="navigation-drawer">
          <ListItem
            key="link-item-public-login"
            button
            component="a"
            href={LOG_IN}
          >
            <ListItemText primary="Log in" />
          </ListItem>
          <ListItem
            key="link-item-public-sign-up"
            button
            component="a"
            href={HOST_SIGNUP}
          >
            <ListItemText primary="Sign up" />
          </ListItem>
          <ListItem
            key="link-item-public-help"
            button
            component="a"
            href={AUTOMATEBNB_HELP_LINK}
          >
            <ListItemText primary="Help" />
          </ListItem>
        </div>
      );
    } else if (userToken) {
      return (
        <div className="navigation-drawer">
          {userToken.is_ops ? (
            <>
              <ListItem
                key="link-item-logged-in-jobs"
                button
                component="a"
                href={JOBS}
              >
                <ListItemText primary="Jobs" />
              </ListItem>
              <ListItem
                key="link-item-logged-in-operations-clients"
                button
                component="a"
                href={OPERATIONS_CLIENTS}
              >
                <ListItemText primary="Hosts" />
              </ListItem>
              <ListItem
                key="link-item-logged-in-my-pros"
                button
                component="a"
                href={MY_PROS}
              >
                <ListItemText primary="My Pros" />
              </ListItem>
            </>
          ) : (
            <ListItem
              key="link-item-logged-in-jobs"
              button
              component="a"
              href={JOBS}
            >
              <ListItemText primary="Jobs" />
            </ListItem>
          )}

          {userToken.is_host && [
            <ListItem
              key="link-item-logged-in-properties"
              button
              component="a"
              href={PROPERTIES}
            >
              <ListItemText primary="Properties" />
            </ListItem>,
            <ListItem
              key="link-item-logged-in-my-pros"
              button
              component="a"
              href={MY_PROS}
            >
              <ListItemText primary="My Pros" />
            </ListItem>,
          ]}
          {/* <ListItem
            key="link-item-logged-in-jobs"
            button
            component="a"
            href={MESSAGES}
          >
            <Typography>
              Messages
              {props?.newMessagesCount > 0 && (
                <StyledBadge max={999} badgeContent={props.newMessagesCount} />
              )}
            </Typography>
          </ListItem> */}
          <ListItem
            key="link-item-logged-in-manage-account"
            button
            component="a"
            href={MANAGE_PROFILE}
          >
            <ListItemText primary="Manage account" />
          </ListItem>
          <ListItem
            key="link-item-logged-in-manage-notifications"
            button
            component="a"
            onClick={() =>
              Analytics.trackSimple(
                "notification_settings_action_from_navigation"
              )
            }
            href={MANAGE_NOTIFICATIONS}
          >
            <ListItemText primary="Notification settings" />
          </ListItem>
          <ListItem
            key="link-item-public-help"
            button
            component="a"
            href={AUTOMATEBNB_HELP_LINK}
          >
            <ListItemText primary="Help" />
          </ListItem>
          <ListItem
            key="link-item-logged-in-logout"
            button
            onClick={(event) => {
              Analytics.trackSimple("log_out_action_from_navigation");
              dispatch(logout());
              event.preventDefault();
            }}
          >
            <ListItemText primary="Log out" />
          </ListItem>
        </div>
      );
    } else {
      return null;
    }
  };

  const MobileNavigationTabs = (props) => {
    const [drawer, setDrawer] = useState(false);
    return (
      <div className="navigation__title">
        {hasASubNav ? (
          <>
            <button
              className="button"
              aria-labelledby="Menu"
              key="navigation-title-back-button"
              onClick={() => history.replace(backToRoute)}
            >
              <ArrowBackIcon />
            </button>
            <h1 key="navigation-title-page-title-back-arrow">{pageTitle}</h1>
          </>
        ) : (
          <>
            <button
              className="button button--menu"
              key="navigation-title-menubar"
              aria-labelledby="Menu"
              onClick={() => setDrawer(!drawer)}
            >
              <MenuIcon />
            </button>
            <PageTitle key="navigation-title-page-title" />
          </>
        )}
        <Drawer
          anchor="left"
          open={drawer}
          className="mobile-nav"
          onClose={() => setDrawer(!drawer)}
        >
          {isEmpty(user) ? (
            <div>Welcome to Automatebnb!</div>
          ) : (
            isMobile && <div className=""></div>
          )}
          <MobileDrawerContent newMessagesCount={props?.newMessagesCount} />
        </Drawer>
      </div>
    );
  };

  const UserNameDropdown = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClose = () => setAnchorEl(null);
    const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
    return (
      <>
        <button
          className="button inline-flex"
          role="button"
          key="button-user-menu"
          aria-haspopup="true"
          aria-controls="user-menu"
          onClick={handleMenuClick}
        >
          <h3>Hi, {user.first}</h3>
          <ArrowDropDownIcon />
        </button>
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => history.replace(MANAGE_PROFILE)}>
            Manage account
          </MenuItem>
          <MenuItem
            onClick={() => {
              Analytics.trackSimple(
                "notification_settings_action_from_navigation"
              );
              history.push(MANAGE_NOTIFICATIONS);
            }}
          >
            Notification settings
          </MenuItem>
          <MenuItem
            onClick={() => window.open(AUTOMATEBNB_HELP_LINK, "_blank")}
          >
            Help
          </MenuItem>
          <MenuItem onClick={() => dispatch(logout())}>Log out</MenuItem>
        </Menu>
      </>
    );
  };

  // Desktop Menudropdown
  const DesktopMenuDropdown = () => {
    const handleInviteAHost = () => {
      Analytics.trackSimple("invite_host_action_from_navigation");
      dispatch(openThisDialog({ dialogType: DT_INVITE_OPS_CLIENTS }));
    };

    if (!isEmpty(user) && !isMobile) {
      return (
        <>
          <div className="navigation__actions navigation__user">
            {isOps && (
              <Button
                color="secondary"
                className="btn-brand--ops"
                size="small"
                variant="contained"
                disableElevation
                onClick={() => handleInviteAHost()}
              >
                Invite a host
              </Button>
            )}
            <UserNameDropdown />
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  // "navigation-wrapper--brand-ops" : isOps,

  if (type === "public") {
    return (
      <AppBar
        color="default"
        position="static"
        elevation={0}
        className="flex align-center py1"
        style={{ backgroundColor: "white", borderBottom: "1px solid #cacaca" }}
      >
        <div className="navigation navigation__actions">
          <a
            href="https://automatebnb.com/"
            target="_new"
            className="flex align-center"
            style={{ textDecoration: "none" }}
          >
            <img
              src={logoBrand}
              alt="automatebnb-logo"
              width={32}
              className="mr1"
            />
            <Typography
              variant="subtitle1"
              color="primary"
              style={{ fontWeight: 500 }}
            >
              automatebnb
            </Typography>
          </a>
          <Link href="/login">Login</Link>
        </div>
      </AppBar>
    );
  } else if (type === "onboarding") {
    return (
      <AppBar
        color="secondary"
        position="static"
        className={classnames("navigation-wrapper", {
          "navigation-wrapper--no-shadow": showSubNav,
          "navigation-wrapper--has-subnav": showSubNav,
          "navigation-wrapper--mobile": isMobile,
        })}
        style={{
          backgroundColor: "white",
          padding: "4px 0",
          borderBottom: "1px solid #b3b3b3",
          boxShadow: "none",
        }}
      >
        <div className={"navigation navigation__actions"}>
          <img
            src={logoBrand}
            alt="automatebnb-logo"
            width={40}
            className="mr1"
          />
          <Button
            variant="text"
            color="primary"
            style={{ fontWeight: 400 }}
            onClick={() => dispatch(logout())}
          >
            Sign out
          </Button>
        </div>
      </AppBar>
    );
  } else {
    return (
      <AppBar
        color="secondary"
        position="static"
        className={classnames("navigation-wrapper", {
          "navigation-wrapper--no-shadow": showSubNav,
          "navigation-wrapper--has-subnav": showSubNav,
          "navigation-wrapper--mobile": isMobile,
        })}
        style={{ backgroundColor: isOps && "#1D2939" }}
      >
        <div className={"navigation"}>
          <div className="navigation__actions">
            {isMobile ? (
              <MobileNavigationTabs newMessagesCount={totalNewMessageCount} />
            ) : (
              <>
                <NavLogo />
                <DesktopNavigationTabs
                  newMessagesCount={totalNewMessageCount}
                />
              </>
            )}
          </div>
          {isMobile ? <MobileNavPrimaryButton /> : <DesktopMenuDropdown />}
        </div>
        {showSubNav && <SubNav />}
      </AppBar>
    );
  }
};

export default connect(
  (state) => ({ subscribe: getSubscription(state).data }),
  (dispatch) => bindActionCreators({ openThisDialog, setDialogOpen }, dispatch)
)(OpsNavigation);
