import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { hideSnackbar } from "../actions/action_snackbar";
import { Snackbar } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import { JOBS } from "../routes";

class SnackbarComponenet extends Component {
  renderLinks() {
    return (
      <Link key="snackbar-link" to={JOBS} className="link snackbar-link">
        DASHBOARD
      </Link>
    );
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.props.hideSnackbar();
  };

  render() {
    const {
      isOpen,
      message,
      hideDuration,
      error,
      success,
    } = this.props.snackbar;

    const duration = hideDuration || 3000;

    return (
      <Snackbar
        open={isOpen}
        onClose={this.handleClose}
        autoHideDuration={duration}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={
          <span id="message-id" className="flex align-center">
            {error || success ? (
              <span className="flex align-center">
                {error ? (
                  <span key="warning" className="icon error mr">
                    <ErrorOutlineIcon />
                  </span>
                ) : (
                  <span key="success" className="icon success mr">
                    <CheckCircleOutlineIcon />
                  </span>
                )}
                <span key="message">{message}</span>
              </span>
            ) : (
              message
            )}
          </span>
        }
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    snackbar: state.snackbar,
  };
}

export default connect(mapStateToProps, { hideSnackbar })(SnackbarComponenet);
