import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import amplitude from "amplitude-js";

import Button from "@material-ui/core/Button";

import { filterParams, parseQuery } from "../helpers/url";
import history from "../helpers/history";

import { fetchPaymentReminders } from "../actions/payments";
import { getPaymentReminders } from "../reducers/payments";

import { fetchReservations } from "../actions/pmsReservations";
import { fetchCleanings } from "../actions/housekeeperCleaings";

import "./paymentReminder.scss";

function pluralize(count, word) {
  if (count === 1 || count === 0) {
    return word;
  } else if (count > 1) {
    return word + "s";
  }
}

const PaymentReminder = (props) => {
  const {
    fetchPaymentReminders,
    fetchCleanings,
    fetchReservations,
    reminders: { pendingPayment, role },
  } = props;

  let _location = null;

  useEffect(() => {
    fetchPaymentReminders();
  }, []);

  const { type, payment_statuses } = parseQuery();

  const handlePendingPaymentFilter = () => {
    if (role === "MAID") {
      amplitude
        .getInstance()
        .logEvent("Clicked.RequestPaymentInPaymentReminderBanner");
      _location = filterParams({
        paymentReminderHack: true,
        typeParam: "completed",
        requestPayment: true,
      });
    } else {
      amplitude
        .getInstance()
        .logEvent("Clicked.MakePaymentInPaymentReminderBanner");
      _location = filterParams({
        paymentReminderHack: true,
        typeParam: "completed",
        paymentStatuses: "requires_source,requires_payment_method",
      });
    }

    if (_location) {
      history.push({
        search: _location,
      });

      if (role === "MAID") {
        fetchCleanings();
      } else {
        fetchReservations();
      }
    }
  };

  let paymentReminderBody = "";
  let paymentReminderActionLabel = "";

  if (role === "MAID") {
    paymentReminderBody = `${pendingPayment} ${pluralize(
      pendingPayment,
      "cleaning"
    )} recently completed.`;
    paymentReminderActionLabel = "Request payment";
  } else {
    paymentReminderBody = `${pendingPayment} ${pluralize(
      pendingPayment,
      "payment"
    )} pending.`;
    paymentReminderActionLabel = "Make payment";
  }

  return pendingPayment > 0 ? (
    <div className="payment-reminder inline-flex align-center space-between">
      <p className="pr1">{paymentReminderBody}</p>
      <Button onClick={() => handlePendingPaymentFilter()}>
        {paymentReminderActionLabel}
      </Button>
    </div>
  ) : (
    <></>
  );
};

export default connect(
  (state) => ({ reminders: getPaymentReminders(state) }),
  (dispatch) =>
    bindActionCreators(
      {
        fetchPaymentReminders,
        fetchCleanings,
        fetchReservations,
        getPaymentReminders,
      },
      dispatch
    )
)(PaymentReminder);
