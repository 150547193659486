import React          from 'react';
import classNames     from 'classnames';

import './styles.scss';


const Card = (props) => (
  <div className={classNames(props.className, "c-card")}>
    {props.children}
  </div>
)

export default Card;
