import React from "react";

import { useMediaQuery } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import IconButton from "@material-ui/core/IconButton";
import JobDetails from "./JobDetails";

export default ({
  name = null,
  phone = null,
  isOps = false,
  isMarketplace = false,
  jobId = null,
  className = "",
}) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [copied, setCopied] = React.useState(false);
  const marketplaceEmail = jobId && `${jobId}@automatebnb.com`;
  const userType = isOps ? "Host" : "Name";
  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(marketplaceEmail)
      .then(() => {
        if (!copied) {
          setCopied(true);
          setTimeout(() => setCopied(false), 3000);
        }
      })
      .catch((err) => console.error("Error copying url. =("));
  };
  const RenderHostContactMethod = () => {
    if (isMarketplace) {
      if (jobId) {
        return [
          <JobDetails.Label key="host-details-phone-label">
            Email
          </JobDetails.Label>,
          <div style={{ paddingBottom: 10, position: "relative" }}>
            <div className="flex align-center">
              <JobDetails.Body key="host-details-phone-value">
                {marketplaceEmail}
              </JobDetails.Body>
              <IconButton onClick={() => handleCopyToClipboard()}>
                <FileCopyIcon />
              </IconButton>
            </div>
            {copied && (
              <span
                style={{
                  fontSize: 14,
                  color: "green",
                  position: "absolute",
                  bottom: -4,
                }}
              >
                Successfully copeid!
              </span>
            )}
          </div>,
        ];
      } else {
        return null;
      }
    } else {
      return [
        <JobDetails.Label key="host-details-phone-label">
          Phone
        </JobDetails.Label>,
        <JobDetails.Link href={`tel:${phone}`} key="host-details-phone-value">
          {phone}
        </JobDetails.Link>,
      ];
    }
  };

  return (
    <JobDetails className="" variant="stack">
      <JobDetails.Title>{`Job provider`}</JobDetails.Title>
      <JobDetails.Content>
        <JobDetails.Group key="name-job-datails">
          <JobDetails.Label>Name</JobDetails.Label>
          <JobDetails.Body>{name}</JobDetails.Body>
        </JobDetails.Group>
        <JobDetails.Group key="job-details-g-host-contact">
          <RenderHostContactMethod />
        </JobDetails.Group>
      </JobDetails.Content>
    </JobDetails>
  );
};
