import React from "react";
import amplitude from "amplitude-js";

import "./newFeatureRelease.scss";

const NewFeatureRelease = props => {
  const { hideBanner } = props;
  return hideBanner ? (
    ""
  ) : (
    <div className="new-features">
      COVID-19 Feature: Automatically delay cleaning by 1 day.{" "}
      <a
        href="https://automatebnb.notion.site/How-to-activate-delay-cleaning-by-24-hours-9efdf01ddceb445f83f02c18cea04322#561e6d4b0a4a4db2b774627d1ed5d989"
        target="_new"
        className="link"
        onClick={() =>
          amplitude
            .getInstance()
            .logEvent("clicked.featureBannerOutboundLinkCOVID")
        }
      >
        Learn more
      </a>
      .
    </div>
  );
};

export default NewFeatureRelease;
