import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";

import { fetchProperty } from "../../actions/pmsProperties";
import { bindActionCreators } from "redux";

import { getPMSProperty } from "../../reducers/pmsProperties";
import { updateProperty } from "../../actions/pmsProperties";

import PropertyDetailsFields from "../../containers/newPropertyDetailsFields";

const PropertyDetailsDialogBody = (props) => {
  const { id, property, updateProperty, closeDialog } = props;
  const [localValue, setLocalValue] = useState();

  useEffect(() => {
    if (id) {
      fetchProperty(id);
    }
  }, [id]);

  useEffect(() => {
    if (property) {
      setLocalValue({
        id: property.id,
        title: property.title,
        city: property.city,
        state: property.state,
        street: property.street,
        zip: property.zip,
        checkIn: property.check_in_time,
        checkOut: property.check_out_time,
        bedrooms: property.bedrooms,
        beds: property.beds,
        bathrooms: property.bathrooms,
        amenities: property.amenities,
        total_guests: property.total_guests,
        parking_info: property.parking_info,
        washer: property.washer,
        dryer: property.dryer,
        lock_code: property.lock_code,
        access_instructions: property.access_instructions,
        gate_code: property.gate_code,
        gate_instructions: property.gate_instructions,
        maid_closet_code: property.maid_closet_code,
        maid_closet_info: property.maid_closet_info,
        trash_instructions: property.trash_instructions,
        wifi_instructions: property.wifi_instructions,
        cleaning_guide_url: property.cleaning_guide_url,
        square_feet: property.square_feet,
      });
    }
  }, [property]);

  const updateValue = (value) => {
    setLocalValue({
      id: property.id,
      title: value.title,
      city: value.city,
      state: value.state,
      street: value.street,
      zip: value.zip,
      checkIn: value.checkIn,
      checkOut: value.checkOut,
      bedrooms: value.bedrooms,
      beds: value.beds,
      bathrooms: value.bathrooms,
      amenities: value.amenities,
      total_guests: value.total_guests,
      parking_info: value.parking_info,
      washer: value.washer,
      dryer: value.dryer,
      lock_code: value.lock_code,
      access_instructions: value.access_instructions,
      gate_code: value.gate_code,
      gate_instructions: value.gate_instructions,
      maid_closet_code: value.maid_closet_code,
      maid_closet_info: value.maid_closet_info,
      trash_instructions: value.trash_instructions,
      wifi_instructions: value.wifi_instructions,
      cleaning_guide_url: value.cleaning_guide_url,
      square_feet: value.square_feet,
    });
  };

  const handleClick = (type) => {
    if (type === "cancel") {
      closeDialog();
    } else if (type === "update") {
      if (
        localValue.id &&
        !isEmpty(localValue.title) &&
        !isEmpty(localValue.street) &&
        localValue.beds > 0 &&
        localValue.bathrooms > 0 &&
        !isEmpty(localValue.city) &&
        !isEmpty(localValue.state) &&
        !isEmpty(localValue.zip) &&
        !isEmpty(localValue.checkIn) &&
        !isEmpty(localValue.checkOut)
      ) {
        updateProperty({
          id: property.id,
          title: localValue.title,
          city: localValue.city,
          state: localValue.state,
          street: localValue.street,
          zip: localValue.zip,
          check_in_time: localValue.checkIn,
          check_out_time: localValue.checkOut,
          amenities: localValue.amenities,
          bedrooms: localValue.bedrooms,
          beds: localValue.beds,
          bathrooms: localValue.bathrooms,
          total_guests: localValue.total_guests,
          parking_info: localValue.parking_info,
          washer: localValue.washer,
          dryer: localValue.dryer,
          lock_code: localValue.lock_code,
          access_instructions: localValue.access_instructions,
          gate_code: localValue.gate_code,
          gate_instructions: localValue.gate_instructions,
          maid_closet_code: localValue.maid_closet_code,
          maid_closet_info: localValue.maid_closet_info,
          trash_instructions: localValue.trash_instructions,
          wifi_instructions: localValue.wifi_instructions,
          cleaning_guide_url: localValue.cleaning_guide_url,
          square_feet: localValue.square_feet,
        });
      }
    }
  };

  return (
    <>
      <DialogTitle
        id="scroll-dialog-title"
        className="assign-housekeeper__title"
      >
        Update property
      </DialogTitle>
      <DialogContent dividers={true}>
        <PropertyDetailsFields
          handleValue={updateValue}
          propertyValues={property}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleClick("cancel")}
          variant="contained"
          disableElevation
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleClick("update")}
          variant="contained"
          color="primary"
          disableElevation
        >
          Update
        </Button>
      </DialogActions>
    </>
  );
};

export default connect(
  (state) => ({ property: getPMSProperty(state) }),
  (dispatch) => bindActionCreators({ fetchProperty, updateProperty }, dispatch)
)(PropertyDetailsDialogBody);
