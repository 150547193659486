import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import classnames from "classnames";

import { isEmpty } from "lodash";

import TextField from "@material-ui/core/TextField";
import { CircularProgress } from "@material-ui/core";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

import normalizePhone from "../../helpers/helper_normalize-phone";

import { invitePro, inviteAndAssignPro } from "../../actions/pros";

const InviteProDialogBody = (props) => {
  const {
    invitePro,
    closeThisDialog,
    isNewPropertyFlow = false,
    inviteAndAssignPro,
    propertyId = null,
  } = props;

  const [values, setValues] = useState({
    first: "",
    last: "",
    phone: "",
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isValidated, setFormValidation] = useState(false);

  const submitForm = () => {
    if (isNewPropertyFlow && propertyId) {
      inviteAndAssignPro({ ...values, propertyId });
    } else {
      invitePro({ ...values });
    }
  };

  function closeDialog() {
    closeThisDialog();
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newVal = "";

    if (name === "phone") {
      newVal = { ...values, [name]: normalizePhone(value) };
    } else {
      newVal = { ...values, [name]: value };
    }

    setValues(newVal);

    if (values.first && values.last && values.phone) {
      setFormValidation(true);
    } else {
      setFormValidation(false);
    }
  };

  return (
    <>
      <DialogTitle id="scroll-dialog-title" className="pms-dialog__title">
        {isNewPropertyFlow ? "Invite & Assign pro" : "Invite pro"}
        <br />
        <Typography variant="body2">
          We'll send a text message to your Pro, but please keep in mind that
          any SMS charges may apply to your Pro.
        </Typography>
      </DialogTitle>
      <DialogContent dividers={true} className="invitePros__content">
        <div>
          <div>
            <TextField
              required
              color="primary"
              name="first"
              autoFocus={true}
              label="First name"
              fullWidth={true}
              onChange={handleInputChange}
              variant="outlined"
              value={values.first}
            />
          </div>
          <div className="mt2">
            <TextField
              required={true}
              name="last"
              color="primary"
              fullWidth={true}
              label="Last name"
              onChange={handleInputChange}
              variant="outlined"
              value={values.last}
            />
          </div>
          <div className="mt2">
            <TextField
              color="primary"
              required={true}
              fullWidth={true}
              name="phone"
              label="Phone number"
              type="tel"
              onChange={handleInputChange}
              variant="outlined"
              value={values.phone}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="inline-flex space-between full-width justify-end">
          <div className="pms-dialog__actions">
            <Button
              onClick={() => closeDialog()}
              variant="text"
              disableElevation
              size="large"
              style={{ marginRight: "8px" }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => submitForm()}
              variant="contained"
              size="large"
              disableElevation
              color="primary"
              disabled={!isValidated}
            >
              {isNewPropertyFlow ? "Invite & Assign pro" : "Invite pro"}
            </Button>
          </div>
        </div>
      </DialogActions>
    </>
  );
};

export default connect(
  (state) => ({}),
  (dispatch) => bindActionCreators({ invitePro, inviteAndAssignPro }, dispatch)
)(InviteProDialogBody);
