import React from "react";
import PhotoIcon from "@material-ui/icons/Photo";
import { s3UrlMapper } from "../../helpers/s3URLMapper";
import { useMediaQuery } from "@material-ui/core";

const PhotoCard = ({
  image_url,
  name,
  width = "178px",
  height = "118px",
  elevation = 2,
}) => {
  return (
    <div
      style={{
        width: width,
        height: height,
        backgroundColor: "#D9D9D9",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        borderRadius: "8px",
        borderWidth: "3px",
        borderStyle: "solid",
        borderColor: "white",
        boxShadow:
          elevation === 2
            ? "rgb(0 0 0 / 25%) 0 4px 5px"
            : "rgba(0, 0, 0, 0.25) 0px 0 1px 1px",
      }}
    >
      {image_url ? (
        <img
          src={s3UrlMapper({ url: image_url, size: "md" })}
          alt={name}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      ) : (
        <PhotoIcon style={{ color: "grey", fontSize: "32px" }} />
      )}
    </div>
  );
};

export default PhotoCard;
