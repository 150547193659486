import React from "react";

import { useMediaQuery } from "@material-ui/core";

import {
  DATE_FORMAT,
  DATE_FORMAT_FULL,
  DATE_FORMAT_STANDARD,
} from "../constants/apiEnum";

import RenderDateTime from "./renderDateTime";

export default ({ jobDate = null, jobTime = null, title = "Job schedule" }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <div
      className={isMobile ? "job-details job-details--mobile" : "job-details"}
    >
      <h3 className="job-details__title">{title}</h3>
      <div className="job-details__wrapper">
        <div className="job-details__group">
          <p className="job-details__label">Date</p>
          <div className="job-details__body job-details__body--bold">
            <RenderDateTime date={jobDate} format={DATE_FORMAT_STANDARD} />
          </div>
        </div>
        <div className="job-details__group">
          <p className="job-details__label">Time</p>
          <div className="job-details__body job-details__body--bold">
            <RenderDateTime time={jobTime} />
          </div>
        </div>
      </div>
    </div>
  );
};
