import React, { useEffect, useState, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { bindActionCreators } from "redux";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { CircularProgress, Typography } from "@material-ui/core";

// Actions
import { fetchUser, updatePro } from "../actions/user";

import { updateHost } from "../actions/pmsUser";

// Reducer
import { getUser, getUserToken } from "../reducers/user";

import { stripPhoneNumberFormatting } from "../helpers/format";
import { ABNB_PROFILE_BASE_URL } from "../constants/apiEnum";
import { updateProfileTallyURLConstructor } from "../helpers/url";

import { PhoneNumberField } from "../containers/accountFields";

const UserProfileDisplay = (props) => {
  const { userToken, user, fetchUser, updatePro, updateHost } = props;

  const { username = null } = user;

  const [values, setValues] = useState({
    id: "",
    first: "",
    last: "",
    phone: "",
  });

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    if (!isEmpty(user)) {
      setValues({
        id: user.id || "",
        first: user.first || "",
        last: user.last || "",
        phone: user.phone || "",
      });
    }
  }, [user]);

  const isValid = useCallback(() => {
    return (
      !isEmpty(values.first) && !isEmpty(values.last) && !isEmpty(values.phone)
    );
  }, [values]);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    console.log(`Input Change - ${name}: ${value}`);
    setValues((prevValues) => ({
      ...prevValues,
      [name]: name === "phone" ? stripPhoneNumberFormatting(value) : value,
    }));
  }, []);

  const handleSubmit = useCallback(() => {
    if (isValid()) {
      if (userToken.is_host) {
        updateHost(values);
      } else if (userToken.is_maid || userToken.is_ops) {
        updatePro(values);
      }
    }
  }, [isValid, userToken, values, updateHost, updatePro]);

  const CopyToClipboard = ({ textToCopy }) => {
    const [copyLabel, setCopyLabel] = useState("Copy");

    const handleCopyClick = () => {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          setCopyLabel("Copied!");
          setTimeout(() => setCopyLabel("Copy"), 2000);
        })
        .catch((err) => console.error("Error copying url. =("));
    };

    return (
      <Button
        onClick={handleCopyClick}
        variant="contained"
        color="default"
        disableElevation
      >
        {copyLabel}
      </Button>
    );
  };

  const handleActiveProfile = () => {
    const profileDetailsUrl = updateProfileTallyURLConstructor({
      ...user,
      tallyId: "3l6K25",
    });
    window.open(profileDetailsUrl, "_blank");
  };

  const RenderMarketplaceProProfile = useMemo(() => {
    const profileUrl = ABNB_PROFILE_BASE_URL + username;
    const profileDetailsUrl = updateProfileTallyURLConstructor({
      ...user,
      tallyId: "wMRErY",
    });
    const pricingConfigUrl = updateProfileTallyURLConstructor({
      ...user,
      tallyId: "n0O7W9",
    });

    return (
      <div className="manage-account pl1 pr1">
        {username && (
          <div>
            <Typography variant="h6">My page link</Typography>
            <div className="flex flex-direction-mobile">
              <TextField variant="outlined" value={profileUrl} fullWidth />
              <div className="flex ml1 mt1-mobile stretch-children-mobile">
                <CopyToClipboard textToCopy={profileUrl} />
                <Button
                  disableElevation
                  onClick={() => window.open(profileUrl, "_blank")}
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: 8 }}
                  endIcon={<OpenInNewIcon />}
                >
                  Visit profile
                </Button>
              </div>
            </div>
          </div>
        )}
        <div className="mt4">
          <Typography variant="h6">Update profile</Typography>
          <Typography variant="body2">
            Ensure your profile details and job preferences are accurate and
            current, as they are showcased to vacation rental hosts. Note that
            editing your profile will redirect you outside of this app.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => window.open(profileDetailsUrl, "_blank")}
            style={{ marginTop: 12 }}
            endIcon={<OpenInNewIcon />}
          >
            Edit profile details
          </Button>
        </div>

        <div className="mt4">
          <Typography variant="h6">Job pricing</Typography>
          <Typography variant="body2">
            Keep your job pricing up-to-date for visibility with vacation rental
            hosts. Updating pricing information will take you outside the
            current app.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => window.open(pricingConfigUrl, "_blank")}
            disableElevation
            style={{ marginTop: 12 }}
            endIcon={<OpenInNewIcon />}
          >
            Adjust job pricing
          </Button>
        </div>
      </div>
    );
  }, [username, user]);

  const RenderCustomerProfileForm = useCallback(
    ({ values, handleInputChange, handleSubmit }) => {
      const handleChange = (event) => {
        const { name, value } = event.target;
        console.log(`Form Change - ${name}: ${value}`);
        handleInputChange(event);
      };

      return (
        <div className="pms-signin pt2">
          <div className="form">
            <div className="mb3">
              <TextField
                required
                color="primary"
                name="first"
                label="First name"
                fullWidth={true}
                onChange={handleChange}
                variant="outlined"
                value={values.first}
              />
            </div>
            <div className="mb3">
              <TextField
                required
                name="last"
                color="primary"
                label="Last name"
                fullWidth={true}
                onChange={handleChange}
                variant="outlined"
                value={values.last}
              />
            </div>
            <div className="mb3">
              <PhoneNumberField
                required
                value={values.phone}
                handleChange={handleChange}
              />
            </div>
            <div className="mt2">
              <Button
                color="primary"
                size="large"
                onClick={handleSubmit}
                fullWidth={true}
                disableElevation
                variant="contained"
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      );
    },
    []
  );

  if (isEmpty(user)) {
    return (
      <div className="flex items-center justify-center">
        <CircularProgress />
      </div>
    );
  } else {
    if (userToken.visible_in_marketplace) {
      return RenderMarketplaceProProfile;
    } else {
      return (
        <RenderCustomerProfileForm
          values={values}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
        />
      );
    }
  }
};

export default connect(
  (state) => ({ userToken: getUserToken(state), user: getUser(state) }),
  (dispatch) =>
    bindActionCreators({ fetchUser, updatePro, updateHost }, dispatch)
)(UserProfileDisplay);
