import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { isEmpty } from "lodash";

import Navigation from "../containers/navigation";
import LoadingScreen from "../components/loadingScreen";
import LoadingSkeleton from "../components/skeleton";

import { parseQuery } from "../helpers/url";

// Model
import { JobModel, PaymentModel, UserTokenModel } from "../models";

// Local components
import JobHeader from "./jobHeader.tsx";
import JobPayout from "./jobPayout.tsx";
import JobLocation from "./jobLocation.tsx";
import JobNote from "./jobNote.tsx";
import JobSpecialRequirements from "./jobSpecialRequirements.tsx";
import JobAssignedPros from "./jobAssignedPros.tsx";
import JobPropertyDetails from "./jobPropertyDetails.tsx";
import JobProvider from "./jobProvider.tsx";
import JobTrashInstructions from "./jobTrashInstructions.tsx";
import JobWifiInstructions from "./jobWifiInstructions.tsx";
import JobMainInstructions from "./jobMainInsturctions.tsx";
import JobGateInstructions from "./jobGateInstructions.tsx";
import JobClosetInstructions from "./jobClosetInstructions.tsx";
import JobChecklist from "./jobChecklist.tsx";
import JobStagingGuide from "./jobStagingGuide.tsx";
import JobInspection from "./jobInspection.tsx";
import JobWalkthrough from "./jobWalkthrough.tsx";
import JobActions from "./jobActions.tsx";

// reducer
import { getUserToken } from "../reducers/user";
import { getJobDetails } from "../reducers/jobs";

// Route
import { JOBS } from "../routes";

// Custom Styles
import "./job.scss";
import {
  fetchHostJobs,
  fetchJobDetails,
  fetchProsJobs,
  fetchWalkthroughTasks,
  updateJobStatus,
} from "../actions/jobs";

import { Button, Chip } from "@material-ui/core";
import {
  CLEANER_STATUS_ACCEPTED,
  CLEANER_STATUS_COMPLETED,
  CLEANER_STATUS_DECLINED,
  CLEANING_STARTED,
  MARKETPLACE,
  PAYMENT_STATUS_SUCCESS,
  WAITING_TO_ACCEPT,
} from "../constants/apiEnum";
import { getNormalizedStatus, getStatusColor } from "../helpers/jobStatuses";
import history from "../helpers/history.js";
import { Analytics } from "../services/analyticsService.tsx";
import { openThisDialog } from "../actions/dialog.js";
import {
  DIALOG_TYPE_MAKE_CLEANING_PAYMENT,
  DT_CANCEL_JOB,
} from "../constants/dialogTypes.js";

interface SubNavProps {
  status: string;
  onBackClick: () => void;
  payment: PaymentModel;
}

function extractJobId(url) {
  // Use a regular expression to match the job ID in the URL before any query parameters
  const regex = /\/job\/([^\/?]+)/;
  const match = url.match(regex);

  // If a match is found, return the job ID
  if (match && match[1]) {
    return match[1];
  } else {
    return null; // Return null if no match is found
  }
}

const SubNav: React.FC<SubNavProps> = ({ status, onBackClick, payment }) => {
  const isStarted = status === CLEANING_STARTED;
  const containerClass = isStarted
    ? "bg-slate-900 text-white border-b border-solid border-slate-600"
    : "bg-white text-black border-b  border-solid border-gray-300";

  const localStatus = isEmpty(payment) ? status : payment.status;

  return (
    <div className={`py-2 flex justify-center px-2 ${containerClass}`}>
      <div
        className="max-w-screen-lg w-full flex justify-between items-center max-w-screen-lg "
        style={{ maxWidth: "1278px", width: "calc(100% - 48px)" }}
      >
        <button
          onClick={onBackClick}
          className={`hover:text-blue-700 ${
            isStarted ? "text-white" : "text-black-500"
          }`}
        >
          <ArrowBackIcon
            className={isStarted ? "text-white" : "text-black-500"}
          />
        </button>
        <div className="text-center flex-1">
          <h4 className="text-l">Job Details</h4>
          <p className={`text-sm font-semibold ${getStatusColor(localStatus)}`}>
            {getNormalizedStatus(localStatus)}
          </p>
        </div>
        <div className="w-12"></div>
      </div>
    </div>
  );
};

const Job: React.FC = () => {
  const dispatch = useDispatch();
  const user: UserTokenModel = useSelector((state) => state.userToken);
  const job: JobModel = useSelector((state) => state.job);

  const jobId = useMemo(() => extractJobId(window.location.href), [
    window.location.href,
  ]);

  const [isDownloadVisible, setIsDownloadVisible] = useState(true);

  const {
    accepted_at,
    additional_fee,
    addon_fees,
    address,
    assignments,
    assignor_name,
    assignor_phone,
    channel,
    cleaning_fee_note,
    completed_at,
    declined_at,
    description,
    guest_rating,
    guest_review,
    has_staging_guide,
    has_walkthrough_report,
    host_name,
    host_phone,
    id,
    isLoading,
    is_assignment,
    is_completed,
    is_flip,
    is_past,
    is_payment_request_available,
    is_walkthrough_enabled,
    job_date,
    job_fee,
    job_time,
    marketplace_fee,
    payment,
    pro,
    property,
    reservation,
    special_request,
    started_at,
    status,
    time_to_accept_or_decline_in_words,
    time_to_complete_in_words,
    total_fee,
    total_payout_minus_fees,
    tracking_status,
  } = job;

  const { is_host = false, is_ops = false, is_maid = false } = user;

  const wifiInstructions = property?.wifi_instructions || {
    network: null,
    password: null,
  };

  const mainEnteranceInsturcionts = {
    instructions: property?.access_instructions,
    code: property?.lock_code,
  };
  const gateInstructions = {
    instructions: property?.gate_instructions,
    code: property?.gate_code,
  };
  const closetInstructions = {
    instructions: property?.maid_closet_info,
    code: property?.maid_closet_code,
  };

  const isDeclined = status === CLEANER_STATUS_DECLINED;
  const isStarted = status === CLEANING_STARTED;
  const isCompleted = status === CLEANER_STATUS_COMPLETED;
  const isAccepted = status === CLEANER_STATUS_ACCEPTED;
  const isWaiting = status === WAITING_TO_ACCEPT;

  // Marketplace jobs are those requested from Cleaning bussinesses
  // public profile (no longer supporting this)
  const isMarketplace = channel === MARKETPLACE;

  const nonEditableStatuses = [
    CLEANING_STARTED,
    CLEANER_STATUS_DECLINED,
    CLEANER_STATUS_COMPLETED,
  ];

  const canEdit = !is_maid && !nonEditableStatuses.includes(status);

  // Workflow should only be visible if the job is Accepted, Started, or Completed
  const isWorflowVisible = isAccepted || isStarted || isCompleted;

  // Disabled if job is in past

  // Ops can assign multiple pros, but hosts can only assign one pro.
  // This will be available for both Ops and Hosts with OM migration
  const canAssignMultiplePros = canEdit && (is_ops ? true : false);

  // Hosts can edit note
  const canEditJobNote = canEdit && !is_ops;

  const {
    make_payment: isMakePaymentInUrl = null,
    payment_id: isPaymentIdInUrl = null,
  } = parseQuery();

  useEffect(() => {
    if (jobId) {
      dispatch(fetchJobDetails(jobId));
      dispatch(fetchWalkthroughTasks(jobId));
    }
  }, [jobId]);

  useEffect(() => {
    // Open dialog to allow host to make payment iff host is accessing make payment link directly
    if (isMakePaymentInUrl && isPaymentIdInUrl) {
      if (!isEmpty(job) && !isEmpty(payment)) {
        if (payment.status !== PAYMENT_STATUS_SUCCESS) {
          dispatch(
            openThisDialog({
              dialogType: DIALOG_TYPE_MAKE_CLEANING_PAYMENT,
              job,
            })
          );
        }
      }
    }
  }, [job]);

  const JobDetails = () => {
    const containerClass = isStarted
      ? "bg-slate-900 text-white"
      : "bg-white text-black";

    const handleBackPress = () => {
      history.goBack();
    };

    const AppDownloadAdoption: React.FC = () => {
      function AppStoreBadges() {
        return (
          <div className="flex items-center space-x-4 mx-auto mt-2">
            <a
              href="https://apps.apple.com/us/app/automatebnb/id1627952707"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center"
            >
              <img
                src="https://www.automatebnb.com/_next/image?url=https%3A%2F%2Fautomatebnb-squarespace.s3.us-west-1.amazonaws.com%2Fapple_badge.png&w=256&q=75"
                alt="Download on the App Store"
                className="h-8"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.automatebnb.app&pli=1&utm_source=jobdetails"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://www.automatebnb.com/_next/image?url=https%3A%2F%2Fautomatebnb-squarespace.s3.us-west-1.amazonaws.com%2Fgoogle-play-badge.png&w=256&q=75"
                alt="Get it on Google Play"
                className="h-11"
              />
            </a>
          </div>
        );
      }

      let downloadAPPString = (
        <p className="text-md text-center">
          Download the Automatebnb app to get push notifications.
        </p>
      );

      if (!is_host) {
        if (isStarted) {
          downloadAPPString = (
            <p className="text-md text-center">
              Download the Automatebnb app{" "}
              <span className="font-semibold">to mark the job complete.</span>
            </p>
          );
        }
        if (isWaiting || isAccepted) {
          downloadAPPString = (
            <p className="text-md text-center">
              Download the Automatebnb app{" "}
              <span className="font-semibold">to Start and Complete Job.</span>
            </p>
          );
        }
      }

      return (
        <div
          className={`duration-300 bg-white ${
            isDownloadVisible ? "opacity-100" : "opacity-0"
          }`}
          id="download-section"
          style={{
            position: "relative",
            bottom: is_host ? 0 : 20,
            width: "100%",
          }}
        >
          <div className="max-w-3xl mx-auto flex justify-center flex-col py-4 border-t border-solid border-gray-200">
            {downloadAPPString}
            <AppStoreBadges />
          </div>
        </div>
      );
    };

    const JonCancelAction = () => {
      const canCancelJob = [CLEANING_STARTED, CLEANER_STATUS_COMPLETED];
      if (!canCancelJob.includes(status)) {
        return (
          <>
            <div className="flex justify-center">
              <Button
                variant="text"
                style={{ color: "red" }}
                onClick={() => {
                  Analytics.trackEvent("job_cancel_action_from_job_details", {
                    job_id: id,
                  });
                  dispatch(
                    openThisDialog({
                      dialogType: DT_CANCEL_JOB,
                      jobId,
                      view: "job",
                    })
                  );
                }}
              >
                Cancel the job
              </Button>
            </div>
          </>
        );
      }

      return null;
    };

    const JobMetadata: React.FC = () => {
      const chips: JSX.Element[] = [];

      if (time_to_complete_in_words) {
        chips.push(
          <Chip
            key="time"
            label={`⏳ ${time_to_complete_in_words} to completion`}
          />
        );
      }

      if (isMarketplace && !is_assignment) {
        chips.push(<Chip key="marketplace" label="💰 Marketplace" />);
      }

      return !isEmpty(chips) ? (
        <>
          <div className="flex flex-wrap gap-2">{chips}</div>
          <Separator />
        </>
      ) : (
        // If Metadata chips are not available then add a separator line between header and location
        <Separator />
      );
    };

    const JobDeclineAction = () => {
      const handleDecline = () => {
        dispatch(updateJobStatus(id, CLEANER_STATUS_DECLINED))
          .then((response) => {
            console.log({ id, status });
            if (response.status === 200) {
              const lowerCaseStatus = status.toLowerCase();
              Analytics.trackEvent(
                `job_${lowerCaseStatus}_action_from_job_details`,
                { job_id: id }
              );

              dispatch(fetchProsJobs());

              dispatch(fetchJobDetails(id));
            }
          })
          .catch((err) => {});
      };
      return (
        <div className="flex justify-center full-width mt-2 mb-4">
          <Button style={{ color: "red" }} onClick={handleDecline}>
            Decline this job
          </Button>
        </div>
      );
    };

    function Separator() {
      return <hr className="opacity-40" />;
    }

    return (
      <div className="bg-slate-100 mb-10">
        <div className={`max-w-7xl mx-auto bg-slate-100 mb-20`}>
          <SubNav
            status={status}
            onBackClick={handleBackPress}
            payment={payment}
          />
          <div className={`p-4 space-y-6 ${containerClass}`}>
            <JobHeader
              {...{
                id,
                job_date,
                status,
                is_assignment,
                is_maid,
                is_flip,
                is_past,
                job_time,
                duration: reservation.duration,
                canEdit,
                isMarketplace,
                is_host,
              }}
            />
            <JobMetadata />
            {/* <Separator /> is added in JobMetadata logic */}
            {payment && (
              <>
                <JobPayout
                  {...{
                    total_fee,
                    additional_fee,
                    job_fee,
                    cleaning_fee_note,
                    is_payment_request_available,
                    marketplace_fee,
                    addon_fees,
                    isStarted,
                    payment,
                    total_payout_minus_fees,
                    is_host,
                  }}
                />
                <Separator />
              </>
            )}
            <JobLocation {...{ address, property, is_host }} />
            <Separator />
            <JobNote {...{ id, description, canEditJobNote, is_past }} />
            <Separator />
            <JobSpecialRequirements {...{ property }} />
            {/* MAID cannot assign pros */}
            {!is_maid && (
              <>
                <JobAssignedPros
                  {...{
                    id,
                    is_host,
                    is_past,
                    isMarketplace,
                    isAccepted,
                    isDeclined,
                    isCompleted,
                    isStarted,
                    is_assignment,
                    assignments,
                    pro,
                    status,
                    canAssignMultiplePros,
                  }}
                />
                <Separator />
              </>
            )}
            <JobPropertyDetails {...{ property }} />
            <Separator />
            {!is_host && (
              <>
                <JobProvider {...{ assignor_name, assignor_phone }} />
                <Separator />
              </>
            )}
            <JobTrashInstructions {...{ property }} />
            <Separator />
            <JobWifiInstructions {...wifiInstructions} />
            <JobGateInstructions {...gateInstructions} />
            <JobMainInstructions {...mainEnteranceInsturcionts} />
            <JobClosetInstructions {...closetInstructions} />
            {/* <JobReservationDetails /> */}
          </div>
          <JobChecklist cleaning_guide_url={property?.cleaning_guide_url} />
          <JobStagingGuide
            {...{
              has_staging_guide,
              is_host,
              property_id: property?.id,
              is_past,
            }}
          />
          {isWorflowVisible && (
            <>
              <JobInspection
                {...{ is_host, status, guest_rating, job_id: id, is_past }}
              />
              <JobWalkthrough
                {...{
                  is_host,
                  status,
                  job_id: id,
                  is_past,
                  isStarted,
                  isAccepted,
                  isWaiting,
                  isDeclined,
                }}
              />
            </>
          )}
          {is_host && <JonCancelAction />}
          {!is_host && isAccepted && <JobDeclineAction />}
        </div>

        <div className="py-3 flex relative justify-center bg-white border-t border-solid border-gray-300">
          <AppDownloadAdoption />
          <JobActions
            {...{
              id,
              job,
              status,
              job_date,
              is_payment_request_available,
              is_past,
              payment,
              is_ops,
              is_host,
              is_assignment,
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <Navigation />
      {isLoading ? "...loading.." : <JobDetails />}
    </>
  );
};

export default Job;
