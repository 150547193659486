import {
  RESET_STAGING_SPACES,
  SET_STAGING_SPACES,
  RESET_STAGING_SPACE,
  SET_STAGING_SPACE,
  RESET_SPACE_TASKS,
  SET_SPACE_TASKS,
} from "../actions/staging";

export const getSpaces = ({ spaces }) => spaces;
export const getSpace = ({ space }) => space;
export const getTasks = ({ tasks }) => tasks;

export const spaces = (state = {}, action) => {
  const { data } = action;
  switch (action.type) {
    case RESET_STAGING_SPACES:
      return { isLoading: true, data: {} };
    case SET_STAGING_SPACES:
      return { ...state, isLoading: false, data };
    default:
      return state;
  }
};

export const space = (state = { isLoading: true }, action) => {
  const { data } = action;
  switch (action.type) {
    case RESET_STAGING_SPACE:
      return { isLoading: true };
    case SET_STAGING_SPACE:
      return { ...state, isLoading: false, data };
    default:
      return state;
  }
};

export const tasks = (state = {}, action) => {
  const { data } = action;
  switch (action.type) {
    case RESET_SPACE_TASKS:
      return { isLoading: true };
    case SET_SPACE_TASKS:
      return { isLoading: false, ...state, ...data };
    default:
      return state;
  }
};
