import React, { useEffect, useState } from "react";
import classnames from "classnames";
import amplitude from "amplitude-js";
import { isEmpty } from "lodash";
import Moment from "react-moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Material UI
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { Chip, useMediaQuery } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import StorefrontIcon from "@material-ui/icons/Storefront";
import LocationOnIcon from "@material-ui/icons/LocationOn";

// Components
import SameDayFlip from "../components/sameDayFlip";
import Pagination from "../containers/pagination";
import Filter from "../containers/filter";
import { setDialogOpen, openThisDialog } from "../actions/dialog";
import ItemsCount from "../components/itemsCount";
import NormalizedCleaningStatus from "../components/normalizedCleaningStatus";
import LoadingScreen from "../components/loadingScreen";

// Helpers
import { parseQuery, filterParams } from "../helpers/url";
import history from "../helpers/history";
import { isAnyFilterActive } from "../helpers/checkFilters";
import { getFormattedCheckInOutTime } from "../helpers/format";

// Constants
import {
  JOB_TYPE_PAST,
  JOB_TYPE_TODO,
  CLEANING_CANCELLED,
  CLEANING_STARTED,
  PAYMENT_STATUS_PENDING,
  SUBSCRIBE_STATUS_TRIAL_EXPIRED,
  PAYMENT_STATUS_FAILED,
  DATE_FORMAT,
} from "../constants/apiEnum";
import {
  DT_JOB_DESCRIPTION,
  // NEW
  DT_REQUEST_A_JOB,
  DT_SUBSCRIBE_PROMPT,
  DT_CHANGE_A_PRO,
  DT_CANCEL_JOB,
  DT_INVITE_TO_PAY,
  DT_UPDATE_JOB_DATE_TIME,
  DT_DISCREPANCIES_JOBS_RESERVATIONS,
} from "../constants/dialogTypes";

// Actions
import { fetchHostJobs } from "../actions/jobs";

// Reducers
import { getJobs } from "../reducers/jobs";
import { getSubscription } from "../reducers/reducer_subscribe";
import LoopIcon from "@material-ui/icons/Loop";
import Tooltip from "@material-ui/core/Tooltip";

import "../styles/startedJob.scss";
import { Analytics } from "../services/analyticsService.tsx";

// A11Y MUI -- Check Material-ui documents
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const HostJobs = (props) => {
  const {
    jobs: { items = {}, page = {}, isLoading = true },
    match,
    address,
    subscribe,
    fetchHostJobs,
    setDialogOpen,
    openThisDialog,
  } = props;
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:840px)");

  const currentRoute = history.location;

  // Grab value of 'type' from URL
  const { type } = parseQuery();

  // create a new URL based on Tab selection
  let newSearch = null;

  // Reset the query params to remove any filters when swtiching between ToDo and Completed
  const emptyParams = {
    propertyIdsParam: [],
    paymentStatuses: [],
    maidIdsParam: [],
    channelsParam: [],
    cleaningStatus: [],
    noteParam: "false",
    requestPayment: "false",
  };

  // TAB Values
  const JOBS_TODO = 0;
  const JOBS_COMPLETED = 1;

  // Set tab state
  const [tabSelected, setTab] = useState(JOBS_TODO);

  // Replace this with a flag from API response
  const isPast = window.location.search.includes("past");

  // Fetch APIs
  useEffect(() => {
    fetchHostJobs();
    // Set tab based on URL
    if (type === JOB_TYPE_TODO) {
      setTab(JOBS_TODO);
    } else if (type === JOB_TYPE_PAST) {
      setTab(JOBS_COMPLETED);
    }
  }, []);

  function handleTabSelection(event, newValue) {
    if (newValue === JOBS_TODO) {
      newSearch = filterParams({
        typeParam: JOB_TYPE_TODO,
        ...emptyParams,
      });
    } else if (newValue === JOBS_COMPLETED) {
      newSearch = filterParams({
        typeParam: JOB_TYPE_PAST,
        ...emptyParams,
      });
    }

    // FILTERS ARE PUSHED TOOO
    history.push({
      search: newSearch,
    });
    setTab(newValue);

    // Fetch jobs based on new URL
    fetchHostJobs();
  }

  function handleJobClick(event, id) {
    history.push(`/job/${id}`);
  }

  // Format job date
  function FormatJobDate({ date }) {
    return <Moment format={DATE_FORMAT}>{date}</Moment>;
  }

  function FormatJobStatus({ props }) {
    const { is_completed, payment, status } = props;
    if (!isEmpty(payment)) {
      return (
        <NormalizedCleaningStatus
          status={payment.status}
          human={payment.status_human}
        />
      );
    } else {
      return <NormalizedCleaningStatus status={status} />;
    }
  }

  function TableCellLinked(props) {
    return (
      <TableCell
        onClick={(event) => handleJobClick(event, props.id)}
        {...props}
      />
    );
  }

  const requestJob = (location) => {
    Analytics.trackSimple("new_job_request_action_from_jobs");
    if (subscribe && subscribe.deactivated) {
      openThisDialog({
        dialogType: DT_SUBSCRIBE_PROMPT,
        dialogBody: "Your subscription has ended. Please subscribe first.",
      });
    } else {
      openThisDialog({
        dialogType: DT_REQUEST_A_JOB,
      });
    }
  };

  const CalendarLayout = () => {
    return (
      <>
        <Table aria-label="simple table" className="table">
          <TableBody className="--bk-white">
            {items.map((item) => {
              const {
                id: jobId,
                channel,
                job_date,
                job_time = "N/A",
                description,
                address,
                pro = {},
                is_payment_request_available: isPaymentRequestAvailable = false,
                status,
                property: { title },
                is_flip: isFlip = false,
                payment,
                is_completed,
                is_past,
              } = item;

              let prosInfo = "";
              const proId = pro ? pro.id : null;

              // Multiple pros assigned to a cleaning
              if (!isEmpty(pro)) {
                prosInfo = (
                  <div>
                    <div className="pros-assigned__name nowrap">{`${pro.first} ${pro.last}`}</div>
                    <div className="label">
                      {pro.category
                        ? pro.category
                        : !pro.email && (
                            <span className="italic grey">Invited</span>
                          )}
                    </div>
                  </div>
                );
              }

              const isCancelled = status === CLEANING_CANCELLED;

              return (
                jobId && (
                  <TableRow
                    key={`host-jobs-table-row-key-${jobId}`}
                    className={`tableRow--clickable ${
                      isCancelled ? "cleaning-cancelled" : ""
                    } ${
                      type === JOB_TYPE_TODO
                        ? "table-row--upcoming"
                        : "table-row--completed"
                    }`} // using Type to bold only ToDo (upcoming) dates
                    hover={true}
                  >
                    <TableCellLinked
                      component="th"
                      scope="row"
                      id={jobId}
                      className="nowrap"
                    >
                      <div className="inline-flex align-center">
                        <div>
                          <span className="bold">
                            <FormatJobDate date={job_date} />
                          </span>
                          <small
                            className="label text-ellipsis table--nowrap--sub-tet"
                            title={`Job time is at ${getFormattedCheckInOutTime(
                              job_time
                            )}`}
                          >{`${getFormattedCheckInOutTime(job_time)}`}</small>
                        </div>
                        {isFlip && (
                          <SameDayFlip tooltip={true} label="Same day flip" />
                        )}
                      </div>
                    </TableCellLinked>
                    <TableCellLinked align="left" id={jobId}>
                      <div
                        title={title}
                        className="text-ellipsis table__nowrap--location"
                      >
                        {title}
                      </div>
                      <small
                        className="label text-ellipsis table__nowrap--location"
                        title={`${channel}  |  ${address}`}
                      >{`${channel}  |  ${address}`}</small>
                    </TableCellLinked>
                    {// If maid_id then render Pros name
                    !isTablet &&
                      (proId ? (
                        <TableCellLinked align="left" id={jobId}>
                          {prosInfo}
                        </TableCellLinked>
                      ) : // Render a button to Assign a Pro
                      is_completed ? (
                        <TableCellLinked align="left" id={jobId}>
                          Not assigned
                        </TableCellLinked>
                      ) : (
                        <TableCell align="left">
                          <Button
                            color="primary"
                            variant="outlined"
                            disabled={Boolean(is_past)}
                            onClick={() =>
                              openThisDialog({
                                dialogType: DT_CHANGE_A_PRO,
                                jobId,
                              })
                            }
                          >
                            {" "}
                            Assign a pro
                          </Button>
                        </TableCell>
                      ))}
                    <TableCellLinked align="left" id={jobId}>
                      <FormatJobStatus
                        props={{ is_completed, payment, status }}
                      />
                    </TableCellLinked>
                    <TableCell>
                      <JobActions
                        props={{
                          jobId,
                          is_completed,
                          isPaymentRequestAvailable,
                          status,
                          job_time,
                          job_date,
                          proId,
                          description,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )
              );
            })}
          </TableBody>
        </Table>
      </>
    );
  };

  function JobActions({ props }) {
    const {
      jobId,
      job_date: jobDate,
      job_time: jobTime,
      isPaymentRequestAvailable,
      is_completed,
      status,
      proId,
      description,
    } = props;

    const VIEW_DETAILS = "View details";
    const CHANGE_A_PROS = "Change a pro";
    const UPDATE_JOB_DATE_TIME = "Update job date/time";
    const ADD_A_NOTE = description ? "Update note" : "Add note";
    const CANCEL_JOB = "Cancel this job";
    // set anchor
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    function handleMenuItemClick(event, item) {
      if (item === VIEW_DETAILS) {
        history.push(`/job/${jobId}`);
      } else if (item === CHANGE_A_PROS) {
        Analytics.trackEvent("change_pro_action_from_jobs", { job_id: jobId });
        openThisDialog({
          dialogType: DT_CHANGE_A_PRO,
          jobId,
          proId,
        });
      } else if (item === UPDATE_JOB_DATE_TIME) {
        Analytics.trackEvent("job_update_schedule_action_from_jobs", {
          job_id: jobId,
        });
        openThisDialog({
          dialogType: DT_UPDATE_JOB_DATE_TIME,
          jobId,
          jobTime,
          jobDate,
        });
      } else if (item === ADD_A_NOTE) {
        Analytics.trackEvent("add_note_action_from_jobs", {
          job_id: jobId,
        });
        openThisDialog({ dialogType: DT_JOB_DESCRIPTION, description, jobId });
      } else if (item === CANCEL_JOB) {
        Analytics.trackEvent("job_cancel_action_from_jobs", { job_id: jobId });
        openThisDialog({
          dialogType: DT_CANCEL_JOB,
          jobId,
          view: "jobs",
        });
      }
    }

    if (isPaymentRequestAvailable) {
      return (
        <Button
          color="secondary"
          className="nowrap"
          size="small"
          variant="contained"
          disableElevation={true}
          onClick={() =>
            openThisDialog({ dialogType: DT_INVITE_TO_PAY, proId })
          }
        >
          Invite to pay
        </Button>
      );
    } else if (!is_completed) {
      return (
        <>
          <IconButton
            onClick={handleMenuClick}
            aria-label="More actions for a job"
            aria-haspopup="true"
            aria-controls="simple-job-actions"
          >
            <MoreHoriz />
          </IconButton>
          <Menu
            id="simple-job-actions"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={(event) => handleMenuItemClick(event, VIEW_DETAILS)}
            >
              {VIEW_DETAILS}
            </MenuItem>
            <MenuItem
              onClick={(event) => handleMenuItemClick(event, CHANGE_A_PROS)}
              className={classnames({
                "Mui-disabled": status === CLEANING_CANCELLED || isPast,
              })}
            >
              {CHANGE_A_PROS}
            </MenuItem>
            <MenuItem
              onClick={(event) =>
                handleMenuItemClick(event, UPDATE_JOB_DATE_TIME)
              }
              className={classnames({
                "Mui-disabled": status === CLEANING_CANCELLED || isPast,
              })}
            >
              {UPDATE_JOB_DATE_TIME}
            </MenuItem>
            <MenuItem
              onClick={(event) => handleMenuItemClick(event, ADD_A_NOTE)}
              className={classnames({
                "Mui-disabled": status === CLEANING_CANCELLED || isPast,
              })}
            >
              {ADD_A_NOTE}
            </MenuItem>
            <MenuItem
              onClick={(event) => handleMenuItemClick(event, CANCEL_JOB)}
              className={classnames("danger", {
                "Mui-disabled": status === CLEANING_CANCELLED || isPast,
              })}
            >
              {CANCEL_JOB}
            </MenuItem>
          </Menu>
        </>
      );
    } else return null;
  }

  const DesktopLayout = () => (
    <>
      <Table aria-label="simple table" className="table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Job date</TableCell>
            <TableCell align="left">Location</TableCell>
            {// Don't render Assigned pro on smaller screens
            !isTablet && <TableCell align="left">Assigned pro</TableCell>}
            <TableCell align="left">Status</TableCell>
            {/* For more dropdown */}
            <TableCell align="left" />
          </TableRow>
        </TableHead>
        <TableBody className="--bk-white">
          {items.map((item) => {
            const {
              id: jobId,
              channel,
              job_date,
              job_time = "N/A",
              description,
              address,
              pro = {},
              is_payment_request_available: isPaymentRequestAvailable = false,
              status,
              property: { title },
              is_flip: isFlip = false,
              payment,
              is_completed,
              is_past,
            } = item;
            const isMarketplace =
              item?.reservation?.channel?.toLowerCase() === "marketplace" ||
              false;

            let prosInfo = "";
            const proId = pro ? pro.id : null;

            // Multiple pros assigned to a cleaning
            if (!isEmpty(pro)) {
              prosInfo = (
                <div>
                  <div className="pros-assigned__name nowrap">{`${pro.first} ${pro.last}`}</div>
                  <div className="label">
                    {pro.category
                      ? pro.category
                      : !pro.email && (
                          <span className="italic grey">Invited</span>
                        )}
                  </div>
                </div>
              );
            }

            const isCancelled = status === CLEANING_CANCELLED;
            const isStarted = status === CLEANING_STARTED;

            return (
              jobId && (
                <TableRow
                  key={`host-jobs-table-row-key-${jobId}`}
                  className={`tableRow--clickable ${
                    isCancelled ? "cleaning-cancelled" : ""
                  } ${isStarted && "table-row--started"} ${
                    type === JOB_TYPE_TODO
                      ? "table-row--upcoming"
                      : "table-row--completed"
                  }`} // using Type to bold only ToDo (upcoming) dates
                  hover={true}
                >
                  <TableCellLinked
                    component="th"
                    scope="row"
                    id={jobId}
                    className="nowrap"
                  >
                    <div className="inline-flex align-center">
                      <div>
                        <span className="bold">
                          <FormatJobDate date={job_date} />
                        </span>
                        <small
                          className="label text-ellipsis table--nowrap--sub-tet"
                          title={`Job time is at ${getFormattedCheckInOutTime(
                            job_time
                          )}`}
                        >{`${getFormattedCheckInOutTime(job_time)}`}</small>
                      </div>
                      {isFlip && (
                        <SameDayFlip tooltip={true} label="Same day flip" />
                      )}
                    </div>
                  </TableCellLinked>
                  <TableCellLinked align="left" id={jobId}>
                    <div
                      title={title}
                      className="text-ellipsis table__nowrap--location"
                    >
                      {title}
                    </div>
                    <small
                      className="label text-ellipsis table__nowrap--location"
                      title={`${channel}  |  ${address}`}
                    >{`${channel}  |  ${address}`}</small>
                  </TableCellLinked>
                  {// If maid_id then render Pros name
                  !isTablet &&
                    (proId ? (
                      <TableCellLinked align="left" id={jobId}>
                        {prosInfo}
                      </TableCellLinked>
                    ) : // Render a button to Assign a Pro
                    is_completed ? (
                      <TableCellLinked align="left" id={jobId}>
                        Not assigned
                      </TableCellLinked>
                    ) : (
                      <TableCell align="left">
                        <Button
                          color="primary"
                          variant="outlined"
                          disabled={Boolean(is_past)}
                          onClick={() =>
                            openThisDialog({
                              dialogType: DT_CHANGE_A_PRO,
                              jobId,
                            })
                          }
                        >
                          {" "}
                          Assign a pro
                        </Button>
                      </TableCell>
                    ))}
                  <TableCellLinked align="left" id={jobId}>
                    <FormatJobStatus
                      props={{ is_completed, payment, status }}
                    />
                  </TableCellLinked>
                  <TableCell>
                    <JobActions
                      props={{
                        jobId,
                        is_completed,
                        isPaymentRequestAvailable,
                        status,
                        job_time,
                        job_date,
                        proId,
                        description,
                      }}
                    />
                  </TableCell>
                </TableRow>
              )
            );
          })}
        </TableBody>
      </Table>
    </>
  );

  const MobileLayout = () => {
    return items.map((item) => {
      const {
        id: jobId,
        job_date,
        job_time = "N/A",
        address,
        status,
        is_flip: isFlip = false,
        pro = {},
        property: { title },
        payment,
        is_completed,
        is_past,
      } = item;

      const isCancelled = status === CLEANING_CANCELLED;
      const isStarted = status === CLEANING_STARTED;

      let prosInfo = "";
      const proId = pro ? pro.id : null;

      // Multiple pros assigned to a cleaning
      if (!isEmpty(pro)) {
        prosInfo = (
          <div>
            <div className="pros-assigned__name nowrap">{`${pro.first} ${pro.last}`}</div>
            <div className="label">
              {pro.category
                ? pro.category
                : !pro.email && <span className="italic grey">Invited</span>}
            </div>
          </div>
        );
      }

      return (
        <div
          key={`jobs-mobile-card-${jobId}`}
          className={`jobs-card ${isStarted && "jobs-card--started"}`}
        >
          <div onClick={(event) => handleJobClick(event, jobId)}>
            <p
              className={`jobs-card__date ${isFlip &&
                "jobs-card__date--split"}`}
            >
              <FormatJobDate date={job_date} />
              <span className="grey" style={{ fontWeight: 400 }}>
                <span style={{ marginRight: "4px", marginLeft: "4px" }}>
                  at
                </span>
                {getFormattedCheckInOutTime(job_time)}
              </span>
              {isFlip && <SameDayFlip />}
            </p>
            <p className="jobs-card__title">{title}</p>
            <p className="jobs-card__location jobs-card__content--secondary mb2">
              {address}
            </p>
          </div>
          {proId ? (
            <div
              className="jobs-card__pros-assigned"
              onClick={(event) => handleJobClick(event, jobId)}
            >
              <div className="pros-assigned__container">{prosInfo}</div>
              <div className="pros-assigned__container">
                <FormatJobStatus props={{ is_completed, payment, status }} />
              </div>
            </div>
          ) : is_completed ? (
            <div onClick={(event) => handleJobClick(event, jobId)}>
              No pro was assigned
            </div>
          ) : (
            <Button
              color="primary"
              disabled={Boolean(is_past)}
              variant="outlined"
              onClick={() =>
                openThisDialog({
                  dialogType: DT_CHANGE_A_PRO,
                  jobId,
                })
              }
            >
              {" "}
              Assign a pro
            </Button>
          )}
        </div>
      );
    });
  };

  const JobsEmptyState = () => {
    function clearFilters() {
      const _location = `${window.location.origin}${window.location.pathname}`;
      window.location = _location;
    }

    function clearCompletedJobsFilters() {}

    const searchPath = window.location.search || "";

    if (isAnyFilterActive()) {
      return (
        <div
          style={{ height: "calc(100% - 55px)" }}
          className="full-width full-height inline-flex justify-center align-center"
        >
          <div>
            <h1 className="header-3">No results found.</h1>
            <Button
              variant="outlined"
              color="primary"
              className="host-primary"
              disableElevation={true}
              onClick={clearFilters}
            >
              Try Clearing filters
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{ height: "calc(100% - 55px)" }}
          className="full-width full-height inline-flex justify-center align-center"
        >
          <div className="text-center">
            <h1 className="header-3">No Jobs found</h1>
            {/* ONLY render CTA Request a Job in a ToDo Tab */}
            {!searchPath.includes("past") && (
              <Button
                variant="contained"
                color="secondary"
                disableElevation={true}
                onClick={() => requestJob("empty")}
              >
                Request a job
              </Button>
            )}
          </div>
        </div>
      );
    }
  };

  return (
    <div className="page-wrapper">
      <div className="page-content">
        {!isMobile && (
          <div className="primary-actions mb2" key="host-jobs-primary-action">
            <h1 className="page-header">Jobs</h1>
            <Button
              variant="contained"
              color="secondary"
              disableElevation={true}
              onClick={() => requestJob()}
            >
              Request a job
            </Button>
          </div>
        )}

        <div
          className="table-wrapper table-wrapper--nav-title table-wrapper--no-padding"
          key="host-jobs-page-content"
        >
          <Tabs
            value={tabSelected}
            onChange={handleTabSelection}
            indicatorColor="primary"
            textColor="primary"
            className="page-content__jobs-tabs"
          >
            <Tab
              {...a11yProps(JOBS_TODO)}
              label="ToDo"
              className="page-content__tab--todo"
            />
            <Tab {...a11yProps(JOBS_COMPLETED)} label="Past" />
          </Tabs>
          {isLoading ? (
            <LoadingScreen />
          ) : !isEmpty(items) ? (
            <>
              <div key="host-jobs-filters" className="mt2 mb2 filters">
                <Filter displaySelectedValue={true} filterType="hostJobs" />
              </div>
              <div className="discrepancies-action">
                <ItemsCount
                  onPage={items.length}
                  totalItems={page.total}
                  type="jobs"
                  className="page__counter mb1"
                  key="host-jobs-item-count"
                />
                <div>
                  {tabSelected === 0 && (
                    <Button
                      onClick={() =>
                        openThisDialog({
                          dialogType: DT_DISCREPANCIES_JOBS_RESERVATIONS,
                        })
                      }
                    >
                      Jobs not sycned?
                    </Button>
                  )}
                </div>
              </div>
              {isMobile ? (
                <MobileLayout />
              ) : (
                <DesktopLayout key="host-desktop-jobs-layout" />
              )}
              {/* Pagination */}
              <Pagination
                nextPage={page.nextPage}
                prevPage={page.prevPage}
                type="hostJobs"
              />
              <div className="intercom-spacer" />
            </>
          ) : (
            <JobsEmptyState />
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    jobs: getJobs(state),
    subscribe: getSubscription(state).data,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        fetchHostJobs,
        setDialogOpen,
        openThisDialog,
      },
      dispatch
    )
)(HostJobs);
