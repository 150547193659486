import axios from "../helpers/axios";
import { setContentLoading } from "./loading";
import { serverError } from "../helpers/helper_serverError";

export const FETCH_INVOICES = "fetch_invoices";
export const RESET_INVOICES = "reset_invoices";

export const fetchPreviousInvoices = (nextPage) => {
  return (dispatch) => {
    let api = "";
    if (nextPage) {
      api = `/v1/invoices?page=${nextPage}`;
    } else {
      dispatch(setContentLoading(true));
      dispatch(resetPreviousInvoices());
      api = `/v1/invoices`;
    }
    return axios
      .get(api)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setContentLoading(false));
          dispatch(setPreviousInvoices(res.data));
        }
      })
      .catch((error) => {
        dispatch(setContentLoading(false));
        dispatch(serverError(error));
      });
  };
};

export const setPreviousInvoices = (data) => ({ type: FETCH_INVOICES, data });
export const resetPreviousInvoices = () => ({ type: RESET_INVOICES });
