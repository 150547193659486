import { SET_OVERLAY_LOADING_SCREEN } from "../actions/overlayLoading";

export const getOverlayFlag = ({ overlay }) => overlay;

export const overlay = (state = { show: false }, action) => {
  switch (action.type) {
    case SET_OVERLAY_LOADING_SCREEN:
      return { show: action.value };

    default:
      return state;
  }
};
