import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import Navigation from "../containers/navigation";
import "./loadingScreen.scss";

const LoadingScreen = (props) => (
  <div className="loading-screen" key="loading-screen-page">
    <CircularProgress color="primary" />
  </div>
);
export default LoadingScreen;
