import React, { useEffect, useState, useRef } from "react";

import amplitude from "amplitude-js";
import Button from "@material-ui/core/Button";

import ImportCalendarTextField from "../../components/importCalendarTextField";
import { serverError } from "../../helpers/helper_serverError";
import { linkPropertyCalendar } from "../../actions/pmsProperties";
import { calendarURL } from "../../constants/validate";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { parseQuery } from "../../helpers/url";
import { CircularProgress } from "@material-ui/core";
import history from "../../helpers/history";
import { showArticle } from "@intercom/messenger-js-sdk";
import { Analytics } from "../../services/analyticsService.tsx";

const ImportCalendar = (props) => {
  const { linkPropertyCalendar, propertyId, serverError } = props;

  const [isValidated, setUrlValidation] = useState(false);
  const [value, setValue] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const effectRan = useRef(false);

  const { opu } = parseQuery();

  const isOnboarding = opu === "onboarding";

  const handleImportCal = () => {
    setLoading(true);
    if (value && calendarURL(value)) {
      linkPropertyCalendar({ property_id: propertyId, url: value })
        .then((res) => {
          if (isOnboarding) {
            Analytics.trackSimple("onboarding_import_calendar");
          } else {
            Analytics.trackSimple("new_property_import_calendar");
          }
          if (res) {
            const { status, data } = res;
            if (status === 200) {
              if (data && data.id) {
                history.push({
                  search: isOnboarding ? "opu=onboarding" : "",
                  state: { ...data, nextStep: "automation" },
                });
              }
              setLoading(true);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          serverError(err);
        });
    }
  };

  const updateValue = (value) => {
    setLoading(false);
    if (calendarURL(value)) {
      setUrlValidation(true);
    } else {
      setUrlValidation(false);
    }

    setValue(value);
  };

  const openHelpArticle = () => {
    if (showArticle) {
      showArticle(9463243);
    } else {
      // Go to link if Intercom is not available
      window.open(
        "https://help.automatebnb.com/en/articles/9463243-list-of-integrations",
        "_blank"
      );
    }
  };

  return (
    <form noValidate autoComplete="off">
      <br />
      <p>
        Link your calendar to automatically sync jobs, and we'll promptly update
        and inform the Pro about any new or modified reservations. Our platform
        supports either Channels or Property Management Software (PMS), but not
        both.
      </p>
      <br />
      <p>
        For more details about supported channels and PMS options, please refer
        to our{" "}
        <a
          href="#"
          className="underline underline-offset-2"
          onClick={openHelpArticle}
        >
          Help Article.
        </a>
      </p>
      <br />
      <ImportCalendarTextField
        value={updateValue}
        isValid={isValidated}
        isDisabled={isLoading}
      />
      <Button
        variant="contained"
        disableElevation
        color="primary"
        style={{ marginTop: "24px" }}
        fullWidth
        onClick={() => handleImportCal()}
        disabled={!isValidated}
      >
        {isLoading ? (
          <CircularProgress size={24} style={{ color: "white" }} />
        ) : (
          "Next: Job automation"
        )}
      </Button>
    </form>
  );
};

export default connect(
  (state) => ({}),
  (dispatch) =>
    bindActionCreators({ linkPropertyCalendar, serverError }, dispatch)
)(ImportCalendar);
