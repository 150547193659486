import * as types from "../constants/action-types";

const initialState = {
  hideDuration: null,
  isOpen: false,
  error: false,
  message: null,
  to: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    //Snackbar
    case types.SHOW_SNACKBAR:
      return {
        ...state,
        erro: false,
        isOpen: true,
        ...action,
      };
    case types.HIDE_SNACKBAR:
      return { ...state, error: false, isOpen: false };

    default:
      return state;
  }
};
