import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import amplitude from "amplitude-js";

// Material-UO
import { CircularProgress } from "@material-ui/core";

// Components
import ChangeProSection from "./ChangeProSection";
import AssignProSection from "./AssignProSection";

import { getPMSProperty } from "../reducers/pmsProperties";

import { fetchProperty } from "../actions/pmsProperties";

const PropertyCleaning = (props) => {
  const { fetchProperty, property = {}, propertyId } = props;
  const {
    assign_maid_rule: assignedRule = null,
    maids = [],
    auto_assigned_maid: assignedPro = null,
    isLoading = true,
  } = property;

  useEffect(() => {
    if (propertyId) {
      fetchProperty(propertyId);
    }
  }, []);

  if (isLoading) {
    return (
      <div
        className="inline-flex align-center justify-center full-width"
        style={{ height: "80%" }}
      >
        <CircularProgress />
      </div>
    );
  } else {
    if (assignedPro) {
      return <ChangeProSection property={property} />;
    } else {
      return <AssignProSection property={property} />;
    }
  }
};

export default connect(
  (state) => ({ property: getPMSProperty(state) }),
  (dispatch) => bindActionCreators({ fetchProperty }, dispatch)
)(PropertyCleaning);
