import {
  SET_PMS_PROPERTIES,
  RESET_PROPERTIES,
  SET_PMS_PROPERTY,
  SET_NEW_PROPERTY_ID,
  RESET_OPS_PROPERTIES_FILTER,
  SET_OPS_PROPERTIES_FILTER,
} from "../actions/pmsProperties";

export const getPMSProperties = ({ properties }) => properties;

export const getPMSProperty = ({ property }) => property;
export const getPMSNewProperty = ({ newProperty }) => newProperty;

export const properties = (
  state = { page: { total: 0 }, items: [] },
  action
) => {
  const { data } = action;
  switch (action.type) {
    case RESET_OPS_PROPERTIES_FILTER:
      return { isLoading: true };
    case SET_OPS_PROPERTIES_FILTER:
      console.log("<< set ops filter..");
      return { page: { total: 0 }, items: data, isLoading: false };
    case RESET_PROPERTIES:
      return { page: { total: 0 }, items: [], isLoading: true };
    case SET_PMS_PROPERTIES:
      return {
        ...state,
        items: [...state.items, ...data.items],
        page: {
          total: data.total,
          currentPage: data.current_page,
          nextPage: data.next_page,
          prevPage: data.previous_page,
          previousPage: data.previous_page,
        },
        isLoading: false,
      };

    default:
      return state;
  }
};

export const property = (state = { isLoading: true }, action) => {
  const { data } = action;
  switch (action.type) {
    case SET_PMS_PROPERTY:
      return { ...state, ...data, isLoading: false };

    default:
      return state;
  }
};

export const newProperty = (state = {}, action) => {
  const { id } = action;
  switch (action.type) {
    case SET_NEW_PROPERTY_ID:
      return { ...state, id };
    default:
      return state;
  }
};
