import React from "react";
import TextField from "@material-ui/core/TextField";

const ImportCalendarTextField = (props) => {
  const handleChange = (event) => {
    const { value } = event.target;
    props.value(value);
  };
  return (
    <TextField
      error={!props.isValid}
      id="standard-error-helper-text"
      label="Calendar URL"
      autoFocus={true}
      InputLabelProps={{
        shrink: true,
      }}
      onChange={handleChange}
      variant="outlined"
      fullWidth={true}
      disabled={props.isDisabled}
      placeholder="Paste your iCS calendar here.."
      helperText={props.isValid ? false : "Please use correct iCS calendar"}
    />
  );
};

export default ImportCalendarTextField;
