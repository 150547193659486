import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { useModal } from "../ModalContext";
import { getDamageInspections } from "../actions/jobs";
import GuestRating from "../components/guestRating";
import {
  CLEANER_STATUS_ACCEPTED,
  CLEANER_STATUS_COMPLETED,
  CLEANER_STATUS_DECLINED,
  CLEANING_STARTED,
  WAITING_TO_ACCEPT,
} from "../constants/apiEnum";
import { s3UrlMapper } from "../helpers/s3URLMapper";

interface InspectionProps {
  is_host: boolean;
  status: string;
  guest_rating: number;
  job_id: string;
  is_past: boolean;
}

const JobInspection: React.FC<InspectionProps> = ({
  job_id,
  status,
  is_host,
  is_past,
  guest_rating,
}) => {
  const dispatch = useDispatch();
  const [reports, setReports] = useState([]);
  const { showModal, hideModal } = useModal();

  useEffect(() => {
    dispatch(getDamageInspections(job_id)).then((response) => {
      if (response.status === 200) {
        setReports(response.data);
      }
    });
  }, []);

  function pluralize(count, label) {
    return count === 1 ? label : `${label}s`;
  }

  const ShowReports = () => {
    return (
      <div className="px-4 py-4 min-w-screen-sm">
        <h3 className="text-lg">Damage reports</h3>
        {reports.map((report, index) => {
          const images = report.image_urls;
          const description = report.description;
          return (
            <div key={index} className="mt-4">
              <h4 className="text-md font-semibold">Issue</h4>
              <div className="flex gap-2 flex-wrap py-1">
                {images &&
                  images.map((image, index) => (
                    <a href={s3UrlMapper({ url: image })} target="_blank">
                      <img
                        width={48}
                        height={48}
                        src={s3UrlMapper({ url: image })}
                        alt={`inspection image`}
                        key={index}
                      />
                    </a>
                  ))}
              </div>
              {description && <p className="py-1">{description}</p>}
              <hr />
            </div>
          );
        })}
        <div className="flex flex-end mt-3">
          <Button onClick={hideModal} variant="outlined" color="primary">
            Close
          </Button>
        </div>
      </div>
    );
  };

  const RenderReports = () => {
    if (!isEmpty(reports)) {
      return (
        <div className="mt-4 flex align-center space-between">
          <div>
            <h4 className="text-sm font-semibold">Damage reports</h4>
            <p>
              ⚠️ {reports.length} {pluralize(reports.length, "issue")} reported
            </p>
          </div>
          <div className="mt-2">
            <Button
              onClick={() => showModal(ShowReports, {})}
              variant="outlined"
              color="primary"
            >
              View reports
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="mt-4">
          <p className="text-sm text-gray-500 italic">No issues reported</p>
        </div>
      );
    }
  };

  const RenderHostLogic = () => {
    const checkStatus = [CLEANER_STATUS_COMPLETED, CLEANING_STARTED];
    if (!checkStatus.includes(status) && !is_past) {
      return (
        <div>
          <p>
            Your pro can Inspect the property, share feedback on the guest's
            visit, and report any damages directly from our iPhone or Android
            app.
          </p>
          <p className="mt-1 italic opacity-50 text-sm">
            Remind your pro to use this feature. It is included in your
            subscription.
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <p className="text-md mb-1">Guests rating:</p>
          <div className="flex align-center">
            <GuestRating rate={guest_rating} />
            {guest_rating === 0 && (
              <p className="text-gray-500 text-sm italic">Not rated</p>
            )}
          </div>
          <RenderReports />
        </div>
      );
    }
  };

  const RenderServiceProvidersLogic = () => {
    const checkStatus = [
      CLEANER_STATUS_COMPLETED,
      CLEANER_STATUS_DECLINED,
      CLEANING_STARTED,
    ];
    if (!checkStatus.includes(status) && !is_past) {
      return (
        <div>
          <p>
            You can Inspect the property, share feedback on the guest's visit,
            and report any damages.
          </p>

          <div className="mt-2">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              disableElevation
              onClick={() =>
                window.open(
                  "https://help.automatebnb.com/en/articles/9666020-how-to-do-a-pre-cleaning-inspection",
                  "_blank"
                )
              }
            >
              Learn more
            </Button>
          </div>
          <p className="italic mt-2 text-gray-500 text-sm">
            <a
              href="https://automatebnb.com/downloads?source=direct"
              target="_new"
              className="link"
            >
              Download our free app
            </a>{" "}
            to access this feature
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <p className="text-md mb-1">Guests rating:</p>
          <div className="flex align-center">
            <GuestRating rate={guest_rating} />
            {guest_rating === 0 && (
              <p className="text-gray-500 text-sm italic">Not rated</p>
            )}
          </div>
          <RenderReports />
        </div>
      );
    }
  };

  return (
    <div className="mb-4 px-4 mt-4 py-4 bg-white rounded-md">
      <h3 className="txt-md font-semibold mb-2">Inspection</h3>
      {is_host ? <RenderHostLogic /> : <RenderServiceProvidersLogic />}
    </div>
  );
};

export default JobInspection;
