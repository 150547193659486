// DEPRECATED
export const PMS_RESERVATIONS = "/reservations";
export const PMS_RESERVATIONS_SHOW = "/reservations/:id";
export const PMS_RESERVATIONS_NEW = "/reservations/new";

// DEPRECATED
// Pros and Hosts will have /jobs and /jobs/:id
export const HOUSEKEEPER_CLEANINGS = "/housekeeper/cleanings";
export const HOUSEKEEPER_CLEANING = "/housekeeper/cleanings/:id";

// DEPRECATED
export const PMS_EVENTS = "/events";
export const PMS_EVENTS_SHOW = "/events/:id";
export const PMS_EVENT_NEW = "/events/new";

// DEPRECATED -- need to update all routes
// ****** WORK ON THIS WHEN ADDING AUTOMATION/DETAILS PAGES ON PROPERTIES UI
export const PMS_PROPERTIES = "/listings";
export const PMS_PROPERTIES_SHOW = "/listings/:id";
export const PMS_PROPERTIES_SHOW_PROPERTY_DETAILS =
  "/listings/:id/listing-details";
export const PMS_PROPERTIES_SHOW_LINK = "/listings/:id/link";
export const PMS_PROPERTIES_SHOW_HOUSEKEEPER = "/listings/:id/cleaning";

/*==================================================== 
    PUBLIC ROUTES
======================================================*/
// ==> if user signed in route to /Jobs
// ==> if user is NOT signed in route to /log-in
export const APP = "/";

// Parked for the Landing page
export const PROS = "/pros";

export const LOG_IN = "/login";
export const HOST_SIGNUP = "/signup";

export const FORGOT_PASSWORD = "/forgot-password";

// Will have to change the hardcoded link in the EMAIL template before changing the path to hyphin.
export const RESET_PASSWORD = "/reset_password";

// Unauthorized pages if user is trying to access page which they are not authorized to view
// i.e. If PRO trys to access /properties or /my-pros
export const UNAUTHORIZED = "/unauthorized";

export const PROS_SIGN_UP = "/pros/signup";

/*====================================================
    PRIVATE ROUTES
======================================================*/

// JOBS - used for Host or Pros
export const JOBS = "/jobs";
export const JOB = "/job/:id";

// PROPERTIES - Host only
export const PROPERTIES = "/properties";
export const PROPERTY_AUTOMATION = "/property/:id/automation";
export const PROPERTY_DETAILS = "/property/:id/details";
export const PROPERTY_TASKS = "/property/:id/tasks";

// MY PROS - host only
export const MY_PROS = "/my-pros";

// MANAGE ACCOUNT - Host or Pros
export const MANAGE_ACCOUNT = "/manage-account";
export const MANAGE_PROFILE = "/manage-account/profile";
export const MANAGE_SECURITY = "/manage-account/security";
export const MANAGE_NOTIFICATIONS = "/manage-account/notifications";
export const MANAGE_INVOICES = "/manage-account/invoices";

// New account routes
export const MANAGE_ACCOUNT_ROOT = "/manage-account";
export const MANAGE_ACCOUNT_PROFILE = "/manage-account/profile";
export const MANAGE_ACCOUNT_NOTIFICATIONS = "/manage-account/notifications";
export const MANAGE_ACCOUNT_INVOICES = "/manage-account/invoices";
export const MANAGE_ACCOUNT_SUBSCRIPTION = "/manage-account/subscription";
export const MANAGE_ACCOUNT_PAYOUT = "/manage-account/payout";
export const MANAGE_ACCOUNT_JOB_PRICING = "/manage-account/job-pricing";

// MANAGE ACCOUNT - Host only
export const MANAGE_SUBSCRIPTION = "/manage-account/subscription";

// MANAGE ACCOUNT - Pros only
export const MANAGE_PAYOUT = "/manage-account/payout";

// Host to subscribe
export const HOST_SUBSCRIBE = "/subscribe";

// Host collect payment method
export const HOST_PAYMENT_METHOD = "/payment-mathod";

/* == REUSE THESE UNTIL NEW ONBOARDING IS IN PLACE == */
export const PMS_PROPERTIES_NEW = "/properties/new";

/*
|
| Operations platform
|
*/

// op/jobs
// op/jobs/:id
// op/clients
// op/clients/:id/properties
// op/clients/:id/properties/:id/checklist
// op/teams

export const OPERATIONS_CLIENTS = "/clients";
export const OPERATIONS_CLIENTS_PROPERTIES = "/clients/:id/properties";
export const INVITED_CLIENTS = "/clients/invited";

// Future OPS routes
export const OPERATIONS_MESSAGES = "/messages";
export const OPERATIONS_CLIENTS_PROPERTIES_CHECKLIST =
  "/clients/:id/properties/:id/checklist";
export const OPERATIONS_CLIENTS_PROPERTIES_SUPPLIES =
  "/clients/:id/properties/:id/supplies";
export const OPERATIONS_CLIENTS_PROPERTIES_REPORTS =
  "/clients/:id/properties/:id/reports";
export const OPERATIONS_CLIENTS_PROPERTIES_PAYMENTS =
  "/clients/:id/properties/:id/payments";

// Parked for onboarding for Host & Pros
// Add properties, Invite staff, setup triggers
export const HOST_ONBOARDING_PROPERTIES = "/new-user/add-properties";
export const HOST_ONBOARDING_MY_PROS = "/new-user/invite-pros";

// Parking route to do onboarding for Pros
// export const PROS_ONBOARDING = "/pros/new/account";

// Chat module
export const MESSAGES = "/messages";

// NEW onboarding and account setup
/*
  REUSE
    - Host
    - Pros
    - Marketplace accounts
*/
export const NEW_USER_SIGNUP = "/user/signup";
export const NEW_USER_ACCOUNT_CREATION = "/user/account";

// MARKETPLACE
export const NEW_USER_MARKETPLACE_USERNAME = "/get/username";
export const NEW_USER_MARKETPLACE_PROFILE_BASIC = "/user/profile/info";
export const NEW_USER_MARKETPLACE_PROFILE_CHECKLIST = "/user/profile/checklist";
export const NEW_USER_MARKETPLACE_PROFILE_PRICING = "/user/profile/pricing";
export const NEW_USER_MARKETPLACE_PROFILE_SERVICES = "/user/profile/services";

// PROS
export const NEW_USER_PRO_PAYMENT = "/user/bank-link";
