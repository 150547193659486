import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import Moment from "react-moment";

// Material UI Components
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// Actions
import { fetchPreviousInvoices } from "../actions/previousInvoices";
import { fetchSubscription } from "../actions/subscription";

// Containers
import Pagination from "../containers/pagination";

// Components
import ItemsCount from "../components/itemsCount";
import EmptyState from "../components/emptyState";

// Constants
import {
  EMPTY_STATE_TYPE_PREVIOUS_INVOICES,
  EMPTY_STATE_TYPE_CLEANING_PAYMENTS,
  DATE_FORMAT_FULL,
} from "../constants/apiEnum";

// Reducer
import { getLoading } from "../reducers/loading";
import { getSubscription } from "../reducers/reducer_subscribe";
import { getPreviousInvoices } from "../reducers/previousInvoices";
import { CircularProgress } from "@material-ui/core";

function RenderInvoices(invoices) {}

const PreviousInvoices = (props) => {
  const {
    invoices = {},
    fetchPreviousInvoices,
    fetchSubscription,
    subscribe,
  } = props;

  const subscriptionObject = subscribe?.data;

  const isIAP = subscriptionObject?.subscription_type === "iap";

  // Extract invoices
  const { isLoading = true, items = [], page = {} } = invoices;

  useEffect(() => {
    fetchPreviousInvoices();
    fetchSubscription();
  }, []);

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const RenderInvoices = ({ invoices }) => (
    <Table aria-label="simple table " className="abnb-table">
      <TableHead>
        <TableRow>
          <TableCell align="left">Date</TableCell>
          <TableCell align="left">Listings</TableCell>
          <TableCell align="left">Amount</TableCell>
          <TableCell align="left"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody className="--bk-white">
        {invoices.map((invoice, index) => {
          const {
            quantity = "",
            amount = "",
            start_date: startDate = "",
            url = "",
          } = invoice || {};
          return (
            <TableRow key={index}>
              <TableCell align="left">
                <Moment format={DATE_FORMAT_FULL}>{startDate}</Moment>
              </TableCell>
              <TableCell align="left">{quantity}</TableCell>
              <TableCell align="left">{`$${amount}`}</TableCell>
              <TableCell>
                <a target="_new" href={url}>
                  View
                </a>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );

  return isLoading || isEmpty(subscriptionObject) ? (
    <div
      className="inline-flex align-center justify-center full-width"
      style={{ height: "90%" }}
    >
      <CircularProgress />
    </div>
  ) : isIAP ? (
    <div className="mb2" style={{ padding: "24px" }}>
      <h2 className="page-header">Apple App Store subscription</h2>
      <p style={{ paddingTop: "8px" }}>
        To view your invoices or purchase history for Automatebnb iPhone App,
        please follow the instructions linked below.
      </p>
      <br />
      <a
        href="https://support.apple.com/en-us/HT204088"
        target="_new"
        style={{ marginTop: "8px" }}
      >
        Purchase history
      </a>
    </div>
  ) : (
    <>
      <div className="primary-actions mb2" key="host-jobs-primary-action">
        <h1 className="page-header">Invoices</h1>
        <div />
      </div>
      <ItemsCount
        onPage={items.length}
        totalItems={page.total}
        type="invoices"
        className="mb1"
        key="invoices-jobs-item-count"
      />
      {!isEmpty(items) ? (
        <RenderInvoices invoices={items} />
      ) : (
        <EmptyState type={EMPTY_STATE_TYPE_PREVIOUS_INVOICES} />
      )}
      <Pagination type="previousInvoices" nextPage={page.nextPage} />
    </>
  );
};

export default connect(
  (state) => ({
    subscribe: getSubscription(state),
    invoices: getPreviousInvoices(state),
  }),
  (dispatch) =>
    bindActionCreators({ fetchPreviousInvoices, fetchSubscription }, dispatch)
)(PreviousInvoices);
