import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { assign, isEmpty } from "lodash";
import Moment from "react-moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Material UI
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { useMediaQuery } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Chip from "@material-ui/core/Chip";
import LoopIcon from "@material-ui/icons/Loop";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import DescriptionIcon from "@material-ui/icons/Description";
import EventIcon from "@material-ui/icons/Event";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import DoneAllIcon from "@material-ui/icons/DoneAll";
// Components
import SameDayFlip from "../components/sameDayFlip";
import Pagination from "../containers/pagination";
import Filter from "../containers/filter";
import { setDialogOpen, openThisDialog } from "../actions/dialog";
import ItemsCount from "../components/itemsCount";
import NormalizedCleaningStatus from "../components/normalizedCleaningStatus";
import LoadingScreen from "../components/loadingScreen";
import RenderDateTime from "../components/renderDateTime";

// Containers
import Navigation from "../containers/navigation";

// Helpers
import { parseQuery, filterParams } from "../helpers/url";
import history from "../helpers/history";
import { isAnyFilterActive } from "../helpers/checkFilters";
import { getFormattedCheckInOutTime } from "../helpers/format";

// Actions
import { updateJobStatus } from "../actions/jobs";

// Constants
import {
  JOB_TYPE_PAST,
  JOB_TYPE_TODO,
  CLEANING_CANCELLED,
  CLEANING_STARTED,
  CLEANER_STATUS_ACCEPTED,
  PAYMENT_STATUS_PENDING,
  PAYMENT_STATUS_FAILED,
  DATE_FORMAT,
  WAITING_TO_ACCEPT,
  CLEANING_DATE_CHANGED,
  CLEANER_STATUS_DECLINED,
  CLEANER_STATUS_COMPLETED,
  MARKETPLACE,
} from "../constants/apiEnum";
import {
  DT_JOB_DESCRIPTION,
  // NEW
  DT_REQUEST_A_JOB,
  DT_SUBSCRIBE_PROMPT,
  DT_CHANGE_A_PRO,
  DT_INVITE_TO_PAY,
  DT_UPDATE_JOB_DATE_TIME,
  DIALOG_TYPE_REQUEST_CLEANING_PAYMENT,
  DT_INVITE_OPS_CLIENTS,
} from "../constants/dialogTypes";

// Actions
import { fetchOpsJobs } from "../actions/ops";

import { Analytics } from "../services/analyticsService.tsx";

// Reducers
import { getJobs } from "../reducers/jobs";
import OpsProsGroup from "../components/opsProsGroup";

import "./opsJobsList.scss";
import "../styles/startedJob.scss";

// A11Y MUI -- Check Material-ui documents
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const JobsList = (props) => {
  const {
    jobs: { items = {}, page = {}, isLoading = true },
    match,
    address,
    fetchOpsJobs,
    setDialogOpen,
    openThisDialog,
    updateJobStatus,
  } = props;
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:840px)");

  const currentRoute = history.location;

  // Grab value of 'type' from URL
  const { type } = parseQuery();

  // create a new URL based on Tab selection
  let newSearch = null;

  // Reset the query params to remove any filters when swtiching between ToDo and Completed
  const emptyParams = {
    propertyIdsParam: [],
    paymentStatuses: [],
    maidIdsParam: [],
    channelsParam: [],
    cleaningStatus: [],
    noteParam: "false",
    requestPayment: "false",
  };

  // TAB Values
  const JOBS_TODO = 0;
  const JOBS_COMPLETED = 1;

  // Set tab state
  const [tabSelected, setTab] = useState(JOBS_TODO);

  // Replace this with a flag from API response
  const isPast = window.location.search.includes("past");

  // Fetch APIs
  useEffect(() => {
    fetchOpsJobs();

    // Set tab based on URL
    if (type === JOB_TYPE_TODO) {
      setTab(JOBS_TODO);
    } else if (type === JOB_TYPE_PAST) {
      setTab(JOBS_COMPLETED);
    }
  }, []);

  function handleTabSelection(event, newValue) {
    if (newValue === JOBS_TODO) {
      newSearch = filterParams({
        typeParam: JOB_TYPE_TODO,
        ...emptyParams,
      });
    } else if (newValue === JOBS_COMPLETED) {
      newSearch = filterParams({
        typeParam: JOB_TYPE_PAST,
        ...emptyParams,
      });
    }

    // FILTERS ARE PUSHED TOOO
    history.push({
      search: newSearch,
    });
    setTab(newValue);

    // Fetch jobs based on new URL
    fetchOpsJobs();
  }

  function handleJobClick(event, id) {
    history.push(`/job/${id}`);
  }

  // Format job date
  function FormatJobDate({ date }) {
    return <Moment format={DATE_FORMAT}>{date}</Moment>;
  }

  function FormatJobStatus({ props }) {
    const { is_completed, payment, status } = props;
    if (!isEmpty(payment)) {
      return (
        <NormalizedCleaningStatus
          status={payment.status}
          human={payment.status_human}
        />
      );
    } else {
      return <NormalizedCleaningStatus status={status} />;
    }
  }

  function TableCellLinked(props) {
    return (
      <TableCell
        onClick={(event) => handleJobClick(event, props.id)}
        {...props}
      />
    );
  }

  const requestJob = () => {
    openThisDialog({
      dialogType: DT_REQUEST_A_JOB,
    });
  };

  function LocalJobActions({ props }) {
    const {
      jobId,
      job_date: jobDate,
      job_time: jobTime,
      isPaymentRequestAvailable,
      is_completed,
      status,
      proId,
      description,
      isMarketplace = false,
      payment,
    } = props;

    const VIEW_DETAILS = "View details";
    const CHANGE_A_PROS = "Assign a pro";
    const UPDATE_JOB_DATE_TIME = "Update schedule";
    // set anchor
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    function handleMenuItemClick(event, item) {
      if (item === VIEW_DETAILS) {
        history.push(`/job/${jobId}`);
      } else if (item === CHANGE_A_PROS) {
        Analytics.trackEvent("assign_pro_action_from_jobs", { job_id: jobId });
        openThisDialog({
          dialogType: DT_CHANGE_A_PRO,
          jobId,
          proId,
        });
      } else if (item === UPDATE_JOB_DATE_TIME) {
        Analytics.trackEvent("job_update_schedule_action_from_jobs", {
          job_id: jobId,
        });
        openThisDialog({
          dialogType: DT_UPDATE_JOB_DATE_TIME,
          jobId,
          jobTime,
          jobDate,
        });
      } else if (item === "RequestPayment") {
        Analytics.trackEvent("request_payment_action_from_jobs", {
          job_id: jobId,
        });
        setDialogOpen(DIALOG_TYPE_REQUEST_CLEANING_PAYMENT, jobId);
      }

      // Close menu
      handleClose();
    }

    const handleAcceptOrDecline = (buttonStatus) => {
      const lowerCaseStatusForAnalytics = buttonStatus.toLowerCase();
      Analytics.trackEvent(
        `job_${lowerCaseStatusForAnalytics}_action_from_jobs`,
        { job_id: jobId }
      );
      updateJobStatus(jobId, buttonStatus).then((response) => {
        if (response.status === 200) {
          fetchOpsJobs();
        }
      });
    };

    const DeclineAction = () => {
      if (status === CLEANER_STATUS_DECLINED) {
        return (
          <MenuItem
            disabled
            style={{ opacity: 0.9 }}
            className="ops-menu__item ops-menu__item--declined"
          >
            <CancelIcon size="small" className="ops-menu__icon" /> Declined
          </MenuItem>
        );
      } else {
        return (
          <MenuItem
            onClick={() => handleAcceptOrDecline(CLEANER_STATUS_DECLINED)}
          >
            <CancelIcon size="small" className="ops-menu__icon" /> Decline
          </MenuItem>
        );
      }
    };

    const AcceptAction = () => {
      if (status === CLEANER_STATUS_ACCEPTED) {
        return (
          <MenuItem
            disabled
            className="ops-menu__item--accepted"
            style={{ opacity: 0.9 }}
          >
            <CheckCircleIcon size="small" className="ops-menu__icon" /> Accepted
          </MenuItem>
        );
      } else {
        return (
          <MenuItem
            onClick={() => handleAcceptOrDecline(CLEANER_STATUS_ACCEPTED)}
          >
            <CheckCircleIcon size="small" className="ops-menu__icon" /> Accept
          </MenuItem>
        );
      }
    };

    const LocalJobActions = () => {
      if (isPast) {
        if (is_completed) {
          return (
            isEmpty(payment) && (
              <MenuItem
                onClick={(event) =>
                  handleMenuItemClick(event, "RequestPayment")
                }
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="btn-brand--ops"
                  disableElevation
                  startIcon={<MonetizationOnIcon />}
                >
                  Request payment
                </Button>
              </MenuItem>
            )
          );
        } else {
          return (
            status !== CLEANER_STATUS_DECLINED && (
              <MenuItem
                onClick={(event) => handleMenuItemClick(event, "MarkComplete")}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disableElevation
                  onClick={() =>
                    handleAcceptOrDecline(CLEANER_STATUS_COMPLETED)
                  }
                  startIcon={<DoneAllIcon />}
                >
                  Mark as complete
                </Button>
              </MenuItem>
            )
          );
        }
      } else {
        return (
          <>
            <AcceptAction />
            <DeclineAction />
          </>
        );
      }
    };

    return (
      <>
        <IconButton
          onClick={handleMenuClick}
          aria-label="More actions for a job"
          aria-haspopup="true"
          aria-controls="simple-job-actions"
          className="ops-menu"
        >
          <MoreHoriz />
        </IconButton>
        <Menu
          id="simple-job-actions"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            className="ops-menu__item"
            onClick={(event) => handleMenuItemClick(event, VIEW_DETAILS)}
          >
            <DescriptionIcon size="small" className="ops-menu__icon" /> Job
            details
          </MenuItem>
          <MenuItem
            onClick={(event) =>
              handleMenuItemClick(event, UPDATE_JOB_DATE_TIME)
            }
            className={classnames({
              "Mui-disabled":
                isMarketplace ||
                status === CLEANER_STATUS_DECLINED ||
                status === CLEANING_CANCELLED ||
                isPast,
            })}
          >
            <EventIcon size="small" className="ops-menu__icon" /> Change
            schedule
          </MenuItem>
          <MenuItem
            onClick={(event) => handleMenuItemClick(event, CHANGE_A_PROS)}
            className={classnames({
              "Mui-disabled":
                (isMarketplace && status !== CLEANER_STATUS_ACCEPTED) ||
                status === CLEANER_STATUS_DECLINED ||
                status === CLEANING_CANCELLED ||
                isPast,
            })}
          >
            <PersonAddIcon size="small" className="ops-menu__icon" /> Assign a
            pro
          </MenuItem>
          <LocalJobActions />
        </Menu>
      </>
    );
  }

  const RenderJobStatus = (props) => {
    const { status, payment, isCompleted } = props;
    if (isCompleted) {
      if (!isEmpty(payment)) {
        return (
          <NormalizedCleaningStatus
            status={payment.status}
            human={payment.status_human}
          />
        );
      } else {
        return <NormalizedCleaningStatus status={status} />;
      }
    } else {
      return <NormalizedCleaningStatus status={status} />;
    }
  };

  //   console.log("JOBS", items);

  const DesktopLayout = () => (
    <Table aria-label="simple table" className="ops-table">
      <TableHead>
        <TableRow>
          <TableCell align="left" className="pl4">
            Job date
          </TableCell>
          <TableCell align="left">Property</TableCell>
          <TableCell align="left">Host</TableCell>
          <TableCell align="left">Flags</TableCell>
          <TableCell align="left">Assignees</TableCell>
          <TableCell align="left" />
        </TableRow>
      </TableHead>
      <TableBody className="--bk-white">
        {items.map((item) => {
          const {
            id: jobId,
            channel,
            job_date,
            job_time = "N/A",
            description,
            address,
            is_completed: isCompleted,
            pro = {},
            host_name: hostName = null,
            is_payment_request_available: isPaymentRequestAvailable = false,
            status,
            property: { title },
            is_flip: isFlip = false,
            payment,
            is_completed,
            assignments,
          } = item;

          let prosInfo = "";
          const proId = pro ? pro.id : null;

          const isMarketplace = channel === MARKETPLACE;

          // Multiple pros assigned to a cleaning
          if (pro && pro.length) {
            prosInfo = <p>Multiple pros</p>;
          } else if (pro) {
            prosInfo = (
              <div>
                <div className="nowrap">{pro && pro.name}</div>
                <div className="label">
                  {pro && pro.category ? pro.category.name : "--"}
                </div>
              </div>
            );
          }

          const isCancelled = status === CLEANING_CANCELLED;
          const isStarted = status === CLEANING_STARTED;

          const handleJobAssign = () => {
            Analytics.trackEvent("assign_pro_action_from_jobs", {
              job_id: jobId,
            });
            openThisDialog({
              dialogType: DT_CHANGE_A_PRO,
              jobId,
            });
          };

          const RenderActionBasedOnJobType = () => {
            if (!isPast) {
              // Todo
              if (isMarketplace && status !== CLEANER_STATUS_ACCEPTED) {
                // Marketplace job that is not accepted
                return (
                  <Button
                    variant="contained"
                    size="small"
                    disableElevation
                    color="primary"
                    onClick={(event) => handleJobClick(event, jobId)}
                  >
                    View Request
                  </Button>
                );
              } else {
                return (
                  <Button
                    variant="contained"
                    size="small"
                    disableElevation
                    disabled={status === CLEANER_STATUS_DECLINED}
                    color="primary"
                    onClick={handleJobAssign}
                  >
                    Assign
                  </Button>
                );
              }
            } else {
              return null;
            }
          };

          return (
            jobId && (
              <TableRow
                key={`host-jobs-table-row-key-${jobId}`}
                className={`tableRow--clickable ${
                  isCancelled ? "cleaning-cancelled" : ""
                } ${isStarted ? "table-row--started" : null} ${
                  type === JOB_TYPE_TODO
                    ? "table-row--upcoming"
                    : "table-row--completed"
                }`} // using Type to bold only ToDo (upcoming) dates
                hover={true}
              >
                <TableCellLinked scope="row" id={jobId} className="nowrap pl4">
                  <span className="bold">
                    <RenderDateTime date={job_date} time={job_time} short />
                  </span>
                  <div className="ops-table__status">
                    <RenderJobStatus
                      status={status}
                      payment={payment}
                      isCompleted={isCompleted}
                    />
                  </div>
                </TableCellLinked>
                <TableCellLinked align="left" id={jobId}>
                  <p>{title}</p>
                  <small className="grey" style={{ fontSize: "12px" }}>
                    {address}
                  </small>
                </TableCellLinked>
                <TableCellLinked align="left" id={jobId}>
                  {hostName}
                </TableCellLinked>
                <TableCellLinked align="left" id={jobId}>
                  <div className="flex flex-wrap rc-gap--xs">
                    {isFlip && (
                      <Chip
                        className="ops-table__flag ops-table__flag--same-day "
                        size="small"
                        icon={
                          <LoopIcon className="ops-table__icon--same-day" />
                        }
                        label="Same-day"
                      />
                    )}
                    {isMarketplace && (
                      <Chip
                        label="Marketplace"
                        size="small"
                        className="ops-table__flag"
                      />
                    )}
                  </div>
                </TableCellLinked>
                {// If maid_id then render Pros name
                !isEmpty(assignments) ? (
                  <TableCellLinked align="left" id={jobId}>
                    <OpsProsGroup assignments={assignments} />
                  </TableCellLinked>
                ) : (
                  <TableCell>
                    <RenderActionBasedOnJobType />
                  </TableCell>
                )}
                <TableCell>
                  <LocalJobActions
                    props={{
                      jobId,
                      is_completed,
                      isPaymentRequestAvailable,
                      status,
                      job_time,
                      job_date,
                      proId,
                      description,
                      payment,
                      isMarketplace,
                    }}
                  />
                </TableCell>
              </TableRow>
            )
          );
        })}
      </TableBody>
    </Table>
  );

  const MobileLayout = () => {
    return items.map((item) => {
      const {
        id: jobId,
        job_date,
        job_time = "N/A",
        address,
        status,
        is_flip: isFlip = false,
        pro = {},
        property: { title },
        payment,
        assignments,
        is_completed,
        is_past,
        channel,
      } = item;

      const isMarketplace = channel === MARKETPLACE;

      const isCancelled = status === CLEANING_CANCELLED;
      const isStarted = status === CLEANING_STARTED;

      let prosInfo = "";
      const proId = pro ? pro.id : null;

      const handleJobAssign = () => {
        Analytics.trackEvent("assign_pro_action_from_jobs", { job_id: jobId });

        openThisDialog({
          dialogType: DT_CHANGE_A_PRO,
          jobId,
        });
      };

      return (
        <div
          key={`jobs-mobile-card-${jobId}`}
          className={`jobs-card ${isStarted && "jobs-card--started"}`}
        >
          <div onClick={(event) => handleJobClick(event, jobId)}>
            <div>
              <FormatJobDate date={job_date} />
              <span className="grey" style={{ fontWeight: 400 }}>
                <span style={{ marginRight: "4px", marginLeft: "4px" }}>
                  at
                </span>
                {getFormattedCheckInOutTime(job_time)}
              </span>
            </div>

            <p className="jobs-card__title" style={{ marginBottom: 4 }}>
              {title}
            </p>
            <div className="flex align-center">
              {isFlip && (
                <Chip
                  icon={<LoopIcon style={{ color: "white" }} />}
                  label="Same day flip"
                  size="small"
                  style={{
                    backgroundColor: "#ff2929",
                    color: "white",
                    marginRight: 4,
                  }}
                />
              )}
              {isMarketplace && (
                <Chip
                  color="primary"
                  variant="outlined"
                  label="Marketplace job"
                  size="small"
                  style={{ borderWidth: 1.5 }}
                />
              )}
            </div>
            <p className="jobs-card__location jobs-card__content--secondary jobs-card__location--mobile mb2 mt1">
              {address}
            </p>
          </div>
          {proId ? (
            <div
              className="jobs-card__pros-assigned"
              onClick={(event) => handleJobClick(event, jobId)}
            >
              <div className="pros-assigned__container">
                {isEmpty(assignments) ? (
                  <span className="italic grey small">No pro assigned</span>
                ) : (
                  <OpsProsGroup assignments={assignments} size="small" />
                )}
              </div>
              <div className="pros-assigned__container">
                <FormatJobStatus props={{ is_completed, payment, status }} />
              </div>
            </div>
          ) : is_completed ? (
            <div onClick={(event) => handleJobClick(event, jobId)}>
              No pro was assigned
            </div>
          ) : (
            <Button
              color="secondary"
              variant="outlined"
              disabled={Boolean(is_past)}
              onClick={() => handleJobAssign()}
            >
              {" "}
              Assign a pro
            </Button>
          )}
        </div>
      );
    });
  };

  const JobsEmptyState = () => {
    function clearFilters() {
      const _location = `${window.location.origin}${window.location.pathname}`;
      window.location = _location;
    }

    function clearCompletedJobsFilters() {}

    const searchPath = window.location.search || "";

    const handleInviteAHost = () => {
      openThisDialog({ dialogType: DT_INVITE_OPS_CLIENTS });
    };

    if (isAnyFilterActive()) {
      return (
        <div
          style={{ height: "calc(100% - 55px)" }}
          className="full-width full-height inline-flex justify-center align-center"
        >
          <div>
            <h1 className="header-3">No results found.</h1>
            <Button
              variant="outlined"
              color="primary"
              disableElevation={true}
              onClick={clearFilters}
            >
              Try Clearing filters
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{ height: "calc(100% - 55px)" }}
          className="full-width full-height inline-flex justify-center align-center"
        >
          <div className="text-center">
            <h1 className="header-3">No Jobs found</h1>
            {/* ONLY render CTA Request a Job in a ToDo Tab */}
            {!searchPath.includes("past") && (
              <Button
                variant="contained"
                color="primary"
                disableElevation={true}
                onClick={() => handleInviteAHost()}
              >
                Invite a host
              </Button>
            )}
          </div>
        </div>
      );
    }
  };

  return (
    <div className="page-wrapper">
      <div className="page-content">
        {!isMobile && (
          <div className="primary-actions mb2" key="host-jobs-primary-action">
            <h1 className="page-header">Jobs</h1>
            {/* NOT MVP
            <Button
              variant="contained"
              color="primary"
              className="host-primary"
              disableElevation={true}
              onClick={requestJob}
            >
              Request a job
            </Button> */}
          </div>
        )}

        <div
          className="table-wrapper table-wrapper--nav-title table-wrapper--no-padding"
          key="host-jobs-page-content"
        >
          <Tabs
            value={tabSelected}
            onChange={handleTabSelection}
            indicatorColor="primary"
            textColor="primary"
            className="page-content__jobs-tabs"
          >
            <Tab
              {...a11yProps(JOBS_TODO)}
              label="ToDo"
              className="page-content__tab--todo"
            />
            <Tab {...a11yProps(JOBS_COMPLETED)} label="Past" />
          </Tabs>
          {isLoading ? (
            <LoadingScreen />
          ) : !isEmpty(items) ? (
            <>
              <div key="host-jobs-filters" className="mt-4 mb2 filters">
                <Filter displaySelectedValue={true} filterType="ops" />
              </div>
              <ItemsCount
                onPage={items.length}
                totalItems={page.total}
                type="jobs"
                className="page__counter mb1"
                key="host-jobs-item-count"
              />
              {isMobile ? (
                <MobileLayout />
              ) : (
                <DesktopLayout key="host-desktop-jobs-layout" />
              )}
              {/* Pagination */}
              <Pagination
                nextPage={page.nextPage}
                prevPage={page.prevPage}
                type="hostJobs"
              />
              <div className="intercom-spacer" />
            </>
          ) : (
            <JobsEmptyState />
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    jobs: getJobs(state),
  }),
  (dispatch) =>
    bindActionCreators(
      { fetchOpsJobs, setDialogOpen, openThisDialog, updateJobStatus },
      dispatch
    )
)(JobsList);
