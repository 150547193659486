import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";

import Button from "@material-ui/core/Button";
import LaunchIcon from "@material-ui/icons/Launch";
import { Typography } from "@material-ui/core";

import { getPayoutBalance } from "../reducers/payments";

import { fetchPayoutBalance } from "../actions/payments";

import { formatMoney } from "../helpers/format";

const PayoutAmount = (props) => {
  const { account, balance, fetchPayoutBalance } = props;

  useEffect(() => {
    fetchPayoutBalance();
  }, []);

  const handleClick = () => {
    const url = balance.stripe_payout_url;
    window.open(url, "_blank");
  };

  if (isEmpty(account)) {
    return "";
  } else {
    return (
      <div className="card mt3">
        <Typography variant="h6">Payout balance</Typography>
        <div className="mt1">
          <Typography variant="caption">
            Your payouts are automatically processed and transferred to your
            account in approximately{" "}
            <span className="bold">2 business days.</span>
          </Typography>
        </div>
        <div className="manage-account--inline">
          <div>
            <div>
              <Typography variant="caption">
                Estimated earning this year
              </Typography>
            </div>
            <Typography variant="body1">
              {formatMoney(balance.total_income_earned_this_year)}
            </Typography>
          </div>
          <div>
            <div>
              <Typography variant="caption">Estimated future payout</Typography>
            </div>
            <Typography variant="body1">
              {formatMoney(balance.pending_amount)}
            </Typography>
          </div>
          <Button
            className="host-primary"
            color="primary"
            disableElevation
            onClick={() => handleClick()}
          >
            View payouts <LaunchIcon fontSize="small" />
          </Button>
        </div>
      </div>
    );
  }
};

export default connect(
  (state) => ({
    balance: getPayoutBalance(state),
  }),
  (dispatch) => bindActionCreators({ fetchPayoutBalance }, dispatch)
)(PayoutAmount);
