export function isAnyFilterActive() {
  const search = window.location.search;
  const filters = [
    "property_ids",
    "channels",
    "maid_ids",
    "cleaning_status",
    "payment_statuses",
    "note",
    "cleaning_status",
  ];

  if (search) {
    for (var i = 0; i <= filters.length; i++) {
      if (search.includes(filters[i])) {
        return true;
      }
    }
  } else {
    return false;
  }
}
