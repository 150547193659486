import React, { useEffect, useState, useRef } from "react";
import { isEmpty } from "lodash";
import amplitude from "amplitude-js";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";

import { createPropertyFromOnboardingFlow } from "../../actions/pmsProperties";
import PropertyDetailsFields from "../../containers/newPropertyDetailsFields";
import { parseQuery } from "../../helpers/url";
import history from "../../helpers/history";
import { Analytics } from "../../services/analyticsService.tsx";

const NewPropertyDetails = (props) => {
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [localValue, setLocalValue] = useState();
  const effectRan = useRef(true);

  const { opu } = parseQuery();

  const isOnboarding = opu === "onboarding";

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (
      !isEmpty(localValue.title) &&
      !isEmpty(localValue.street) &&
      !isEmpty(localValue.city) &&
      !isEmpty(localValue.state) &&
      !isEmpty(localValue.zip) &&
      !isEmpty(localValue.checkIn) &&
      !isEmpty(localValue.checkOut)
    ) {
      dispatch(
        createPropertyFromOnboardingFlow({
          title: localValue.title,
          city: localValue.city,
          state: localValue.state,
          street: localValue.street,
          zip: localValue.zip,
          check_in_time: localValue.checkIn,
          check_out_time: localValue.checkOut,
          bedrooms: localValue.bedrooms,
          beds: localValue.beds,
          bathrooms: localValue.bathrooms,
          amenities: localValue.amenities,
          total_guests: localValue.total_guests,
          parking_info: localValue.parking_info,
          washer: localValue.washer,
          dryer: localValue.dryer,
          lock_code: localValue.lock_code,
          access_instructions: localValue.access_instructions,
          gate_code: localValue.gate_code,
          gate_instructions: localValue.gate_instructions,
          maid_closet_code: localValue.maid_closet_code,
          maid_closet_info: localValue.maid_closet_info,
          trash_instructions: localValue.trash_instructions,
          wifi_instructions: localValue.wifi_instructions,
          cleaning_guide_url: localValue.cleaning_guide_url,
        })
      ).then((res) => {
        if (res.status === 200) {
          if (isOnboarding) {
            Analytics.trackSimple("onboarding_property_details");
          } else {
            Analytics.trackSimple("new_property_property_details");
          }
          if (res) {
            const { data } = res;
            if (data) {
              // Add property data to state so it can be retrieved if user refreshes the page.
              history.push({
                search: isOnboarding ? `opu=onboarding` : "",
                state: { ...data, nextStep: "calendar" },
              });
            }
          }
        }
      });
    }
  };

  const updateValue = (value) => {
    setLocalValue(value);
    if (
      !isEmpty(value.title) &&
      !isEmpty(value.street) &&
      value.beds > 0 &&
      value.bathrooms > 0 &&
      !isEmpty(value.city) &&
      !isEmpty(value.state) &&
      !isEmpty(value.zip) &&
      !isEmpty(value.checkIn) &&
      !isEmpty(value.checkOut)
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  const details = {};

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
      <div>
        <PropertyDetailsFields
          handleValue={updateValue}
          propertyValues={details}
        />
      </div>
      <Button
        variant="contained"
        disableElevation
        fullWidth
        color="primary"
        style={{ marginTop: "24px" }}
        size="large"
        onClick={() => handleSubmit()}
        disabled={isButtonDisabled}
      >
        Next: Import calendar
      </Button>
    </form>
  );
};

export default NewPropertyDetails;
