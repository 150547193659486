import React from "react";
import { Chip } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";

interface GateInstructionsProps {
  instructions: string | null;
  code: string | null;
}

const JobGateInstructions: React.FC<GateInstructionsProps> = ({
  instructions,
  code,
}) => {
  return (
    (instructions || code) && (
      <>
        <div className="mb-4">
          <div>
            <h3 className="txt-lg">
              <LockIcon style={{ fontSize: 20, opacity: 0.8 }} /> Gate entrance
            </h3>
            <div className="flex align-center space-between mt-2">
              {instructions && <p>{instructions}</p>}
              {code && <Chip label={code} />}
            </div>
          </div>
        </div>
        <hr />
      </>
    )
  );
};

export default JobGateInstructions;
