import { isEmpty, fromPairs } from "lodash";
import { useLocation } from "react-router-dom";
import URL from "url";

export const parseURL = (url) => {
  return URL.parse(typeof url !== "undefined" ? url : window.location);
};

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const relativeURL = (url) => {
  const { pathname, search, hash } = parseURL(url);

  return [pathname, search, hash].join("");
};

export const sanitizeQueryParams = (obj, isString = false) => {
  return obj ? obj.split(",").map((x) => (isString ? x : parseInt(x))) : [];
};

export const sanitizeUrl = (url) => {
  // Find the index of the "?" character which starts the query parameters
  const queryStartIndex = url.indexOf("?");

  // If there is no "?", return the original URL as there are no query parameters
  if (queryStartIndex === -1) return url;

  // Otherwise, return the substring of the URL before the "?"
  return url.substring(0, queryStartIndex);
};

export const parseURLQuery = (queryString) => {
  var query = {};
  var pairs = (queryString[0] === "?"
    ? queryString.substr(1)
    : queryString
  ).split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
};

export const getUserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const parseQuery = () => {
  let query = "";

  query = window.location.search;

  if (query.charAt(0) === "?") {
    query = query.substr(1);
  }

  if (!isEmpty(query)) {
    return fromPairs(
      (query || parseURL().query || "")
        .split("&")
        .filter((p) => p)
        .map((p) =>
          (([k, v = ""]) => [k, v].map(decodeURIComponent))(p.split("="))
        )
    );
  } else {
    return query;
  }
};

export const filterParams = (params) => {
  const origin = window.location.origin;
  const pathName = window.location.pathname;

  let location = "";

  const {
    property_ids,
    categories,
    maid_ids,
    channels,
    note,
    pros_categories,
    type,
    request_payment,
    payment_statuses,
    cleaning_status,
  } = parseQuery();
  const {
    propertyIdsParam,
    prosCategories,
    paymentStatuses,
    maidIdsParam,
    channelsParam,
    noteParam,
    requestPayment,
    typeParam,
    paymentReminderHack,
    cleaningStatus,
  } = params;

  // default

  if (propertyIdsParam) {
    // Reset property
    if (isEmpty(propertyIdsParam)) {
      location += "";
    } else {
      location += `&property_ids=${propertyIdsParam}`;
    }
  } else {
    location += `${property_ids ? `&property_ids=${property_ids}` : ""}`;
  }

  if (maidIdsParam) {
    if (isEmpty(maidIdsParam)) {
      location += "";
    } else {
      location += `&maid_ids=${maidIdsParam}`;
    }
  } else {
    location += `${maid_ids ? `&maid_ids=${maid_ids}` : ""}`;
  }

  if (channelsParam) {
    if (isEmpty(channelsParam)) {
      location += "";
    } else {
      location += `&channels=${channelsParam}`;
    }
  } else {
    location += `${channels ? `&channels=${channels}` : ""}`;
  }

  if (cleaningStatus) {
    if (isEmpty(cleaningStatus)) {
      location += "";
    } else {
      location += `&cleaning_status=${cleaningStatus}`;
    }
  } else {
    location += `${
      cleaning_status ? `&cleaning_status=${cleaning_status}` : ""
    }`;
  }

  if (!isEmpty(noteParam)) {
    if (noteParam === "false") {
      location += "";
    } else {
      location += `&note=${noteParam}`;
    }
  } else {
    location += `${note ? `&note=${note}` : ""}`;
  }

  if (paymentReminderHack) {
    location = "";
  }

  if (typeParam) {
    if (isEmpty(typeParam)) {
      location += "";
    } else {
      location += `&type=${typeParam}`;
    }
  } else {
    location += `${type ? `&type=${type}` : ""}`;
  }

  if (paymentStatuses) {
    if (isEmpty(paymentStatuses)) {
      location += "";
    } else {
      location += `&payment_statuses=${paymentStatuses}`;
    }
  } else {
    location += `${
      payment_statuses ? `&payment_statuses=${payment_statuses}` : ""
    }`;
  }

  if (requestPayment) {
    if (requestPayment === "false") {
      location += ``;
    } else {
      location += `&request_payment=${requestPayment}`;
    }
  } else {
    location += `${
      request_payment ? `&request_payment=${request_payment}` : ""
    }`;
  }

  if (prosCategories) {
    if (isEmpty(prosCategories)) {
      location += "";
    } else {
      location += `&categories=${prosCategories}`;
    }
  } else {
    location += `${pros_categories ? `&categories=${pros_categories}` : ""}`;
  }

  return location;
};

export const updateProfileTallyURLConstructor = (params) => {
  const UPDATE_PROFILE_TALLY_URL = "https://tally.so/r/";
  let userQueryPrams,
    tallyQueryPrams = "";

  const {
    id: proId = null,
    business_name = null,
    first = null,
    last = null,
    team_size = null,
    years_of_experience = null,
    phone = null,
    bio = null,
    distance_in_miles = null,
    accepts_same_day_flip = false,
    rate_sheet = {},
    zip = null,
    email = null,
    username = null,

    tallyId = null,
    dynamicHeight = true,
    hideTitle = true,
    transparentBackground = true,
    alignLeft = true,
  } = params;

  if (proId) {
    userQueryPrams += `&pro_id=${proId}`;
  }

  if (business_name) {
    userQueryPrams += `&business=${business_name}`;
  }

  // Cannot be null
  if (accepts_same_day_flip == true || accepts_same_day_flip == false) {
    userQueryPrams += `&accepts_same_day_flip=${
      accepts_same_day_flip ? "Yes" : "No"
    }`;
  }

  if (email) {
    userQueryPrams += `&email_h=${email}`;
  }

  if (username) {
    userQueryPrams += `&username_h=${username}`;
  }

  if (rate_sheet) {
    const {
      base_cleaning = null,
      bathrooms = null,
      bbq_grill = null,
      bedrooms = null,
      beds = null,
      firepit = null,
      fireplace = null,
      offsite_laundry = null,
      patio = null,
      pet_cleaning = null,
      wash_dishes = null,
    } = rate_sheet;

    if (base_cleaning) {
      userQueryPrams += `&base_cleaning=${base_cleaning}`;
    }

    if (bathrooms) {
      userQueryPrams += `&bathrooms=${bathrooms}`;
    }
    if (bbq_grill) {
      userQueryPrams += `&bbq_grill=${bbq_grill}`;
    }
    if (bedrooms) {
      userQueryPrams += `&bedrooms=${bedrooms}`;
    }
    if (beds) {
      userQueryPrams += `&beds=${beds}`;
    }
    if (firepit) {
      userQueryPrams += `&firepit=${firepit}`;
    }
    if (fireplace) {
      userQueryPrams += `&fireplace=${fireplace}`;
    }
    if (offsite_laundry) {
      userQueryPrams += `&offsite_laundry=${offsite_laundry}`;
    }
    if (patio) {
      userQueryPrams += `&patio=${patio}`;
    }
    if (pet_cleaning) {
      userQueryPrams += `&pet_cleaning=${pet_cleaning}`;
    }
    if (wash_dishes) {
      userQueryPrams += `&wash_dishes=${wash_dishes}`;
    }
  }

  if (first) {
    userQueryPrams += `&first=${first}`;
  }

  if (last) {
    userQueryPrams += `&last=${last}`;
  }

  if (team_size) {
    userQueryPrams += `&team_size=${team_size}`;
  }

  if (years_of_experience) {
    userQueryPrams += `&experience=${years_of_experience}`;
  }

  if (phone) {
    userQueryPrams += `&phone=${phone}`;
  }

  if (zip) {
    userQueryPrams += `&zip=${zip}`;
  }

  if (bio) {
    userQueryPrams += `&bio=${bio}`;
  }

  if (distance_in_miles) {
    userQueryPrams += `&distance_in_miles=${distance_in_miles}`;
  }

  if (dynamicHeight) {
    tallyQueryPrams += "&dynamicHeight=1";
  }

  if (hideTitle) {
    tallyQueryPrams += "&hideTitle=1";
  }

  if (transparentBackground) {
    tallyQueryPrams += "&transparentBackground=1";
  }

  if (alignLeft) {
    tallyQueryPrams += "&alignLeft=1";
  }

  return (
    UPDATE_PROFILE_TALLY_URL + tallyId + "?" + tallyQueryPrams + userQueryPrams
  );
};

export const turncateString = (string, chrCount) => {
  return string.length >= chrCount ? string.slice(0, chrCount) + "..." : string;
};
