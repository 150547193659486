import axios from "../helpers/axios";

// NOT sure what it does
import { serverError } from "../helpers/helper_serverError";
import { showSnackbar } from "./action_snackbar";
import { setContentLoading } from "./loading";
import { closeThisDialog, setDialogClose } from "./dialog";

import {
  DIALOG_TYPE_REQUEST_CLEANING_PAYMENT,
  DIALOG_TYPE_MAKE_CLEANING_PAYMENT,
  DIALOG_TYPE_CANCEL_PAYMENT_PROMPT,
} from "../constants/dialogTypes";
import {
  fetchHousekeeperCleaning,
  fetchCleanings,
} from "./housekeeperCleaings";
import { parseURLQuery } from "../helpers/url";
import { fetchReservation, fetchReservations } from "./pmsReservations";
import { message } from "antd";
import {
  fetchHostJobs,
  fetchJobDetails,
  fetchProsJobDetails,
  fetchProsJobs,
} from "./jobs";

export const SET_PAYMENT_REMINDER = "set_payment_reminder";
export const SET_PAYMENT_METHOD = "set_payment_method";
export const SET_PAYMENT = "set_payment";
export const SET_PAYOUT_ACCOUNT = "set_payout_acount";
export const SET_PAYOUT_BALANCE = "set_payment_amount";
export const SET_CLEANING_PAYMENT_INVOICES = "set_payment_cleaning_invoice";

const CONNECT_STRIP_FIRST = "Please connect your Stripe Account first.";

export const fetchPayoutAccount = () => {
  return (dispatch) => {
    return axios
      .get("/v1/stripe_connect/payout_account")
      .then((res) => {
        if (res && res.status === 200) {
          dispatch(setPayoutAccount(res.data));
        }
        dispatch(setContentLoading(false));
      })
      .catch((error) => {
        dispatch(setContentLoading(false));
      });
  };
};

export const fetchPayoutBalance = () => {
  return (dispatch) => {
    return axios
      .get(`/v1/stripe_connect/payout_balance`)
      .then((res) => {
        if (res && res.status === 200) {
          dispatch(setPayoutBalance(res.data));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          const {
            data: { message },
          } = error.response;
          if (message !== CONNECT_STRIP_FIRST) {
            dispatch(serverError(error));
          }
        }
      });
  };
};

export const cancelPayment = (id) => {
  return (dispatch) => {
    return axios
      .post(`/v1/payments/${id}/cancel_payment`)
      .then((res) => {
        if (res && res.status === 200) {
          const { data } = res;
          dispatch(setDialogClose(DIALOG_TYPE_CANCEL_PAYMENT_PROMPT));
          if (data && data.cleaning) {
            dispatch(fetchHousekeeperCleaning(data.cleaning.id));
          }
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
};

export const fetchPaymentReminders = () => {
  return (dispatch) => {
    return axios
      .get("/v1/users/dashboard")
      .then((res) => {
        const { status, data } = res;
        if (status === 200) {
          dispatch(setPaymentReminders(data));
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
};

export const fetchPayment = (id) => {
  return (dispatch) => {
    return axios
      .get(`/v1/payments/${id}`)
      .then((res) => {
        const { status, data } = res;
        if (status === 200) {
          dispatch(setPayment(data));
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
};

export const fetchPaymentMethod = () => {
  return (dispatch) => {
    return axios
      .get(`/v1/payments/payment_methods`)
      .then((res) => {
        const { status, data } = res;
        if (status === 200) {
          dispatch(setPaymentMethod(data));
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
};

export const makePayment = (id) => {
  return (dispatch) => {
    return axios
      .get(`/v1/payments/${id}/confirm`)
      .then((res) => {
        const { status, data } = res;
        if (status === 200) {
          dispatch(
            closeThisDialog({ dialogType: DIALOG_TYPE_MAKE_CLEANING_PAYMENT })
          );
          if (data && data.cleaning) {
            dispatch(fetchJobDetails(data.cleaning.id));
            dispatch(fetchHostJobs());
          }
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
};

export const fetchCleaningPaymentInvoices = () => {
  return (dispatch) => {
    return axios
      .get(`/v1/payments`)
      .then((res) => {
        if (res && res.status === 200) {
          dispatch(setCleaningPaymentInvoices(res.data));
          dispatch(setContentLoading(false));
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
        dispatch(setContentLoading(false));
      });
  };
};

export const requestCleaningPayment = ({
  id,
  cleaningFee,
  additionalFee,
  comment,
}) => {
  // Passing 0 so it accounts to some value
  if (!additionalFee) {
    additionalFee = 0;
  }

  if (!cleaningFee) {
    cleaningFee = 0;
  }
  return (dispatch) => {
    return axios
      .post(
        `/v1/payments/request_payment?cleaning_id=${id}&cleaning_fee=${cleaningFee}&additional_fee=${additionalFee}&cleaning_fee_note=${comment}`
      )
      .then((res) => {
        if (res && res.status === 200) {
          const { data } = res;
          dispatch(setDialogClose(DIALOG_TYPE_REQUEST_CLEANING_PAYMENT));
          if (data && data.cleaning.id) {
            // dispatch(fetchHousekeeperCleaning(data.cleaning.id));
            dispatch(fetchProsJobDetails(data.cleaning.id));
            dispatch(fetchProsJobs());
            dispatch(fetchPaymentReminders());
          }
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
};

export const stripeConnectUrl = () => {
  return (dispatch) => {
    return axios
      .get(`/v1/stripe_connect/connect_url`, {
        params: {
          redirect_uri: `${window.location.host}/manage-account/payout`,
        },
      })
      .then((res) => {
        if (res && res.status === 200) {
          const url = res.data.url;
          const state = parseURLQuery(url).state;
          localStorage.setItem("abStat3Stp06202020", state);
          window.location = url;
        }
      })
      .catch((error) => {
        dispatch(serverError(error));
      });
  };
};

export const stripeAuthenticate = ({ code, state }) => {
  return (dispatch) => {
    if (state === localStorage.abStat3Stp06202020) {
      return axios
        .get(`/v1/stripe_connect/authenticate?code=${code}`)
        .then((res) => {
          if (res && res.status === 200) {
            dispatch(fetchPayoutAccount());
            dispatch(fetchPayoutBalance());
          }
        })
        .catch((error) => {
          dispatch(serverError(error));
        });
    } else {
      dispatch(serverError({}, "Unable to connect with stripe. Try again."));
      return false;
    }
  };
};

export const setPaymentReminders = (data) => ({
  type: SET_PAYMENT_REMINDER,
  data,
});

export const setPayment = (data) => ({
  type: SET_PAYMENT,
  data,
});

export const setPayoutAccount = (data) => ({
  type: SET_PAYOUT_ACCOUNT,
  data,
});

export const setPayoutBalance = (data) => ({
  type: SET_PAYOUT_BALANCE,
  data,
});

export const setCleaningPaymentInvoices = (data) => ({
  type: SET_CLEANING_PAYMENT_INVOICES,
  data,
});

export const setPaymentMethod = (data) => ({
  type: SET_PAYMENT_METHOD,
  data,
});
