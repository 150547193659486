import axios from "../helpers/axios";
import { closeThisDialog } from "./dialog";

import { fetchProperty } from "./pmsProperties";

import {
  DT_PROMPT_UNASSIGN_PRO_FROM_PROPERTY,
  DT_ASSIGN_PRO_TO_PROPERTY,
} from "../constants/dialogTypes";
import { serverError } from "../helpers/helper_serverError";

export function assignProToProperty({ propertyId, proId, assignExistingJobs }) {
  return (dispatch) => {
    return axios
      .put(
        `/v1/properties/${propertyId}/update_assign_rule?assign_maid_rule=ASSIGN_MAID_AUTO&maid_id=${proId}&assign_existing_jobs=${assignExistingJobs}`
      )
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          if (data && data.id) {
            dispatch(
              closeThisDialog({ dialogType: DT_ASSIGN_PRO_TO_PROPERTY })
            );
            dispatch(fetchProperty(data.id));
          }
        }
      })
      .catch((err) => {
        dispatch(serverError(err));
      });
  };
}

export function unassignProFromProperty(propertyId) {
  return (dispatch) => {
    return axios
      .put(
        `/v1/properties/${propertyId}/update_assign_rule?assign_maid_rule=ASSIGN_MAID_AUTO&maid_id=""`
      )
      .then((res) => {
        if (res && res.status === 200) {
          closeThisDialog({ dialogType: DT_PROMPT_UNASSIGN_PRO_FROM_PROPERTY });
          if (res.data && res.data.id) {
            dispatch(fetchProperty(res.data.id));
          }
        }
      })
      .catch((err) => {
        dispatch(serverError(err));
      });
  };
}
