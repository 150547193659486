export const SET_DIALOG_OPEN = "open_dialog";
export const SET_DIALOG_CLOSE = "close_dialog";
export const CLOSE_DIALOG_ACTION = "close_dialog_action";
export const OPEN_DIALOG_ACTION = "open_dialog_action";

export const setDialogOpen = (dialogType, id, object) => ({
  type: SET_DIALOG_OPEN,
  dialogType,
  id,
  object,
});
export const setDialogClose = (dialogType) => ({
  type: SET_DIALOG_CLOSE,
  dialogType,
});

export const openThisDialog = (object) => {
  return { type: OPEN_DIALOG_ACTION, object };
};

export const closeThisDialog = () => {
  return { type: CLOSE_DIALOG_ACTION };
};
