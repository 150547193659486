import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import ImportCalendarTextField from "../../components/importCalendarTextField";
import { calendarURL } from "../../constants/validate";
import {
  linkPropertyCalendar,
  fetchProperty,
} from "../../actions/pmsProperties";
import { parseQuery } from "../../helpers/url";
import { serverError } from "../../helpers/helper_serverError";
import { CircularProgress } from "@material-ui/core";
import { isEmpty } from "lodash";

const ImportCalendarDialogBody = (props) => {
  const { id, closeDialog } = props;
  const [isValidated, setUrlValidation] = useState(true);
  const [value, setValue] = useState();
  const [isLoading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const updateValue = (value) => {
    if (calendarURL(value)) {
      setUrlValidation(true);
    } else {
      setUrlValidation(false);
    }

    setValue(value);
  };

  const handleDialogClose = (type) => {
    if (type === "cancel") {
      closeDialog();
    } else if (type === "add") {
      setLoading(true);
      if (value && calendarURL(value)) {
        dispatch(linkPropertyCalendar({ property_id: id, url: value }))
          .then((res) => {
            if (res.status === 200) {
              dispatch(fetchProperty(id));
              closeDialog();
            }
          })
          .catch((err) => {
            dispatch(serverError(err));
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  return (
    <>
      <DialogTitle
        id="scroll-dialog-title"
        className="assign-housekeeper__title"
      >
        Add a calendar
      </DialogTitle>
      <DialogContent dividers={true}>
        <ImportCalendarTextField value={updateValue} isValid={isValidated} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleDialogClose("cancel")}
          variant="contained"
          disableElevation
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (!isLoading) {
              handleDialogClose("add");
            }
          }}
          variant="contained"
          color="primary"
          disableElevation
          disabled={isEmpty(value) || !isValidated || isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : "Save"}
        </Button>
      </DialogActions>
    </>
  );
};

export default ImportCalendarDialogBody;
